
import { defineComponent } from "vue";
import Dropdown from "primevue/dropdown";

export default defineComponent({
  components: { Dropdown },
  methods: {
    updateLocale() {
      localStorage.setItem("locale", this.$i18n.locale);
      this.$moment.locale(this.$i18n.locale);
    },
  },
});
