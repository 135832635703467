import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "wp1200 withPadding normalHeight" }
const _hoisted_2 = { class: "mainTitle" }
const _hoisted_3 = { class: "wasteManagement" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "ellipsis" }
const _hoisted_7 = {
  key: 0,
  class: "wasteStatus"
}
const _hoisted_8 = {
  class: "map",
  style: {"height":"500px"}
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "statistics_title" }
const _hoisted_11 = { class: "chartAccordionWrapper" }
const _hoisted_12 = { class: "content" }
const _hoisted_13 = { class: "wasteChart" }
const _hoisted_14 = { class: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_MapCustom = _resolveComponent("MapCustom")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_SidebarWasteWrapper = _resolveComponent("SidebarWasteWrapper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("waste.waste_management")), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_SidebarWasteWrapper, {
        sidebarLeft: true,
        sidebarActive: _ctx.showSidebar,
        mapComponent: _ctx.mapComponent
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: "showSidebar icon-warpit_icon_filter left",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSidebar = !_ctx.showSidebar))
          }),
          (Object.keys(_ctx.station).length !== 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("small", null, _toDisplayString(_ctx.$t("waste.station_location")), 1),
                  _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.station.address), 1),
                  (_ctx.$store.getters['user/isLoggedIn'])
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: _normalizeClass([
              _ctx.station.favourite
                ? 'icon-warpit_icon_lokacija-priljubljena-full'
                : 'icon-warpit_icon_lokacija-priljubljena'
            , "favourite_station"]),
                        style: {"cursor":"pointer"},
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setFavoriteStation(_ctx.station))),
                        onKeypress: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.setFavoriteStation(_ctx.station)), ["enter"])),
                        tabindex: "0"
                      }, null, 34))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.isSmartStation)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.station.trashcans, (trashcan, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          (trashcan.smart === 1)
                            ? (_openBlock(), _createElementBlock("li", {
                                key: 0,
                                class: _normalizeClass(trashcan.waste_type)
                              }, [
                                _createElementVNode("span", {
                                  class: _normalizeClass([_ctx.getTrashcanValue(trashcan.waste_type, 'icon'), "icon"])
                                }, null, 2),
                                _createVNode(_component_ProgressBar, {
                                  value: trashcan.waste_level
                                }, null, 8, ["value"])
                              ], 2))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_MapCustom, {
                    markers: [_ctx.marker],
                    center: _ctx.marker.position
                  }, null, 8, ["markers", "center"])
                ]),
                (_ctx.isSmartStation)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t("waste.waste_statistics")), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.smartStationTrashcans, (trashcan, index) => {
                        return (_openBlock(), _createBlock(_component_Accordion, {
                          key: index,
                          onClick: ($event: any) => (_ctx.loadGraph(index, trashcan.id)),
                          onTabOpen: ($event: any) => (_ctx.loadGraph(index, trashcan.id))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AccordionTab, null, {
                              header: _withCtx(() => [
                                _createElementVNode("div", _hoisted_11, [
                                  _createElementVNode("span", {
                                    class: _normalizeClass([[
                      _ctx.getTrashcanValue(trashcan.waste_type, 'icon'),
                      _ctx.getTrashcanValue(trashcan.waste_type, 'name'),
                    ], "icon customIcon"])
                                  }, null, 2),
                                  _createElementVNode("h3", {
                                    class: _normalizeClass(_ctx.getTrashcanValue(trashcan.waste_type, 'name'))
                                  }, _toDisplayString(trashcan.title), 3)
                                ])
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_12, [
                                  _createElementVNode("div", _hoisted_13, [
                                    _createElementVNode("div", _hoisted_14, [
                                      (_ctx.trashcanCharts[index])
                                        ? (_openBlock(), _createBlock(_component_highcharts, {
                                            key: 0,
                                            options: _ctx.trashcanCharts[index]
                                          }, null, 8, ["options"]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ])
                                ])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "onTabOpen"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["sidebarActive", "mapComponent"])
    ])
  ], 64))
}