import { ActionContext } from "vuex";
import { User } from "@/typings/User";
import $http from "axios";
import jwt_decode from "jwt-decode";
import { setUserId } from "firebase/analytics";
import { useFirebase } from "@/plugins/firebase";

interface DecodedAccessToken {
  smartcity: {
    uuid: string;
  };
}

interface State {
  tokens: Tokens | null;
  user: User | null;
  notifications: Notifications | null;
}

interface Tokens {
  access_token: string;
  refresh_token: string;
}

interface Notifications {
  notifications: number;
  messages: number;
}

interface Credentials {
  username: string;
  password: string;
}

const state = (): State => ({
  tokens: null,
  user: null,
  notifications: null,
});

const getters = {
  tokens: (state: State): Tokens | null => {
    return state.tokens;
  },
  notifications: (state: State): Notifications | null => {
    return state.notifications;
  },
  isLoggedIn: (state: State): boolean => {
    return state.tokens !== null;
  },
  user: (state: State): User | null => {
    return state.user;
  },
  verificationLevel: (state: State): number => {
    if (state.user !== null) {
      return state.user.verification_level;
    }
    return 0;
  },
  userImage(state: State): string {
    if (state.user !== null && state.user.profile_image_url !== null) {
      return state.user.profile_image_url;
    }
    return require("@/assets/images/userIcon.svg");
  },
  analyticsInfo(state: State) {
    return {
      user_type: state.user ? "registered" : "guest",
      user_id: state.user?.id,
      verification_level: state.user?.verification_level,
    };
  },
};

const mutations = {
  setTokens(state: State, tokens: Tokens | null): void {
    state.tokens = tokens;

    if (tokens === null) {
      localStorage.removeItem("tokens");
      return;
    }

    localStorage.setItem(
      "tokens",
      JSON.stringify({
        access_token: tokens.access_token,
        refresh_token: tokens.refresh_token,
      }),
    );
  },
  setUser(state: State, user: User): void {
    state.user = user;
  },
  setNotifications(state: State, notifications: Notifications): void {
    state.notifications = notifications;
  },
};

const actions = {
  login(
    context: ActionContext<State, State>,
    credentials: Credentials,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      $http
        .post("api/v1/auth/login", credentials)
        .then((response) => {
          if (response.data.verify) {
            return reject(response.data);
          }
          context.commit("setTokens", {
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token,
          });

          context
            .dispatch("userinfo")
            .then(() => {
              resolve();
            })
            .catch((error) => {
              context.dispatch("logout");
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  authorizationFlowLogin(
    context: ActionContext<State, State>,
    code: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      $http
        .post("api/v1/auth/authorization-code-login", { code })
        .then((response) => {
          context.commit("setTokens", {
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token,
          });

          context
            .dispatch("userinfo")
            .then(() => {
              resolve();
            })
            .catch((error) => {
              context.dispatch("logout");
              reject(error);
            });
        });
    });
  },
  refresh(context: ActionContext<State, State>): Promise<void> {
    return new Promise((resolve, reject) => {
      $http
        .post("api/v1/auth/refresh", {
          refresh_token: context.getters["tokens"].refresh_token,
        })
        .then((response) => {
          context.commit("setTokens", {
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token,
          });
          resolve();
        })
        .catch((error) => {
          context.commit("setTokens", {
            access_token: null,
            refresh_token: null,
          });
          reject(error);
        });
    });
  },
  userinfo(context: ActionContext<State, State>): Promise<void> {
    return new Promise((resolve, reject) => {
      const decodedAccessToken = jwt_decode(
        context.getters["tokens"].access_token,
      ) as DecodedAccessToken;

      const firebaseInstance = useFirebase();
      if (firebaseInstance !== null) {
        setUserId(
          firebaseInstance.analytics(),
          decodedAccessToken.smartcity.uuid,
        );
      }

      $http
        .get("api/v1/user/" + decodedAccessToken.smartcity.uuid)
        .then((response) => {
          context.commit("setUser", response.data);
          context
            .dispatch("notifications")
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  notifications(context: ActionContext<State, State>): Promise<void> {
    return new Promise((resolve, reject) => {
      $http
        .get("api/v1/dashboard/notifications")
        .then((response) => {
          context.commit("setNotifications", response.data);
          resolve();
        })
        .catch((error) => {
          context.commit("setNotifications", null);
          reject(error);
        });
    });
  },
  logout(context: ActionContext<State, State>): Promise<void> {
    return new Promise((resolve) => {
      $http
        .post("api/v1/auth/logout", {
          refresh_token: context.getters["tokens"].refresh_token,
        })
        .finally(() => {
          context.commit("setTokens", null);
          context.commit("setUser", null);

          localStorage.removeItem("tokens");

          const firebaseInstance = useFirebase();
          if (firebaseInstance !== null) {
            setUserId(firebaseInstance.analytics(), "guest_user");
          }
          resolve();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
