
import { defineComponent, PropType } from "vue";
import { Reward } from "@/typings/Reward";

type Locale = "en" | "sl";

export default defineComponent({
  data: () => ({
    loyaltyPoints: 0,
  }),
  props: {
    reward: {
      type: Object as PropType<Reward>,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    name(): string {
      const currentLocale = this.$i18n.locale as Locale;
      return this.reward.name[currentLocale] ?? this.reward.name["sl"];
    },
  },
});
