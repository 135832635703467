
import { defineComponent, markRaw } from "vue";
import MapCustom from "@/components/MapCustom.vue";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import SidebarStations from "@/components/publicTransport/SidebarStations.vue";
import { Stop } from "@/typings/Stop";
import { Marker } from "@/typings/Marker";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  name: "Stations",
  components: { SidebarWrapper, MapCustom },
  data: () => ({
    sidebarComponent: markRaw(SidebarStations),
    station: null as Stop | null,
  }),
  mounted() {
    this.loadStation();
  },
  methods: {
    calculateArrivalTime(arrival_time: string) {
      const date_difference = this.$moment(arrival_time, "HH:mm:ss").diff(
        this.$moment(new Date(), "HH:mm:ss"),
      );
      const duration = this.$moment.duration(date_difference);
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes() as number;
      return { hours, minutes };
    },
    loadStation() {
      if ("id" in this.$route.params === false) {
        return;
      }
      this.$http
        .get("api/v1/public-transport/stops/" + this.$route.params.id)
        .then((response) => {
          this.station = response.data;
        });
    },
    setFavoriteStation(station: Stop | null) {
      if (station === null) return;
      if (station.favourite === true) {
        firebaseInstance?.analytics().logEvent("public_transport_screen", {
          page: "public_transport_screen",
          category: "public_transport_list",
          sub_category: "remove_from_favorites",
          sub_category_desc: "Uporabnik je odstranil iz priljubljenih",
          category_desc: "Zaslon javnega prevoza odprt",
          station_id: station.id,
          ...this.$store.getters["user/analyticsInfo"],
        });
        let params = {
          external_identifier: station.id,
          city_id: 1,
        };
        this.$http.get("api/v1/locations", { params }).then((response) => {
          if (response.data.length === 0) {
            return;
          }
          let locationId = response.data[0].id;
          this.$http.delete("api/v1/locations/" + locationId).then(() => {
            station.favourite = false;
          });
        });
        return;
      }
      firebaseInstance?.analytics().logEvent("public_transport_screen", {
        page: "public_transport_screen",
        category: "public_transport_list",
        sub_category: "add_to_favorites",
        sub_category_desc: "Uporabnik je dodal med priljubljene",
        category_desc: "Zaslon javnega prevoza odprt",
        station_id: station.id,
        ...this.$store.getters["user/analyticsInfo"],
      });
      this.$http
        .post("api/v1/locations", {
          type_id: 2,
          city_id: 1,
          external_identifier: station.id,
        })
        .then(() => {
          station.favourite = true;
        });
    },
  },
  computed: {
    marker(): Marker | null {
      if (
        this.station === null ||
        this.station.latitude === null ||
        this.station.longitude === null
      ) {
        return null;
      }
      return {
        id: this.station.id,
        position: {
          lat: parseFloat(this.station.latitude),
          lng: parseFloat(this.station.longitude),
        },
        on_click_route_name: null,
        type: "station",
        title: this.station.name,
      };
    },
  },
  watch: {
    "$route.params.id"() {
      this.loadStation();
    },
  },
});
