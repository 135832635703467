import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "top0" }
const _hoisted_2 = {
  key: 0,
  class: "left transport c-mt-6"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex where" }
const _hoisted_6 = {
  key: 0,
  class: "stationList"
}
const _hoisted_7 = { class: "stationNumber" }
const _hoisted_8 = { class: "stationTime flex" }
const _hoisted_9 = { class: "arrival_time" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "map"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapCustom = _resolveComponent("MapCustom")!
  const _component_SidebarWrapper = _resolveComponent("SidebarWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SidebarWrapper, {
      sidebarComponent: _ctx.sidebarComponent,
      sidebarLeft: true
    }, {
      default: _withCtx(() => [
        (_ctx.station)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h2", _hoisted_3, [
                _createTextVNode(_toDisplayString(_ctx.station.name) + " ", 1),
                (_ctx.$store.getters['user/isLoggedIn'])
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass(
              _ctx.station.favourite
                ? 'icon-warpit_icon_lokacija-priljubljena-full'
                : 'icon-warpit_icon_lokacija-priljubljena'
            ),
                      style: {"cursor":"pointer"},
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setFavoriteStation(_ctx.station)))
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.station.arrivals.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("stations.subtitle")), 1),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.station.code), 1)
                    ]),
                    (_ctx.station.arrivals)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.station.arrivals, (route) => {
                            return (_openBlock(), _createElementBlock("li", {
                              class: "stationName",
                              key: route
                            }, [
                              _createElementVNode("span", _hoisted_7, _toDisplayString(route.route_name), 1),
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$moment(route.arrival_time, "HH:mm:ss").format("HH:mm")), 1),
                                _createElementVNode("span", _hoisted_9, [
                                  (_ctx.calculateArrivalTime(route.arrival_time).hours > 0)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.calculateArrivalTime(route.arrival_time).hours) + " h ", 1))
                                    : _createCommentVNode("", true),
                                  _createTextVNode(_toDisplayString(_ctx.calculateArrivalTime(route.arrival_time).minutes) + " min", 1)
                                ])
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.marker !== null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_MapCustom, {
                      center: _ctx.marker.position,
                      markers: [_ctx.marker]
                    }, null, 8, ["center", "markers"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["sidebarComponent"])
  ]))
}