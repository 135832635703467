
import { defineComponent } from "vue";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import SidebarReward from "@/components/rewards/SidebarReward.vue";
import InfinityScroll from "@/components/InfinityScroll.vue";
import RewardCard from "@/components/rewards/RewardCard.vue";
import { Reward } from "@/typings/Reward";

export default defineComponent({
  data: () => ({
    sidebarComponent: SidebarReward,
    rewards: [],
  }),
  mounted() {
    this.loadRewards();
  },
  methods: {
    loadRewards() {
      this.$http.get("api/v1/loyalty/rewards").then((response) => {
        this.rewards = response.data.data;
      });
    },
  },
  computed: {
    filterRewards() {
      if (
        "type" in this.$route.query === false ||
        this.$route.query.type === null
      ) {
        return this.rewards;
      }
      return this.rewards.filter(
        (item: Reward) => item.category.name === this.$route.query.type
      );
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: {
    RewardCard,
    SidebarWrapper,
    InfinityScroll,
  },
});
