
import { Provider } from "@/typings/Provider";
export default {
  props: {
    provider: {
      type: Object as () => Provider,
      required: true,
    },
  },
};
