
import { defineComponent } from "vue";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    googleMapsUrl:
      "https://goo.gl/maps/" + process.env.VUE_APP_GMAPS_LOCATION_ID,
  }),
  mounted() {
    firebaseInstance?.analytics().logEvent("contact_view", {
      page: "contact_view",
      category: "contact_view",
      category_desc: "Tell City zaslon za kontaktne informacije odprt",
      ...this.$store.getters["user/analyticsInfo"],
    });
  },
});
