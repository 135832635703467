import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wp1200" }
const _hoisted_2 = { class: "hidden sm:flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Filters, {
        filters: _ctx.filters,
        "selected-filter": _ctx.selectedFilter,
        onSelectFilter: _ctx.selectFilter
      }, null, 8, ["filters", "selected-filter", "onSelectFilter"])
    ]),
    (_ctx.categories)
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: 0,
          filters: _ctx.filters,
          providers: _ctx.providers,
          onSelectFilter: _ctx.selectFilter
        }, null, 8, ["filters", "providers", "onSelectFilter"]))
      : _createCommentVNode("", true)
  ]))
}