
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

export default defineComponent({
  data: () => ({
    code: "",
    error: "",
  }),
  methods: {
    sendCode() {
      if (this.code === "") {
        this.error = "Vnesite aktivacijsko kodo";
        return;
      }
      this.$emit("submit", this.code);
    },
  },
  components: {
    InputText,
    Button,
  },
});
