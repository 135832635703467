import { directus } from "@/plugins/directus";
import { ActionContext } from "vuex";
import { InfoFeature } from "@/typings/Info";

interface State {
  features: InfoFeature[] | null;
}

async function getFeatures() {
  return directus.get(`features`).then((response) => {
    return response.data.data.filter(
      (feature: InfoFeature) => feature.enabled,
    ) as InfoFeature[];
  });
}

const state = (): State => ({
  features: null,
});

const getters = {
  features: (state: State): InfoFeature[] | null => {
    return state.features;
  },
};

const mutations = {
  getFeatures(state: State, features: InfoFeature[]): void {
    state.features = features;
  },
};

const actions = {
  async getFeatures(context: ActionContext<State, State>) {
    const data = await getFeatures();
    context.commit("getFeatures", data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
