
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    tabs: [
      {
        id: 1,
        key: "all-applications",
        title: "forms.new_form.tabs.all_applications",
        route: "MyApplications",
      },
      {
        id: 2,
        key: "id-2",
        title: "forms.new_form.tabs.construction_of_real_estate",
        route: "MyApplications",
      },
    ],
    activeTab: {
      id: 1,
      key: "all-applications",
      title: "forms.new_form.tabs.all_applications",
    },
  }),
  created() {
    this.$emit("callback", this.activeTab);
  },
});
