
import Search from "@/components/Search.vue";
import { defineComponent } from "vue";
import { QueryParameters } from "@/typings/QueryParameters";
import InfinityScroll from "@/components/InfinityScroll.vue";
import { Paginated } from "@/typings/Paginated";
import { Trip } from "@/typings/Trip";

export default defineComponent({
  components: { Search, InfinityScroll },
  data: () => ({
    trips: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Trip>,
    searchTerm: "",
    isLoading: false,
  }),
  mounted() {
    this.loadTrips();
  },
  methods: {
    loadTrips(page = 1) {
      let params: QueryParameters = {
        page,
        perPage: 20,
      };
      this.isLoading = true;
      if (this.searchTerm) {
        params.filters = {
          short_name: encodeURIComponent("%" + this.searchTerm + "%"),
        };
      }
      this.$http
        .get("api/v1/public-transport/trips", { params })
        .then((response) => {
          if (page !== 1) {
            this.trips.data = this.trips.data.concat(response.data.data);
            this.trips.current_page = response.data.current_page;
            return;
          }
          this.trips = response.data;
          if (
            Object.prototype.hasOwnProperty.call(this.$route.params, "id") ===
              true ||
            this.trips.data.length === 0 ||
            this.$route.name !== "Lines"
          ) {
            return;
          }

          let firstTrip: { id: number } = this.trips.data[0];
          this.$router.push({
            name: "LinesById",
            params: { id: firstTrip.id },
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getHeadSignPart(headsign: string | null, index: number): string {
      if (headsign === null || headsign.length === 0) {
        return "";
      }

      let parts = headsign.split("-");
      if (parts.length > index) {
        return parts[index].trim();
      }
      return "";
    },
  },
});
