
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
export default defineComponent({
  data: () => ({
    showDialog: true,
  }),
  components: {
    Button,
    Dialog,
  },
});
