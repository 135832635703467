import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-column gap-3" }
const _hoisted_2 = { class: "flex gap-2" }
const _hoisted_3 = { class: "flex gap-1 p-2 border-1 border-300 border-round-xl w-fit h-fit text-base" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "flex flex-column text-base gap-2" }
const _hoisted_6 = { class: "text-primary font-semibold" }
const _hoisted_7 = { class: "flex gap-1" }
const _hoisted_8 = { class: "text-600" }
const _hoisted_9 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconWalk = _resolveComponent("IconWalk")!
  const _component_IconBike = _resolveComponent("IconBike")!
  const _component_IconCar = _resolveComponent("IconCar")!
  const _component_IconChevronRight = _resolveComponent("IconChevronRight")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex w-full gap-3 justify-content-between cursor-pointer",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRouteSelection(_ctx.route)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.route.legs, (leg, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex gap-1 align-items-center"
          }, [
            _createElementVNode("div", _hoisted_3, [
              (leg.mode === 'WALK')
                ? (_openBlock(), _createBlock(_component_IconWalk, { key: 0 }))
                : _createCommentVNode("", true),
              (leg.mode === 'BICYCLE')
                ? (_openBlock(), _createBlock(_component_IconBike, { key: 1 }))
                : _createCommentVNode("", true),
              (leg.mode === 'CAR')
                ? (_openBlock(), _createBlock(_component_IconCar, { key: 2 }))
                : _createCommentVNode("", true)
            ]),
            (index !== _ctx.route.legs.length - 1)
              ? (_openBlock(), _createBlock(_component_IconChevronRight, {
                  key: 0,
                  class: "text-sm text-600"
                }))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("span", null, [
        _createTextVNode("do "),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.route.legs[0].steps[_ctx.route.legs[0].steps.length - 1].streetName), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getDuration(_ctx.route)), 1),
      _createElementVNode("span", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("route_panner.arrival")), 1),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.getArrivalTime(_ctx.route)), 1)
      ])
    ])
  ]))
}