
import { CurrentWeatherModel } from "@/models/weather/Weather";
import { CurrentWeather, Weather } from "@/typings/Weather";
import { PropType, defineComponent } from "vue";
import Divider from "primevue/divider";

export default defineComponent({
  props: {
    payload: {
      type: Object as PropType<{
        currently: CurrentWeather;
        daily: {
          data: Array<Weather>;
        };
      }>,
      required: true,
    },
  },
  data: () => ({
    currentWeather: null as null | CurrentWeatherModel,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    forecastData() {
      return this.payload.daily.data.slice(0, 3);
    },
  },
  mounted() {
    this.currentWeather = new CurrentWeatherModel(this.payload.currently);
  },
  components: { Divider },
});
