import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/googleplay.svg'
import _imports_1 from '@/assets/images/appstore.svg'


const _hoisted_1 = { class: "getApp" }
const _hoisted_2 = { class: "wp1200" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "links" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["alt"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("get_app.title")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("get_app.content")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", {
          href: $options.googlePlayUrl,
          target: "_blank",
          class: "google"
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: _ctx.$t('accessibility.app.google')
          }, null, 8, _hoisted_6)
        ], 8, _hoisted_5),
        _createElementVNode("a", {
          href: $options.appStoreUrl,
          target: "_blank",
          class: "apple"
        }, [
          _createElementVNode("img", {
            src: _imports_1,
            alt: _ctx.$t('accessibility.app.apple')
          }, null, 8, _hoisted_8)
        ], 8, _hoisted_7)
      ])
    ])
  ]))
}