
import { PropType, defineComponent } from "vue";
import NewsGrid from "@/components/news/NewsGrid.vue";
import Paginator from "primevue/paginator";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import ForYou from "@/components/news/ForYou.vue";
import { Filter, NewsProvider } from "@/typings/News";
import { Paginated } from "@/typings/Paginated";
import { Article } from "@/typings/Article";
import { QueryParameters } from "@/typings/QueryParameters";

export default defineComponent({
  props: {
    filters: {
      type: Object as PropType<Array<Filter>>,
      required: true,
    },
    providers: {
      type: Object as PropType<Array<NewsProvider>>,
      required: true,
    },
  },
  data: () => ({
    selectedFilter: null as null | Filter,
    selectedProviders: null as null | Array<NewsProvider>,
    news: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Article>,
  }),
  methods: {
    loadNews(page = 1) {
      if (this.selectedFilter?.id === "for-you") {
        return;
      }

      let params: QueryParameters & { page: number; withPopularity?: boolean } =
        {
          page: Number(this.$route.query.page ?? 1),
          perPage: 13,
          filters: {
            category_id:
              typeof this.selectedFilter?.id === "number"
                ? this.selectedFilter?.id
                : undefined,
            provider_id: this.selectedProviders ?? undefined,
          },
          orderBy: {
            created_at: "DESC",
          },
          withPopularity: this.selectedFilter?.id === "current" || undefined,
        };

      if (this.selectedFilter?.id === "current") {
        params.orderBy = {
          popularity: "DESC",
        };
      }

      this.$http.get("api/v1/news", { params }).then((response) => {
        if (page !== 1) {
          this.news.data = this.news.data.concat(response.data.data);
          this.news.current_page = response.data.current_page;
          return;
        }
        this.news = response.data;
      });
    },
    updatePage(page: number) {
      if (Number(this.$route.query.page) === page + 1) {
        return;
      }

      this.$router.push({
        name: "News",
        query: { ...this.$route.query, page: page + 1 },
      });
    },
    useQueryFilter() {
      let filter: Filter | undefined;

      if (this.$route.query.view) {
        filter = this.filters.find((f) => f.id === this.$route.query.view);
      } else if (this.$route.query.category_id) {
        filter = this.filters.find(
          (f) => f.id === Number(this.$route.query.category_id),
        );
      }

      if (filter === undefined) {
        this.selectedFilter = this.filters[0];
        return;
      }

      this.selectedFilter = filter;
    },
    resetNews() {
      this.news = {
        data: [],
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      };
    },
    updateSavedValue(article: Article, value: boolean) {
      const found = this.news.data.find((a) => a.id === article.id);

      if (!found) {
        return;
      }

      found.saved = value;
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        if (Object.keys(this.$route.query).length === 0) {
          return;
        }

        this.useQueryFilter();
        this.loadNews();
      },
    },
  },
  components: {
    NewsGrid,
    Paginator,
    MultiSelect,
    Dropdown,
    ForYou,
  },
});
