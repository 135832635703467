import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-between align-items-center w-full mb-5" }
const _hoisted_2 = { class: "font-semibold text-xl" }
const _hoisted_3 = { class: "flex flex-column pt-2 h-full" }
const _hoisted_4 = { class: "flex flex-column" }
const _hoisted_5 = { class: "text-primary" }
const _hoisted_6 = { class: "text-base" }
const _hoisted_7 = { class: "text-sm mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createElementBlock("div", {
    tabindex: "1",
    class: "flex flex-column bg-white border-round-3xl border-1 border-300 p-4 w-20rem sm:w-25rem h-28rem cursor-pointer",
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'TrafficInfoMap' }))),
    onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$router.push({ name: 'TrafficInfoMap' })), ["enter"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("widgets.traffic.title")), 1),
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: "icon-warpit_icon_settings text-2xl",
            tabindex: "1",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('edit')), ["stop"])),
            onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('edit')), ["stop"]), ["enter"]))
          }, null, 32))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payload, (feature, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex flex-column border-round-2xl"
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("traffic_info.type." + feature.sub_category)), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.truncateDescription(feature.description, 50)), 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$moment(feature.updated_at).fromNow()), 1),
            (index < _ctx.payload.length - 1)
              ? (_openBlock(), _createBlock(_component_Divider, { key: 0 }))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ], 32))
}