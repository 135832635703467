
import Search from "@/components/Search.vue";
import { QueryParameters } from "@/typings/QueryParameters";
import { defineComponent } from "vue";
import { Stop } from "@/typings/Stop";
import InfinityScroll from "@/components/InfinityScroll.vue";
import { Paginated } from "@/typings/Paginated";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  components: { InfinityScroll, Search },
  data: () => ({
    stops: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Stop>,
    searchTerm: "",
    isLoading: false,
  }),
  mounted() {
    this.loadStops();
  },
  methods: {
    modifyRoutes(routes: string[]) {
      if (routes.length > 6) {
        routes.splice(5, routes.length, "...");
        return routes;
      }
      return routes;
    },
    loadStops(page = 1) {
      let url = "api/v1/public-transport/stops";
      let params: QueryParameters & {
        latitude: number;
        longitude: number;
        radius: number;
      } = {
        orderBy: {
          name: "ASC",
        },
        page,
        perPage: 20,
        latitude: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        longitude: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
        radius: 5000,
      };
      if (this.searchTerm) {
        firebaseInstance?.analytics().logEvent("public_transport_screen", {
          page: "public_transport_screen",
          category: "public_transport_list",
          sub_category: "search_bus_station",
          category_desc: "Zaslon javnega prevoza odprt",
          sub_category_desc: "Uporabnik je iskal avtobus javnega prevoza",
          search_query: this.searchTerm,
          ...this.$store.getters["user/analyticsInfo"],
        });
        params.filters = {
          name: encodeURIComponent("%" + this.searchTerm + "%"),
        };
      }
      this.isLoading = true;
      this.$http
        .get(url, { params })
        .then((response) => {
          firebaseInstance?.analytics().logEvent("public_transport_screen", {
            page: "public_transport_screen",
            category: "public_transport_list",
            sub_category: "select_bus_station",
            category_desc: "Zaslon javnega prevoza odprt",
            sub_category_desc: "Uporabnik je izbral avtobus javnega prevoza",
            ...this.$store.getters["user/analyticsInfo"],
          });
          if (page !== 1) {
            this.stops.data = this.stops.data.concat(response.data.data);
            this.stops.current_page = response.data.current_page;
            return;
          }
          this.stops = response.data;
          firebaseInstance?.analytics().logEvent("public_transport_screen", {
            page: "public_transport_screen",
            category: "public_transport_list",
            sub_category: "view_arrival_page",
            category_desc: "Zaslon javnega prevoza odprt",
            sub_category_desc: "Uporabnik je ogledal zaslon postaj",
            ...this.$store.getters["user/analyticsInfo"],
          });
          if (
            Object.prototype.hasOwnProperty.call(this.$route.params, "id") ===
              true ||
            this.stops.data.length === 0 ||
            this.$route.name !== "Station"
          ) {
            return;
          }

          let firstStop: { id: number } = this.stops.data[0];
          this.$router.push({
            name: "StationById",
            params: { id: firstStop.id },
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
