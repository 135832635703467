import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import router from "@/plugins/router";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_GOOGLE_ANALYTICS_API_KEY,
  authDomain: process.env.VUE_APP_GOOGLE_ANALYTICS_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_GOOGLE_ANALYTICS_PROJECT_ID,
  storageBucket: process.env.VUE_APP_GOOGLE_ANALYTICS_STORAGE_BUCKET,
  messagingSenderId: process.env.GOOGLE_ANALYTICS_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_GOOGLE_ANALYTICS_APP_ID,
  measurementId: process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
};

export let firebaseInstance: firebase.app.App | null = null;

export function useFirebase(): firebase.app.App | null {
  if (process.env.VUE_APP_GOOGLE_ANALYTICS_ENABLE !== "true") {
    return null;
  }

  if (firebaseInstance !== null) {
    return firebaseInstance;
  }

  firebaseInstance = firebase.initializeApp(firebaseConfig);

  router.beforeEach(async (to, from, next) => {
    if (firebaseInstance === null) {
      return;
    }
    const title = to.meta.title;

    if (routeToCategoryMap[title as string]) {
      firebaseInstance.analytics().logEvent("page_title", {
        app_name: "web",
        screen_name: title,
        category: routeToCategoryMap[title as string]["category"],
        categoryDesc: routeToCategoryMap[title as string]["categoryDesc"],
      });
    }
    next();
  });

  return firebaseInstance;
}

export const routeToCategoryMap: Record<
  string,
  { category: string; categoryDesc: string }
> = {
  home: { category: "home_screen", categoryDesc: "Domači zaslon odprt" },
  login: { category: "login_screen", categoryDesc: "Prijavni zaslon odprt" },
  forgot_password: {
    category: "forgotten_password_screen",
    categoryDesc: "Zaslon za pozabljeno geslo odprt",
  },
  register: {
    category: "register_flow_screen",
    categoryDesc: "Zaslon registracijskega toka odprt",
  },
  search: { category: "search_screen", categoryDesc: "Zaslon iskanja odprt" },
  feed: { category: "feed", categoryDesc: "Feed odsek odprt" },
  my_city: { category: "my_city", categoryDesc: "Moje mesto odprto" },
  events: { category: "events", categoryDesc: "Zaslon dogodkov odprt" },
  news: { category: "news", categoryDesc: "Zaslon novic odprt" },
  weather: { category: "weather", categoryDesc: "Zaslon vremena odprt" },
  air_quality: {
    category: "air_quality",
    categoryDesc: "Zaslon kakovosti zraka odprt",
  },
  tell_city: { category: "tell_city", categoryDesc: "Tell City zaslon odprt" },
  feedback: {
    category: "feedback",
    categoryDesc: "Zaslon povratnih informacij odprt",
  },
  parking: { category: "parking", categoryDesc: "Zaslon parkiranja odprt" },
  bikes: { category: "bikes", categoryDesc: "Zaslon koles odprt" },
  info: { category: "info", categoryDesc: "Informacijski zaslon odprt" },
  traffic_info: {
    category: "traffic_info",
    categoryDesc: "Zaslon prometnih informacij odprt",
  },
  shared_mobility: {
    category: "shared_mobility",
    categoryDesc: "Zaslon deljene mobilnosti odprt",
  },
  public_transport: {
    category: "public_transport_screen",
    categoryDesc: "Zaslon javnega prevoza odprt",
  },
  ecoIsland: {
    category: "public_waste_screen",
    categoryDesc: "Zaslon javnih odpadkov odprt",
  },
  about_us: { category: "about_screen", categoryDesc: "Zaslon o nas odprt" },
  privacy_policy: {
    category: "privacy_policy_screen",
    categoryDesc: "Zaslon politike zasebnosti odprt",
  },
  terms_and_conditions: {
    category: "terms_and_conditions_screen",
    categoryDesc: "Zaslon pogojev in določil odprt",
  },
  declaration_conformity: {
    category: "declaration_conformity_screen",
    categoryDesc: "Zaslon izjave o skladnosti odprt",
  },
  survey: { category: "survey_screen", categoryDesc: "Zaslon ankete odprt" },
  my_payment: {
    category: "my_payment_screen",
    categoryDesc: "Zaslon mojih plačil odprt",
  },
  my_super_city: {
    category: "my_super_city",
    categoryDesc: "Zaslon mojega super mesta odprt",
  },
  my_locations: {
    category: "my_locations_screen",
    categoryDesc: "Zaslon mojih lokacij odprt",
  },
  my_points: {
    category: "my_points_screen",
    categoryDesc: "Zaslon mojih točk odprt",
  },
  my_points_overview: {
    category: "my_points_overview",
    categoryDesc: "Pregled mojih točk odprt",
  },
  my_points_transfer: {
    category: "my_points_transfer",
    categoryDesc: "Prenos mojih točk odprt",
  },
  my_vehicles: {
    category: "my_vehicles_screen",
    categoryDesc: "Zaslon mojih vozil odprt",
  },
  my_waste: {
    category: "my_waste_screen",
    categoryDesc: "Zaslon mojih odpadkov odprt",
  },
  privateWaste: {
    category: "private_waste_screen",
    categoryDesc: "Zaslon zasebnih odpadkov odprt",
  },
  privateWaste_timeline: {
    category: "privateWaste_timeline_screen",
    categoryDesc: "Zaslon časovnice zasebnih odpadkov odprt",
  },
  privateWaste_takeaway: {
    category: "privateWaste_takeaway_screen",
    categoryDesc: "Zaslon za prevzem zasebnih odpadkov odprt",
  },
  related_accounts: {
    category: "related_accounts_screen",
    categoryDesc: "Povezani računi odprti",
  },
  my_profile: {
    category: "user_profile_screen",
    categoryDesc: "Zaslon uporabniškega profila odprt",
  },
  my_consents: {
    category: "consents_screen",
    categoryDesc: "Zaslon soglasij odprt",
  },
  active_surveys: {
    category: "survey_screen",
    categoryDesc: "Zaslon ankete odprt",
  },
  completed_surveys: {
    category: "survey_screen",
    categoryDesc: "Zaslon ankete odprt",
  },
  support: {
    category: "technical_support_screen",
    categoryDesc: "Zaslon tehnične podpore odprt",
  },
  cookies: {
    category: "cookies_screen",
    categoryDesc: "Zaslon piškotkov odprt",
  },
  phone_verification: {
    category: "verify_account_phone_screen",
    categoryDesc: "Zaslon za preverjanje računa po telefonu odprt",
  },
  email_verification: {
    category: "verify_account_mail_screen",
    categoryDesc: "Zaslon za preverjanje računa po pošti odprt",
  },
  "delete profile": {
    category: "confirm_delete_user_screen",
    categoryDesc: "Zaslon za potrditev izbrisa uporabnika odprt",
  },
  change_password: {
    category: "change_password_screen",
    categoryDesc: "Zaslon za spremembo gesla odprt",
  },
  edit_profile: {
    category: "user_profile_edit_screen",
    categoryDesc: "Zaslon za urejanje uporabniškega profila odprt",
  },
  add_vehicles: {
    category: "my_vehicles_add_new",
    categoryDesc: "Uporabnik je dodal novo vozilo",
  },
  public_survey: {
    category: "survey_screen",
    categoryDesc: "Zaslon ankete odprt",
  },
  survey_id: { category: "survey_screen", categoryDesc: "Zaslon ankete odprt" },
};
