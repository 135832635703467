
import { defineComponent } from "vue";
import CalendarIcon from "@/components/customComponents/CalendarIcon.vue";
import { FeedElement } from "@/typings/FeedElement";

export default defineComponent({
  props: {
    item: {
      type: Object as () => FeedElement,
    },
  },
  components: { CalendarIcon },
});
