import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "wrapperMessage" }
const _hoisted_2 = { class: "userIcons" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "messageText" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.message)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["message", { reply: _ctx.user.id !== _ctx.message.user_id }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: _ctx.getUserImage(),
              class: "user",
              alt: _ctx.$t('accessibility.message.user')
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.message.value)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: _normalizeClass(["messageImage", _ctx.message.type.name === 'location' ? 'cursor-pointer' : '']),
                    src: _ctx.message.value,
                    alt: _ctx.$t('accessibility.message.image'),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.message.type.name === 'location'
                ? _ctx.openGoogleMaps(_ctx.message.text)
                : null
            ))
                  }, null, 10, _hoisted_6))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.message.text), 1)
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}