
import { defineComponent } from "vue";
import SidebarApplicationMenu from "@/components/eApplication/SidebarApplicationMenu.vue";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import NewApplicationBox from "@/components/eApplication/NewApplicationBox.vue";

export default defineComponent({
  data: () => ({
    sidebarComponent: SidebarApplicationMenu,
    title: "",
    forms: [],
    activeTab: {
      title: "forms.new_form.tabs.all_applications ",
      key: "all-applications",
    },
  }),
  mounted() {
    this.loadApplications();
  },

  methods: {
    loadApplications() {
      this.$http.get("api/v1/forms").then((response) => {
        this.forms = response.data;
      });
    },
  },
  computed: {
    filter() {
      if (this.activeTab.key === "all-applications" || !this.activeTab)
        return this.forms;
      return this.forms;
    },
  },
  components: {
    SidebarWrapper,
    NewApplicationBox,
  },
});
