import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteLocationRaw,
} from "vue-router";
import routes from "@/routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeResolve(async (to: RouteLocationNormalized) => {
  if (!to.meta.middleware) {
    return true;
  }
  const middleware: Array<() => RouteLocationRaw | void> = Array.isArray(
    to.meta.middleware
  )
    ? to.meta.middleware
    : [to.meta.middleware];

  for (let index = 0; index < middleware.length; index++) {
    const result = middleware[index]();
    if (result !== undefined) {
      return result;
    }
  }
});

router.afterEach((to) => {
  document.body.setAttribute("tabindex", "-1");
  document.body.focus();
  document.body.removeAttribute("tabindex");
  if (!to.meta.disableScrollToTop) {
    window.scrollTo(0, 0);
  }
});

export default router;
