
import { defineComponent } from "vue";
import SidebarUserWrapper from "@/components/SidebarUserWrapper.vue";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";
import Button from "primevue/button";
import { Vehicle } from "@/typings/Vehicle";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    vehicleToDelete: null as Vehicle | null,
    sidebarComponent: SidebarUserMenu,
    vehicles: [] as Vehicle[],
  }),

  mounted() {
    firebaseInstance?.analytics().logEvent("my_vehicles", {
      page: "my_vehicles",
      category: "my_vehicles_screen",
      category_desc: "Zaslon mojih vozil odprt",
      sub_category: "open_my_vehicles",
      sub_category_desc: "Uporabnik je odprl moja vozila",
      ...this.$store.getters["user/analyticsInfo"],
    });
    this.loadVehicles();
  },

  methods: {
    onAddVehicles() {
      firebaseInstance?.analytics().logEvent("my_vehicles", {
        page: "my_vehicles",
        category: "my_vehicles_screen",
        category_desc: "Zaslon mojih vozil odprt",
        sub_category: "my_vehicles_add_new",
        sub_category_desc: "Uporabnik je dodal novo vozilo",
        ...this.$store.getters["user/analyticsInfo"],
      });
      this.$router.push({ name: "AddVehicles" });
    },
    loadVehicles() {
      this.$http.get("api/v1/license-plates").then((response) => {
        this.vehicles = response.data;
      });
    },
    deleteVehicle(vehicleToDelete: Vehicle | null) {
      if (vehicleToDelete === null) {
        return;
      }
      this.$http
        .delete("api/v1/license-plates/" + vehicleToDelete.id)
        .then(() => {
          let index = this.vehicles
            .map((vehicle) => vehicle.id)
            .indexOf(vehicleToDelete.id);
          this.vehicles.splice(index, 1);
          this.vehicleToDelete = null;
        });
    },
  },
  components: {
    SidebarUserWrapper,
    Button,
  },
});
