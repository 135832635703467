
import { defineComponent } from "vue";
import Paginator from "primevue/paginator";
import { Paginated } from "@/typings/Paginated";
import { Event } from "@/typings/Event";
import EventsList from "./EventsList.vue";
import { QueryParameters } from "@/typings/QueryParameters";

export default defineComponent({
  props: {
    from: {
      type: String,
      default: null,
    },
    until: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    page: 1,
    events: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Event>,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    loadSavedEvents(page = 1) {
      let params: QueryParameters & {
        page: number;
        datetime?: [string, string];
        saved: boolean;
      } = {
        page: this.page,
        perPage: 10,
        orderBy: {
          datetime: "ASC",
        },
        saved: true,
      };

      if (this.from !== null && this.until !== null) {
        params.datetime = [this.from, this.until];
      }

      this.$http.get("api/v1/events", { params }).then((response) => {
        if (page !== 1) {
          this.events.data = this.events.data.concat(response.data.data);
          this.events.current_page = response.data.current_page;
          return;
        }
        this.events = response.data;
      });
    },
    updatePage(page: number) {
      if (this.page === page + 1) {
        return;
      }

      this.page = page + 1;
      this.loadSavedEvents();
    },
    formatDate(date: string) {
      const eventDate = this.$moment(date);
      const currentTime = this.$moment();

      // Check if the event was published less than 24 hours ago
      if (currentTime.diff(eventDate, "hours") < 24) {
        return eventDate.fromNow();
      }

      return eventDate.format("DD. MM. yyyy");
    },
  },
  watch: {
    page() {
      this.$router.push({
        name: "Events",
        query: {
          view: "for-you",
          tab: 2,
          page: this.page,
          from: this.from ?? undefined,
          until: this.until ?? undefined,
        },
      });
    },
    from() {
      this.loadSavedEvents();
    },
    until() {
      this.loadSavedEvents();
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page);
    }

    this.loadSavedEvents();
  },
  components: { Paginator, EventsList },
});
