
import { Invoice } from "@/typings/Invoice";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    invoice: {
      type: Object as () => Invoice | null,
    },
  },
  methods: {
    payment() {
      this.$emit("submit");
    },
  },
});
