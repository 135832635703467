import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "fillDialogTitle" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-column"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "fillDialogButtons" }
const _hoisted_6 = { class: "flex flex-column" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.show,
    class: "successDialog",
    tabindex: "1",
    closable: false,
    draggable: false,
    modal: "",
    onHide: _cache[7] || (_cache[7] = 
      () => {
        _ctx.reset();
        _ctx.$emit('hide');
      }
    )
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("widgets.settings")), 1),
      (_ctx.selectedIsConfigurable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("widgets.choose")), 1),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedItem,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event)),
              options: _ctx.options,
              "option-value": _ctx.widgets[_ctx.widget.type].configuration?.value,
              "option-label": _ctx.getOptionLabel,
              tabindex: "1"
            }, {
              empty: _withCtx(() => [
                (_ctx.widget.type === 'waste-collection')
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: { name: 'PrivateWasteTimeline' }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("widgets.waste-collection.empty_options")), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("widgets.empty_options")), 1))
              ]),
              _: 1
            }, 8, ["modelValue", "options", "option-value", "option-label"])
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("widgets.not_configurable")), 1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.selectedIsConfigurable)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.saveChanges && _ctx.saveChanges(...args))),
                onKeypress: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.saveChanges && _ctx.saveChanges(...args)), ["enter"])),
                tabindex: "1",
                class: "defaultButton",
                disabled: _ctx.selectedIsConfigurable && _ctx.selectedItem === null
              }, _toDisplayString(_ctx.$t("global.save_changes")), 41, _hoisted_7))
            : _createCommentVNode("", true),
          _withDirectives((_openBlock(), _createElementBlock("button", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.deleteWidget && _ctx.deleteWidget(...args))),
            onKeypress: _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.deleteWidget && _ctx.deleteWidget(...args)), ["enter"])),
            tabindex: "1",
            class: _normalizeClass(["defaultButton", {
            whiteBG: _ctx.currentWidgets.length > 1,
          }]),
            disabled: _ctx.currentWidgets.length === 1
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t("widgets.delete")), 1)
          ], 42, _hoisted_8)), [
            [
              _directive_tooltip,
              
            _ctx.currentWidgets.length === 1 ? _ctx.$t('widgets.required') : null
          ,
              void 0,
              { top: true }
            ]
          ])
        ]),
        _createElementVNode("span", {
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('hide'))),
          tabindex: "1",
          onKeypress: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.$emit('hide')), ["enter"]))
        }, _toDisplayString(_ctx.$t("global.cancel")), 33)
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}