
import { defineComponent } from "vue";
import ProgressSpinner from "primevue/progressspinner";
export default defineComponent({
  name: "Loader",
  components: {
    ProgressSpinner,
  },
  computed: {
    activeRequests(): number {
      return this.$store.getters["loader/activeRequests"];
    },
  },
  methods: {
    preventCtrlZoom(event: WheelEvent) {
      if (this.activeRequests > 0 && event.ctrlKey) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
  },
  mounted() {
    window.addEventListener("wheel", this.preventCtrlZoom, { passive: false });
  },
  beforeUnmount() {
    window.removeEventListener("wheel", this.preventCtrlZoom);
  },
});
