<template>
  <div class="wp1200 p-0">
    <div class="homeTabs" tabindex="-1">
      <div class="flex">
        <router-link class="w-full text-center" :to="{ name: 'BikesList' }">{{
          $t("bikes.all")
        }}</router-link>
        <router-link
          class="w-full text-center"
          :to="{ name: 'BikesFavourite' }"
          >{{ $t("bikes.favourite") }}</router-link
        >
      </div>
    </div>

    <router-view />
  </div>
</template>

<style scoped lang="scss">
@media only screen and (min-width: 450px) {
  .homeTabs {
    width: 22rem;
    padding-top: 20px;
  }
}
</style>
