
import { defineComponent } from "vue";
import MainImage from "@/components/home/MainImage.vue";
import MyCity from "@/views/home/MyCity.vue";
import GetApp from "@/components/home/GetApp.vue";
import WidgetBar from "@/components/home/hero/WidgetBar.vue";

export default defineComponent({
  components: {
    GetApp,
    MainImage,
    MyCity,
    WidgetBar,
  },
  data: () => ({
    dashboard: {},
  }),
  mounted() {
    this.loadDashboardInfo();
  },
  methods: {
    loadDashboardInfo() {
      let cityId = process.env.VUE_APP_CITY_ID;
      this.$http
        .get("api/v1/dashboard", {
          params: {
            city_id: cityId,
            withEvents: true,
            withNews: true,
          },
        })
        .then((response) => {
          this.dashboard = response.data;
        });
    },
  },
});
