<template>
  <div class="boxMessage">
    <div class="flex shortDescription">
      <div>
        <span class="bold">{{ $t("home_air_quality.aqi") }}</span>
        <span
          >{{ $t("air_quality.measuring_point") }} 786776, Bravničarjeva
          13</span
        >
        <span>1000 Ljubjana</span>
      </div>
      <a class="icon" href="#"><i class="icon-warpit_icon_dot_menu"></i></a>
    </div>
    <div class="content flex">
      <i class="icon-warpit_icon_Home"></i>
      <div class="col1_3"></div>
      <div class="col2_3">
        <span class="bold">{{ $t("air_quality.normal") }}</span>
        <span>{{ $t("air_quality.desription") }}</span>
      </div>
    </div>
    <span class="when">{{ $t("air_quality.three_days_ago") }}</span>
  </div>
</template>

<script>
export default {
  name: "AirQuality",
};
</script>

<style scoped></style>
