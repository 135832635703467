
import { defineComponent } from "vue";
import { FeedElement } from "@/typings/FeedElement";

export default defineComponent({
  props: {
    item: {
      type: Object as () => FeedElement,
    },
  },
});
