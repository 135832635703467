import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tabsContent" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = { class: "imageBorder" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "data" }
const _hoisted_6 = { class: "status" }
const _hoisted_7 = { class: "date" }
const _hoisted_8 = { class: "price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_InfinityScroll = _resolveComponent("InfinityScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Search, {
      modelValue: _ctx.searchTerm,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadHistory()))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_InfinityScroll, {
      onLoadNextPage: _ctx.loadHistory,
      data: _ctx.invoices,
      class: "browser-infinity-scroll userAccordion",
      "dont-use-mobile-handler": true
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoices.data, (invoice) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "payment",
            key: invoice.id
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (invoice.provider.image_url !== null)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: invoice.provider.image_url,
                      alt: _ctx.$t('payment.history')
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t("payments.paid")) + " " + _toDisplayString(_ctx.$moment.utc(invoice.updated_at).format("DD.MM.YYYY")), 1),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.getStatusColor(invoice.status))
                }, _toDisplayString(_ctx.getStatus(invoice.status)), 3)
              ]),
              _createElementVNode("h3", null, [
                _createElementVNode("strong", null, _toDisplayString(invoice.user_code?.custom_name || invoice.provider.name), 1)
              ]),
              _createElementVNode("h3", null, _toDisplayString(invoice.provider.name), 1),
              _createElementVNode("span", _hoisted_7, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t("payments.number")) + " " + _toDisplayString(invoice.document_id), 1),
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t("payments.due_date")) + ": " + _toDisplayString(_ctx.$moment.utc(invoice.due_date).format("DD.MM.YYYY")), 1)
              ]),
              _createElementVNode("span", _hoisted_8, _toDisplayString((invoice.amount / 10000).toLocaleString(_ctx.$i18n.locale, {
                maximumFractionDigits: 2,
              })) + " " + _toDisplayString(invoice.currency), 1)
            ])
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["onLoadNextPage", "data"])
  ]))
}