
import { PropType, defineComponent } from "vue";
import { Parking } from "@/typings/Parking";

export default defineComponent({
  props: {
    selectedType: {
      type: [String, null] as PropType<string | null>,
      required: true,
    },
    parking: {
      type: Object as () => Parking,
      required: true,
    },
  },

  computed: {
    description() {
      return this.parking.smart === 1
        ? this.$t("parking.card.available_smart")
        : this.$t("parking.card.available");
    },
  },
  methods: {
    checkAvailability(parking: Parking) {
      if (this.selectedType === null) {
        return false;
      }

      const isSmartParking = parking.smart === 1;
      const type = `spaces_${this.selectedType}${
        isSmartParking ? "_available" : ""
      }`;

      return parking[type as keyof Parking] !== null;
    },

    availableParkingsOfType(parking: Parking) {
      if (this.selectedType === null) {
        return null;
      }

      const isSmartParking = parking.smart === 1;
      const type = `spaces_${this.selectedType}${
        isSmartParking ? "_available" : ""
      }`;

      return parking[type as keyof Parking];
    },
  },
});
