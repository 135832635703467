import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sLayout transport" }
const _hoisted_2 = { class: "sLayout__header" }
const _hoisted_3 = { class: "sLayout__body" }
const _hoisted_4 = {
  key: 0,
  style: {"text-align":"center"}
}
const _hoisted_5 = { class: "wrapper" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "from" }
const _hoisted_8 = {
  key: 0,
  class: "flex dist"
}
const _hoisted_9 = { class: "stationName flex wide" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InfinityScroll = _resolveComponent("InfinityScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Search, {
        modelValue: _ctx.searchTerm,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadStops()))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_InfinityScroll, {
        onLoadNextPage: _ctx.loadStops,
        data: _ctx.stops,
        class: "stations",
        loading: _ctx.isLoading,
        "dont-use-mobile-handler": true
      }, {
        default: _withCtx(() => [
          (_ctx.stops.data.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("global.no_data")), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stops.data, (stop) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              to: { name: 'StationById', params: { id: stop.id } },
              class: "flex customTabLink",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('sidebar-toggled'))),
              key: stop.id
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, [
                    _createTextVNode(_toDisplayString(_ctx.$t("sidebar_stations.station")) + " ", 1),
                    _createElementVNode("strong", _hoisted_7, _toDisplayString(stop.name), 1)
                  ]),
                  (stop.route_names)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modifyRoutes(stop.route_names.split(',')), (route) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: route,
                              class: "stationNumber"
                            }, _toDisplayString(route), 1))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["onLoadNextPage", "data", "loading"])
    ])
  ]))
}