
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

export default defineComponent({
  data: () => ({
    customName: "",
  }),
  components: {
    InputText,
    Button,
  },
});
