
import { PropType, defineComponent } from "vue";
import Dialog from "primevue/dialog";
import { Event } from "@/typings/Event";
import PlaceholderImage from "../PlaceholderImage.vue";

export default defineComponent({
  emits: ["save", "unsave"],
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
    showcase: Boolean,
    side: Boolean,
  },
  data: () => ({
    showDialog: false,
    storageUrl: process.env.VUE_APP_STORAGE_URL,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    eventImageUrl() {
      return this.event.image_url ?? this.storageUrl + "/logo.png";
    },
  },
  methods: {
    saveEvent() {
      this.$http
        .post(`api/v1/events/${this.event.id}/save`)
        .then(() => this.$emit("save"));
    },
    removeSavedEvent() {
      this.$http.post(`api/v1/events/${this.event.id}/unsave`).then(() => {
        this.showDialog = false;
        this.$emit("unsave");
      });
    },
    formatDate(date: string) {
      const articleDate = this.$moment(date);
      const currentTime = this.$moment();

      // Check if the article was published less than 24 hours ago
      if (currentTime.diff(articleDate, "hours") < 24) {
        return articleDate.fromNow();
      }

      return articleDate.format("DD. MM. yyyy");
    },
  },
  components: { Dialog, PlaceholderImage },
});
