
import { Filter } from "@/typings/News";
import { PropType, defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Navigation } from "swiper";

import "swiper/swiper-bundle.css";

export default defineComponent({
  props: {
    filters: {
      type: Array as PropType<Array<Filter>>,
    },
    selectedFilter: {
      type: [Object, null] as PropType<Filter | null>,
    },
  },
  data: () => ({
    modules: [Navigation],
  }),
  components: { Swiper, SwiperSlide },
});
