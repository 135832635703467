
import { defineComponent } from "vue";
import { InterviewerSurvey } from "@/typings/InterviewerSurvey";
export default defineComponent({
  name: "Completed",
  data: () => ({
    surveys: [] as InterviewerSurvey[],
  }),
  mounted() {
    this.loadCompletedSurveys();
  },
  methods: {
    filterSurveys(surveys: InterviewerSurvey[]) {
      return surveys.filter((survey) => {
        return survey.qStatus === 1;
      });
    },
    loadCompletedSurveys() {
      this.$http.get("api/v1/surveys").then((response) => {
        this.surveys = this.filterSurveys(response.data);
      });
    },
  },
});
