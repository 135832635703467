import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wp1200 withPadding normalHeight" }
const _hoisted_2 = { class: "mainTitle" }
const _hoisted_3 = { class: "flex tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("public_transport.title")), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: { name: 'FindPath' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("public_transport.find")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: { name: 'Station' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("public_transport.stations")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}