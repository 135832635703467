import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loginScreen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepOne = _resolveComponent("StepOne")!
  const _component_StepTwo = _resolveComponent("StepTwo")!
  const _component_StepThree = _resolveComponent("StepThree")!
  const _component_StepFour = _resolveComponent("StepFour")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.step === 1)
      ? (_openBlock(), _createBlock(_component_StepOne, {
          key: 0,
          onUpdateUsername: _ctx.updateUsername
        }, null, 8, ["onUpdateUsername"]))
      : _createCommentVNode("", true),
    (_ctx.step === 2)
      ? (_openBlock(), _createBlock(_component_StepTwo, {
          key: 1,
          username: _ctx.username,
          onUpdateToken: _ctx.updateToken
        }, null, 8, ["username", "onUpdateToken"]))
      : _createCommentVNode("", true),
    (_ctx.step === 3)
      ? (_openBlock(), _createBlock(_component_StepThree, {
          key: 2,
          username: _ctx.username,
          token: _ctx.token,
          onNextStep: _cache[0] || (_cache[0] = ($event: any) => (_ctx.step++))
        }, null, 8, ["username", "token"]))
      : _createCommentVNode("", true),
    (_ctx.step === 4)
      ? (_openBlock(), _createBlock(_component_StepFour, { key: 3 }))
      : _createCommentVNode("", true)
  ]))
}