import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "whiteBoxInfo related select" }
const _hoisted_2 = { class: "flex boxShadow" }
const _hoisted_3 = { class: "relatedAccountLogo" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "relatedAccountData" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        ($props.provider.image_url !== null)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: $props.provider.image_url,
              alt: _ctx.$t('accessibility.payment.connect_account')
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", null, _toDisplayString($props.provider.name), 1)
      ])
    ])
  ]))
}