import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-492a577e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-column wp1200 gap-6 toolbar-spacing" }
const _hoisted_2 = { class: "flex flex-column-reverse sm:flex-row gap-6 w-full justify-content-between sm:gap-8" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-column gap-5 w-full sm:w-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopularLocations = _resolveComponent("PopularLocations")!
  const _component_RecentLocations = _resolveComponent("RecentLocations")!
  const _component_SavedLocations = _resolveComponent("SavedLocations")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PopularLocations),
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_RecentLocations),
            _createVNode(_component_SavedLocations)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}