import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "new-application__wrapper height100" }
const _hoisted_2 = { class: "new-application__title" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewApplicationBox = _resolveComponent("NewApplicationBox")!
  const _component_SidebarWrapper = _resolveComponent("SidebarWrapper")!

  return (_openBlock(), _createBlock(_component_SidebarWrapper, {
    title: _ctx.$t('forms.new_form.title'),
    sidebarComponent: _ctx.sidebarComponent,
    onCallback: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.activeTab.title)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.activeTab.title)), 1))
            : _createCommentVNode("", true)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filter, (application) => {
          return (_openBlock(), _createBlock(_component_NewApplicationBox, {
            key: application.id,
            application: application
          }, null, 8, ["application"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title", "sidebarComponent"]))
}