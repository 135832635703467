
import { Component, defineComponent, PropType } from "vue";
import OverlayPanel from "primevue/overlaypanel";
import Divider from "primevue/divider";
import InputSwitch from "primevue/inputswitch";
import Button from "primevue/button";
import IconWalk from "~icons/tabler/walk";
import IconBike from "~icons/tabler/bike";
import IconCar from "~icons/tabler/car";
import IconLeaf from "~icons/tabler/leaf";
import IconMoodSmile from "~icons/tabler/mood-smile";
import IconWheelchair from "~icons/tabler/wheelchair";
import IconChevronDown from "~icons/tabler/chevron-down";

export default defineComponent({
  components: {
    OverlayPanel,
    Divider,
    InputSwitch,
    Button,
    IconWalk,
    IconBike,
    IconCar,
    IconLeaf,
    IconMoodSmile,
    IconWheelchair,
    IconChevronDown,
  },
  props: {
    activeModes: {
      type: Array as PropType<Array<string>>,
      required: false,
    },
  },
  emits: ["save"],
  data: () => ({
    options: [] as Array<{ value: string; enabled: boolean; icon: Component }>,
  }),
  computed: {
    selectedOptions() {
      return this.options.filter((opt) => opt.enabled).map((opt) => opt.value);
    },
  },
  methods: {
    toggle(event: Event) {
      (this.$refs.overlayPanel as OverlayPanel).toggle(event);
    },
    isLastEnabled(option: { enabled: boolean }) {
      const enabledCount = this.options.filter((opt) => opt.enabled).length;
      return enabledCount === 1 && option.enabled;
    },
    handleToggle(option: { enabled: boolean }) {
      if (
        !option.enabled &&
        this.options.filter((opt) => opt.enabled).length === 0
      ) {
        option.enabled = true;
      }
    },
    saveSelection() {
      this.$emit("save", this.selectedOptions);
    },
  },
  watch: {
    activeModes: {
      handler(value) {
        this.options = [
          {
            value: "walk",
            icon: IconWalk,
            enabled: true,
          },
          {
            value: "bicycle",
            icon: IconBike,
            enabled: true,
          },
          {
            value: "car",
            icon: IconCar,
            enabled: true,
          },
          {
            value: "weightCo2",
            icon: IconLeaf,
            enabled: true,
          },
          {
            value: "weightHumanEffort",
            icon: IconMoodSmile,
            enabled: true,
          },
          {
            value: "wheelchairFriendly",
            icon: IconWheelchair,
            enabled: true,
          },
        ];

        if (value && value.length > 0) {
          this.options.forEach((opt) => {
            opt.enabled = value?.includes(opt.value) ?? false;
          });
        }

        this.saveSelection();
      },

      immediate: true,
    },
  },
});
