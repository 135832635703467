
import { defineComponent } from "vue";
import StepOne from "@/components/user/relatedAccounts/StepOne.vue";
import StepTwo from "@/components/user/relatedAccounts/StepTwo.vue";
import StepThree from "@/components/user/relatedAccounts/StepThree.vue";
import ThankYou from "@/components/user/relatedAccounts/ThankYou.vue";
import WheretoFind from "@/components/user/relatedAccounts/WhereToFind.vue";
import { Provider } from "@/typings/Provider";
import Dialog from "primevue/dialog";

export default defineComponent({
  data: () => ({
    step: 1,
    selectedProvider: null as Provider | null,
    activationCode: "",
    display: false,
  }),

  methods: {
    setProvider(selectedProvider: Provider) {
      this.selectedProvider = selectedProvider;
      this.step++;
    },
    setCode(code: string) {
      this.activationCode = code;
      this.step++;
    },
    postUserCode(customName: string) {
      if (this.selectedProvider === null || this.activationCode === null) {
        return;
      }

      this.$http
        .post("api/v1/user-codes", {
          provider_id: this.selectedProvider.id,
          custom_name: customName,
          user_code: this.activationCode,
        })
        .then(() => {
          this.step++;
          return;
        })
        .catch(() => {
          this.display = true;
          return;
        });
    },
    resetForm() {
      this.step = 1;
      this.selectedProvider = null;
      this.activationCode = "";
    },
  },

  components: {
    StepOne,
    StepTwo,
    StepThree,
    ThankYou,
    WheretoFind,
    Dialog,
  },
});
