
import { PropType, defineComponent } from "vue";
import NewsCard from "@/components/news/NewsCard.vue";
import { Article } from "@/typings/Article";

export default defineComponent({
  props: {
    news: {
      type: Array as PropType<Array<Article>>,
    },
  },
  data: () => ({
    isMobile: window.innerWidth <= 760,
    sidebarFilterOpen: false,
  }),
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 760;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  components: {
    NewsCard,
  },
});
