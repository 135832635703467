
import { useResizeObserver } from "@vueuse/core";
import { defineComponent, ref } from "vue";

export default defineComponent({
  data: () => ({
    cardOpen: false,
  }),
  props: {
    title: { type: String, default: "" },
    sidebarLeft: { type: Boolean, default: false },
    sidebarComponent: { type: Object },
    mapComponent: { type: Object, default: null },
  },
  setup() {
    const colLeft = ref(null);
    const colRight = ref(null);
    const colLeftHeight = ref(0);
    const colRightHeight = ref(0);

    useResizeObserver(colLeft, (entries) => {
      const entry = entries[0];
      colLeftHeight.value = entry.contentRect.height;
    });

    useResizeObserver(colRight, (entries) => {
      const entry = entries[0];
      colRightHeight.value = entry.contentRect.height;
    });

    return {
      colLeft,
      colRight,
      colLeftHeight,
      colRightHeight,
    };
  },
  methods: {
    toggleCard() {
      this.cardOpen = !this.cardOpen;
    },
    toggleSidebar() {
      this.cardOpen = !this.cardOpen;
    },
  },
});
