
/// <reference types="@types/google.maps" />

import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import Dialog from "primevue/dialog";

import { FeedbackForm } from "@/typings/FeedbackForm";
import { FeedbackFormErrors } from "@/typings/FeedbackFormErrors";
import { Coordinates, Marker } from "@/typings/Marker";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import { FeedbackType } from "@/typings/FeedbackType";
import { TellCityLocalCommunity } from "@/typings/TellCityLocalCommunity";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  components: { InputText, Calendar, Dropdown, Textarea, Dialog },

  data: () => ({
    form: {} as FeedbackForm,
    previewImage: null as string | null,
    errors: {} as FeedbackFormErrors,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    marker: {} as Marker,
    feedbackTypes: [] as FeedbackType[],
    localCommunities: [] as TellCityLocalCommunity[],
    showErrorDialog: false,
    publicFeedbackEnabled:
      process.env.VUE_APP_PUBLIC_FEEDBACK_ENABLE === "true",
    apiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    selectedImages: [] as string[],
  }),

  computed: {
    types() {
      return this.feedbackTypes.map((type) => {
        return {
          id: type.id,
          name: this.$t(`feedback.${type.name}`),
        };
      });
    },
  },

  mounted() {
    this.getFeedbackTypes();
    this.getLocalCommunities();
    this.setCurrentData();
  },

  methods: {
    addImage() {
      (this.$refs.imageUpload as HTMLInputElement).click();
    },
    removeImage(index: number) {
      this.selectedImages.splice(index, 1);
    },
    async handleMapClick(place: {
      latLng: { lat: () => number; lng: () => number };
    }) {
      this.marker.position = {
        lat: place.latLng.lat(),
        lng: place.latLng.lng(),
      };

      try {
        // eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder();

        const response = await geocoder.geocode({
          location: {
            lat: place.latLng.lat(),
            lng: place.latLng.lng(),
          },
        });

        if (response.results.length === 0) {
          return;
        }

        const pattern = /^(.*[a-zA-Z0-9])[,].*$/;
        this.form.address = response.results[0].formatted_address.replace(
          pattern,
          "$1",
        );
      } catch {
        //
      }
    },
    removeLocation() {
      this.form.address = undefined;
      this.form.latitude = undefined;
      this.form.longitude = undefined;
      this.center = {
        lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
      };
      this.marker = {} as Marker;
    },
    onTitleFocusOut() {
      firebaseInstance?.analytics().logEvent("tell_city", {
        page: "tell_city",
        category: "feedback_screen",
        category_desc: "Zaslon povratnih informacij odprt",
        sub_category: "user_adds_notification_title",
        sub_category_desc: "Uporabnik je dodal naslov obvestila",
        ...this.$store.getters["user/analyticsInfo"],
      });
    },
    onDescriptionFocusOut() {
      firebaseInstance?.analytics().logEvent("tell_city", {
        page: "tell_city",
        category: "feedback_screen",
        category_desc: "Zaslon povratnih informacij odprt",
        sub_category: "user_writes_description",
        sub_category_desc: "Uporabnik je napisal opis",
        ...this.$store.getters["user/analyticsInfo"],
      });
    },
    getFeedbackTypes() {
      this.$http.get("api/v1/feedback/types").then((response) => {
        this.feedbackTypes = response.data.types;
      });
    },
    getLocalCommunities() {
      this.$http.get("api/v1/feedback/local-communities").then((response) => {
        this.localCommunities = [
          ...response.data,
          { id: 9999, name: this.$t("feedback.all_local_communities") }, // Option for all local communities since null is not allowed
        ];
      });
    },

    setCurrentData() {
      this.form.time = new Date();
      this.form.type_id = 1;
      this.form.local_community_id = 9999; // Option for all local communities
    },
    setAddress(place: GmapPlaceResult) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const address = place.formatted_address;
      this.form.address = address;
      this.form.latitude = latitude.toString();
      this.form.longitude = longitude.toString();
      this.marker.title = address;
      this.marker.position = {
        lat: latitude,
        lng: longitude,
      };
      this.center = this.marker.position;
    },
    resetFileInput() {
      (this.$refs.imageUpload as HTMLInputElement).value = "";
    },
    buildImages(): string[] {
      return this.selectedImages.map((image) => image.split("base64,")[1]);
    },
    sendFeedback() {
      firebaseInstance?.analytics().logEvent("tell_city", {
        page: "tell_city",
        category: "feedback_screen",
        category_desc: "Zaslon povratnih informacij odprt",
        sub_category: "click_submit_feedback",
        sub_category_desc: "Uporabnik je napisal opis",
        ...this.$store.getters["user/analyticsInfo"],
      });
      this.validateForm();
      if (Object.keys(this.errors).length !== 0) {
        return;
      }
      let params = { ...this.form };

      if (params.local_community_id === 9999) {
        params.local_community_id = null;
      }

      if (this.selectedImages.length !== 0) {
        params.images = this.buildImages();
      }

      this.$http
        .post("/api/v2/feedback", params)
        .then((response) => {
          if (response.status === 201) {
            firebaseInstance?.analytics().logEvent("tell_city", {
              page: "tell_city",
              category: "feedback_screen",
              category_desc: "Zaslon povratnih informacij odprt",
              sub_category: "feedback_submit",
              sub_category_desc: "Uporabnik je oddal povratne informacije",
              ...this.$store.getters["user/analyticsInfo"],
            });

            this.$router.push({ name: "FeedbackThankYou" });
          }
        })
        .catch(() => {
          firebaseInstance?.analytics().logEvent("tell_city", {
            page: "tell_city",
            category: "feedback_screen",
            category_desc: "Zaslon povratnih informacij odprt",
            sub_category: "feedback_sent_error",
            sub_category_desc: "Napaka pri oddaji obvestila",
            ...this.$store.getters["user/analyticsInfo"],
          });
          this.errors.something_wrong = this.$t("feedback_error.problem");
        });
    },
    uploadImage() {
      firebaseInstance?.analytics().logEvent("tell_city", {
        page: "tell_city",
        category: "feedback_screen",
        category_desc: "Zaslon povratnih informacij odprt",
        sub_category: "user_adds_photo",
        sub_category_desc: "Uporabnik je dodal fotografijo",
        ...this.$store.getters["user/analyticsInfo"],
      });
      const allowedFileTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
      ];
      let input = this.$refs.imageUpload as HTMLInputElement;
      let file = input.files;
      if (file && file[0] && file !== null) {
        if (
          file[0].size >= 5 * 1024 ** 2 ||
          !allowedFileTypes.includes(file[0].type)
        ) {
          input.value = "";
          this.showErrorDialog = true;
          return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = (e) => {
          if (e.target && e.target.result) {
            this.selectedImages.push(e.target.result.toString());

            this.resetFileInput();
          }
        };
      }
    },
    validateForm() {
      this.errors = {};
      if (!this.form.title) {
        this.errors.title = this.$t("feedback_error.enter_address");
      }
      if (!this.form.time) {
        this.errors.time = this.$t("feedback_error.complete_form");
      }
      if (!this.form.type_id) {
        this.errors.type_id = this.$t("feedback_error.complete_form");
      }
      if (!this.form.description) {
        this.errors.description = this.$t("feedback_error.complete_form");
      }
    },
  },
});
