
import { PropType, defineComponent } from "vue";
import Dialog from "primevue/dialog";
import { Article } from "@/typings/Article";
import PlaceholderImage from "../PlaceholderImage.vue";

export default defineComponent({
  props: {
    article: {
      type: Object as PropType<Article>,
      required: true,
    },
    showcase: Boolean,
  },
  data: () => ({
    showDialog: false,
    storageUrl: process.env.VUE_APP_STORAGE_URL,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    saveArticle() {
      this.$http
        .post(`api/v1/news/${this.article.id}/save`)
        .then(() => this.$emit("save"));
    },
    removeSavedArticle() {
      this.$http.post(`api/v1/news/${this.article.id}/unsave`).then(() => {
        this.showDialog = false;
        this.$emit("unsave");
      });
    },
    formatDate(date: string) {
      const articleDate = this.$moment(date);
      const currentTime = this.$moment();

      // Check if the article was published less than 24 hours ago
      if (currentTime.diff(articleDate, "hours") < 24) {
        return articleDate.fromNow();
      }

      return articleDate.format("DD. MM. yyyy");
    },
  },
  components: { Dialog, PlaceholderImage },
});
