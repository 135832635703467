import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withKeys as _withKeys, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a3f8148"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wp1200 p-0" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick", "onKeyup"]
const _hoisted_4 = { class: "flex gap-3" }
const _hoisted_5 = { class: "flex flex-column gap-2" }
const _hoisted_6 = { class: "text-primary font-semibold" }
const _hoisted_7 = { class: "text-600" }
const _hoisted_8 = { class: "flex flex-column sm:flex-row align-items-end sm:align-items-center gap-2 text-right flex-shrink-0" }
const _hoisted_9 = {
  key: 0,
  class: "icon-warpit_icon_car text-2xl text-600"
}
const _hoisted_10 = {
  key: 1,
  class: "icon-warpit_icon_parking text-2xl text-600"
}
const _hoisted_11 = {
  key: 2,
  class: "icon-warpit_icon_electricity_1 text-2xl text-600"
}
const _hoisted_12 = {
  key: 1,
  class: "pl-4 pt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListFilters = _resolveComponent("ListFilters")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_InfinityScroll = _resolveComponent("InfinityScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListFilters, {
      "search-placeholder-key": "shared_mobility.search",
      onAddressChange: _ctx.setAddress,
      onClear: _ctx.clearAddress,
      onMapMode: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'SharedMobilityMap' })))
    }, null, 8, ["onAddressChange", "onClear"]),
    _createVNode(_component_InfinityScroll, {
      onLoadNextPage: _ctx.loadStations,
      data: _ctx.stations,
      loading: _ctx.isLoading,
      "dont-use-mobile-handler": true,
      class: "list"
    }, {
      default: _withCtx(() => [
        (_ctx.stations.data && _ctx.stations.data.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stations.data, (station) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: station.id
                }, [
                  _createElementVNode("div", {
                    tabindex: "0",
                    class: "flex justify-content-between align-items-center gap-1 p-3 pt-4 px-3 cursor-pointer",
                    onClick: ($event: any) => (
              _ctx.$router.push({
                name: 'SharedMobilityById',
                params: { id: station.id },
              })
            ),
                    onKeyup: _withKeys(($event: any) => (
              _ctx.$router.push({
                name: 'SharedMobilityById',
                params: { id: station.id },
              })
            ), ["enter"])
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(station.name), 1),
                        _createElementVNode("span", _hoisted_7, _toDisplayString((station.distance / 1000).toFixed(1)) + " km " + _toDisplayString(_ctx.address !== null
                      ? _ctx.$t("shared_mobility.from_search")
                      : _ctx.$t("shared_mobility.from_center")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      (station.all_cars > 0)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_9))
                        : _createCommentVNode("", true),
                      (station.free_parking_places > 0)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_10))
                        : _createCommentVNode("", true),
                      (station.chargers > 0)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_11))
                        : _createCommentVNode("", true)
                    ])
                  ], 40, _hoisted_3),
                  _createVNode(_component_Divider, { class: "m-0" })
                ]))
              }), 128))
            ]))
          : (!_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t("shared_mobility.no_stations")), 1))
            : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onLoadNextPage", "data", "loading"])
  ]))
}