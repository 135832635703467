
import { defineComponent } from "vue";
import { QueryParameters } from "@/typings/QueryParameters";
import { Paginated } from "@/typings/Paginated";
import { Parking } from "@/typings/Parking";
import FavouriteButton from "@/components/FavouriteButton.vue";
import InfinityScroll from "@/components/InfinityScroll.vue";
import Divider from "primevue/divider";
import { Coordinates } from "@/typings/Marker";

export default defineComponent({
  components: { FavouriteButton, InfinityScroll, Divider },
  data: () => ({
    parkings: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Parking>,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    isLoading: false,
  }),
  methods: {
    checkAvailability(parking: Parking) {
      const types = ["regular", "ev", "accessible"];
      const isSmartParking = parking.smart === 1;

      return types.every((typeValue) => {
        const type = `spaces_${typeValue}${isSmartParking ? "_available" : ""}`;
        return parking[type as keyof Parking] !== null;
      });
    },
    loadParkings(page = 1) {
      this.isLoading = true;

      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
      } = {
        filters: {
          favourite: true,
        },
        page,
        perPage: 20,
      };

      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;
        params.radius = Math.pow(2, 20);
      }

      this.$http
        .get("api/v2/parkings", { params })
        .then((response) => {
          if (page !== 1) {
            this.parkings.data = this.parkings.data.concat(response.data.data);
            this.parkings.current_page = response.data.current_page;
            return;
          }
          this.parkings = response.data;
        })
        .finally(() => (this.isLoading = false));
    },
    deleteFavouriteParking(parking: Parking) {
      this.$http
        .delete("api/v1/locations/" + parking.my_location_id)
        .then(() => {
          this.loadParkings();
        });
    },
    totalSmartParkingAvailable(parking: Parking) {
      return (
        parking.spaces_regular -
        parking.spaces_regular_occupied +
        (parking.spaces_accessible - parking.spaces_accessible_occupied) +
        (parking.spaces_ev - parking.spaces_ev_occupied)
      );
    },
    totalParkingAvailable(parking: Parking) {
      return (
        parking.spaces_regular + parking.spaces_accessible + parking.spaces_ev
      );
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  mounted() {
    this.loadParkings();
  },
});
