import { RouteLocationRaw } from "vue-router";

export default function routePlannerEnabled(): RouteLocationRaw | void {
  const routePlannerEnabled =
    process.env.VUE_APP_ROUTE_PLANNER_ENABLE === "true";

  if (!routePlannerEnabled) {
    return { name: "Feed" };
  }
}
