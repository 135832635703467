
import { defineComponent } from "vue";
import InfinityScroll from "@/components/InfinityScroll.vue";
import { FeedbackItem } from "@/typings/FeedbackItem";
import { FeedbackType } from "@/typings/FeedbackType";
import { Paginated } from "@/typings/Paginated";
import { QueryParameters } from "@/typings/QueryParameters";

export default defineComponent({
  components: { InfinityScroll },

  data: () => ({
    feedbacks: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<FeedbackItem>,
    feedbackTypes: [] as FeedbackType[],
    selectedType: null as null | FeedbackType,
    isLoading: false,
  }),

  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    selectType(type: FeedbackType) {
      if (this.selectedType && this.selectedType.name === type.name) {
        this.selectedType = null;
      } else {
        this.selectedType = type;
      }

      this.getFeedbacks();
    },
    getFeedbackTypes() {
      this.$http.get("api/v1/feedback/types").then((response) => {
        this.feedbackTypes = response.data.types;
      });
    },
    getFeedbacks(page = 1) {
      let params: QueryParameters = {
        page,
        perPage: 20,
        orderBy: {
          created_at: "desc",
        },
        filters: {
          type_id: this.selectedType?.id,
        },
      };
      this.isLoading = true;
      this.$http
        .get("api/v1/feedback", { params })
        .then((response) => {
          if (page !== 1) {
            this.feedbacks.data = this.feedbacks.data.concat(
              response.data.data,
            );
            this.feedbacks.current_page = response.data.current_page;
            return;
          }
          this.feedbacks = response.data;
        })
        .finally(() => (this.isLoading = false));
    },
  },

  mounted() {
    this.getFeedbackTypes();
    this.getFeedbacks();
  },
});
