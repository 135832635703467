
import { PropType, defineComponent } from "vue";
import { SharedMobility } from "@/typings/SharedMobility";
import Divider from "primevue/divider";
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";
import RRuleParser from "@/services/RRule";

export default defineComponent({
  components: { Divider, Sidebar, Button },
  props: {
    station: {
      type: Object as PropType<SharedMobility>,
      required: true,
    },
  },
  methods: {
    goBack(toMap = false) {
      this.$emit("back");

      if (toMap) {
        this.$router.push({ name: "SharedMobilityMap" });
        return;
      }

      this.$router.back();
    },
    parseRRule(rule: string) {
      const rruleParser = new RRuleParser(this.$i18n.locale);
      return rruleParser.localize(rule);
    },
    openGoogleMaps() {
      window.open(
        `https://maps.google.com/?q=${this.station.latitude},${this.station.longitude}`,
        "_blank"
      );
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
});
