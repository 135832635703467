import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11ece3c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-column pt-3 gap-3 sm:flex-row sm:justify-content-between sm:align-items-center sm:pb-5 sm:hidden" }
const _hoisted_2 = { class: "text-2xl" }
const _hoisted_3 = { class: "pt-4 pb-3 sm:pb-5" }
const _hoisted_4 = { class: "text-2xl" }
const _hoisted_5 = { class: "w-full flex justify-content-center" }
const _hoisted_6 = { class: "box flex flex-column pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Categories = _resolveComponent("Categories")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("events.title")), 1),
      _createVNode(_component_Dropdown, {
        options: _ctx.filters.filter((f) => !f.divider),
        optionLabel: "name",
        placeholder: _ctx.$t('events.choose_category'),
        class: "flex sm:hidden",
        onChange: _cache[0] || (_cache[0] = (e) => _ctx.goToNews(e.value))
      }, null, 8, ["options", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.$t("events.title")), 1),
        _createElementVNode("strong", null, " / " + _toDisplayString(_ctx.$t("events.my_sub")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Categories)
      ])
    ])
  ], 64))
}