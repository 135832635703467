
import { PropType, defineComponent } from "vue";
import { Event } from "@/typings/Event";
import EventsCard from "./EventsCard.vue";

export default defineComponent({
  props: {
    events: {
      type: Array as PropType<Array<Event>>,
      required: true,
    },
    grouped: Boolean,
  },
  computed: {
    firstEvent() {
      return this.events[0];
    },
    groupedEvents() {
      return this.events?.reduce(
        (grouped: Record<string, Array<Event>>, event: Event) => {
          const date = event.datetime
            ? this.$moment(event.datetime).format("DD. MMM dddd")
            : undefined;

          if (!grouped[date]) {
            grouped[date] = [];
          }

          grouped[date].push(event);

          return grouped;
        },
        {},
      );
    },
  },
  components: {
    EventsCard,
  },
});
