import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "e-application-upn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("span", { key: index }, [
        _createElementVNode("b", null, _toDisplayString(_ctx.getTranslation(item.key)), 1),
        _createTextVNode(": " + _toDisplayString(_ctx.getTranslation(item.value)) + _toDisplayString(_ctx.items.length === ++index ? "." : ","), 1)
      ]))
    }), 128))
  ]))
}