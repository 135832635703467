import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/images/userIcon2.svg'


const _hoisted_1 = { class: "userIcon" }
const _hoisted_2 = ["alt"]
const _hoisted_3 = {
  key: 0,
  class: "newNotification"
}
const _hoisted_4 = { class: "subject" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { class: "date" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.feedback)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "messageNotification",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggled')))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: _ctx.$t('accessibility.feedback.user')
          }, null, 8, _hoisted_2),
          (_ctx.feedback.unread_owner > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.feedback.title)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(_ctx.feedback.title), 1))
            : (_openBlock(), _createElementBlock("h4", _hoisted_6, [
                _createElementVNode("em", null, _toDisplayString(_ctx.$t("feedback.no_title")), 1)
              ])),
          (_ctx.feedback.type)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t(`feedback.${_ctx.feedback.type.name}`)), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_8, [
                _createElementVNode("em", null, _toDisplayString(_ctx.$t("feedback.no_category")), 1)
              ]))
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.feedback.time)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$moment(_ctx.feedback.time).format("DD.MM.YYYY")), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("feedback.no_time")), 1))
        ])
      ]))
    : _createCommentVNode("", true)
}