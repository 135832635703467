
import { PropType, defineComponent } from "vue";
import MapCustom from "@/components/MapCustom.vue";
import { Marker } from "@/typings/Marker";

export default defineComponent({
  props: {
    externalId: {
      type: Number,
    },
    payload: {
      type: Object as PropType<{
        id: number;
        name: string;
        latitude: string | null;
        longitude: string | null;
        arrivals: Array<{ arrival_time: string }>;
      }>,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    marker(): Marker | null {
      if (this.payload.latitude === null || this.payload.longitude === null) {
        return null;
      }

      return {
        id: this.payload.id,
        position: {
          lat: parseFloat(this.payload.latitude),
          lng: parseFloat(this.payload.longitude),
        },
        on_click_route_name: null,
        type: "station",
        title: this.payload.name,
      };
    },
    arrivals() {
      return this.payload.arrivals.slice(0, 9);
    },
  },
  methods: {
    calculateArrivalTime(arrival_time: string) {
      const date_difference = this.$moment(arrival_time, "HH:mm:ss").diff(
        this.$moment(new Date(), "HH:mm:ss"),
      );
      const duration = this.$moment.duration(date_difference);
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes() as number;
      return { hours, minutes };
    },
  },
  components: { MapCustom },
});
