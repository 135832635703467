
import { defineComponent } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { RewardCategory } from "@/typings/RewardCategory";

export default defineComponent({
  data: () => ({
    categories: [] as RewardCategory[],
    selectedCategory: null as null | string,
    loyaltyPoints: 0,
  }),
  mounted() {
    this.loadRewardCategories();
    this.loadUserLoyaltyPoints();
  },
  methods: {
    loadRewardCategories() {
      this.$http.get("api/v1/loyalty/reward-categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    setCategory(type: string | null) {
      this.$router.push({
        name: "Rewards",
        query: {
          type,
        },
      });
    },
    loadUserLoyaltyPoints() {
      this.$http
        .get("api/v1/user/" + this.user.id + "/points")
        .then((response) => {
          this.loyaltyPoints = response.data.current;
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: { Accordion, AccordionTab },
});
