
import { PropType, defineComponent } from "vue";
import Button from "primevue/button";
import { InfoItem } from "@/typings/Info";

export default defineComponent({
  components: { Button },
  props: {
    item: {
      type: Object as PropType<InfoItem>,
      required: true,
    },
  },
  methods: {
    openGoogleMaps() {
      const [lng, lat] = this.buildCenterCoordinates(this.item);
      window.open(`https://maps.google.com/?q=${lat},${lng}`, "_blank");
    },

    buildCenterCoordinates(item: InfoItem) {
      if (this.item.geometry.type === "Point") {
        return [item.geometry.coordinates[0], item.geometry.coordinates[1]];
      }
      const coordinates = this.getCoordinates(item) as Array<number>;

      let x = 0;
      let y = 0;

      for (let vertex of coordinates) {
        if (!Array.isArray(vertex)) {
          continue;
        }

        x += vertex[0];
        y += vertex[1];
      }

      const centerX = x / coordinates.length;
      const centerY = y / coordinates.length;

      return [centerX, centerY];
    },

    getCoordinates(
      item: InfoItem,
    ): number | Array<number> | Array<Array<number>> {
      return item.geometry.type === "Polygon"
        ? item.geometry.coordinates[0]
        : item.geometry.coordinates;
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
});
