
import { defineComponent } from "vue";
import { QueryParameters } from "@/typings/QueryParameters";
import { Paginated } from "@/typings/Paginated";
import FavouriteButton from "@/components/FavouriteButton.vue";
import InfinityScroll from "@/components/InfinityScroll.vue";
import Divider from "primevue/divider";
import { Coordinates } from "@/typings/Marker";
import { BikeStation } from "@/typings/BikeStation";

export default defineComponent({
  components: { FavouriteButton, InfinityScroll, Divider },
  data: () => ({
    stations: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<BikeStation>,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    isLoading: false,
  }),
  methods: {
    loadStations(page = 1) {
      this.isLoading = true;

      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
      } = {
        filters: {
          favourite: true,
        },
        page,
        perPage: 20,
      };

      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;
        params.radius = Math.pow(2, 20);
      }

      this.$http
        .get("api/v1/bike-stations", { params })
        .then((response) => {
          if (page !== 1) {
            this.stations.data = this.stations.data.concat(response.data.data);
            this.stations.current_page = response.data.current_page;
            return;
          }
          this.stations = response.data;
        })
        .finally(() => (this.isLoading = false));
    },
    deleteFavouriteStation(station: BikeStation) {
      this.$http
        .delete("api/v1/locations/" + station.my_location_id)
        .then(() => {
          this.loadStations();
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  mounted() {
    this.loadStations();
  },
});
