
import { defineComponent } from "vue";
import { Card } from "@/typings/Card";
import { Invoice } from "@/typings/Invoice";

export default defineComponent({
  data: () => ({
    paymentMethods: [] as Card[],
    selectedPaymentMethod: null as null | "flik" | "card",
    selectedCard: null as Card | null,
    flikEnabled: process.env.VUE_APP_FLIK_ENABLE === "true",
  }),
  props: {
    invoice: {
      type: Object as () => Invoice | null,
    },
  },
  mounted() {
    this.loadCards();
  },
  methods: {
    loadCards() {
      this.$http.get("/api/v1/credit-cards").then((res) => {
        this.paymentMethods = res.data.data;
      });
    },
  },
});
