
import { defineComponent } from "vue";
import Button from "primevue/button";

export default defineComponent({
  components: { Button },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openGoogleMaps() {
      window.open(
        `https://maps.google.com/?q=${this.item.latitude},${this.item.longitude}`,
        "_blank",
      );
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
});
