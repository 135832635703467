
import { defineComponent, PropType } from "vue";
import { FeedbackItem } from "@/typings/FeedbackItem";

export default defineComponent({
  props: {
    feedback: {
      type: Object as PropType<FeedbackItem>,
    },
  },
});
