
import { defineComponent } from "vue";
import { VueShowdown } from "vue-showdown";
import Map from "@/components/Map.vue";
import { Coordinates, Marker } from "@/typings/Marker";
import { InfoContent, InfoFeature } from "@/typings/Info";

export default defineComponent({
  data: () => ({
    contents: null as null | InfoContent,
    isLoading: false,
    markers: [] as Marker[],
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    imageUrl: null as null | string,
  }),
  methods: {
    openGoogleMaps() {
      if (!this.contents) {
        return;
      }

      window.open(
        `https://maps.google.com/?q=${this.contents.lat},${this.contents.lng}`,
        "_blank",
      );
    },
    async loadContent() {
      this.isLoading = true;

      this.$directus
        .get(`static/${this.$route.params.feature}`)
        .then((response) => {
          const contents = response.data.data as InfoContent;

          this.contents = contents;

          if (this.contents.image) {
            this.imageUrl = `${process.env.VUE_APP_DIRECTUS_URL}/assets/${this.contents.image}`;
          }

          this.setMarker();
        })
        .finally(() => (this.isLoading = false));
    },
    setMarker() {
      if (!this.contents) {
        return;
      }

      if (!this.contents.lat || !this.contents.lng) {
        return;
      }

      this.markers.push({
        id: 1,
        position: {
          lat: this.contents.lat,
          lng: this.contents.lng,
        },
        title: this.contents.title,
        type: "item",
        icon: "/markers/parking-green.svg",
        on_click_route_name: null,
      });

      this.center = this.markers[0].position;
    },
  },
  computed: {
    infoFeatures(): InfoFeature[] | null {
      return this.$store.getters["info-features/features"];
    },
    infoFeatureExists(): boolean {
      return (
        this.infoFeatures !== null &&
        this.infoFeatures.some(
          (feature) =>
            feature.collection === this.$route.params.feature &&
            feature.view === "Static",
        )
      );
    },
  },
  watch: {
    infoFeatures: {
      handler() {
        if (this.infoFeatureExists) {
          this.loadContent();
        }
      },
      immediate: true,
    },
  },
  components: { VueShowdown, Map },
});
