import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.$i18n.locale,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
    options: _ctx.$i18n.availableLocales,
    class: "chooseLanguage",
    panelClass: "languages",
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateLocale()))
  }, {
    value: _withCtx((props) => [
      _createTextVNode(_toDisplayString(props.value.toUpperCase()), 1)
    ]),
    option: _withCtx((props) => [
      _createTextVNode(_toDisplayString(props.option.toUpperCase()), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "options"]))
}