
import { PropType, defineComponent } from "vue";
import Button from "primevue/button";
import SavedNews from "./SavedNews.vue";
import { NewsProvider } from "@/typings/News";
import Subscribed from "./Subscribed.vue";

export default defineComponent({
  props: {
    selectedProviders: {
      type: [Array, null] as PropType<Array<NewsProvider> | null>,
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    tabs() {
      return [
        {
          id: 1,
          label: this.$t("news.subscribed"),
        },
        {
          id: 2,
          label: this.$t("news.saved"),
        },
      ];
    },
    selectedTab(): number {
      return Number(this.$route.query.tab) || 1;
    },
  },
  methods: {
    selectTab(tabId: number) {
      this.$router.replace({
        name: "News",
        query: { view: "for-you", tab: tabId },
      });
    },
  },
  mounted() {
    this.selectTab(this.selectedTab);
  },
  components: { Button, SavedNews, Subscribed },
});
