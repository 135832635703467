
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import { EditProfileForm, EditProfileFormErrors } from "@/typings/EditProfile";
import { User } from "@/typings/User";
import Dialog from "primevue/dialog";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  components: { InputText, Button, Calendar, Dialog },
  data: () => ({
    form: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      gender_id: 0,
      contact_info: {
        address: "",
        house_number: "",
        municipality: "",
        code: "",
        city: "",
        country: "",
      },
    } as EditProfileForm,
    addressSearch: null as null | string,
    birth_date: undefined,
    errors: {} as EditProfileFormErrors,
    openedImage: null as null | string,
    imageOk: true,
    regexEmail: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    regexPhone:
      /^(?:(?:(\+|00)\d{3}\s?)|(?:\d{3}))?(\s?\d{9}|\s?\d{3}(?:[\s-]?\d{3}){2}|\s?\d{2}(?:[\s-]?\d{3}){2})$/,
    showErrorDialog: false,
  }),
  mounted() {
    this.fillForm();
  },
  methods: {
    encodeImage(fileObject: File) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.openedImage = event.target?.result?.toString() || null;
      };
      reader.readAsDataURL(fileObject);
    },
    pickFile() {
      const allowedFileTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/webp",
        "image/gif",
      ];
      let input = this.$refs.imageUpload as HTMLInputElement;
      let file = input.files;
      if (
        file &&
        file[0] &&
        file[0].size <= 5 * 1024 ** 2 &&
        allowedFileTypes.includes(file[0].type)
      ) {
        this.encodeImage(file[0]);
        return;
      }
      this.showErrorDialog = true;
    },
    changeImage() {
      (this.$refs.imageUpload as HTMLInputElement).click();
    },
    setAddress(place: GmapPlaceResult) {
      this.form.contact_info.address =
        place.address_components.find((component) =>
          component.types.includes("route"),
        )?.long_name || "";
      this.form.contact_info.house_number =
        place.address_components.find((component) =>
          component.types.includes("street_number"),
        )?.long_name || "";
      this.form.contact_info.city =
        place.address_components.find(
          (component) =>
            component.types.includes("postal_town") ||
            component.types.includes("locality"),
        )?.long_name || "";
      this.form.contact_info.municipality =
        place.address_components.find((component) =>
          component.types.includes("postal_town"),
        )?.long_name || "";
      this.form.contact_info.code =
        place.address_components.find((component) =>
          component.types.includes("postal_code"),
        )?.long_name || "";
      this.form.contact_info.country =
        place.address_components.find((component) =>
          component.types.includes("country"),
        )?.long_name || "";

      this.addressSearch = null;
    },
    fillForm() {
      let user = this.$store.getters["user/user"] as User;
      this.form.first_name = user.first_name;
      this.form.last_name = user.last_name;
      this.form.phone_number = user.phone_number;
      this.form.email = user.email;
      this.form.gender_id = user.gender_id;
      if (user.birth_date !== null) {
        this.birth_date = this.$moment(user.birth_date).format("DD.MM.YYYY");
      }
      if (user.contact_info !== null) {
        this.form.contact_info.address = user.contact_info.address || "";
        this.form.contact_info.house_number =
          user.contact_info.house_number?.toString() || "";
        this.form.contact_info.municipality =
          user.contact_info.municipality || "";
        this.form.contact_info.code = user.contact_info.code || "";
        this.form.contact_info.city = user.contact_info.city || "";
        this.form.contact_info.country = user.contact_info.country || "";
      }
      this.openedImage = this.$store.getters["user/userImage"];
    },
    calculateAccountCompletion() {
      let params = this.form;
      let value = 0;
      if (params.first_name !== null) value += 10;
      if (params.last_name !== null) value += 10;
      if (params.email !== null) value += 10;
      if (params.phone_number !== null) value += 10;
      if (params.birth_date !== null) value += 10;
      if (params.contact_info.address !== null) value += 10;
      if (params.contact_info.house_number !== null) value += 10;
      if (params.contact_info.code !== null) value += 10;
      if (params.contact_info.city !== null) value += 10;
      if (params.contact_info.country !== null) value += 10;
      return value;
    },
    putUser() {
      this.validateForm();
      if (Object.keys(this.errors).length !== 0) {
        return;
      }
      let params = { ...this.form };
      if (this.birth_date !== undefined) {
        params.birth_date = this.$moment(this.birth_date, "DD.MM.YYYY").format(
          "YYYY-MM-DD",
        );
      }
      if (this.openedImage !== null) {
        params.profile_image = this.openedImage.split("base64,")[1];
      }

      if (params.phone_number) {
        params.phone_number = params.phone_number.replace(/[\s-]/g, "");
      }

      this.$http
        .put("/api/v2/user", params)
        .then(async () => {
          var power = this.calculateAccountCompletion();
          if (power === 100)
            firebaseInstance?.analytics().logEvent("user_profile", {
              page: "user_profile",
              category: "user_profile_screen",
              category_desc: "Zaslon uporabniškega profila odprt",
              sub_category: "profile_power_100",
              sub_category_desc: "Uporabniški profil verificiran na 100",
              ...this.$store.getters["user/analyticsInfo"],
            });
          else if (power > 50)
            firebaseInstance?.analytics().logEvent("user_profile", {
              page: "user_profile",
              category: "user_profile_screen",
              category_desc: "Zaslon uporabniškega profila odprt",
              sub_category: "profile_power_more_than_50",
              sub_category_desc: "Uporabniški profil verificiran na več kot 50",
              ...this.$store.getters["user/analyticsInfo"],
            });
          else if (power < 50)
            firebaseInstance?.analytics().logEvent("user_profile", {
              page: "user_profile",
              category: "user_profile_screen",
              category_desc: "Zaslon uporabniškega profila odprt",
              sub_category: "profile_power_less_than_50",
              sub_category_desc:
                "Uporabniški profil verificiran na manj kot 50",
              ...this.$store.getters["user/analyticsInfo"],
            });
          await this.$store.dispatch("user/userinfo");
          this.$router.push({ name: "MyProfile" });
        })
        .catch((error) => {
          this.errors = {};
          if ("errors" in error.response.data === false) {
            this.errors.something_wrong = this.$t("error.mistake");
            return;
          }
          const errors = error.response.data.errors;
          if (
            "email" in errors === false &&
            "phone_number" in errors === false
          ) {
            this.errors.something_wrong = this.$t("error.mistake");
            return;
          }
          if ("email" in errors === true) {
            if (errors.email.includes("validation.email") === true) {
              this.errors.email = this.$t("error.enter_valid_email");
            }
            if (errors.email.includes("validation.unique") === true) {
              this.errors.email = this.$t("register_error.used_email");
            }
          }
          if ("phone_number" in errors === true) {
            if (errors.phone_number.includes("validation.phone") === true) {
              this.errors.phone_number = this.$t("error.enter_valid_phone");
            }
            if (errors.phone_number.includes("validation.unique") === true) {
              this.errors.phone_number = this.$t("register_error.used_phone");
            }
          }
        });
    },
    validateForm() {
      this.errors = {};
      if (!this.form.first_name) {
        this.errors.first_name = this.$t("error.enter_first_name");
      }
      if (!this.form.last_name) {
        this.errors.last_name = this.$t("error.enter_last_name");
      }
      if (!this.form.email) {
        this.errors.email = this.$t("error.enter_email");
      }
      if (
        this.birth_date !== undefined &&
        this.$moment(this.birth_date, "DD.MM.YYYY").isValid() === false
      ) {
        this.errors.birth_date = this.$t("error.valid_birth_date_age");
      }
      if (
        this.birth_date !== undefined &&
        this.$moment().diff(
          this.$moment(this.birth_date, "DD.MM.YYYY").format("YYYY-MM-DD"),
          "years",
        ) < 15
      ) {
        this.errors.birth_date = this.$t("error.valid_birth_date_age");
      }
      if (this.regexEmail.test(this.form.email) === false) {
        this.errors.email = this.$t("error.enter_valid_email");
      }
      if (
        this.form.phone_number
          ? !this.regexPhone.test(this.form.phone_number)
          : false
      ) {
        this.errors.phone_number = this.$t("error.enter_valid_phone");
      }
    },
  },
});
