import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withKeys as _withKeys, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "wp1200 withPadding" }
const _hoisted_2 = ["onKeypress"]
const _hoisted_3 = ["id", "checked", "onClick"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "text" }
const _hoisted_6 = {
  class: "col2_3",
  ref: "colRight"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarEko = _resolveComponent("SidebarEko")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mapComponent)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mapComponent), { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: _normalizeClass(["icon-warpit_icon_filter wasteFilter", { hiddenFilter: _ctx.cardOpen }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleFilter && _ctx.toggleFilter(...args)))
    }, null, 2),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", {
        class: _normalizeClass(["wasteFilterButtons overflow-x-auto", { hiddenFilter: _ctx.cardOpen }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter) => {
          return (_openBlock(), _createElementBlock("li", {
            key: filter.id,
            tabindex: "0",
            onKeypress: _withKeys(($event: any) => (_ctx.activateFilter(filter.id)), ["enter"])
          }, [
            _createElementVNode("input", {
              type: "checkbox",
              name: "waste",
              id: filter.id,
              checked: filter.name === _ctx.selectedFilter.name,
              onClick: ($event: any) => (_ctx.activateFilter(filter.id))
            }, null, 8, _hoisted_3),
            _createElementVNode("label", {
              for: filter.id
            }, [
              _createElementVNode("span", {
                class: _normalizeClass([filter.icon, "icon"])
              }, null, 2),
              _createElementVNode("span", _hoisted_5, _toDisplayString(filter.name), 1)
            ], 8, _hoisted_4)
          ], 40, _hoisted_2))
        }), 128))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["flex specialColumnLayout", [
        { left: _ctx.sidebarLeft },
        { 'specialColumnLayout--fixed': _ctx.colLeftHeight <= _ctx.colRightHeight },
      ]])
      }, [
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default", {
            onSidebarToggled: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
          })
        ], 512),
        _createElementVNode("div", {
          class: _normalizeClass(["col1_3 sideBar noPad right", [
          { floatBottom: !_ctx.toggleFilters, closed: _ctx.cardOpen },
          _ctx.$route.name,
        ]]),
          ref: "colLeft",
          style: _normalizeStyle({ 'min-height': _ctx.colRightHeight + 'px' })
        }, [
          _createVNode(_component_SidebarEko, {
            filter: _ctx.selectedFilter,
            onSidebarToggled: _ctx.toggleSidebar
          }, null, 8, ["filter", "onSidebarToggled"])
        ], 6)
      ], 2)
    ])
  ], 64))
}