import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loginScreen registerSteps" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepOne = _resolveComponent("StepOne")!
  const _component_StepTwo = _resolveComponent("StepTwo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.step === 1)
      ? (_openBlock(), _createBlock(_component_StepOne, {
          key: 0,
          responseErrors: _ctx.errors,
          onSubmit: _ctx.register
        }, null, 8, ["responseErrors", "onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.step === 2 && _ctx.email)
      ? (_openBlock(), _createBlock(_component_StepTwo, {
          key: 1,
          type: "email",
          email: _ctx.email,
          onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'RegisterSuccess' })))
        }, null, 8, ["email"]))
      : _createCommentVNode("", true)
  ]))
}