import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "topSearch" }
const _hoisted_2 = { class: "wp1200" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "wp1200 results" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("search.title")), 1),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchString) = $event)),
            placeholder: _ctx.$t('search.enter'),
            onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"])),
            type: "text"
          }, null, 40, _hoisted_4), [
            [_vModelText, _ctx.searchString]
          ]),
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args))),
            class: "buttonSearch"
          }, _toDisplayString(_ctx.$t("search.find")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (
        _ctx.searchResult.events?.length === 0 && _ctx.searchResult.news?.length === 0
      )
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("search.empty")), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResult.news, (article) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "result",
          key: article.id
        }, [
          _createElementVNode("h2", null, _toDisplayString(article.title), 1),
          _createElementVNode("p", null, _toDisplayString(article.summary), 1),
          _createVNode(_component_router_link, {
            to: { name: 'Article', params: { id: article.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("global.more")), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResult.events, (event) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "result",
          key: event.id
        }, [
          _createElementVNode("h2", null, _toDisplayString(event.title), 1),
          _createElementVNode("p", null, _toDisplayString(event.summary), 1),
          _createVNode(_component_router_link, {
            to: { name: 'Event', params: { id: event.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("global.more")), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ])
  ], 64))
}