
import { defineComponent } from "vue";
import Password from "primevue/password";

export default defineComponent({
  data: () => ({
    verificationToken: null,
    error: false,
  }),
  methods: {
    checkVerificationToken() {
      this.$http
        .post("api/v1/register-verification", {
          type: this.type,
          verification_token: this.verificationToken,
          emailOrPhone: this.email,
        })
        .then(() => {
          this.$emit("submit");
        })
        .catch(() => {
          this.error = true;
        });
    },
    resendCode() {
      this.$http.post("api/v1/register-verification-request", {
        type: this.type,
        emailOrPhone: this.email,
      });
    },
  },
  components: { Password },
  props: {
    type: {
      type: String,
      required: true,
    },
    email: { type: String, required: true },
  },
});
