
import Search from "@/components/Search.vue";
import { defineComponent } from "vue";
import { Invoice } from "@/typings/Invoice";
import InfinityScroll from "@/components/InfinityScroll.vue";
import { QueryParameters } from "@/typings/QueryParameters";
import { Paginated } from "@/typings/Paginated";

export default defineComponent({
  data: () => ({
    invoices: {} as Paginated<Invoice>,
    searchTerm: "",
  }),
  mounted() {
    this.loadHistory();
  },
  methods: {
    loadHistory(page = 1) {
      let params: QueryParameters = {
        filters: {
          status: ["progress", "closed"],
        },
        orderBy: {
          updated_at: "desc",
        },
        page,
      };

      if (this.searchTerm !== "") {
        params.filters = {
          ...params.filters,
          subject: encodeURIComponent("%" + this.searchTerm + "%"),
        };
      }

      this.$http.get("api/v1/invoices", { params }).then((response) => {
        this.invoices = response.data;
      });
    },
    getStatus(key: string) {
      let status = this.statusTypes.find((status) => status.key === key);
      return status ? status.value : "";
    },
    getStatusColor(key: string) {
      let status = this.statusColors.find((status) => status.key === key);
      return status ? status.value : "";
    },
  },
  computed: {
    statusTypes() {
      return [
        { key: "paid", value: this.$t("payments.paid") },
        { key: "progress", value: this.$t("payments.in_progress") },
        { key: "failed", value: this.$t("payments.failed") },
        { key: "closed", value: this.$t("payments.confirmed") },
      ];
    },
    statusColors() {
      return [
        { key: "closed", value: "green" },
        { key: "progress", value: "yellow" },
        { key: "failed", value: "red" },
      ];
    },
  },
  components: { Search, InfinityScroll },
});
