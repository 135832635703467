import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f377e80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebarButton" }
const _hoisted_2 = { class: "sidebar-application-menu" }
const _hoisted_3 = ["onClick", "onKeypress"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: "showSidebar icon-warpit_icon_filter sidebar",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sidebar-toggled')))
      })
    ]),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          onClick: ($event: any) => (_ctx.$emit('callback', tab), (_ctx.activeTab.id = tab.id)),
          class: _normalizeClass({ active: _ctx.activeTab.id == tab.id }),
          tabindex: "0",
          onKeypress: _withKeys(($event: any) => (_ctx.$emit('callback', tab), (_ctx.activeTab.id = tab.id)), ["enter"])
        }, [
          _createElementVNode("a", null, _toDisplayString(_ctx.$t(tab.title)), 1)
        ], 42, _hoisted_3))
      }), 128))
    ])
  ], 64))
}