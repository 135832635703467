import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "transport" }
const _hoisted_2 = { class: "searchStations" }
const _hoisted_3 = {
  key: 0,
  style: {"text-align":"center"}
}
const _hoisted_4 = { class: "wrapper" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "stationName" }
const _hoisted_7 = { class: "stationNumber" }
const _hoisted_8 = { class: "names" }
const _hoisted_9 = { class: "from" }
const _hoisted_10 = {
  key: 0,
  class: "to"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InfinityScroll = _resolveComponent("InfinityScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Search, {
        modelValue: _ctx.searchTerm,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadTrips()))
      }, null, 8, ["modelValue"])
    ]),
    _createVNode(_component_InfinityScroll, {
      onLoadNextPage: _ctx.loadTrips,
      data: _ctx.trips,
      loading: _ctx.isLoading,
      class: "mainLines"
    }, {
      default: _withCtx(() => [
        (_ctx.trips.data.length === 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("global.no_data")), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trips.data, (trip) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: { name: 'LinesById', params: { id: trip.id } },
            class: "flex customTabLink",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('sidebar-toggled'))),
            key: trip.id
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(trip.route.short_name || trip.headsign), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(trip.route.long_name || _ctx.getHeadSignPart(trip.headsign, 0)), 1),
                    (!trip.route.long_name)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.getHeadSignPart(trip.headsign, 1)), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["onLoadNextPage", "data", "loading"])
  ]))
}