
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";
import MapCustom from "@/components/MapCustom.vue";
import { Coordinates, Marker } from "@/typings/Marker";
import { QueryParameters } from "@/typings/QueryParameters";
import { Location } from "@/typings/Location";

export default defineComponent({
  data: () => ({
    showMap: false,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    zoom: 15,
    markers: [] as Marker[],
  }),
  methods: {
    openMap() {
      this.loadLocations();
      this.showMap = true;
    },
    closeMap() {
      this.showMap = false;
    },
    updateCenter(value: Coordinates) {
      this.center = value;
      this.loadLocations();
    },
    updateZoom(value: number) {
      this.zoom = value;
      this.loadLocations();
    },
    loadLocations() {
      let cityId = process.env.VUE_APP_CITY_ID;
      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
        city_id: number;
      } = {
        city_id: cityId,
      };

      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;
        params.radius = Math.pow(2, 27 - this.zoom);
      }

      this.$http.get("api/v1/locations", { params }).then((response) => {
        let locationArray = response.data as Location[];
        this.markers = locationArray.map((location) => ({
          id: location.external_data?.id,
          position: {
            lat: parseFloat(location.external_data?.latitude),
            lng: parseFloat(location.external_data?.longitude),
          },
          on_click_route_name: this.getRoute(location.type.name),
          type: this.getMarkerType(location.type.name),
          title: location.external_data?.name || location.external_data?.title,
        }));
      });
    },
    getRoute(type: string) {
      if (type === "parking") {
        return "ParkingById";
      }
      if (type === "transport_stop") {
        return "StationById";
      }
      if (type === "waste_station") {
        return "EcoIslandById";
      }
      if (type === "bike_station") {
        return "BikesById";
      }
      return "Home";
    },
    getMarkerType(type: string) {
      if (type === "parking") {
        return "parking";
      }
      if (type === "transport_stop") {
        return "station";
      }
      if (type === "waste_station") {
        return "waste_station";
      }
      if (type === "bike_station") {
        return "bike_station";
      }
      return "obstacle";
    },
  },
  components: { MapCustom, Dialog },
});
