
import { defineComponent } from "vue";
import { WeatherAlert as WeatherAlertsInterface } from "@/typings/Weather";

export default defineComponent({
  data: () => ({
    alerts: [] as WeatherAlertsInterface[],
    showAllDetails: false,
  }),
  mounted() {
    this.loadWeatherWarnings();
  },
  methods: {
    loadWeatherWarnings() {
      this.$http.get("api/v1/weather/alerts?active=1").then((response) => {
        const alertData = response.data.data;
        this.alerts = alertData
          .filter((alert: WeatherAlertsInterface) => this.isValidAlert(alert))
          .map((alert: WeatherAlertsInterface) => ({
            id: alert.id,
            category: alert.sub_category,
            sub_category: alert.sub_category,
            description: alert.description,
            severity: alert.severity,
            icon: this.mapWeatherEvents(alert.sub_category),
          }));
      });
    },
    getAlertTypeText(subCategory: string) {
      if (!this.$te("weather.alerts.weather_event." + subCategory)) {
        return;
      }

      return this.$t("weather.alerts.weather_event." + subCategory);
    },
    getAlertText(severity: string, weatherEvent: string) {
      const severityColor = this.$t(
        `weather.alerts.severity_color.${severity}`,
      );
      const eventText = this.$tc(
        `weather.alerts.weather_event.${weatherEvent}`,
        2,
      );
      return this.$t("weather.alerts.text", {
        severity_color: severityColor,
        weather_event: eventText,
      });
    },
    toggleAllAlertDetails() {
      this.showAllDetails = !this.showAllDetails;
    },
    mapWeatherEvents(subCategory: string) {
      let icon;
      switch (subCategory) {
        case "rainfall":
          icon = "rainfall";
          break;
        case "snow":
          icon = "cloudy-snowing";
          break;
        case "wind":
          icon = "wind";
          break;
        case "thunderstorms":
          icon = "thunderstorm";
          break;
        case "fireRisk":
          icon = "fire-danger";
          break;
        case "lowTemperature":
          icon = "thermometer-loss";
          break;
        case "highTemperature":
          icon = "thermometer-add";
          break;
        case "avalancheRisk":
          icon = "avalanche";
          break;
        case "hail":
          icon = "hail";
          break;
        case "ice":
          icon = "black-ice";
          break;
      }
      return icon;
    },
    getSeverityColor(severity: string) {
      if (severity === "high") {
        return "orange";
      } else if (severity === "critical") {
        return "red";
      }

      return "black";
    },
    isValidAlert(alert: WeatherAlertsInterface): boolean {
      const isValidSeverity = ["critical", "high"].includes(alert.severity);
      const isValidCategory = alert.category === "weather";
      const isValidSubCategory = [
        "wind",
        "rainfall",
        "thunderstorms",
        "snow",
        "ice",
        "highTemperature",
        "lowTemperature",
        "fireRisk",
        "avalancheRisk",
        "hail",
      ].includes(alert.sub_category);

      return isValidSeverity && isValidCategory && isValidSubCategory;
    },
  },
});
