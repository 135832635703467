
import { defineComponent } from "vue";
import Button from "primevue/button";
import IconChevronLeft from "~icons/tabler/chevron-left";
import { Route } from "@/typings/RoutePlanner";
import IconWalk from "~icons/tabler/walk";
import IconBike from "~icons/tabler/bike";
import IconCar from "~icons/tabler/car";
import IconChevronRight from "~icons/tabler/chevron-right";
import Divider from "primevue/divider";

export default defineComponent({
  components: {
    Button,
    IconChevronLeft,
    IconWalk,
    IconBike,
    IconCar,
    IconChevronRight,
    Divider,
  },

  computed: {
    route(): Route {
      return this.$store.getters["route-planner/route"];
    },
  },

  methods: {
    goBack() {
      if (window.history.length > 1) {
        this.$router.back();
      } else {
        this.$router.push({ name: "RoutePlanner" });
      }
    },
    getDuration(route: Route): string {
      const duration = this.$moment.duration(
        this.$moment(route.endTime).diff(this.$moment(route.startTime)),
      );

      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.minutes());

      return hours >= 1 ? `${hours}h ${minutes}min` : `${minutes}min`;
    },
    getDistance(route: Route): string {
      const meters = Math.floor(route.legs[0].distance.value);
      const kilometers = Math.floor(meters / 1000);
      const remainingMeters = Math.floor(meters % 1000);

      return kilometers > 0
        ? `${kilometers} km ${remainingMeters} m`
        : `${meters} m`;
    },
    getArrivalTime(route: Route): string {
      return this.$moment(route.endTime).format("HH:mm");
    },
  },
});
