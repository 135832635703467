
import { defineComponent } from "vue";
import Dropdown, { DropdownChangeEvent } from "primevue/dropdown";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import Button from "primevue/button";

export default defineComponent({
  components: { Dropdown, Button },
  props: {
    searchPlaceholderKey: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    address: null,
    selectedSorting: "distance",
  }),
  methods: {
    clearAddress() {
      this.address = null;
      this.$emit("clear");
    },
  },
  computed: {
    sortOptions() {
      return [
        { name: this.$t("parking.sort_by_distance"), value: "distance" },
        { name: this.$t("parking.sort_by_occupancy"), value: "occupancy" },
      ];
    },
  },
});
