
import { defineComponent } from "vue";
import Button from "primevue/button";
import Menu from "primevue/menu";
import FavouriteDialog from "@/components/routePlanner/FavouriteDialog.vue";
import { FavouriteLocation } from "@/typings/RoutePlanner";
import IconHome from "~icons/tabler/home";
import IconBuildingSkyscraper from "~icons/tabler/building-skyscraper";
import IconSchool from "~icons/tabler/school";
import IconBusStop from "~icons/tabler/bus-stop";
import IconBuildingFactory from "~icons/tabler/building-factory";
import IconBuilding from "~icons/tabler/building";
import IconBuildingHospital from "~icons/tabler/building-hospital";
import IconPlane from "~icons/tabler/plane";
import IconBuildingChurch from "~icons/tabler/building-church";
import IconBuildingCastle from "~icons/tabler/building-castle";
import IconBuildingStore from "~icons/tabler/building-store";
import IconDotsVertical from "~icons/tabler/dots-vertical";
import IconMapPin from "~icons/tabler/map-pin";
import IconPencil from "~icons/tabler/pencil";
import IconTrash from "~icons/tabler/trash";
import Dialog from "primevue/dialog";
import { Paginated } from "@/typings/Paginated";

export default defineComponent({
  components: {
    Button,
    FavouriteDialog,
    IconMapPin,
    IconHome,
    IconBuildingSkyscraper,
    IconSchool,
    IconBusStop,
    IconBuildingFactory,
    IconBuilding,
    IconBuildingHospital,
    IconPlane,
    IconBuildingChurch,
    IconBuildingCastle,
    IconBuildingStore,
    IconDotsVertical,
    IconPencil,
    IconTrash,
    Menu,
    Dialog,
  },
  data: () => ({
    page: 1,
    savedLocations: null as null | Paginated<FavouriteLocation>,
    selectedLocation: null as null | FavouriteLocation,
    showDeleteLocationDialog: false,
    isAddressModalOpen: false,
    isSavedLocationsModalOpen: false,
    icons: [
      {
        value: "map-pin",
        icon: IconMapPin,
      },
      {
        value: "home",
        icon: IconHome,
      },
      {
        value: "building-skyscraper",
        icon: IconBuildingSkyscraper,
      },
      {
        value: "school",
        icon: IconSchool,
      },
      {
        value: "bus-stop",
        icon: IconBusStop,
      },
      {
        value: "building-factory",
        icon: IconBuildingFactory,
      },
      {
        value: "building",
        icon: IconBuilding,
      },
      {
        value: "building-hospital",
        icon: IconBuildingHospital,
      },
      {
        value: "plane",
        icon: IconPlane,
      },
      {
        value: "building-church",
        icon: IconBuildingChurch,
      },
      {
        value: "building-castle",
        icon: IconBuildingCastle,
      },
      {
        value: "building-store",
        icon: IconBuildingStore,
      },
    ],
  }),
  methods: {
    async getSavedLocations() {
      try {
        const response = await this.$maas.get("api/v1/citizen-locations", {
          params: {
            page: this.page,
            perPage: 3,
          },
        });

        if (this.savedLocations && this.page !== 1) {
          this.savedLocations.data = this.savedLocations.data.concat(
            response.data.data,
          );

          this.savedLocations.last_page = response.data.last_page;

          return;
        }

        this.savedLocations = response.data;
      } catch (error) {
        //
      }
    },
    getIconComponent(iconValue: string) {
      const icon = this.icons.find((item) => item.value === iconValue);
      return icon ? icon.icon : IconMapPin;
    },
    async loadMore() {
      this.page++;
      await this.getSavedLocations();
    },
    showMenu(event: Event, location: FavouriteLocation) {
      this.selectedLocation = location;
      const menuRef = this.$refs.menu as Menu;
      menuRef?.toggle(event);
    },
    showDeleteDialog(location: FavouriteLocation) {
      this.selectedLocation = location;
      this.showDeleteLocationDialog = true;
    },
    openEditDialog(location: FavouriteLocation) {
      this.selectedLocation = { ...location };
      this.isAddressModalOpen = true;
    },
    closeFavouriteDialog() {
      this.isAddressModalOpen = false;
      this.selectedLocation = null;
    },
    async deleteLocation(location: FavouriteLocation) {
      try {
        await this.$maas.delete(`api/v1/citizen-locations/${location.id}`);

        this.showDeleteLocationDialog = false;
        this.selectedLocation = null;

        this.page = 1;
        await this.getSavedLocations();
      } catch (error) {
        //
      }
    },
    cancelDeletion() {
      this.showDeleteLocationDialog = false;
      this.selectedLocation = null;
    },
    handleDelete() {
      if (this.selectedLocation) {
        this.deleteLocation(this.selectedLocation);
      }
    },
  },
  computed: {
    showLoadMore() {
      return this.savedLocations && this.savedLocations.last_page > this.page;
    },
    menuItems() {
      return [
        {
          label: this.$t("global.edit"),
          icon: "IconPencil",
          command: () => {
            if (this.selectedLocation) {
              this.openEditDialog(this.selectedLocation);
            }
          },
        },
        {
          label: this.$t("global.delete"),
          icon: "IconTrash",
          command: () => {
            if (this.selectedLocation) {
              this.showDeleteDialog(this.selectedLocation);
            }
          },
        },
      ];
    },
  },
  async mounted() {
    await this.getSavedLocations();
  },
});
