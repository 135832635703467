
import { defineComponent } from "vue";
import Button from "primevue/button";
import Divider from "primevue/divider";
import Modes from "./Modes.vue";
import TimeSelect from "./TimeSelect.vue";
import AddressSearch from "./AddressSearch.vue";
import { RouteLocation } from "@/typings/RoutePlanner";
import IconMapPin from "~icons/tabler/map-pin";
import IconFlag from "~icons/tabler/flag";
import IconChevronLeft from "~icons/tabler/chevron-left";

export default defineComponent({
  components: {
    Button,
    Divider,
    Modes,
    TimeSelect,
    AddressSearch,
    IconMapPin,
    IconFlag,
    IconChevronLeft,
  },
  data: () => ({
    startLoc: null as null | RouteLocation,
    endLoc: null as null | RouteLocation,
    modes: [] as Array<string>,
    eventType: "now",
    eventAt: new Date(),
  }),
  computed: {
    hasRouteQuery() {
      return Object.keys(this.$route.query).length > 0;
    },
  },
  methods: {
    goBack() {
      this.startLoc = null;
      this.endLoc = null;
      this.modes = [];
      this.eventType = "now";
      this.eventAt = new Date();

      this.$router.push({ name: "RoutePlanner" });
    },
    goToRouteView() {
      if (!this.startLoc || !this.endLoc) {
        return;
      }

      const query = {
        startLoc: this.startLoc?.display_name,
        startLocLat: this.startLoc?.lat,
        startLocLon: this.startLoc?.lon,
        endLoc: this.endLoc?.display_name,
        endLocLat: this.endLoc?.lat,
        endLocLon: this.endLoc?.lon,
        modes: this.modes?.join(","),
        eventType: this.eventType,
        eventAt: this.$moment(this.eventAt).toISOString(),
      };

      this.$router.push({
        name: "RoutesPlannerView",
        query,
      });
    },
  },
  watch: {
    "$route.query": {
      handler() {
        if (!this.hasRouteQuery) {
          return;
        }

        this.startLoc = {
          display_name: this.$route.query.startLoc as string,
          lat: this.$route.query.startLocLat as string,
          lon: this.$route.query.startLocLon as string,
        };
        this.endLoc = {
          display_name: this.$route.query.endLoc as string,
          lat: this.$route.query.endLocLat as string,
          lon: this.$route.query.endLocLon as string,
        };
        this.modes = this.$route.query.modes
          ? (this.$route.query.modes as string).split(",").map((mode) => mode)
          : [];
        this.eventType = this.$route.query.eventType as string;
        this.eventAt = this.$route.query.eventAt
          ? this.$moment(this.$route.query.eventAt).toDate()
          : new Date();
      },

      immediate: true,
    },
  },
});
