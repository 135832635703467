import { ActionContext } from "vuex";

interface State {
  activeRequests: number;
}

const state = (): State => ({
  activeRequests: 0,
});

const getters = {
  activeRequests: (state: State): number => {
    return state.activeRequests;
  },
};

const mutations = {
  increase(state: State): void {
    state.activeRequests += 1;
  },
  decrease(state: State): void {
    state.activeRequests -= 1;
  },
};

const actions = {
  increaseActiveRequests(context: ActionContext<State, State>): void {
    context.commit("increase");
  },
  decreaseActiveRequests(context: ActionContext<State, State>): void {
    context.commit("decrease");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
