<template>
  <div class="thankYou">
    <img
      src="@/assets/images/hearts.svg"
      :alt="$t('accessibility.payment.invoice_payment')"
      class="hearts"
    />

    <h2>{{ $t("payments.thanks") }}, Mihaela!</h2>
    <hr class="primaryShort center" />
    <p>
      <strong>{{ $t("payments.payment_sent") }}</strong
      ><br /><br />
      Pellentesque vel ex dui. Aenean vel lorem ex. Phasellus ullamcorper
      imperdiet fringilla. Donec at ex nisl. Class aptent taciti sociosqu ad
      litora torquent per conubia nostra, per inceptos himenaeos.
    </p>
    <button
      class="defaultButton whiteBG"
      @click="$router.push({ name: 'PaymentHistory' })"
    >
      {{ $t("payments.list_accounts") }}
    </button>
    <router-link :to="{ name: 'Home' }" class="cancel"
      >{{ $t("payments.start_screen") }}
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped></style>
