
import { defineComponent } from "vue";
import SidebarUserWrapper from "@/components/SidebarUserWrapper.vue";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import {
  WasteForm,
  WasteFormErrors,
  WasteSMSNotifications,
} from "@/typings/Waste";

export default defineComponent({
  data: () => ({
    showInfoDialog: false,
    showUpdateSuccessfulDialog: false,
    sidebarComponent: SidebarUserMenu,
    form: {
      om_type: "",
      phone: "",
      sms_notifications: false,
    } as WasteForm,
    errors: {} as WasteFormErrors,
    regexPhone:
      /^(?:(?:(\+|00)\d{3}\s?)|(?:\d{3}))?(\s?\d{9}|\s?\d{3}(?:[\s-]?\d{3}){2}|\s?\d{2}(?:[\s-]?\d{3}){2})$/,
  }),

  methods: {
    saveSettings() {
      if (this.validate()) {
        return;
      }

      let params = {
        omOznaka: this.form.om_type,
        telefon: this.form.phone,
        datumPrijava: new Date(),
      } as WasteSMSNotifications;

      if (!this.form.sms_notifications) {
        params.datumOdjava = new Date();
      }

      if (this.form.phone) {
        this.form.phone = this.form.phone.replace(/[\s-]/g, "");
      }

      this.showInfoDialog = false;

      this.$http
        .post("api/v1/waste-management/sms-notification", params)
        .then(() => {
          this.showUpdateSuccessfulDialog = true;
        })
        .catch(() => {
          this.errors.response_error = this.$t("error.mistake");
          return;
        });
    },
    validate() {
      this.errors = {} as WasteFormErrors;
      if (this.form.om_type.trim().length === 0) {
        this.errors.om_type_error = this.$t("my_waste_errors.omType");
      }

      if (this.form.phone.trim().length === 0) {
        this.errors.phone_error = this.$t("my_waste_errors.phone");
      }

      if (
        this.form.phone.trim().length !== 0 &&
        !this.regexPhone.test(this.form.phone)
      ) {
        this.errors.phone_error = this.$t("my_waste_errors.phone_valid");
      }

      if (Object.keys(this.errors).length === 0) {
        return false;
      }

      return true;
    },
  },
  components: {
    SidebarUserWrapper,
    Button,
    Dialog,
    InputText,
    InputSwitch,
  },
});
