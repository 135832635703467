
import { defineComponent } from "vue";
import Filters from "@/components/news/Filters.vue";
import { Filter, NewsProvider } from "@/typings/News";

export default defineComponent({
  data: () => ({
    selectedFilter: null as null | Filter,
    categories: null as null | Array<Filter>,
    providers: null as null | Array<NewsProvider>,
  }),
  computed: {
    translatedCategories() {
      if (this.categories === null) {
        return [];
      }

      return this.categories?.map((category) => {
        return {
          ...category,
          name: this.$t(`news.categories.${category.name}`),
        };
      });
    },
    filters(): Array<Filter> {
      return [
        {
          id: "current",
          name: this.$t(`news.categories.current`),
        },
        {
          id: "for-you",
          name: this.$t(`news.categories.for-you`),
        },
        {
          id: "chronologically",
          name: this.$t(`news.categories.chronologically`),
        },
        {
          name: "divider",
          divider: true,
        },
        ...(this.categories
          ?.filter((item) => this.$te(`news.categories.${item.name}`))
          .map((cat) => ({
            ...cat,
            name: this.$t(`news.categories.${cat.name}`),
          })) ?? []),
      ];
    },
  },
  methods: {
    async loadCategories() {
      let params = {
        perPage: 30,
      };

      return this.$http
        .get("api/v1/news/categories", { params })
        .then((response) => {
          this.categories = response.data.data;
        });
    },
    loadProviders() {
      let params = {
        orderBy: { title: "ASC" },
        perPage: 100,
      };

      this.$http.get("api/v1/news/providers", { params }).then((response) => {
        this.providers = response.data.data;
      });
    },
    selectFilter(filter: Filter) {
      this.selectedFilter = filter;
    },
    useQueryFilter() {
      // Ignore single article and subscriptions views
      if (
        this.$route.name === "Article" ||
        this.$route.name === "NewsSubscriptions"
      ) {
        return;
      }

      let filter: Filter | undefined;

      if (this.$route.query.view) {
        filter = this.filters.find((f) => f.id === this.$route.query.view);
      } else if (this.$route.query.category_id) {
        filter = this.filters.find(
          (f) => f.id === Number(this.$route.query.category_id),
        );
      }

      if (filter === undefined) {
        this.selectedFilter = this.filters[0];
        return;
      }

      this.selectedFilter = filter;
    },
    buildQuery() {
      let query: {
        [key: string]: string | number;
      } = {};

      if (this.selectedFilter && typeof this.selectedFilter.id === "number") {
        query.category_id = this.selectedFilter.id;
      }

      if (this.selectedFilter && typeof this.selectedFilter.id === "string") {
        query.view = this.selectedFilter.id;
      }

      if (isNaN(Number(this.$route.query.page)) === false) {
        query.page = Number(this.$route.query.page);
      }

      return query;
    },
  },
  watch: {
    selectedFilter: {
      handler(newValue, oldValue) {
        if (newValue === null) {
          return;
        }

        const query = this.buildQuery();

        // Reset page to 1 on filter change
        if (oldValue && newValue.id !== oldValue.id) {
          query.page = 1;
        }

        this.$router.push({
          name: "News",
          query,
        });
      },
    },
    "$route.query": {
      handler() {
        if (this.$route.query.view === "for-you") {
          return;
        }

        if (Object.keys(this.$route.query).length === 0) {
          return;
        }

        this.useQueryFilter();
      },
    },
  },
  mounted() {
    this.loadCategories().then(() => this.useQueryFilter());
    this.loadProviders();
  },
  components: { Filters },
});
