
import { defineComponent } from "vue";
import CustomButton from "@/components/customComponents/CustomButton.vue";

export default defineComponent({
  methods: {
    pushRoute(name: string) {
      this.$router.push({ name });
      this.$store.dispatch("navigation/setShow", false);
    },
  },
  components: { CustomButton },
});
