import { Consents } from "@/typings/Consent";
import { createGtm } from "@gtm-support/vue-gtm";

const localStorageConsents = JSON.parse(
  localStorage.getItem("consents") || "{}",
) as Consents;

const consent = localStorageConsents.analytics || false;

const gtm = createGtm({
  id: process.env.VUE_APP_GTM_ID as string,
  defer: true,
  enabled: process.env.VUE_APP_GTM_ENABLE === "true" && consent,
});

export default gtm;
