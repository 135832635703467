
import { defineComponent } from "vue";
import SidebarUserWrapper from "@/components/SidebarUserWrapper.vue";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  components: { SidebarUserWrapper },
  data: () => ({
    sidebarComponent: SidebarUserMenu,
    wasteSubscriberCollection:
      process.env.VUE_APP_WASTE_SUBSCRIBER_COLLECTION_ENABLED === "true",
    wasteManagement: process.env.VUE_APP_WASTE_MANAGEMENT_ENABLE === "true",
  }),
  mounted() {
    firebaseInstance?.analytics().logEvent("private_waste_screen", {
      page: "private_waste_screen",
      category: "private_waste_screen",
      category_desc: "Zaslon zasebnih odpadkov odprt",
      ...this.$store.getters["user/analyticsInfo"],
    });
  },
});
