
import { defineComponent, markRaw } from "vue";
import SidebarUserWrapper from "@/components/SidebarUserWrapper.vue";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";

export default defineComponent({
  data: () => ({
    sidebarComponent: markRaw(SidebarUserMenu),
  }),
  components: {
    SidebarUserWrapper,
  },
});
