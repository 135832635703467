
import Search from "@/components/Search.vue";
import { defineComponent } from "vue";
import Button from "primevue/button";
import { Invoice } from "@/typings/Invoice";
import InfinityScroll from "@/components/InfinityScroll.vue";
import { Paginated } from "@/typings/Paginated";
import { QueryParameters } from "@/typings/QueryParameters";
import Dialog from "primevue/dialog";

export default defineComponent({
  data: () => ({
    invoices: {} as Paginated<Invoice>,
    searchTerm: "",
    showErrorDialog: false,
    showMissingAddressDialog: false,
  }),
  mounted() {
    this.loadInvoices();
    if ("error" in this.$route.query) {
      this.showErrorDialog = true;
    }
  },
  methods: {
    loadInvoices(page = 1) {
      let params: QueryParameters = {
        filters: {
          status: ["open"],
        },
        orderBy: {
          due_date: "asc",
        },
        page,
      };

      if (this.searchTerm !== "") {
        params.filters = {
          ...params.filters,
          subject: encodeURIComponent("%" + this.searchTerm + "%"),
        };
      }

      this.$http.get("api/v1/invoices", { params }).then((response) => {
        if (page !== 1) {
          this.invoices.data = this.invoices.data.concat(response.data.data);
          this.invoices.current_page = response.data.current_page;
          return;
        }
        this.invoices = response.data;
      });
    },
    openInvoice(id: number) {
      if (this.userHasAddress === false) {
        this.showMissingAddressDialog = true;
        return;
      }

      this.$router.push({
        name: "InvoicePayment",
        params: { id },
      });
    },
  },
  computed: {
    userHasAddress() {
      const user = this.$store.getters["user/user"];

      if (
        user.contact_info === null ||
        user.contact_info.address === null ||
        user.contact_info.city === null ||
        user.contact_info.code === null
      ) {
        return false;
      }

      return true;
    },
  },
  components: { Search, Button, InfinityScroll, Dialog },
});
