
import { defineComponent } from "vue";
import OverlayPanel from "primevue/overlaypanel";
import { NotificationData, Notification } from "@/typings/Notification";
import InfinityScroll from "@/components/InfinityScroll.vue";
import { QueryParameters } from "@/typings/QueryParameters";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    displayModalHeader: false,
    notifications: {} as NotificationData,
    isLoading: false,
  }),
  methods: {
    openModalHeader(event: Event) {
      firebaseInstance?.analytics().logEvent("open_notifications", {
        page: "open_notifications",
        category: "register_screen_step_1",
        sub_category: "registration_completed",
        category_desc: "Prvi korak registracijskega zaslona odprt",
        sub_category_desc: "Uporabnik je zaključil registracijo",
        ...this.$store.getters["user/analyticsInfo"],
      });
      this.loadNotifications();
      (this.$refs.navRef as HTMLFormElement).toggle(event);
    },
    loadNotifications(page = 1) {
      let params: QueryParameters = {
        page,
        perPage: 20,
      };
      this.isLoading = true;
      this.$http
        .get("api/v1/notification", { params })
        .then((response) => {
          if (page !== 1) {
            this.notifications.data = this.notifications.data.concat(
              response.data.data,
            );
            this.notifications.current_page = response.data.current_page;
            return;
          }
          this.notifications = response.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getTitle(key: string) {
      let title = this.notificationTitles.find((title) => title.key === key);
      return title ? title.value : "";
    },
    getText(key: string) {
      let text = this.notificationText.find((text) => text.key === key);
      return text ? text.value : "";
    },
    pushRoute(notification: Notification) {
      this.$http
        .post("api/v1/feed/" + notification.id + "/firstTimeSeen")
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch("user/notifications");
          }
        });
      let routes = [
        { key: "chat", path: "FeedbackDashboard" },
        { key: "air-quality", path: "AirQuality" },
        { key: "survey", path: "Active" },
        { key: "event", path: "Events" },
        { key: "news", path: "News" },
        { key: "weather", path: "Weather" },
      ];
      routes.forEach((value) => {
        if (value.key === notification.details.onclick) {
          this.$router.push({ name: value.path });
        }
      });
      (this.$refs.navRef as HTMLFormElement).toggle();
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    notificationTitles() {
      return [
        { key: "chat", value: this.$t("notification.chat.title") },
        {
          key: "air_quality",
          value: this.$t("notification.air_quality.title"),
        },
        { key: "survey", value: this.$t("notification.survey.title") },
        { key: "traffic", value: this.$t("notification.traffic.title") },
        { key: "event", value: this.$t("notification.event.title") },
        { key: "news", value: this.$t("notification.news.title") },
        { key: "weather", value: this.$t("notification.weather.title") },
      ];
    },
    notificationText() {
      return [
        { key: "chat", value: this.$t("notification.chat.text") },
        {
          key: "air_quality",
          value: this.$t("notification.air_quality.text"),
        },
        { key: "survey", value: this.$t("notification.survey.text") },
        { key: "traffic", value: this.$t("notification.traffic.text") },
        { key: "event", value: this.$t("notification.event.text") },
        { key: "news", value: this.$t("notification.news.text") },
        { key: "weather", value: this.$t("notification.weather.text") },
      ];
    },
    notificationsCount() {
      return this.$store.getters["user/notifications"]?.notifications || 0;
    },
  },
  components: { OverlayPanel, InfinityScroll },
});
