
import { LocalComunityAddress } from "@/typings/WasteManagement";
import { TrashCan, WasteCollection } from "@/typings/WasteManagement";
import Divider from "primevue/divider";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  props: {
    externalId: {
      type: Number,
    },
    payload: {
      type: Object as PropType<WasteCollection>,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    trashCans(): TrashCan[] {
      return [
        {
          name: "organic",
          title: this.$t("waste.organic_trash"),
          icon: "icon-warpit_icon_waste_organic",
        },
        {
          name: "other",
          title: this.$t("waste.other_trash"),
          icon: "icon-warpit_icon_waste_other",
        },
        {
          name: "plastic",
          title: this.$t("waste.packaging_trash"),
          icon: "icon-warpit_icon_waste_packaging",
        },
        {
          name: "glass",
          title: this.$t("waste.glass_trash"),
          icon: "icon-warpit_icon_waste_glass",
        },
        {
          name: "paper",
          title: this.$t("waste.paper_trash"),
          icon: "icon-warpit_icon_waste_paper",
        },
        {
          name: "oil",
          title: this.$t("waste.oil_trash"),
          icon: "icon-warpit_icon_oil",
        },
        {
          name: "textile",
          title: this.$t("waste.textile_trash"),
          icon: "icon-warpit_icon_chlotes",
        },
        {
          name: "batteries",
          title: this.$t("waste.batteries_trash"),
          icon: "icon-warpit_icon_waste_paper",
        },
        {
          name: "WEEE",
          title: this.$t("waste.weee_trash"),
          icon: "icon-warpit_icon_phone_broken",
        },
      ];
    },
    firstTwoDates() {
      if (!this.payload) {
        return null
      }

      const firstTwoKeys = Object.keys(this.payload).slice(0, 2);
      const filteredData = {} as WasteCollection;
      firstTwoKeys.forEach((key) => {
        filteredData[key] = this.payload[key];
      });
      return filteredData;
    },
  },
  methods: {
    buildAddress(address?: LocalComunityAddress): string {
      if (!address) {
        return "";
      }
      return address.street + " " + address.house_number + ", " + address.city;
    },
    getTrashcanValue(waste_type: string, type: string) {
      let trashcan = this.trashCans.find(
        (trashCan) => trashCan.name === waste_type,
      ) as TrashCan;

      return trashcan ? trashcan[type as keyof TrashCan] : "";
    },
  },
  components: { Divider },
});
