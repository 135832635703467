<template>
  <form class="customForm">
    <ul class="chooseCard">
      <li class="selected">
        <input type="radio" id="visa" name="card" /><label for="visa"
          ><span class="icon-warpit_icon_Visa"></span
        ></label>
      </li>
      <li>
        <input type="radio" id="amex" name="card" /><label for="amex"
          ><span class="icon-warpit_icon_AMEX"></span
        ></label>
      </li>
      <li>
        <input type="radio" id="mastercard" name="card" /><label
          for="mastercard"
          ><span class="icon-warpit_icon_Master"></span
        ></label>
      </li>
      <li>
        <input type="radio" id="maestro" name="card" /><label for="maestro"
          ><span class="icon-warpit_icon_Maestro"></span
        ></label>
      </li>
    </ul>
    <div class="specialForm">
      <label for="cardHolder"> {{ $t("payments.name_on_card") }}</label>
      <InputText id="cardHolder" type="text" v-model="card.holder" />
    </div>
    <div class="specialForm">
      <label class="cardNumber" for="cardNumber">
        {{ $t("payments.card_number") }}</label
      >
      <div id="cardNumber"></div>
    </div>
    <div class="flex twoInRow">
      <div class="col1_2">
        <div class="specialForm">
          <label for="cardExpiryDate"> {{ $t("payments.card_valid") }}</label>
          <InputMask
            id="cardExpiryDate"
            type="text"
            v-model="card.expiryDate"
            mask="99/99"
            autoClear
            @focus="inputMaskFix"
          />
        </div>
      </div>
      <div class="col1_2">
        <div class="specialForm">
          <label for="cardCVV">CVV</label>
          <div id="cardCVV"></div>
        </div>
      </div>
      <a href="#" class="info"
        ><span
          class="icon-warpit_icon_info"
          @click="showInfoDialog = true"
        ></span
      ></a>
    </div>

    <hr class="grayWide" />

    <div class="saveCard" v-if="showSaveSwitch">
      <InputSwitch v-model="saveCard" />
      <label> {{ $t("payments.save_card_data") }}</label>
    </div>

    <div
      class="specialForm last"
      v-if="showSaveSwitch === false || saveCard === true"
    >
      <label for="cardCustomName"> {{ $t("payments.your_card_name") }}</label>
      <InputText id="cardCustomName" type="text" v-model="card.customName" />
    </div>
    <hr class="grayWide" />
    <p v-for="error in errors" :key="error" style="color: red; font-size: 13px">
      {{ error }}
    </p>
    <button class="defaultButton" @click.prevent="tokenizeCard">
      {{ $t("payments.continue") }}
    </button>
  </form>
  <Dialog
    v-model:visible="showInfoDialog"
    :draggable="false"
    class="confirmationDialog info-dialog"
  >
    <span class="ml-3">
      {{ $t("payments.cvv_description") }}
    </span>
    <img
      src="@/assets/images/card_cvv.svg"
      :alt="$t('forms.payment_methods.credit_card')"
    />
  </Dialog>
</template>
<script>
import { defineComponent } from "vue";
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Dialog from "primevue/dialog";

export default defineComponent({
  data: () => ({
    card: {
      holder: "",
      expiryDate: "",
      customName: "",
    },
    saveCard: false,
    showInfoDialog: false,
    // eslint-disable-next-line no-undef
    paymentInstance: new PaymentJs(),
    errors: [],
  }),
  props: {
    showSaveSwitch: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.initPaymentJs();
  },
  methods: {
    initPaymentJs() {
      this.paymentInstance.init(
        process.env.VUE_APP_BANKART_PUBLIC_INTEGRATION_KEY,
        "cardNumber",
        "cardCVV",
        function (payment) {
          payment.setNumberStyle({
            width: "100%",
            margin: 0,
            padding: "5px 0",
            border: "none",
            "border-bottom": "1px solid #aaa",
            color: "#1B1B1B",
            "font-size": "18px",
            outline: "none",
            "border-radius": 0,
          });
          payment.setCvvStyle({
            width: "100%",
            margin: 0,
            padding: "5px 0",
            border: "none",
            "border-bottom": "1px solid #aaa",
            color: "#1B1B1B",
            "font-size": "18px",
            outline: "none",
            "border-radius": 0,
          });
        }
      );
    },

    tokenizeCard() {
      this.checkUserContactInfo();
      if (this.errors.length !== 0) {
        return;
      }
      let data = {
        card_holder: this.card.holder,
        month: this.card.expiryDate.split("/")[0],
        year: this.card.expiryDate.split("/")[1],
      };
      this.paymentInstance.tokenize(
        data,
        (token, cardData) => {
          this.$emit("continue", {
            token: token,
            cardData: cardData,
            saveCard: this.saveCard,
            customName: this.card.customName,
          });
        },
        (errors) => {
          this.parseBankartErrors(errors);
        }
      );
    },
    parseBankartErrors(errors) {
      this.errors = [];
      for (let i = 0; i < errors.length; i++) {
        if (errors[i].attribute === "card_holder") {
          this.errors.push(this.$t(`error.invalid_card_holder_name`));
        }
        if (errors[i].attribute === "number") {
          if (errors[i].key === "errors.blank") {
            this.errors.push(this.$t(`error.blank_card_number`));
          } else {
            this.errors.push(this.$t(`error.invalid_card_number`));
          }
        }
        if (errors[i].attribute === "cvv") {
          if (errors[i].key === "errors.blank") {
            this.errors.push(this.$t(`error.blank_cvv`));
          } else {
            this.errors.push(this.$t(`error.invalid_cvv`));
          }
        }
        if (errors[i].attribute === "month") {
          if (errors[i].key === "errors.blank") {
            this.errors.push(this.$t(`error.empty_expiration_month`));
          } else {
            this.errors.push(this.$t(`error.invalid_expiration_month`));
          }
        }
        if (errors[i].attribute === "year") {
          if (errors[i].key === "errors.blank") {
            this.errors.push(this.$t(`error.empty_expiration_year`));
          } else if (errors[i].key === "errors.expired") {
            this.errors.push(this.$t(`error.expired_card`));
          } else {
            this.errors.push(this.$t(`Invalid_expiration_year`));
          }
        }
      }
    },
    checkUserContactInfo() {
      this.errors = [];

      const userContact = this.$store.getters["user/user"].contact_info;

      if (userContact === null) {
        this.errors.push(this.$t(`error.empty_user_address`));
      }
      if (userContact && userContact.address === null) {
        this.errors.push(this.$t(`error.empty_user_address`));
      }
      if (userContact && userContact.city === null) {
        this.errors.push(this.$t(`error.empty_user_city`));
      }
      if (userContact && userContact.code === null) {
        this.errors.push(this.$t(`error.empty_zip`));
      }
      if (this.saveCard === true && this.card.customName === "") {
        this.errors.push(this.$t(`error.empty_card_name`));
      }
    },
    inputMaskFix(event) {
      event.target.value = (event.target.value || "") + " ";
    },
  },
  components: {
    InputText,
    InputMask,
    InputSwitch,
    Dialog,
  },
});
</script>

<style scoped></style>
