<template>
  <div>
    <h2>{{ $t("verification.title") }}</h2>
    <hr class="primaryShort" />
    <div class="verificationStatus">
      <span class="icon-warpit_icon_check-round icon"></span>
      <span class="text"> {{ $t("verification.successful") }} </span>
    </div>
    <Button
      class="defaultButton"
      :label="$t(`global.continue`)"
      @click="$router.push({ name: 'MyProfile' })"
    ></Button>
    <!-- error
    <div class="verificationStatus">
      <span class="icon-warpit_icon_alert icon"></span>
      <span class="text">Nekaj je šlo narobe!</span>
    </div>--->
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Button from "primevue/button";

export default defineComponent({
  components: { Button },
});
</script>

<style scoped></style>
