
import { PropType, defineComponent } from "vue";
import Paginator from "primevue/paginator";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import ForYou from "@/components/events/ForYou.vue";
import { Filter } from "@/typings/News";
import { Paginated } from "@/typings/Paginated";
import EventsList from "@/components/events/EventsList.vue";
import { Event } from "@/typings/Event";
import { LocationQueryValueRaw } from "vue-router";
import { QueryParameters } from "@/typings/QueryParameters";

interface EventQuery
  extends Record<
    string | number,
    string | number | LocationQueryValueRaw[] | null | undefined
  > {
  category_id?: string;
  from?: string;
  until?: string;
}

export default defineComponent({
  emits: ["selectFilter"],
  props: {
    filters: {
      type: Object as PropType<Array<Filter>>,
      required: true,
    },
  },
  data: () => ({
    selectedFilter: null as null | Filter,
    events: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Event>,
  }),
  computed: {
    from: {
      get() {
        return this.$route.query.from
          ? this.$moment(this.$route.query.from).toDate()
          : this.$moment().toDate();
      },
      set(value: string) {
        let query: EventQuery = {
          ...this.$route.query,
          from: value ? this.$moment(value).toISOString() : undefined,
        };

        if (!this.until) {
          query.until = this.$moment(value).add(1, "months").toISOString();
        }

        this.$router.push({
          name: "Events",
          query,
        });
      },
    },
    until: {
      get() {
        return this.$route.query.until
          ? this.$moment(this.$route.query.until).toDate()
          : this.$moment().add(1, "months").toDate();
      },
      set(value: string) {
        let query: EventQuery = {
          ...this.$route.query,
          until: value
            ? this.$moment(value).endOf("day").toISOString()
            : undefined,
        };

        if (!this.from) {
          query.from = this.$moment().toISOString();
        }

        this.$router.push({
          name: "Events",
          query,
        });
      },
    },
  },
  methods: {
    loadEvents() {
      if (this.selectedFilter?.id === "for-you") {
        return;
      }

      let params: QueryParameters & {
        page: number;
        datetime?: [string, string];
      } = {
        page: Number(this.$route.query.page ?? 1),
        perPage: 10,
        filters: {
          category_id:
            typeof this.selectedFilter?.id === "number"
              ? this.selectedFilter?.id
              : undefined,
        },
        orderBy: {
          datetime: "ASC",
        },
      };

      if (this.from !== null && this.until !== null) {
        params.datetime = [this.from, this.until];
      }

      this.$http.get("api/v1/events", { params }).then((response) => {
        this.events = response.data;
      });
    },
    updatePage(page: number) {
      if (Number(this.$route.query.page) === page + 1) {
        return;
      }

      this.$router.push({
        name: "Events",
        query: { ...this.$route.query, page: page + 1 },
      });
    },
    useQueryFilter() {
      let filter: Filter | undefined;

      if (this.$route.query.view) {
        filter = this.filters.find((f) => f.id === this.$route.query.view);
      } else if (this.$route.query.category_id) {
        filter = this.filters.find(
          (f) => f.id === Number(this.$route.query.category_id),
        );
      }

      if (filter === undefined) {
        this.selectedFilter = this.filters[0];
        return;
      }

      this.selectedFilter = filter;
    },
    resetEvents() {
      this.events = {
        data: [],
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      };
    },
    updateSavedValue(event: Event, value: boolean) {
      const found = this.events.data.find((e) => e.id === event.id);

      if (!found) {
        return;
      }

      found.saved = value;
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        if (Object.keys(this.$route.query).length === 0) {
          return;
        }

        this.useQueryFilter();
        this.loadEvents();
      },
    },
  },
  mounted() {
    if (this.$primevue.config.locale) {
      this.$primevue.config.locale.clear = this.$t("global.clear");
      this.$primevue.config.locale.today = this.$t("global.today");
    }
  },
  components: {
    Paginator,
    Calendar,
    Dropdown,
    ForYou,
    EventsList,
  },
});
