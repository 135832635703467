<template>
  <div>
    <h2>{{ $t("payments.payment") }}</h2>
    <hr class="primaryShort" />
    <span
      style="
        width: 100%;
        height: 450px;
        background: #231f20;
        color: #fff;
        display: flex;
        justify-content: center;
      "
      >Span zamenjaj za PDF</span
    >

    <hr class="grayWide mt-20" />
    <button
      class="defaultButton next mt-20"
      @click="$router.push({ name: 'PaymentStep1' })"
    >
      {{ $t("payments.forward") }}
      <span class="icon-warpit_icon_arrow-R"></span>
    </button>
    <router-link :to="{ name: 'PaymentOpen' }" class="cancel">
      {{ $t("payments.decline_pay") }}
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    name_lastname: "",
    cardNumber: "",
    ccv: "",
    date: "",
  }),
});
</script>

<style scoped></style>
