
import { defineComponent } from "vue";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  mounted() {
    firebaseInstance?.analytics().logEvent("tell_city", {
      page: "tell_city",
      category: "feedback_screen",
      category_desc: "Zaslon povratnih informacij odprt",
      sub_category: "feedback_submit",
      sub_category_desc: "Uporabnik je oddal povratne informacije",
      ...this.$store.getters["user/analyticsInfo"],
    });
  },
});
