
import ProgressBar from "primevue/progressbar";
import {
  WasteStations,
  TrashCans,
  PaginatedWasteStations,
} from "@/typings/WasteManagement";
import { defineComponent } from "vue";
import InfinityScroll from "@/components/InfinityScroll.vue";
import { StationQueryParameters } from "@/typings/WasteManagement";

export default defineComponent({
  components: {
    ProgressBar,
    InfinityScroll,
  },
  props: {
    filter: Object,
  },
  data: () => ({
    wasteStations: {} as PaginatedWasteStations,
    isLoading: false,
    trashCans: [
      {
        name: "organic",
        icon: "icon-warpit_icon_waste_organic",
        disable: false,
        show: true,
      },
      {
        name: "other",
        icon: "icon-warpit_icon_waste_other",
        disable: false,
        show: true,
      },
      {
        name: "plastic",
        icon: "icon-warpit_icon_waste_packaging",
        disable: false,
        show: true,
      },
      {
        name: "glass",
        icon: "icon-warpit_icon_waste_glass",
        disable: false,
        show: true,
      },
      {
        name: "paper",
        icon: "icon-warpit_icon_waste_paper",
        disable: false,
        show: true,
      },
      {
        name: "oil",
        icon: "icon-warpit_icon_oil",
        disable: false,
        show: true,
      },
      {
        name: "textile",
        icon: "icon-warpit_icon_chlotes",
        disable: false,
        show: true,
      },
      {
        name: "batteries",
        icon: "icon-warpit_icon_battery",
        disable: false,
        show: true,
      },
      {
        name: "WEEE",
        icon: "icon-warpit_icon_phone_broken",
        disable: false,
        show: true,
      },
    ],
  }),
  methods: {
    checkIfStationSmart(trashcans: TrashCans[]) {
      return trashcans.some((trashcan: TrashCans) => trashcan.smart === 1);
    },
    getProgressBarColorClass(index: number | null) {
      if (index === null) {
        return "sensorGreen";
      }
      return index > 80
        ? "sensorRed"
        : index >= 66 && index <= 80
        ? "sensorOrange"
        : index >= 51 && index <= 65
        ? "sensorYellow"
        : "sensorGreen";
    },
    loadWasteStations(page = 1) {
      let params: StationQueryParameters = {
        page,
        perPage: 20,
      };
      if (this.filter && this.filter.id !== "all") {
        params.waste_type = this.filter.id;
      }
      this.isLoading = true;
      this.$http
        .get("api/v1/waste-management/stations", { params })
        .then((response) => {
          if (page !== 1) {
            this.wasteStations.data = this.wasteStations.data.concat(
              response.data.data
            );
            this.wasteStations.current_page = response.data.current_page;
            return;
          }
          this.wasteStations = response.data;
          this.configTrashcans();

          if (
            "id" in this.$route.params === true ||
            this.wasteStations.data.length === 0
          ) {
            return;
          }

          let firstWasteStation: WasteStations = this.wasteStations.data[0];
          this.$router.push({
            name: "EcoIslandById",
            params: { id: firstWasteStation.id },
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    checkTrashcanExists(stationTrashCans: TrashCans[], trashcan: string) {
      return stationTrashCans.some((stationTrashCan: TrashCans) => {
        return stationTrashCan.waste_type === trashcan;
      });
    },
    configTrashcans() {
      for (let trashcan of this.trashCans) {
        if (trashcan.name !== this.filter?.id && this.filter?.id !== "all") {
          trashcan.disable = true;
          continue;
        }
        trashcan.disable = false;
      }
    },
  },
  watch: {
    filter() {
      this.loadWasteStations();
    },
  },
});
