
import { defineComponent } from "vue";
import Button from "primevue/button";

export default defineComponent({
  props: {
    application: { type: Object },
  },
  components: { Button },
});
