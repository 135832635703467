
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import AccordionTab from "primevue/accordiontab";
import Accordion from "primevue/accordion";
import Sipass from "@/components/login/Sipass.vue";
import Rekono from "@/components/login/Rekono.vue";
import { firebaseInstance } from "@/plugins/firebase";

interface Error {
  verify: boolean;
}

export default defineComponent({
  components: {
    InputText,
    Password,
    Button,
    Accordion,
    AccordionTab,
    Sipass,
    Rekono,
  },
  data: () => ({
    form: {
      username: "",
      password: "",
    },
    errors: "",
    sipassEnabled: process.env.VUE_APP_SIPASS_ENABLE === "true",
    rekonoEnabled: process.env.VUE_APP_REKONO_ENABLE === "true",
  }),
  mounted() {
    if (this.$route.query.code) {
      this.loginWithKeycloakCode();
    }
  },
  methods: {
    login() {
      const data = { ...this.form };

      if (!data.username.includes("@")) {
        data.username = data.username.replace(/[\s-]/g, "");
      }

      this.$store
        .dispatch("user/login", data)
        .then(() => {
          firebaseInstance?.analytics().logEvent("login_screen", {
            page: "login_screen",
            category: "login_screen",
            category_desc: "Prijavni zaslon odprt",
            sub_category: "user_logged_in",
            sub_category_desc: "Uporabnik se je prijavil",
            login_type: "simple",
            ...this.$store.getters["user/analyticsInfo"],
          });
          this.$router.push({ name: "Home" });
        })
        .catch((error: Error) => {
          if (error.verify === true) {
            this.$router.push({
              name: "Register",
              state: {
                phoneEmail: this.form.username,
              },
            });
            return;
          }
          this.errors = this.$t(`error.login_unsuccessful`);
        });
    },
    loginWithKeycloakCode() {
      if (!this.$route.query.code) {
        return;
      }
      this.$store
        .dispatch("user/authorizationFlowLogin", this.$route.query.code)
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .finally(() => {
          this.$router.replace({ query: undefined });
        });
    },
  },
});
