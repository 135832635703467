import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "time-buttons" }
const _hoisted_2 = { class: "time-content" }
const _hoisted_3 = { class: "time-filters" }
const _hoisted_4 = { class: "time-chart" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "zone-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: _normalizeClass(["time-button", { active: _ctx.selectedTimePeriod === 'week' }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.selectedTimePeriod = 'week';
        _ctx.loadAirQualityHistory();
      })
      }, _toDisplayString(_ctx.$t("air_quality.last_week")), 3),
      _createElementVNode("button", {
        class: _normalizeClass(["time-button", { active: _ctx.selectedTimePeriod === 'day' }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => {
        _ctx.selectedTimePeriod = 'day';
        _ctx.loadAirQualityHistory();
      })
      }, _toDisplayString(_ctx.$t("air_quality.last_day")), 3)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("air_quality.select_param")), 1),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.sensor.measurements.PM10 !== null)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: _normalizeClass(["time-filter", { active: _ctx.selectedFilter === 'pm10' }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => {
          _ctx.selectedFilter = 'pm10';
          _ctx.loadAirQualityHistory();
        })
            }, _toDisplayString(_ctx.$t("sensors_parameters.sensor_attributes.pm10.label")), 3))
          : _createCommentVNode("", true),
        (_ctx.sensor.measurements.PM2_5 !== null)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: _normalizeClass(["time-filter", { active: _ctx.selectedFilter === 'pm2_5' }]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => {
          _ctx.selectedFilter = 'pm2_5';
          _ctx.loadAirQualityHistory();
        })
            }, _toDisplayString(_ctx.$t("sensors_parameters.sensor_attributes.pm2_5.label")), 3))
          : _createCommentVNode("", true),
        (_ctx.sensor.measurements.SO2 !== null)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: _normalizeClass(["time-filter", { active: _ctx.selectedFilter === 'so2' }]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => {
          _ctx.selectedFilter = 'so2';
          _ctx.loadAirQualityHistory();
        })
            }, _toDisplayString(_ctx.$t("sensors_parameters.sensor_attributes.so2.label")), 3))
          : _createCommentVNode("", true),
        (_ctx.sensor.measurements.O3 !== null)
          ? (_openBlock(), _createElementBlock("button", {
              key: 3,
              class: _normalizeClass(["time-filter", { active: _ctx.selectedFilter === 'o3' }]),
              onClick: _cache[5] || (_cache[5] = ($event: any) => {
          _ctx.selectedFilter = 'o3';
          _ctx.loadAirQualityHistory();
        })
            }, _toDisplayString(_ctx.$t("sensors_parameters.sensor_attributes.o3.label")), 3))
          : _createCommentVNode("", true),
        (_ctx.sensor.measurements.NO2 !== null)
          ? (_openBlock(), _createElementBlock("button", {
              key: 4,
              class: _normalizeClass(["time-filter", { active: _ctx.selectedFilter === 'no2' }]),
              onClick: _cache[6] || (_cache[6] = ($event: any) => {
          _ctx.selectedFilter = 'no2';
          _ctx.loadAirQualityHistory();
        })
            }, _toDisplayString(_ctx.$t("sensors_parameters.sensor_attributes.no2.label")), 3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("i", {
          class: "icon-warpit_icon_alert icon",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showDialog = true))
        }),
        (_ctx.graphLoaded)
          ? (_openBlock(), _createBlock(_component_Chart, {
              key: 0,
              options: _ctx.graphOptions
            }, null, 8, ["options"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showDialog,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showDialog) = $event)),
      class: "sensor-dialog",
      draggable: false
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("p", null, null, 512), [
            [_directive_t, 
            'sensors_parameters.sensor_attributes.' + _ctx.selectedFilter + '.label'
          ]
          ]),
          _withDirectives(_createElementVNode("p", null, null, 512), [
            [_directive_t, 
            'sensors_parameters.sensor_attributes.' + _ctx.selectedFilter + '.name'
          ]
          ])
        ])
      ]),
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("p", null, null, 512), [
          [_directive_t, 
        'sensors_parameters.sensor_attributes.' +
        _ctx.selectedFilter +
        '.description'
      ]
        ]),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aqRanges[_ctx.selectedFilter], (range, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "color",
                  style: _normalizeStyle({ 'background-color': _ctx.colors[index] })
                }, null, 4),
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.aqRanges[_ctx.selectedFilter][index - 1] ?? 0) + "-" + _toDisplayString(range) + " µg/m³ - ", 1),
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t("sensors_parameters.sensor_levels." + _ctx.levels[index])), 1)
                ])
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}