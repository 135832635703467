
import { defineComponent } from "vue";
import { Coordinates, Marker } from "@/typings/Marker";

export default defineComponent({
  data: () => ({
    temporaryCenter: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    zoom: 14,
    hoveredMarker: null as Marker | null,
  }),
  props: {
    center: {
      type: Object as () => Coordinates,
      default: () => ({
        lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
      }),
    },
    markers: {
      type: Array as () => Marker[],
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    if (this.markers !== null && this.markers.length === 1) {
      this.$emit("centerChanged", this.markers[0].position);
    }
  },
  methods: {
    markerIcon(marker: Marker) {
      if (marker.type === "parking") {
        return "/markers/parking.svg";
      } else if (marker.type === "air_quality") {
        return "/markers/airQuality.svg";
      } else if (marker.type === "station") {
        return "/markers/bus.svg";
      } else if (marker.type === "obstacle") {
        return "/markers/obstacle.svg";
      } else if (marker.type === "roadblock") {
        return "/markers/roadblock.svg";
      } else if (marker.type === "roadwork") {
        return "/markers/roadwork.svg";
      } else if (marker.type === "trafficjam") {
        return "/markers/trafficJam.svg";
      } else if (marker.type === "waste_station") {
        return "/markers/waste-island.svg";
      } else if (marker.type === "bike_station") {
        return "/markers/bike.svg";
      }
      return "/markers/marker.svg";
    },
    openInfo(marker: Marker) {
      if (marker.on_click_route_name === null) {
        return;
      }
      this.$router.push({
        name: marker.on_click_route_name,
        params: { id: marker.id },
      });
      this.$emit("close");
    },
    updateTemporaryCenter(event: { lat(): number; lng(): number }) {
      // This event is called every time center coordinates changes.
      // If we update center property directly, map freezes.
      // Thats why we only update center when dragend event fires
      this.temporaryCenter = { lat: event.lat(), lng: event.lng() };
    },
    updateCenter() {
      this.$emit("centerChanged", this.temporaryCenter);
    },
    updateZoom(value: number) {
      // Zoom event is called twice for some reason
      if (this.zoom === value) {
        return;
      }
      this.zoom = value;
      this.$emit("zoomChanged", value);
    },
  },
});
