
import { defineComponent } from "vue";
import InfinityScroll from "@/components/InfinityScroll.vue";
import AirQuality from "@/components/home/FeedItems/AirQuality.vue";
import News from "@/components/home/FeedItems/News.vue";
import Event from "@/components/home/FeedItems/Event.vue";
import Survey from "@/components/home/FeedItems/Survey.vue";
import Default from "@/components/home/FeedItems/Default.vue";
import { FeedElement } from "@/typings/FeedElement";
import { QueryParameters } from "@/typings/QueryParameters";
import { Paginated } from "@/typings/Paginated";

export default defineComponent({
  data: () => ({
    feed: {
      data: [] as FeedElement[],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
      notSeen: 0,
    } as Paginated<FeedElement> & { notSeen: number },
    bikesEnabled: process.env.VUE_APP_BIKES_ENABLE === "true",
    sharedMobilityEnabled: process.env.VUE_APP_SHARED_MOBILITY_ENABLE === "true",
    isLoading: false,
  }),
  mounted() {
    this.loadFeed();
  },
  methods: {
    loadFeed(page = 1) {
      let params: QueryParameters = {
        page,
      };
      this.isLoading = true;
      this.$http
        .get("api/v1/feed", { params })
        .then((response) => {
          if (page !== 1) {
            this.feed.data = this.feed.data.concat(response.data.data);
            this.feed.current_page = response.data.current_page;
            return;
          }
          this.feed = response.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getComponentName(itemType: string) {
      switch (itemType) {
        case "news":
          return News;
        case "event":
          return Event;
        case "survey":
          return Survey;
        default:
          return Default;
      }
    },
  },
  components: {
    InfinityScroll,
    AirQuality,
    News,
    Event,
    Survey,
    Default,
  },
});
