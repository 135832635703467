import { ActionContext } from "vuex";
import { Route } from "@/typings/RoutePlanner";

interface State {
  route: Route | null;
}

const state = (): State => ({
  route: null,
});

const getters = {
  route: (state: State): Route | null => {
    return state.route;
  },
};

const mutations = {
  setRoute(state: State, route: Route): void {
    state.route = route;
  },
};

const actions = {
  setRoute(context: ActionContext<State, State>, route: Route): void {
    context.commit("setRoute", route);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
