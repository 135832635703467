
import { defineComponent } from "vue";
import CustomButton from "@/components/customComponents/CustomButton.vue";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    origin: "",
    destination: "",
  }),
  methods: {
    search() {
      if (this.origin === "" || this.destination === "") {
        return;
      }
      firebaseInstance?.analytics().logEvent("public_transport_screen", {
        page: "public_transport_screen",
        category: "find_route",
        sub_category: "enter_start_of_route",
        category_desc: "Poišči linijo",
        sub_category_desc: "Vnesi začetek linije",
        ...this.$store.getters["user/analyticsInfo"],
      });
      let url =
        "https://www.google.com/maps/dir/?api=1&origin=" +
        this.origin +
        "&destination=" +
        this.destination +
        "&travelmode=transit";
      window.open(url, "_blank");
    },
    onOriginChange(value: string) {
      firebaseInstance?.analytics().logEvent("public_transport_screen", {
        page: "public_transport_screen",
        category: "find_route",
        sub_category: "enter_start_of_route",
        category_desc: "Poišči linijo",
        sub_category_desc: "Uporabnik je iskal destinacijo na zaslonu postaj",
        ...this.$store.getters["user/analyticsInfo"],
      });
      this.origin = value;
    },
    onDestinationChange(value: string) {
      firebaseInstance?.analytics().logEvent("public_transport_screen", {
        page: "public_transport_screen",
        category: "find_route",
        sub_category: "enter_destination",
        category_desc: "Poišči linijo",
        sub_category_desc: "Uporabnik je iskal pot na zaslonu postaj",
        ...this.$store.getters["user/analyticsInfo"],
      });
      this.destination = value;
    },
  },
  components: { CustomButton },
});
