
import { defineComponent } from "vue";
import Loader from "@/components/customComponents/Loader.vue";

export default defineComponent({
  name: "App",
  components: { Loader },
  created() {
    const locale = localStorage.getItem("locale");
    if (locale !== null) {
      this.$i18n.locale = locale;
      this.$moment.locale(locale);
    }
    document.title = this.$t("city.app_name");
  },
  computed: {
    activeRequests() {
      return this.$store.getters["loader/activeRequests"];
    },
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("city.app_name");
    },
  },
});
