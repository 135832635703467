import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex tabs user" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SidebarUserWrapper = _resolveComponent("SidebarUserWrapper")!

  return (_openBlock(), _createBlock(_component_SidebarUserWrapper, {
    title: _ctx.$t('user.my_payments'),
    sidebarComponent: _ctx.sidebarComponent
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, { to: { name: 'PaymentOpen' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("user.open_invoices")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: { name: 'PaymentHistory' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("user.history")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: { name: 'PaymentCards' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("user.cards")), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }, 8, ["title", "sidebarComponent"]))
}