
import { defineComponent } from "vue";
import Button from "primevue/button";
import SavedEvents from "./SavedEvents.vue";
import Subscribed from "./Subscribed.vue";
import { User } from "@/typings/User";

export default defineComponent({
  props: {
    from: {
      type: String,
      default: null,
    },
    until: {
      type: String,
      default: null,
    },
  },
  computed: {
    user(): User {
      return this.$store.getters["user/user"];
    },
    tabs(): Array<{ id: number; label: string }> {
      return [
        {
          id: 1,
          label: this.$t("events.subscribed"),
        },
        {
          id: 2,
          label: this.$t("events.saved"),
        },
      ];
    },
    selectedTab(): number {
      return Number(this.$route.query.tab) || 1;
    },
  },
  methods: {
    selectTab(tabId: number) {
      this.$router.replace({
        name: "Events",
        query: {
          view: "for-you",
          tab: tabId,
          from: this.from ? this.$moment(this.from).toISOString() : undefined,
          until: this.until
            ? this.$moment(this.until).toISOString()
            : undefined,
        },
      });
    },
  },
  mounted() {
    this.selectTab(this.selectedTab);
  },
  components: { Button, SavedEvents, Subscribed },
});
