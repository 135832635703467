
import { defineComponent } from "vue";
import Map from "@/components/Map.vue";
import { Coordinates, Marker } from "@/typings/Marker";
import Information from "@/components/TrafficInfo/Information.vue";
import { Paginated } from "@/typings/Paginated";
import { TrafficInfo } from "@/typings/TrafficInfo";
import Button from "primevue/button";
import { QueryParameters } from "@/typings/QueryParameters";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import NearbyCard from "@/components/TrafficInfo/NearbyCard.vue";

export default defineComponent({
  components: { Map, Button, Information, NearbyCard },
  data: () => ({
    trafficInfo: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<TrafficInfo>,
    selectedTrafficInfo: null as null | TrafficInfo,
    selectedMarker: null as null | Marker,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    markers: [] as Marker[],
    zoom: 14,
    address: null,
    isLoading: false,
  }),
  computed: {
    nearestTrafficInfos() {
      return this.trafficInfo.data.slice(0, 6);
    },
  },
  methods: {
    updateCenter(value: Coordinates) {
      this.center = value;

      this.loadTrafficInfo();
    },
    updateZoom(value: number, center: Coordinates) {
      this.zoom = value;
      this.center = center;

      this.loadTrafficInfo();
    },
    async loadTrafficInfo() {
      this.isLoading = true;
      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
      } = {
        perPage: 20,
        orderBy: {
          // Order by distance only when searching as it affects results
          distance: this.address !== null ? "ASC" : undefined,
        },
      };

      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;
        params.radius = Math.pow(2, 27 - this.zoom);
      }

      return this.$http
        .get("api/v2/traffic-info", { params })
        .then((response) => {
          this.trafficInfo = response.data;
          this.setMarkers();
        })
        .finally(() => (this.isLoading = false));
    },
    loadSelectedTrafficInfo() {
      if ("id" in this.$route.params === false) {
        this.unselectTrafficInfo();
        return;
      }
      this.isLoading = true;
      this.$http
        .get("api/v2/traffic-info/" + this.$route.params.id)
        .then((response) => {
          this.selectedTrafficInfo = response.data;

          if (!this.selectedTrafficInfo) {
            return;
          }

          this.selectMarker(this.selectedTrafficInfo);
          this.selectTrafficInfo(this.selectedTrafficInfo);
        })
        .catch(() => {
          this.$router.push({ name: "TrafficInfoMap" });
        })
        .finally(() => {
          this.isLoading = false;

          this.loadTrafficInfo();
        });
    },
    setMarkers() {
      this.markers = this.trafficInfo.data.map((trafficInfo) => ({
        id: trafficInfo.id,
        position: {
          lat: parseFloat(trafficInfo.latitude),
          lng: parseFloat(trafficInfo.longitude),
        },
        on_click_route_name: "TrafficInfoById",
        type:
          trafficInfo.id === this.selectedMarker?.payload?.id
            ? "selected"
            : "trafficInfo",
        title: trafficInfo.title,
        icon: this.setMarkerIcon(trafficInfo),
        payload: trafficInfo,
      }));

      if (this.selectedMarker === null) {
        return;
      }

      const existingMarkerIndex = this.markers.findIndex(
        (marker) => marker.id === this.selectedMarker?.id,
      );

      if (existingMarkerIndex !== -1) {
        this.markers[existingMarkerIndex] = this.selectedMarker;
        return;
      }

      this.markers.push(this.selectedMarker);
    },
    selectMarker(trafficInfo: TrafficInfo) {
      if (this.selectedMarker?.payload?.id === trafficInfo.id) {
        return;
      }

      this.selectedMarker = {
        id: trafficInfo.id,
        position: {
          lat: parseFloat(trafficInfo.latitude),
          lng: parseFloat(trafficInfo.longitude),
        },
        on_click_route_name: "TrafficInfoById",
        type: "selected",
        title: trafficInfo.title,
        icon: this.setMarkerIcon(trafficInfo),
        payload: trafficInfo,
      };

      if ("id" in this.$route.params && this.selectedTrafficInfo) {
        this.selectTrafficInfo(trafficInfo);
      }

      this.setMarkers();
    },
    unselectMarker() {
      this.selectedMarker = null;
      this.setMarkers();
    },
    selectTrafficInfo(trafficInfo: TrafficInfo) {
      this.selectedTrafficInfo = trafficInfo;

      this.selectMarker(trafficInfo);

      this.center = {
        lat: parseFloat(trafficInfo.latitude),
        lng: parseFloat(trafficInfo.longitude),
      };

      this.zoom = 16;

      this.$router.push({
        name: "TrafficInfoById",
        params: { id: trafficInfo.id },
      });
    },
    unselectTrafficInfo() {
      this.selectedTrafficInfo = null;

      this.zoom = 14;
      this.updateCenter(this.center);
    },
    setAddress(place: GmapPlaceResult) {
      if (place.formatted_address === undefined) {
        return;
      }

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      this.selectedTrafficInfo = null;

      this.zoom = 17;
      this.updateCenter({
        lat: latitude,
        lng: longitude,
      });
    },
    setMarkerIcon(trafficInfo: TrafficInfo) {
      if (trafficInfo.sub_category === "roadClosed") {
        return "/markers/roadblock.svg";
      } else if (trafficInfo.sub_category === "roadWorks") {
        return "/markers/roadwork.svg";
      } else if (trafficInfo.sub_category === "trafficJam") {
        return "/markers/trafficJam.svg";
      } else if (trafficInfo.sub_category === "carAccident") {
        return "/markers/carAccident.svg";
      }
      return "/markers/obstacle.svg";
    },
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.loadSelectedTrafficInfo();
      },
      immediate: true,
    },
  },
});
