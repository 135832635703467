
import { defineComponent } from "vue";
import Button from "primevue/button";
import Password from "primevue/password";
import {
  PasswordChangeForm,
  PasswordChangeErrors,
} from "@/typings/ChangePasswordForm";

export default defineComponent({
  components: { Password, Button },
  data: () => ({
    form: {} as PasswordChangeForm,
    errors: {} as PasswordChangeErrors,
  }),
  methods: {
    putPassword() {
      this.validateForm();
      if (Object.keys(this.errors).length !== 0) {
        return;
      }
      this.$http
        .put("/api/v2/user/change-password", this.form)
        .then((response) => {
          if (response.status === 204) {
            this.$router.push({ name: "MyProfile" });
          }
        })
        .catch((error) => {
          if ("errors" in error.response.data === false) {
            this.errors.something_wrong = this.$t("error.mistake");
            return;
          }
          const errors = error.response.data.errors;
          if ("current_password" in errors === true) {
            this.errors.current_password = this.$t(
              "password_error.old_password"
            );
            return;
          }
          this.errors.something_wrong = this.$t("error.mistake");
          return;
        });
    },
    checkValidPassword(password: string) {
      return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(
        password
      );
    },
    validateForm() {
      this.errors = {};
      if (!this.form.current_password) {
        this.errors.current_password = this.$t(
          "password_error.current_password"
        );
      }
      if (!this.form.password) {
        this.errors.password = this.$t("password_error.new_password");
      }
      if (!this.form.password_confirmation) {
        this.errors.password_confirmation = this.$t(
          "password_error.repeat_password"
        );
      }
      if (
        !this.form.password ||
        this.form.password.length < 8 ||
        !this.checkValidPassword(this.form.password)
      ) {
        this.errors.password = this.$t("error.unvalid_password");
      }
      if (
        this.form.current_password &&
        this.form.password &&
        this.form.password === this.form.current_password
      ) {
        this.errors.password = this.$t("password_error.different_new_password");
      }
      if (this.form.password !== this.form.password_confirmation) {
        this.errors.password_confirmation = this.$t(
          "password_error.passwords_must_match"
        );
      }
    },
  },
});
