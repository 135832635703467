
import Navigation from "@/components/Navigation.vue";
import { defineComponent } from "vue";
import AvatarCustom from "./AvatarCustom.vue";
import Notifications from "@/components/headerNotifications/Notifications.vue";
import ChangeLanguage from "@/components/ChangeLanguage.vue";
import Accessibility from "@kolaj/accessibility/src/components/Accessibility.vue";
import "@kolaj/accessibility";

export default defineComponent({
  data: () => ({
    storageUrl: process.env.VUE_APP_STORAGE_URL,
    isAccessibilityActive: false,
  }),
  methods: {
    toggleMenu() {
      this.isAccessibilityActive = false;
      this.showMenu = !this.showMenu;
    },
    pushRoute(name: string) {
      this.$router.push({ name });
      this.showMenu = false;
    },
    updateLocale() {
      localStorage.setItem("locale", this.$i18n.locale);
      this.$moment.locale(this.$i18n.locale);
    },
    openAccesibilityMenu() {
      (this.$refs.accessibilityMenu as typeof Accessibility).toggle();
    },
  },
  computed: {
    showMenu: {
      get() {
        return this.$store.getters["navigation/show"];
      },
      set(value: boolean) {
        this.$store.dispatch("navigation/setShow", value);
      },
    },
    user() {
      return this.$store.getters["user/user"];
    },
    notifications() {
      let notifications = this.$store.getters["user/notifications"];
      if (notifications === null) {
        return false;
      }
      return notifications.notifications !== 0 && notifications.messages !== 0;
    },
  },
  components: {
    ChangeLanguage,
    Notifications,
    Navigation,
    AvatarCustom,
    Accessibility,
  },
});
