
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    changeStep(stepTo: number) {
      this.$emit("step", stepTo);
    },
  },
});
