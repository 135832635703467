
import { defineComponent } from "vue";
import { Coordinates } from "@/typings/Marker";
import Divider from "primevue/divider";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import ListFilters from "@/components/info/ListFilters.vue";
import { InfoItem, InfoFeature } from "@/typings/Info";
import MultiSelect from "primevue/multiselect";
import { Filter, SelectedFilters } from "@/typings/Info";

export default defineComponent({
  components: { Divider, ListFilters, MultiSelect },
  data: () => ({
    items: [] as Array<InfoItem>,
    address: null as null | string,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    isLoading: false,
    selectedFilters: {} as SelectedFilters,
  }),
  methods: {
    async loadItems() {
      this.isLoading = true;

      this.$directus
        .get(`${this.$route.params.feature}`)
        .then((response) => {
          const items = response.data.data as Array<InfoItem>;

          this.items = items;
        })
        .finally(() => (this.isLoading = false));
    },

    setAddress(place: GmapPlaceResult) {
      this.address = place.formatted_address;

      if (this.address === undefined) {
        return;
      }

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      this.center = {
        lat: latitude,
        lng: longitude,
      };

      this.loadItems();
    },
    clearAddress() {
      this.address = null;

      this.center = {
        lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
      };

      this.loadItems();
    },
    getTitle(key: string) {
      if (!this.infoFeatures) {
        return key;
      }

      const feature = this.infoFeatures.find(
        (feature: InfoFeature) => feature.collection === key,
      );

      if (!feature) {
        return key;
      }

      return feature[("name_" + this.$i18n.locale) as keyof InfoFeature];
    },
  },
  computed: {
    filters() {
      const filters = [] as Array<Filter>;

      if (!this.infoFeature || !this.items) {
        return filters;
      }

      if (!this.infoFeature.filters) {
        return;
      }

      for (const item of this.items) {
        if (!item.properties) {
          continue;
        }

        const properties = item.properties;

        this.infoFeature.filters
          .filter((filter) => properties[filter])
          .some((filter) => {
            if (!this.selectedFilters[filter]) {
              this.selectedFilters[filter] = [];
            }

            const type = properties[filter];

            const foundFilter = filters.find((obj) => obj.key === filter);

            if (!foundFilter) {
              filters.push({
                key: filter,
                data: [
                  {
                    title: String(type),
                  },
                ],
              });

              // exit
              return true;
            }

            const foundItem = foundFilter.data.find(
              (obj) => obj.title === type,
            );

            if (!foundItem) {
              foundFilter.data.push({
                title: String(type),
              });
            }
          });
      }

      return filters;
    },
    filteredItems(): Array<InfoItem> {
      if (this.checkIfFiltersSelected) {
        return this.items;
      }

      return this.items.filter((item: InfoItem) => {
        return Object.keys(this.selectedFilters).every((filter) => {
          return (
            item.properties &&
            this.selectedFilters[filter].includes(
              String(item.properties[filter]),
            )
          );
        });
      });
    },
    checkIfFiltersSelected() {
      return !Object.keys(this.selectedFilters).some(
        (key) =>
          this.selectedFilters[key] && this.selectedFilters[key].length !== 0,
      );
    },
    user() {
      return this.$store.getters["user/user"];
    },
    infoFeatures(): InfoFeature[] | null {
      return this.$store.getters["info-features/features"];
    },
    infoFeatureExists(): boolean {
      return (
        this.infoFeatures !== null &&
        this.infoFeatures.some(
          (feature) =>
            feature.collection === this.$route.params.feature &&
            feature.view === "Map",
        )
      );
    },
    infoFeatureHasId(): boolean {
      if (!this.infoFeatures) {
        return false;
      }

      return !!this.infoFeatures.find(
        (feature) => feature.collection === this.$route.params.feature,
      );
    },

    infoFeature(): InfoFeature | undefined | null {
      if (!this.infoFeatures) {
        return null;
      }

      return this.infoFeatures.find(
        (feature) => feature.collection === this.$route.params.feature,
      );
    },
  },
  watch: {
    infoFeatures: {
      handler() {
        if (this.infoFeatureExists) {
          this.loadItems();
        }
      },
      immediate: true,
    },
  },
});
