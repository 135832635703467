import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-column-reverse justify-content-between gap-3 mb-3 sm:flex-row sm:align-items-center" }
const _hoisted_2 = { class: "flex justify-content-between border-bottom-1 border-400 w-full sm:w-fit" }
const _hoisted_3 = ["onClick", "onKeyup"]
const _hoisted_4 = {
  key: 0,
  class: "flex flex-column mt-3"
}
const _hoisted_5 = { class: "mb-0 font-bold" }
const _hoisted_6 = { class: "mt-0" }
const _hoisted_7 = { class: "mt-0" }
const _hoisted_8 = { class: "mt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Subscribed = _resolveComponent("Subscribed")!
  const _component_SavedEvents = _resolveComponent("SavedEvents")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
          return (_openBlock(), _createElementBlock("span", {
            key: tab.id,
            class: _normalizeClass(["inline-block w-full text-center py-2 px-3 cursor-pointer sm:w-fit", {
          'text-primary font-bold border-bottom-2': tab.id === _ctx.selectedTab,
        }]),
            tabindex: "1",
            onClick: ($event: any) => (_ctx.selectTab(tab.id)),
            onKeyup: _withKeys(($event: any) => (_ctx.selectTab(tab.id)), ["enter"])
          }, _toDisplayString(tab.label), 43, _hoisted_3))
        }), 128))
      ]),
      (_ctx.user && _ctx.selectedTab === 1)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            tabindex: "1",
            label: _ctx.$t('news.edit_subscription'),
            icon: "pi pi-cog",
            class: "bg-primary border-none py-2 px-3 border-round-3xl flex-shrink-0 line-height-1 w-full sm:w-fit",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'EventsSubscriptions' })))
          }, null, 8, ["label"]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("news.guest")), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("news.guest_description")), 1),
          _createElementVNode("p", _hoisted_7, [
            _createTextVNode(_toDisplayString(_ctx.$t("news.guest_instruction_1")) + " ", 1),
            _createVNode(_component_router_link, {
              to: { name: 'Login' },
              class: "font-bold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("news.here")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("p", _hoisted_8, [
            _createTextVNode(_toDisplayString(_ctx.$t("news.guest_instruction_2")) + " ", 1),
            _createVNode(_component_router_link, {
              to: { name: 'Register' },
              class: "font-bold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("news.here")), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : (_ctx.selectedTab === 1)
        ? (_openBlock(), _createBlock(_component_Subscribed, {
            key: 1,
            from: _ctx.from,
            until: _ctx.until
          }, null, 8, ["from", "until"]))
        : (_ctx.selectedTab === 2)
          ? (_openBlock(), _createBlock(_component_SavedEvents, {
              key: 2,
              from: _ctx.from,
              until: _ctx.until
            }, null, 8, ["from", "until"]))
          : _createCommentVNode("", true)
  ], 64))
}