
import { defineComponent, markRaw } from "vue";
import SidebarUserWrapper from "@/components/SidebarUserWrapper.vue";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";
import { Location } from "@/typings/Location";
import MapLocations from "@/components/user/MapLocations.vue";

export default defineComponent({
  data: () => ({
    sidebarComponent: markRaw(SidebarUserMenu),
    locations: [] as Location[],
  }),
  mounted() {
    this.loadLocations();
  },

  methods: {
    loadLocations() {
      let cityId = process.env.VUE_APP_CITY_ID;
      this.$http.get("api/v1/locations?city_id=" + cityId).then((response) => {
        this.locations = response.data.filter((location: Location) => {
          if (location.external_data) {
            return (
              location.type.name === "parking" ||
              location.type.name === "transport_stop" ||
              location.type.name === "waste_station" ||
              location.type.name === "bike_station"
            );
          }
        });
      });
    },
    deleteLocation(location: Location) {
      this.$http.delete("api/v1/locations/" + location.id).then(() => {
        let index = this.locations
          .map((location) => location.id)
          .indexOf(location.id);
        this.locations.splice(index, 1);
      });
    },
    getRoute(type: string) {
      if (type === "parking") {
        return "ParkingById";
      }
      if (type === "transport_stop") {
        return "StationById";
      }
      if (type === "waste_station") {
        return "EcoIslandById";
      }
      if (type === "bike_station") {
        return "BikesById";
      }
      return "Home";
    },
    getIcon(type: string) {
      if (type === "parking") {
        return "icon-warpit_icon_parking-dumb";
      }
      if (type === "transport_stop") {
        return "icon-warpit_icon_bus";
      }
      if (type === "waste_station") {
        return "icon-warpit_icon_waste_container";
      }
      if (type === "bike_station") {
        return "icon-warpit_icon_bike";
      }
      return "icon-warpit_icon_info";
    },
  },
  components: {
    MapLocations,
    SidebarUserWrapper,
  },
});
