import { LocaleMessages, VueMessageType, createI18n } from "vue-i18n";

import loadDefaultMessages, { loadMessagesAsync } from "@/localization";

export const defaultLocale = process.env.VUE_APP_DEFAULT_LOCALE;

const messages = loadDefaultMessages() as Record<
  string,
  LocaleMessages<VueMessageType>
>;
export const availableLocales = Object.keys(messages);

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: "en",
  messages,
});

export async function loadLocaleMessagesAsync(): Promise<void> {
  if (!process.env.VUE_APP_FLAVOUR) {
    return;
  }

  const flavourMessages = await loadMessagesAsync(process.env.VUE_APP_FLAVOUR);
  for (const language of availableLocales) {
    i18n.global.mergeLocaleMessage(language, flavourMessages[language]);
  }
}

export default i18n;
