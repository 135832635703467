
import { defineComponent } from "vue";
import SidebarUserWrapper from "@/components/SidebarUserWrapper.vue";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";
import ProgressBar from "primevue/progressbar";
import { Activity, ParameterType } from "@/typings/Activity";

export default defineComponent({
  data: () => ({
    sidebarComponent: SidebarUserMenu,
    activities: [] as Activity[],
    loyaltyEnabled: process.env.VUE_APP_LOYALTY_ENABLE === "true",
    loyaltyPoints: 0,
  }),
  mounted() {
    this.loadActivity();
    this.loadUserLoyaltyPoints();
  },
  methods: {
    getActivityValue(value: string) {
      return value ?? this.$t("profiles_activity.empty").toString();
    },
    calculateAccountCompletion() {
      let value = 0;
      let userParameters = [
        "first_name",
        "last_name",
        "email",
        "phone_number",
        "birth_date",
      ];
      for (let i = 0; i < userParameters.length; i++) {
        if (this.user[userParameters[i]]) {
          value += 10;
        }
      }

      if (this.user.contact_info === null) {
        return value;
      }

      let userContactInfoParameters = [
        "address",
        "house_number",
        "code",
        "city",
        "country",
      ];

      for (let i = 0; i < userContactInfoParameters.length; i++) {
        if (this.user.contact_info[userContactInfoParameters[i]]) {
          value += 10;
        }
      }
      return value;
    },
    loadActivity() {
      this.$http.get("api/v1/user/activity").then((response) => {
        this.activities = response.data;
      });
    },
    getActivityText(key: string) {
      let activity = this.activityTypes.find(
        (activity) => activity.key === key
      );
      return activity ? activity.value : "";
    },
    checkActivityParameter(key: string) {
      if (key === "keycloak_id" || key === "id") {
        return false;
      }
      return true;
    },
    getParameterText(parameter: string) {
      if (
        parameter in this.parameters &&
        this.parameters[parameter as keyof ParameterType].show_value
      ) {
        return this.parameters[parameter as keyof ParameterType].text;
      }
      return null;
    },
    getActivityIcon(item: Activity): string {
      if (item.activity === "updated") {
        return "icon-warpit_icon_user";
      }
      return "icon-warpit_icon_survey";
    },
    getActivityValues(item: Activity) {
      if (item.activity !== "updated") {
        return;
      }
      if (item.current_value === null && item.previous_value !== null) {
        return item.previous_value + " -> " + item.previous_value;
      } else if (item.current_value !== null && item.previous_value === null) {
        return (
          this.$t("profiles_activity.empty") +
          " -> " +
          this.$t("profiles_activity.empty")
        );
      } else {
        return item.previous_value + " -> " + item.current_value;
      }
    },
    loadUserLoyaltyPoints() {
      if (this.loyaltyEnabled === false) {
        return;
      }
      this.$http
        .get("api/v1/user/" + this.user.id + "/points")
        .then((response) => {
          this.loyaltyPoints = response.data.current;
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    parameters(): ParameterType {
      return {
        first_name: {
          text: this.$t("profiles_activity.name"),
          show_value: true,
        },
        last_name: {
          text: this.$t("profiles_activity.surname"),
          show_value: true,
        },
        phone_number: {
          text: this.$t("profiles_activity.number"),
          show_value: true,
        },
        gender_id: {
          text: this.$t("profiles_activity.gender"),
          values: {
            0: this.$t("profiles_activity.female"),
            1: this.$t("profiles_activity.male"),
          },
          show_value: true,
        },
        password: {
          text: this.$t("profiles_activity.password"),
          show_value: true,
        },
        birth_date: {
          text: this.$t("profiles_activity.birthday"),
          show_value: true,
        },
        address: {
          text: this.$t("profiles_activity.address"),
          show_value: true,
        },
        house_number: {
          text: this.$t("profiles_activity.house_number"),
          show_value: true,
        },
        city_id: {
          text: this.$t("profiles_activity.city_id"),
          show_value: true,
        },
        keycloak_id: {
          text: this.$t("profiles_activity.keycloak_id"),
          show_value: false,
        },
        gdpr_token: {
          text: this.$t("profiles_activity.gdpr"),
          show_value: false,
        },
        user_contact_info_id: {
          text: this.$t("profiles_activity.contact_info"),
          show_value: false,
        },
        password_reset_hash: {
          text: this.$t("profiles_activity.password_reset_hash"),
          show_value: false,
        },
        password_reset_tries: {
          text: this.$t("profiles_activity.password_reset_tries"),
          show_value: false,
        },
        password_reset_valid_until: {
          text: this.$t("profiles_activity.password_reset_valid_until"),
          show_value: false,
        },
        phone_verification_token: {
          text: this.$t("profiles_activity.phone_verification_token"),
          show_value: false,
        },
        phone_verified_at: {
          text: this.$t("profiles_activity.phone_verified_at"),
          show_value: true,
        },
        phone_verification_valid_until: {
          text: this.$t("profiles_activity.phone_verification_valid_until"),
          show_value: false,
        },
        email_verification_token: {
          text: this.$t("profiles_activity.email_verification_token"),
          show_value: false,
        },
        email_verification_valid_until: {
          text: this.$t("profiles_activity.email_verification_valid_until"),
          show_value: false,
        },
        email_verified_at: {
          text: this.$t("profiles_activity.email_verified_at"),
          show_value: true,
        },
      };
    },
    activityTypes() {
      return [
        {
          key: "verification-sent",
          value: this.$t("profiles_activity.verification_sent"),
        },
        { key: "blocked", value: this.$t("profiles_activity.blocked") },
        { key: "created", value: this.$t("profiles_activity.created") },
        { key: "call", value: this.$t("profiles_activity.call") },
        {
          key: "password-reset",
          value: this.$t("profiles_activity.pass_reset"),
        },
        {
          key: "password-reset-request",
          value: this.$t("profiles_activity.request_pass_reset"),
        },
        { key: "unblocked", value: this.$t("profiles_activity.unblocked") },
        { key: "updated", value: this.$t("profiles_activity.updated") },
        {
          key: "admin-access",
          value: this.$t("profiles_activity.admin_access"),
        },
        {
          key: "survey-finished",
          value: this.$t("profiles_activity.finished"),
        },
        { key: "feedback", value: this.$t("profiles_activity.feedback") },
        {
          key: "email-inbound",
          value: this.$t("profiles_activity.inbound_mail"),
        },
        {
          key: "email-outbound",
          value: this.$t("profiles_activity.outbound_mail"),
        },
        { key: "ma-email", value: this.$t("profiles_activity.ma_mail") },
        { key: "ma-sms", value: this.$t("profiles_activity.ma_sms") },
        { key: "admin-sms", value: this.$t("profiles_activity.admin_sms") },
        { key: "parking", value: this.$t("profiles_activity.parking") },
        {
          key: "personal-verification",
          value: this.$t("profiles_activity.personal_verification"),
        },
      ];
    },
  },
  components: {
    SidebarUserWrapper,
    ProgressBar,
  },
});
