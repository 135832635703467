
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    items: [
      {
        key: "purpose_of_payment_title",
        value: "purpose_of_payment",
      },
      {
        key: "purpose_code_title",
        value: "purpose_code",
      },
      {
        key: "name_of_recipient_title",
        value: "name_of_recipient",
      },
      {
        key: "address_title",
        value: "address",
      },
      {
        key: "city_title",
        value: "city",
      },
      {
        key: "bank_code_title",
        value: "bank_code",
      },
      {
        key: "account_number_title",
        value: "account_number",
      },
      {
        key: "reference_title",
        value: "reference",
      },
    ],
  }),
  methods: {
    getTranslation(value: string) {
      return this.$t(`forms.upn.${value}`);
    },
  },
});
