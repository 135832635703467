
import { defineComponent } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import ProviderItem from "@/components/user/relatedAccounts/ProviderItem.vue";
import Button from "primevue/button";
import { Paginated } from "@/typings/Paginated";
import { Provider } from "@/typings/Provider";

export default defineComponent({
  data: () => ({
    providers: {} as Paginated<Provider>,
    selectedProvider: null as Provider | null,
    error: "",
  }),
  mounted() {
    this.loadProviders();
  },
  methods: {
    loadProviders() {
      this.$http.get("api/v1/providers").then((response) => {
        this.providers = response.data;
      });
    },
    sendProvider() {
      if (this.selectedProvider === null) {
        this.error = this.$t("connected_accounts.error");
        return;
      }
      this.$emit("submit", this.selectedProvider);
    },
  },
  components: {
    Button,
    Accordion,
    AccordionTab,
    ProviderItem,
  },
});
