
import { PropType, defineComponent } from "vue";
import Categories from "@/components/news/Categories.vue";
import Dropdown from "primevue/dropdown";
import { Filter } from "@/typings/News";
import Providers from "@/components/news/Providers.vue";

export default defineComponent({
  props: {
    filters: {
      type: Object as PropType<Array<Filter>>,
      required: true,
    },
  },
  data: () => ({
    selectedTab: 1,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    tabs() {
      return [
        {
          id: 1,
          label: this.$t("news.category_sub"),
        },
        {
          id: 2,
          label: this.$t("news.sub_notifications"),
        },
      ];
    },
  },
  methods: {
    goToNews(filter: Filter) {
      this.$router.push({
        name: "News",
        query: this.buildQuery(filter),
      });
    },
    buildQuery(filter: Filter) {
      const query: {
        [key: string]: string | number;
      } = {};

      if (typeof filter.id === "number") {
        query.category_id = filter.id;
      }

      if (typeof filter.id === "string") {
        query.view = filter.id;
      }

      return query;
    },
  },
  mounted() {
    this.$emit("selectFilter", null);
  },
  components: {
    Categories,
    Dropdown,
    Providers,
  },
});
