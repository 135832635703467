import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "wp1200 withPadding" }
const _hoisted_2 = {
  key: 0,
  class: "mainTitle"
}
const _hoisted_3 = {
  class: "col2_3",
  ref: "colRight"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mapComponent)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mapComponent), { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass(["showSidebar icon-warpit_icon_filter c-mt-1", { left: _ctx.sidebarLeft }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCard && _ctx.toggleCard(...args)))
      }, null, 2),
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["flex specialColumnLayout", [
        { left: _ctx.sidebarLeft },
        { 'specialColumnLayout--fixed': _ctx.colLeftHeight <= _ctx.colRightHeight },
      ]])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ], 512),
        _createElementVNode("div", {
          class: _normalizeClass(["col1_3 sideBar right", [{ closed: _ctx.cardOpen }, _ctx.$route.name]]),
          ref: "colLeft",
          style: _normalizeStyle({ 'min-height': _ctx.colRightHeight + 'px' })
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.sidebarComponent), {
            onSidebarToggled: _ctx.toggleSidebar,
            onCallback: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('callback', $event)))
          }, null, 40, ["onSidebarToggled"]))
        ], 6)
      ], 2)
    ])
  ], 64))
}