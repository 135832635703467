
import { defineComponent } from "vue";
import ListFilters from "@/components/TrafficInfo/ListFilters.vue";
import { QueryParameters } from "@/typings/QueryParameters";
import { Paginated } from "@/typings/Paginated";
import { TrafficInfo } from "@/typings/TrafficInfo";
import { Coordinates } from "@/typings/Marker";
import InfinityScroll from "@/components/InfinityScroll.vue";
import Divider from "primevue/divider";
import { GmapPlaceResult } from "@/typings/GoogleMaps";

export default defineComponent({
  components: {
    ListFilters,
    InfinityScroll,
    Divider,
  },
  data: () => ({
    trafficInfos: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<TrafficInfo>,
    address: null as null | string,
    selectedType: null as string | null,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    isLoading: false,
  }),
  methods: {
    loadTrafficInfo(page = 1) {
      this.isLoading = true;

      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
        sub_category: string | null;
      } = {
        page,
        perPage: 20,
        orderBy: { distance: "ASC" },
        sub_category: this.selectedType,
      };

      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;

        params.radius = Math.pow(2, 20);

        if (this.address !== null) {
          params.radius = 7000;
        }
      }

      this.$http
        .get("api/v2/traffic-info", { params })
        .then((response) => {
          if (page !== 1) {
            this.trafficInfos.data = this.trafficInfos.data.concat(
              response.data.data,
            );
            this.trafficInfos.current_page = response.data.current_page;
            return;
          }
          this.trafficInfos = response.data;
        })
        .finally(() => (this.isLoading = false));
    },
    setAddress(place: GmapPlaceResult) {
      this.address = place.formatted_address;

      if (this.address === undefined) {
        return;
      }

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      this.center = {
        lat: latitude,
        lng: longitude,
      };

      this.loadTrafficInfo();
    },
    clearAddress() {
      this.address = null;

      this.center = {
        lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
      };

      this.loadTrafficInfo();
    },
    changeTrafficInfoTypeFilter(type: string) {
      if (this.selectedType === type) {
        this.selectedType = null;
      } else {
        this.selectedType = type;
      }

      this.trafficInfos = {} as Paginated<TrafficInfo>;

      this.loadTrafficInfo();
    },
    getTypeIcon(type: string) {
      if (type === "roadClosed") {
        return "zapora";
      }
      if (type === "roadWorks") {
        return "road-work";
      }
      if (type === "hazardOnRoad") {
        return "opozorilo";
      }
      if (type === "trafficJam") {
        return "jam";
      }
      if (type === "carStopped") {
        return "car";
      }
      if (type === "carAccident") {
        return "prometno-opozorilo";
      }

      return "car";
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  mounted() {
    this.loadTrafficInfo();
  },
  watch: {
    selectedSorting() {
      this.loadTrafficInfo();
    },
  },
});
