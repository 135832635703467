
import { defineComponent } from "vue";
import Checkbox from "primevue/checkbox";
import Menu from "primevue/menu";
import { Filter, NewsSubscriber } from "@/typings/News";
import { QueryParameters } from "@/typings/QueryParameters";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    categories: null as null | Array<Filter>,
    subscribe: {
      categories_phone: false,
      categories_mail: false,
      categories_push: false,
    } as NewsSubscriber,
    selectedCategory: null as null | Filter,
    searchQuery: "",
    subscriptionExists: false,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    searchedCategories() {
      if (this.searchQuery.trim() === "") {
        return this.categories;
      }

      return this.categories?.filter((category) =>
        category.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
      );
    },
  },
  methods: {
    loadCategories() {
      let params: QueryParameters = {
        perPage: 30,
      };

      if (this.searchQuery !== "") {
        params.filters = {
          ...params.filters,
          name: encodeURIComponent("%" + this.searchQuery.trim() + "%"),
        };
      }

      this.$http.get("api/v1/news/categories", { params }).then((response) => {
        this.categories = response.data.data
          .filter((cat: Filter) => this.$te(`news.categories.${cat.name}`))
          .map((cat: Filter) => ({
            ...cat,
            name: this.$t(`news.categories.${cat.name}`),
          }));
      });
    },
    loadSubscriptions() {
      this.$http.get("api/v1/news/subscriptions").then((response) => {
        if (response && response.data.total > 0) {
          this.subscribe = response.data.data[0];
          this.subscriptionExists = true;
        }
      });
    },
    saveSubscriptions() {
      let payload = { ...this.subscribe };

      if (this.subscriptionExists === false) {
        payload = {
          ...payload,
          providers_phone: false,
          providers_mail: false,
          providers_push: false,
        };
      }

      firebaseInstance?.analytics().logEvent("news_screen", {
        page: "news_screen",
        category: "news_subscription",
        sub_category: "news_subscribe_trigger",
        category_desc: "Zaslon naročnin na novice odprt",
        sub_category_desc: "Uporabnik se je naročil na novice",
        ...this.$store.getters["user/analyticsInfo"],
        ...payload,
      });

      this.$http
        .post("api/v1/news/subscriptions", payload)
        .then(() => this.loadSubscriptions());
    },
    updateSubscription(category: Filter, subscribed: boolean) {
      this.$http
        .post("/api/v1/news/category-subscribers", {
          category_id: category.id,
          subscribed,
        })
        .then(() => this.loadCategories());
    },
    toggle(event: MouseEvent | KeyboardEvent) {
      (this.$refs.menu as Menu).toggle(event);
    },
  },
  mounted() {
    this.loadCategories();
    this.loadSubscriptions();
  },
  components: {
    Checkbox,
    Menu,
  },
});
