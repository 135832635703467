
import { PropType, defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import { Widget } from "@/typings/Widget";

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    widget: {
      type: Object as PropType<Widget>,
      required: true,
    },
    currentWidgets: {
      type: Object as PropType<Array<Widget>>,
      required: true,
    },
  },
  data: () => ({
    selectedItem: null as null | string | number,
    options: [],
    latitude: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
    longitude: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
  }),
  methods: {
    deleteWidget() {
      this.$http
        .delete(`/api/v1/dashboard/widgets/${this.widget.id}`)
        .then(() => this.$emit("refresh"));
    },
    saveChanges() {
      this.$http
        .put(`/api/v1/dashboard/widgets/${this.widget.id}`, {
          external_id: this.selectedItem,
        })
        .then(() => this.$emit("refresh"));
    },
    getOptions() {
      const configuration = this.widgets[this.widget.type].configuration;

      if (configuration === undefined) {
        return;
      }

      this.$http
        .get(configuration.url, { params: configuration.params })
        .then((response) => {
          this.options = response.data.data;

          const field = this.widgets[this.widget.type].configuration?.value;

          if (field === undefined) {
            return;
          }

          // Exclude existing widgets options
          this.options = this.options.filter((item) => {
            return this.existingWidgetsIds.indexOf(String(item[field])) === -1;
          });

          // Display only unique values based on field
          this.options = [
            ...new Map(this.options.map((obj) => [obj[field], obj])).values(),
          ];
        });
    },
    reset() {
      this.selectedItem = null;
      this.options = [];
    },
    // eslint-disable-next-line
    getOptionLabel(option: Record<string, any>) {
      if (this.widget.type === "waste-collection") {
        return option.address.street + " " + option.address.house_number + ", " + option.address.city;
      }

      return option.name;
    },
  },
  computed: {
    widgets(): Record<
      string,
      {
        label: string;
        configuration?: {
          url: string;
          params: Record<
            string,
            string | number | Record<string, string | number>
          >;
          value: string;
        };
      }
    > {
      return {
        weather: {
          label: this.$t("widgets.weather.title"),
        },
        "air-quality": {
          label: this.$t("widgets.air-quality.title"),
          configuration: {
            url: "api/v2/air-quality",
            params: {
              perPage: 30,
            },
            value: "id",
          },
        },
        "public-transport": {
          label: this.$t("widgets.public-transport.title"),
          configuration: {
            url: `api/v1/public-transport/stops`,
            params: {
              perPage: 30,
              longitude: this.longitude,
              latitude: this.latitude,
              radius: 5000,
            },
            value: "id",
          },
        },
        events: {
          label: this.$t("widgets.events.title"),
        },
        "waste-collection": {
          label: this.$t("widgets.waste-collection.title"),
          configuration: {
            url: `api/v1/waste-management/collection-subscriber`,
            params: {
              filters: {
                citizen_id: this.user.id,
              },
            },
            value: "address_id",
          },
        },
        traffic: {
          label: this.$t("widgets.traffic.title"),
        },
        "traffic-info": {
          label: this.$t("widgets.traffic.title"),
        },
      };
    },
    selectedIsConfigurable() {
      return this.widgets[this.widget.type].configuration !== undefined;
    },
    existingWidgetsIds() {
      return this.currentWidgets
        .filter(
          (item) =>
            item.external_id !== null &&
            item.external_id != this.widget.external_id &&
            item.type === this.widget.type &&
            item.id !== this.widget.id,
        )
        .map((item) => item.external_id);
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  watch: {
    show: {
      handler() {
        if (this.show === true) {
          this.getOptions();

          if (isNaN(Number(this.widget.external_id))) {
            this.selectedItem = String(this.widget.external_id);
          } else {
            this.selectedItem = Number(this.widget.external_id);
          }
        }
      },
      immediate: true,
    },
  },
  components: {
    Dialog,
    Dropdown,
  },
});
