import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wp1200 withPadding"
}
const _hoisted_2 = { class: "mainTitle pages" }
const _hoisted_3 = {
  key: 0,
  class: "h-30rem relative"
}
const _hoisted_4 = { class: "map-button" }
const _hoisted_5 = { class: "center-static-image" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Map = _resolveComponent("Map")!
  const _component_VueShowdown = _resolveComponent("VueShowdown")!

  return (_ctx.contents)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.contents?.title), 1),
        (_ctx.contents.lat && _ctx.contents.lng)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Map, {
                markers: _ctx.markers,
                center: _ctx.center
              }, null, 8, ["markers", "center"]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "bg-primary border-none py-2 px-4 border-round-3xl text-lg flex-shrink-0 mt-2 cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openGoogleMaps && _ctx.openGoogleMaps(...args)), ["stop"]))
                }, _toDisplayString(_ctx.$t("parking.card.go")), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.contents !== null)
          ? (_openBlock(), _createBlock(_component_VueShowdown, {
              key: 1,
              markdown: _ctx.contents.content,
              options: { emoji: true }
            }, null, 8, ["markdown"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.imageUrl)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.imageUrl,
                alt: "image"
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}