
import { defineComponent } from "vue";
import Password from "primevue/password";
import { ForgotPasswordErrors } from "@/typings/ForgottenPasswordForm";

export default defineComponent({
  components: { Password },

  data: () => ({
    password: "",
    password_confirmation: "",
    errors: {} as ForgotPasswordErrors,
  }),
  props: {
    username: String,
    token: String,
  },
  methods: {
    resetPassword() {
      this.checkForm();
      if (Object.keys(this.errors).length !== 0) {
        return;
      }
      this.$http
        .post("/api/v1/password-reset/" + this.token, {
          username: this.username,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(() => {
          this.$emit("nextStep");
        })
        .catch((error) => {
          if (error.response.data.message === "Invalid token") {
            this.errors.token = this.$t(`error.invalid_token`);
            return;
          }
          this.errors.something_wrong = this.$t(`error.mistake`);
        });
    },
    checkValidPassword(password: string) {
      return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(
        password
      );
    },
    checkForm() {
      this.errors = {};
      if (
        !this.password ||
        this.password.length < 8 ||
        !this.checkValidPassword(this.password)
      ) {
        this.errors.password = this.$t("error.unvalid_password");
      }
      if (this.password !== this.password_confirmation) {
        this.errors.password_confirmation = this.$t(
          `error.invalid_password_confirmation`
        );
      }
    },
  },
});
