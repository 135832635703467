
import { defineComponent } from "vue";
import { Coordinates, Marker } from "@/typings/Marker";
import { GeoJson } from "@/typings/GeoJson";

export default defineComponent({
  data: () => ({
    temporaryCenter: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    hoveredMarker: null as Marker | null,
    infoWindowPosition: null as null | Coordinates,
  }),
  props: {
    center: {
      type: Object as () => Coordinates,
      default: () => ({
        lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
      }),
    },
    markers: {
      type: Array as () => Marker[],
      default: () => {
        return [];
      },
    },
    zoom: {
      type: Number,
      default: 15,
    },
    markerInfoOnHover: Boolean,
    navigateOnClick: Boolean,

    geoJson: {
      type: Array as () => GeoJson[],
    },
    selectedGeoJson: {
      type: Object as () => GeoJson,
    },
  },
  mounted() {
    if (this.markers !== null && this.markers.length === 1) {
      this.$emit("centerChanged", this.markers[0].position);
    }
  },

  watch: {
    selectedGeoJson(geoJson: GeoJson | null) {
      if (geoJson === null) {
        return;
      }

      this.infoWindowPosition = geoJson.path[0];
    },
  },

  methods: {
    openInfoWindow(item: GeoJson) {
      this.infoWindowPosition = item.path[0];

      this.$emit("selectGeoJson", item.payload);
    },
    openInfo(marker: Marker) {
      this.$emit("selectMarker", marker.payload);
      if (
        marker.on_click_route_name === null ||
        this.navigateOnClick === false
      ) {
        return;
      }
      this.$router.push({
        name: marker.on_click_route_name,
        params: { id: marker.id },
      });
      this.$emit("close");
    },
    hoverOpen(marker: Marker) {
      if (this.markerInfoOnHover === false) {
        return;
      }

      this.hoveredMarker = marker;
    },
    updateTemporaryCenter(event: { lat(): number; lng(): number }) {
      // This event is called every time center coordinates changes.
      // If we update center property directly, map freezes.
      // Thats why we only update center when dragend event fires
      this.temporaryCenter = { lat: event.lat(), lng: event.lng() };
    },
    updateCenter() {
      this.$emit("centerChanged", this.temporaryCenter);
    },
    updateZoom(value: number) {
      // Zoom event is called twice for some reason
      if (this.zoom === value) {
        return;
      }

      this.$emit("zoomChanged", value, this.temporaryCenter);
    },
  },
});
