import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "backgroundImage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventSlider = _resolveComponent("EventSlider")!
  const _component_Map = _resolveComponent("Map")!
  const _component_NewsSlider = _resolveComponent("NewsSlider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_EventSlider, {
      events: _ctx.dashboard.events
    }, null, 8, ["events"]),
    _createVNode(_component_Map),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NewsSlider, {
        news: _ctx.dashboard.news
      }, null, 8, ["news"])
    ])
  ], 64))
}