import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contentLogin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      class: "submitButton defaultButton siPass",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sipassLogin())),
      label: _ctx.$t('login.sipass_entry'),
      disabled: !_ctx.sipassConsent
    }, null, 8, ["label", "disabled"]),
    _createElementVNode("label", null, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sipassConsent) = $event))
      }, null, 512), [
        [_vModelCheckbox, _ctx.sipassConsent]
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("login.sipass_disclaimer")), 1)
    ])
  ]))
}