
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import { ForgotPasswordErrors } from "@/typings/ForgottenPasswordForm";

export default defineComponent({
  components: { InputText },
  data: () => ({
    form: {
      username: "",
    },
    errors: {} as ForgotPasswordErrors,
  }),
  methods: {
    requestPasswordReset() {
      this.checkForm();
      if (Object.keys(this.errors).length !== 0) {
        return;
      }
      this.$http
        .post("/api/v1/password-reset", this.form)
        .then(() => {
          this.$emit("updateUsername", this.form.username);
        })
        .catch((error) => {
          if (error.response.data.message === "User does not exists") {
            this.errors.username = this.$t(`error.user_does_not_exist`);
            return;
          }
          this.errors.something_wrong = this.$t(`error.mistake`);
        });
    },
    checkForm() {
      this.errors = {};
      if (this.form.username === "") {
        this.errors.username = this.$t(`error.enter_email_or_phone`);
      }
    },
  },
});
