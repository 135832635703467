
import { defineComponent } from "vue";
import Map from "@/components/Map.vue";
import { Coordinates, Marker } from "@/typings/Marker";
import Information from "@/components/bikes/Information.vue";
import { Paginated } from "@/typings/Paginated";
import Button from "primevue/button";
import Divider from "primevue/divider";
import { QueryParameters } from "@/typings/QueryParameters";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import NearbyCard from "@/components/NearbyCard.vue";
import { BikeStation } from "@/typings/BikeStation";

export default defineComponent({
  components: { Map, Button, Information, Divider, NearbyCard },
  data: () => ({
    stations: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<BikeStation>,
    selectedStation: null as null | BikeStation,
    selectedMarker: null as null | Marker,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    markers: [] as Marker[],
    zoom: 14,
    address: null,
    isLoading: false,
  }),
  computed: {
    nearestStations() {
      return this.stations.data.slice(0, 6);
    },
  },
  methods: {
    updateCenter(value: Coordinates) {
      this.center = value;

      this.loadStations();
    },
    updateZoom(value: number, center: Coordinates) {
      this.zoom = value;
      this.center = center;

      this.loadStations();
    },
    async loadStations() {
      this.isLoading = true;
      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
      } = {
        perPage: 20,
        orderBy: {
          // Order by distance only when searching as it affects results
          distance: this.address !== null ? "ASC" : undefined,
        },
      };

      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;
        params.radius = Math.pow(2, 27 - this.zoom);
      }

      return this.$http
        .get("api/v1/bike-stations", { params })
        .then((response) => {
          this.stations = response.data;

          this.setMarkers();
        })
        .finally(() => (this.isLoading = false));
    },
    loadSelectedStation() {
      if ("id" in this.$route.params === false) {
        this.unselectStation();
        return;
      }
      this.isLoading = true;
      this.$http
        .get("api/v1/bike-stations/" + this.$route.params.id)
        .then((response) => {
          this.selectedStation = response.data;

          if (!this.selectedStation) {
            return;
          }

          this.selectMarker(this.selectedStation);
          this.selectStation(this.selectedStation);
        })
        .catch(() => {
          this.$router.push({ name: "BikesMap" });
        })
        .finally(() => {
          this.isLoading = false;

          this.loadStations();
        });
    },
    setMarkers() {
      this.markers = this.stations.data.map((station) => ({
        id: station.id,
        position: {
          lat: parseFloat(station.latitude),
          lng: parseFloat(station.longitude),
        },
        on_click_route_name: "BikesById",
        type:
          station.id === this.selectedMarker?.payload?.id
            ? "selected"
            : "bike_station",
        title: station.name,
        icon: this.setMarkerIcon(station),
        payload: station,
      }));

      if (this.selectedMarker === null) {
        return;
      }

      const existingMarkerIndex = this.markers.findIndex(
        (marker) => marker.id === this.selectedMarker?.id,
      );

      if (existingMarkerIndex !== -1) {
        this.markers[existingMarkerIndex] = this.selectedMarker;
        return;
      }

      this.markers.push(this.selectedMarker);
    },
    selectMarker(station: BikeStation) {
      if (this.selectedMarker?.payload?.id === station.id) {
        return;
      }

      this.selectedMarker = {
        id: station.id,
        position: {
          lat: parseFloat(station.latitude),
          lng: parseFloat(station.longitude),
        },
        on_click_route_name: "BikesById",
        type: "selected",
        title: station.name,
        icon: "/markers/parking-selected.svg",
        payload: station,
      };

      if ("id" in this.$route.params && this.selectedStation) {
        this.selectStation(station);
      }

      this.setMarkers();
    },
    unselectMarker() {
      this.selectedMarker = null;
      this.setMarkers();
    },
    selectStation(station: BikeStation) {
      this.selectedStation = station;

      this.selectMarker(station);

      this.center = {
        lat: parseFloat(station.latitude),
        lng: parseFloat(station.longitude),
      };
      this.zoom = 16;

      this.$router.push({ name: "BikesById", params: { id: station.id } });
    },
    unselectStation() {
      this.selectedStation = null;

      this.zoom = 14;
      this.updateCenter(this.center);
    },
    setFavouriteStation(station: BikeStation) {
      if (station.favourite === true) {
        this.$http
          .delete("api/v1/locations/" + station.my_location_id)
          .then(() => {
            if ("id" in this.$route.params) {
              this.loadSelectedStation();
              return;
            }

            station.favourite = false;
          });
        return;
      }

      this.$http
        .post("api/v1/locations", {
          type_id: 5,
          external_identifier: station.id,
        })
        .then((response) => {
          if ("id" in this.$route.params) {
            this.loadSelectedStation();
            return;
          }
          station.favourite = true;
          station.my_location_id = response.data.id;
        });
      return;
    },
    setAddress(place: GmapPlaceResult) {
      if (place.formatted_address === undefined) {
        return;
      }

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      this.selectedStation = null;

      this.zoom = 17;
      this.updateCenter({
        lat: latitude,
        lng: longitude,
      });
    },
    setMarkerIcon(station: BikeStation) {
      if (station.id === this.selectedStation?.id) {
        return "/markers/parking-selected.svg";
      }

      const totalAvailable = this.calculatePercentAvailability(station);

      if (isNaN(totalAvailable)) {
        return "/markers/parking-grey.svg";
      }

      if (totalAvailable > 50) {
        return "/markers/parking-green.svg";
      } else if (totalAvailable >= 25) {
        return "/markers/parking-yellow.svg";
      } else if (totalAvailable >= 10) {
        return "/markers/parking-orange.svg";
      } else {
        return "/markers/parking-red.svg";
      }
    },

    calculatePercentAvailability(station: BikeStation) {
      const totalAvailable =
        (station.slots_available / station.slots_total) * 100;
      return totalAvailable;
    },
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.loadSelectedStation();
      },
      immediate: true,
    },
  },
});
