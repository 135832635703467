import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/error.svg'


const _hoisted_1 = {
  key: 0,
  class: "errorEmpty c-mt-3"
}
const _hoisted_2 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RewardCard = _resolveComponent("RewardCard")!
  const _component_SidebarWrapper = _resolveComponent("SidebarWrapper")!
  const _component_InfinityScroll = _resolveComponent("InfinityScroll")!

  return (_openBlock(), _createBlock(_component_InfinityScroll, {
    class: "browser-infinity-scroll",
    "dont-use-mobile-handler": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SidebarWrapper, {
        title: _ctx.$t('rewards.title'),
        sidebarComponent: _ctx.sidebarComponent,
        onUpdateRewards: _ctx.loadRewards
      }, {
        default: _withCtx(() => [
          (_ctx.filterRewards.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "error",
                  alt: _ctx.$t('accessibility.error')
                }, null, 8, _hoisted_2),
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t("rewards.no_rewards_title")), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("rewards.no_rewards")), 1)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterRewards, (reward, index) => {
            return (_openBlock(), _createBlock(_component_RewardCard, {
              reward: reward,
              key: index
            }, null, 8, ["reward"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["title", "sidebarComponent", "onUpdateRewards"])
    ]),
    _: 1
  }))
}