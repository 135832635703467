
import { defineComponent } from "vue";
import ListFilters from "@/components/ListFilters.vue";
import { QueryParameters } from "@/typings/QueryParameters";
import { Paginated } from "@/typings/Paginated";
import { Parking } from "@/typings/Parking";
import { Coordinates } from "@/typings/Marker";
import FavouriteButton from "@/components/FavouriteButton.vue";
import InfinityScroll from "@/components/InfinityScroll.vue";
import Divider from "primevue/divider";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import ParkingSpaces from "@/components/parking/ParkingSpaces.vue";

export default defineComponent({
  components: {
    ListFilters,
    FavouriteButton,
    InfinityScroll,
    Divider,
    ParkingSpaces,
  },
  data: () => ({
    parkings: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Parking>,
    address: null as null | string,
    selectedSorting: "distance",
    selectedType: null as string | null,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    isLoading: false,
  }),
  methods: {
    loadParkings(page = 1) {
      this.isLoading = true;

      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
        type: string | null;
      } = {
        page,
        perPage: 20,
        orderBy: this.configureOrdering(),
        type: this.selectedType,
      };

      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;

        params.radius = Math.pow(2, 20);

        if (this.address !== null) {
          params.radius = 7000;
        }
      }

      this.$http
        .get("api/v2/parkings", { params })
        .then((response) => {
          if (page !== 1) {
            this.parkings.data = this.parkings.data.concat(response.data.data);
            this.parkings.current_page = response.data.current_page;
            return;
          }
          this.parkings = response.data;
        })
        .finally(() => (this.isLoading = false));
    },
    setAddress(place: GmapPlaceResult) {
      this.address = place.formatted_address;

      if (this.address === undefined) {
        return;
      }

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      this.center = {
        lat: latitude,
        lng: longitude,
      };

      this.loadParkings();
    },
    clearAddress() {
      this.address = null;

      this.center = {
        lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
      };

      this.loadParkings();
    },
    setFavouriteParking(parking: Parking) {
      if (!this.user) {
        this.$router.push({ name: "ParkingFavourite" });
        return;
      }

      if (parking.favourite === true) {
        this.$http
          .delete("api/v1/locations/" + parking.my_location_id)
          .then(() => {
            parking.favourite = false;
          });
        return;
      }

      this.$http
        .post("api/v1/locations", {
          type_id: 1,
          external_identifier: parking.id,
        })
        .then((response) => {
          parking.favourite = true;
          parking.my_location_id = response.data.id;
        });
      return;
    },
    changeParkingTypeFilter(type: string) {
      if (this.selectedType === type) {
        this.selectedType = null;
      } else {
        this.selectedType = type;
      }

      this.parkings = {} as Paginated<Parking>;

      this.loadParkings();
    },
    configureOrdering() {
      const orderBy: { [key: string]: string } = {};

      if (this.selectedSorting === "distance") {
        orderBy.distance = "ASC";
      }

      if (this.selectedSorting === "occupancy") {
        orderBy.smart = "DESC";

        if (this.selectedType === null) {
          orderBy.spaces_total_available = "DESC";
        } else if (this.selectedType === "regular") {
          orderBy.spaces_regular_available = "DESC";
        } else if (this.selectedType === "ev") {
          orderBy.spaces_ev_available = "DESC";
        } else if (this.selectedType === "accessible") {
          orderBy.spaces_accessible_available = "DESC";
        }
      }

      return orderBy;
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  mounted() {
    this.loadParkings();
  },
  watch: {
    selectedSorting() {
      this.loadParkings();
    },
  },
});
