import { ActionContext } from "vuex";

interface State {
  show: boolean;
}

const state = (): State => ({
  show: false,
});

const getters = {
  show: (state: State): boolean => {
    return state.show;
  },
};

const mutations = {
  setShow(state: State, show: boolean): void {
    state.show = show;
  },
};

const actions = {
  setShow(context: ActionContext<State, State>, show: boolean): void {
    context.commit("setShow", show);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
