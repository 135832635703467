
import { PropType, defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Paginator from "primevue/paginator";
import { Article } from "@/typings/Article";
import { Paginated } from "@/typings/Paginated";
import { NewsProvider } from "@/typings/News";
import PlaceholderImage from "../PlaceholderImage.vue";

export default defineComponent({
  props: {
    selectedProviders: {
      type: [Array, null] as PropType<Array<NewsProvider> | null>,
    },
  },
  data: () => ({
    page: 1,
    news: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Article>,
    selectedItem: null as null | Article,
    showDialog: false,
    isMobile: window.innerWidth <= 760,
    storageUrl: process.env.VUE_APP_STORAGE_URL,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    loadSavedNews(page = 1) {
      let params = {
        page: this.page,
        perPage: 10,
        filters: {
          provider_id: this.selectedProviders ?? undefined,
        },
        saved: true,
      };
      this.$http.get("api/v1/news", { params }).then((response) => {
        if (page !== 1) {
          this.news.data = this.news.data.concat(response.data.data);
          this.news.current_page = response.data.current_page;
          return;
        }
        this.news = response.data;
      });
    },
    updatePage(page: number) {
      if (this.page === page + 1) {
        return;
      }

      this.page = page + 1;
      this.loadSavedNews();
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 760;
    },
    selectAndShowDialog(item: Article) {
      this.selectedItem = item;
      this.showDialog = true;
    },
    saveArticle(article: Article) {
      this.$http
        .post(`api/v1/news/${article.id}/save`)
        .then(() => this.loadSavedNews());
    },
    removeSavedArticle(article: Article) {
      this.$http.post(`api/v1/news/${article.id}/unsave`).then(() => {
        this.showDialog = false;
        this.loadSavedNews();
      });
    },
    formatDate(date: string) {
      const articleDate = this.$moment(date);
      const currentTime = this.$moment();

      // Check if the article was published less than 24 hours ago
      if (currentTime.diff(articleDate, "hours") < 24) {
        return articleDate.fromNow();
      }

      return articleDate.format("DD. MM. yyyy");
    },
  },
  watch: {
    page() {
      this.$router.push({
        name: "News",
        query: { view: "for-you", tab: 2, page: this.page },
      });
    },
    selectedProviders() {
      this.loadSavedNews();
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page);
    }

    this.loadSavedNews();

    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  components: { Dialog, Paginator, PlaceholderImage },
});
