
import { Measurements } from "@/typings/Measurements";
import { AqRanges } from "@/typings/Sensor";
import { PropType, defineComponent } from "vue";
import { aqRangeColors } from "@/services/AirQuality";
import ProgressBar from "primevue/progressbar";
import AdvancedGaugeChartHome from "@/components/charts/AdvancedGaugeChartHome.vue";

export default defineComponent({
  props: {
    externalId: {
      type: Number,
    },
    payload: {
      type: Object as PropType<{
        name: string;
        measurements: Measurements;
      }>,
      required: true,
    },
  },
  data: () => ({
    aqRanges: {
      index: [50, 75, 100, 200],
      pm10: [40, 75, 100, 200],
      pm2_5: [20, 40, 80, 160],
      o3: [100, 180, 240, 480],
      no2: [100, 200, 400, 800],
      so2: [200, 350, 500, 1000],
      co: [7500, 10000, 12500, 20000],
      co2: [1000, 2000, 5000, 10000],
    } as AqRanges,
    aqRangeColors: aqRangeColors,
  }),
  methods: {
    getValueColorClass(type: keyof AqRanges, value: number | null) {
      if (value === null) {
        return "sensorGrey";
      }
      let ranges = this.aqRanges[type];
      return value > ranges[2]
        ? "sensorRed"
        : value > ranges[1]
        ? "sensorOrange"
        : value > ranges[0]
        ? "sensorYellow"
        : "sensorGreen";
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: { ProgressBar, AdvancedGaugeChartHome },
});
