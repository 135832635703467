import { AqRanges } from "@/typings/Sensor";

export const aqConversion = {
  index: 1,
  pm10: 1,
  pm2_5: 1,
  o3: 0.000473,
  no2: 0.000494,
  so2: 0.000355,
};

export const aqRanges: AqRanges = {
  index: [50, 75, 100, 200],
  pm10: [20, 40, 50, 100, 150, 200],
  pm2_5: [10, 20, 25, 50, 75, 150],
  o3: [50, 100, 130, 240, 380, 600],
  no2: [40, 90, 120, 230, 340, 600],
  so2: [100, 200, 350, 500, 750, 1000],
};

export const aqRangeColors = {
  blue: getComputedStyle(document.documentElement).getPropertyValue(
    "--blue-600"
  ),
  green: getComputedStyle(document.documentElement).getPropertyValue(
    "--green-600"
  ),
  yellow: getComputedStyle(document.documentElement).getPropertyValue(
    "--yellow-500"
  ),
  orange: getComputedStyle(document.documentElement).getPropertyValue(
    "--orange-500"
  ),
  red: getComputedStyle(document.documentElement).getPropertyValue("--red-400"),
  purple: getComputedStyle(document.documentElement).getPropertyValue(
    "--purple-500"
  ),
  grey: getComputedStyle(document.documentElement).getPropertyValue(
    "--gray-400"
  ),
};
