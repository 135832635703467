
import { defineComponent, PropType } from "vue";
import CustomButton from "@/components/customComponents/CustomButton.vue";
import { VueShowdown } from "vue-showdown";
import { loadStaticContent } from "@/static";
import { Consents } from "@/typings/Consent";
import Dialog from "primevue/dialog";

export default defineComponent({
  data: () => ({
    contents: null as Record<string, PropType<string | null>> | null,
    consents: {
      system: true,
      analytics: false,
      social: false,
    } as Consents,
    showUpdateCookiesDialog: false,
  }),
  async mounted() {
    this.contents = await loadStaticContent("cookies");
    this.checkConsents();
  },
  methods: {
    checkConsents() {
      this.consents = this.$store.getters["consents/consents"] ?? this.consents;
    },
    selectAllAndClose() {
      this.consents.system = true;
      this.consents.analytics = true;
      this.consents.social = true;
      this.save();
    },
    save() {
      this.$store.dispatch("consents/setConsents", this.consents).then(() => {
        this.showUpdateCookiesDialog = true;
      });
    },
    refresh() {
      window.location.reload();
    },
  },
  computed: {
    content() {
      return this.contents !== null ? this.contents[this.$i18n.locale] : null;
    },
  },
  components: { VueShowdown, CustomButton, Dialog },
});
