
import { defineComponent } from "vue";
import FeedbackThread from "@/components/tellCity/FeedbackThread.vue";
import Thread from "@/views/tellCity/Thread.vue";
import InfinityScroll from "@/components/InfinityScroll.vue";
import { Paginated } from "@/typings/Paginated";
import { FeedbackItem } from "@/typings/FeedbackItem";
import { QueryParameters } from "@/typings/QueryParameters";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  components: { FeedbackThread, InfinityScroll, Thread },

  data: () => ({
    mobileThreadVisible: false,
    feedbacks: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<FeedbackItem>,
    selectedFeedback: null as null | FeedbackItem,
    isLoading: false,
  }),

  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    isDesktop() {
      return screen.width > 650;
    },
    activeFeedbacks() {
      return this.feedbacks.data.filter(
        (feedback) => feedback.status_id === 1 || feedback.status_id === 2,
      );
    },
    finishedFeedbacks() {
      return this.feedbacks.data.filter(
        (feedback) => feedback.status_id === 3 || feedback.status_id === 4,
      );
    },
  },

  methods: {
    toggleMobileThread() {
      this.mobileThreadVisible = !this.mobileThreadVisible;
      this.selectedFeedback = null;
      this.$router.push({ name: "FeedbackDashboard" });
    },
    getFeedbacks(page = 1) {
      let params: QueryParameters = {
        page,
        perPage: 20,
        orderBy: {
          created_at: "desc",
        },
      };
      this.isLoading = true;
      this.$http
        .get("api/v1/feedback", { params })
        .then((response) => {
          if (page !== 1) {
            this.feedbacks.data = this.feedbacks.data.concat(
              response.data.data,
            );
            this.feedbacks.current_page = response.data.current_page;
            return;
          }
          this.feedbacks = response.data;
          if (!this.isDesktop) {
            return; // Select first thread only on desktop because on mobile it would force thread view
          }

          if (
            "id" in this.$route.params === false &&
            this.feedbacks.data.length > 0
          ) {
            this.selectFeedback(this.feedbacks.data[0]);
            return;
          }

          const feedbackById = this.feedbacks.data.find(
            (feedback) => feedback.id.toString() === this.$route.params.id,
          );

          if (feedbackById) {
            this.selectFeedback(feedbackById);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectFeedback(feedback: FeedbackItem) {
      this.selectedFeedback = feedback;
      this.selectedFeedback.unread_owner = 0;
      this.$router.push({
        name: "FeedbackThread",
        params: { id: this.selectedFeedback.id },
      });
    },
  },

  mounted() {
    firebaseInstance?.analytics().logEvent("tell_city", {
      page: "tell_city",
      category: "feedback_screen",
      category_desc: "Zaslon povratnih informacij odprt",
      ...this.$store.getters["user/analyticsInfo"],
    });
    this.getFeedbacks();
  },
});
