
import { PropType, defineComponent } from "vue";
import { Parking } from "@/typings/Parking";
import Divider from "primevue/divider";
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";
import RRuleParser from "@/services/RRule";
import FavouriteButton from "@/components/FavouriteButton.vue";
import moment from "moment";

export default defineComponent({
  components: { Divider, Sidebar, Button, FavouriteButton },
  props: {
    parking: {
      type: Object as PropType<Parking>,
      required: true,
    },
  },
  methods: {
    checkAvailability(parking: Parking) {
      const types = ["regular", "ev", "accessible"];
      const isSmartParking = parking.smart === 1;

      return types.every((typeValue) => {
        const type = `spaces_${typeValue}${isSmartParking ? "_available" : ""}`;
        return parking[type as keyof Parking] !== null;
      });
    },
    goBack(toMap = false) {
      this.$emit("back");

      if (toMap) {
        this.$router.push({ name: "ParkingMap" });
        return;
      }

      this.$router.back();
    },
    parseRRule(rule: string) {
      const rruleParser = new RRuleParser(this.$i18n.locale);
      return rruleParser.localize(rule);
    },
    openGoogleMaps() {
      window.open(
        `https://maps.google.com/?q=${this.parking.latitude},${this.parking.longitude}`,
        "_blank",
      );
    },
    formatTime(openTime: string, closeTime: string) {
      const openMoment = moment(openTime, "HH-mm-ss");
      const closeMoment = moment(closeTime, "HH-mm-ss");

      if (moment.duration(closeMoment.diff(openMoment)).asHours() >= 23) {
        return "24h";
      }

      return openMoment.format("HH:mm") + " - " + closeMoment.format("HH:mm");
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    totalAvailableSmartParking() {
      return (
        this.parking.spaces_regular -
        this.parking.spaces_regular_occupied +
        (this.parking.spaces_accessible -
          this.parking.spaces_accessible_occupied) +
        (this.parking.spaces_ev - this.parking.spaces_ev_occupied)
      );
    },
    totalAvailableParking() {
      return (
        this.parking.spaces_regular +
        this.parking.spaces_accessible +
        this.parking.spaces_ev
      );
    },
    evSpaces() {
      return this.parking.spaces?.filter(
        (space) =>
          space.type_id === 3 &&
          (space.ev_connector_power !== null ||
            space.ev_connector_type !== null),
      );
    },
  },
});
