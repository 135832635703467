import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b548741c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-content-between align-items-center w-full mb-5" }
const _hoisted_2 = { class: "font-semibold text-xl" }
const _hoisted_3 = { class: "flex flex-column pt-2 h-full" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex flex-column gap-3" }
const _hoisted_6 = { class: "relative flex justify-content-center align-items-center" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { class: "flex flex-column w-full" }
const _hoisted_9 = { class: "font-semibold title" }
const _hoisted_10 = {
  key: 0,
  class: "font-semibold title"
}
const _hoisted_11 = { class: "text-sm" }
const _hoisted_12 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlaceholderImage = _resolveComponent("PlaceholderImage")!

  return (_openBlock(), _createElementBlock("div", {
    tabindex: "1",
    class: "flex flex-column bg-white border-round-3xl border-1 border-300 p-4 w-20rem sm:w-25rem h-28rem cursor-pointer",
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'Events' }))),
    onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$router.push({ name: 'Events' })), ["enter"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("widgets.events.title")), 1),
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: "icon-warpit_icon_settings text-2xl",
            tabindex: "1",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('edit')), ["stop"])),
            onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('edit')), ["stop"]), ["enter"]))
          }, null, 32))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.payload.length === 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("widgets.events.empty")), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payload, (event) => {
          return (_openBlock(), _createElementBlock("div", {
            key: event.id,
            class: "flex gap-2"
          }, [
            _createElementVNode("div", _hoisted_6, [
              (event.image_url)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: event.image_url,
                    alt: event.image_alt,
                    class: "w-8rem h-6rem border-1 border-transparent border-round-2xl",
                    style: {"object-fit":"cover"}
                  }, null, 8, _hoisted_7))
                : (_openBlock(), _createBlock(_component_PlaceholderImage, {
                    key: 1,
                    class: "w-8rem h-6rem border-1 border-transparent border-round-2xl"
                  }))
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(event.title), 1),
              (event.canceled_at)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("widgets.events.canceled")) + ": " + _toDisplayString(event.canceled_reason), 1))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_11, _toDisplayString(event.venue ?? event.address), 1),
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$moment(event.datetime).format("DD. MM. YYYY HH:mm")), 1)
            ])
          ]))
        }), 128))
      ])
    ])
  ], 32))
}