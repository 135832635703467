
import { PropType, defineComponent } from "vue";
import { TrafficInfo } from "@/typings/TrafficInfo";
import Divider from "primevue/divider";

export default defineComponent({
  props: {
    payload: {
      type: Object as PropType<Array<TrafficInfo>>,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: { Divider },
  methods: {
    truncateDescription(description: string, maxLength: number): string {
      if (description.length <= maxLength) {
        return description;
      } else {
        return description.slice(0, maxLength) + "...";
      }
    },
  },
});
