
export default {
  computed: {
    appStoreUrl(): string {
      return process.env.VUE_APP_APP_STORE_URL;
    },
    googlePlayUrl(): string {
      return process.env.VUE_APP_GOOGLE_PLAY_URL;
    },
  },
};
