
import { defineComponent } from "vue";
import ProgressBar from "primevue/progressbar";
import { InterviewerSurvey } from "@/typings/InterviewerSurvey";

export default defineComponent({
  data: () => ({
    surveys: [] as InterviewerSurvey[],
  }),
  mounted() {
    this.loadSurveys();
  },
  methods: {
    loadSurveys() {
      this.$http.get("api/v1/surveys").then((response) => {
        this.surveys = response.data.filter((survey: InterviewerSurvey) => {
          return survey.qStatus !== 1;
        });
      });
    },
    startSurvey(index: number) {
      let link = this.surveys[index].url;
      if (link === null) {
        return;
      }
      window.open(link, "_blank");
    },
  },
  components: {
    ProgressBar,
  },
});
