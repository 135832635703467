import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withKeys as _withKeys, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "userMyForms" }
const _hoisted_2 = { class: "filters" }
const _hoisted_3 = ["onClick", "onKeypress"]
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ApplicationBox = _resolveComponent("ApplicationBox")!
  const _component_SidebarUserWrapper = _resolveComponent("SidebarUserWrapper")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_SidebarUserWrapper, {
    title: _ctx.$t('forms.my_form.title'),
    sidebarComponent: _ctx.sidebarComponent
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createElementBlock("li", {
              key: tab.id,
              onClick: ($event: any) => (_ctx.activeTab = tab.key),
              tabindex: "0",
              onKeypress: _withKeys(($event: any) => (_ctx.activeTab = tab.key), ["enter"])
            }, [
              _createElementVNode("a", {
                class: _normalizeClass(["links", { active: _ctx.activeTab == tab.key }])
              }, _toDisplayString(tab.title), 3)
            ], 40, _hoisted_3))
          }), 128))
        ]),
        (_ctx.verificationLevel < 3)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_Button, {
                disabled: "",
                class: "newApplication_button defaultButton",
                label: _ctx.$t('forms.my_form.submit_form')
              }, null, 8, ["label"])
            ])), [
              [
                _directive_tooltip,
                _ctx.$t('forms.my_form.submit_application_verification'),
                void 0,
                { top: true }
              ]
            ])
          : (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              class: "newApplication_button defaultButton",
              label: _ctx.$t('forms.my_form.submit_form'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'NewApplications' }))),
              tabindex: "0",
              onKeypress: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$router.push({ name: 'NewApplications' })), ["enter"]))
            }, null, 8, ["label"])),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredApplications, (application) => {
          return (_openBlock(), _createBlock(_component_ApplicationBox, {
            key: application.id,
            application: application
          }, null, 8, ["application"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title", "sidebarComponent"]))
}