
import { defineComponent } from "vue";
import PopularLocations from "@/components/routePlanner/PopularLocations.vue";
import SavedLocations from "@/components/routePlanner/SavedLocations.vue";
import RecentLocations from "@/components/routePlanner/RecentLocations.vue";
import { FavouriteLocation } from "@/typings/RoutePlanner";

export default defineComponent({
  components: {
    PopularLocations,
    SavedLocations,
    RecentLocations,
  },

  data: () => ({
    isAddAddressModalOpen: false,
    routeData: null as null | { data: boolean },
    savedLocations: [] as FavouriteLocation[],
  }),

  computed: {
    hasRouteQuery() {
      return Object.keys(this.$route.query).length > 0;
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
});
