import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-668689b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sLayout wasteSideBar" }
const _hoisted_2 = { class: "sLayout__body" }
const _hoisted_3 = {
  key: 0,
  style: {"text-align":"center"}
}
const _hoisted_4 = { class: "wrapper ellipsis" }
const _hoisted_5 = { class: "title ellipsis" }
const _hoisted_6 = { class: "progressBar" }
const _hoisted_7 = { class: "wasteIcons" }
const _hoisted_8 = { class: "ellipsis" }
const _hoisted_9 = { class: "icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InfinityScroll = _resolveComponent("InfinityScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InfinityScroll, {
        onLoadNextPage: _ctx.loadWasteStations,
        data: _ctx.wasteStations,
        loading: _ctx.isLoading
      }, {
        default: _withCtx(() => [
          (_ctx.wasteStations.data && _ctx.wasteStations.data.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("global.no_data")), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wasteStations.data, (station) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              class: "flex customTabLink",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sidebar-toggled'))),
              key: station.id,
              to: { name: 'EcoIslandById', params: { id: station.id } }
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass([[
              _ctx.checkIfStationSmart(station.trashcans)
                ? 'icon-warpit_icon_waste_containers_smart'
                : 'icon-warpit_icon_waste_containers',
            ], "icon"])
                }, null, 2),
                _createElementVNode("span", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(station.title), 1),
                  _createElementVNode("span", _hoisted_6, [
                    (_ctx.checkIfStationSmart(station.trashcans))
                      ? (_openBlock(), _createBlock(_component_ProgressBar, {
                          key: 0,
                          value: station.waste_level,
                          class: _normalizeClass(_ctx.getProgressBarColorClass(station.waste_level))
                        }, null, 8, ["value", "class"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(station.address), 1),
                    _createElementVNode("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trashCans, (trashcan, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          (_ctx.checkTrashcanExists(station.trashcans, trashcan.name))
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: _normalizeClass([[trashcan.icon, { disable: trashcan.disable }], "icon"])
                              }, null, 2))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["onLoadNextPage", "data", "loading"])
    ])
  ]))
}