
import { defineComponent } from "vue";
import { Event } from "@/typings/Event";
import { Article } from "@/typings/Article";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    searchResult: {
      news: null as Article[] | null,
      events: null as Event[] | null,
    },
    searchString: "",
  }),
  methods: {
    search() {
      firebaseInstance
        ?.analytics()
        .logEvent("search", { data: this.searchString });
      this.$http
        .get("api/v1/search", {
          params: { searchString: encodeURIComponent(this.searchString) },
        })
        .then((response) => {
          this.searchResult = response.data;
        });
    },
  },
});
