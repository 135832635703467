
import { defineComponent, ref } from "vue";
import SidebarEko from "@/components/wasteManagement/SidebarEko.vue";
import { useResizeObserver } from "@vueuse/core";

export default defineComponent({
  components: { SidebarEko },
  data: () => ({
    cardOpen: false,
    toggleFilters: false,
    selectedFilter: {},
  }),
  mounted() {
    this.selectedFilter = this.filters[0];
  },
  setup() {
    const colLeft = ref(null);
    const colRight = ref(null);
    const colLeftHeight = ref(0);
    const colRightHeight = ref(0);

    useResizeObserver(colLeft, (entries) => {
      const entry = entries[0];
      colLeftHeight.value = entry.contentRect.height;
    });

    useResizeObserver(colRight, (entries) => {
      const entry = entries[0];
      colRightHeight.value = entry.contentRect.height;
    });

    return {
      colLeft,
      colRight,
      colLeftHeight,
      colRightHeight,
    };
  },
  props: {
    title: { type: String, default: "" },
    sidebarLeft: { type: Boolean, default: false },
    sidebarComponent: { type: Object },
    mapComponent: { type: Object, default: null },
    sidebarActive: { type: Boolean, default: false },
  },
  methods: {
    toggleSidebar() {
      this.cardOpen = !this.cardOpen;
    },
    toggleFilter() {
      this.toggleFilters = !this.toggleFilters;
    },
    activateFilter(filterId: string) {
      this.filters.forEach((filter) => {
        if (filter.id === filterId) {
          this.selectedFilter = filter;
        }
      });
    },
  },
  computed: {
    filters() {
      return [
        {
          id: "all",
          name: this.$t("waste_types.all"),
          icon: "icon-warpit_icon_3x3",
        },
        {
          id: "other",
          name: this.$t("waste_types.other"),
          icon: "icon-warpit_icon_waste_other",
        },
        {
          id: "organic",
          name: this.$t("waste_types.organic"),
          icon: "icon-warpit_icon_waste_organic",
        },
        {
          id: "plastic",
          name: this.$t("waste_types.packaging"),
          icon: "icon-warpit_icon_waste_packaging",
        },
        {
          id: "glass",
          name: this.$t("waste_types.glass"),
          icon: "icon-warpit_icon_waste_glass",
        },
        {
          id: "paper",
          name: this.$t("waste_types.paper"),
          icon: "icon-warpit_icon_waste_paper",
        },
        {
          id: "oil",
          name: this.$t("waste_types.oil"),
          icon: "icon-warpit_icon_oil",
        },
        {
          id: "textile",
          name: this.$t("waste_types.textile"),
          icon: "icon-warpit_icon_chlotes",
        },
        {
          id: "batteries",
          name: this.$t("waste_types.batteries"),
          icon: "icon-warpit_icon_battery",
        },
        {
          id: "WEEE",
          name: this.$t("waste_types.WEEE"),
          icon: "icon-warpit_icon_phone_broken",
        },
      ];
    },
  },
  watch: {
    sidebarActive() {
      this.toggleSidebar();
    },
  },
});
