import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b0007c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-header overflow-hidden" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 0,
  class: "hidden sm:block"
}
const _hoisted_4 = { class: "flex justify-content-between align-items-center w-full pt-3 h-2rem" }
const _hoisted_5 = { class: "flex gap-2 align-items-center" }
const _hoisted_6 = {
  key: 0,
  class: "text-xs px-2 py-1 surface-200 border-round-3xl line-height-1"
}
const _hoisted_7 = { class: "text-sm" }
const _hoisted_8 = { class: "fillDialogTitle" }
const _hoisted_9 = { class: "fillDialogButtons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlaceholderImage = _resolveComponent("PlaceholderImage")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      tabindex: "1",
      class: _normalizeClass(["card flex flex-column cursor-pointer", {
      showcase: _ctx.showcase,
    }]),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'Article', params: { id: _ctx.article.id } }))),
      onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$router.push({ name: 'Article', params: { id: _ctx.article.id } })), ["enter"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["card-image relative flex justify-content-center align-items-center w-full", {
          'h-10rem': !_ctx.showcase,
          'h-18rem': _ctx.showcase,
        }])
        }, [
          (_ctx.article.image_url)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.article.image_url,
                alt: _ctx.article.image_alt,
                class: "w-full h-full"
              }, null, 8, _hoisted_2))
            : (_openBlock(), _createBlock(_component_PlaceholderImage, { key: 1 }))
        ], 2),
        _createElementVNode("span", {
          class: _normalizeClass(["inline-block font-semibold text-lg line-height-2 w-full", {
          'text-4xl': _ctx.showcase,
          title: !_ctx.showcase,
        }])
        }, _toDisplayString(_ctx.article.title), 3),
        (_ctx.showcase)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.article.summary), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (
            _ctx.article.category && _ctx.$te(`news.categories.${_ctx.article.category.name}`)
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t(`news.categories.${_ctx.article.category.name}`).toUpperCase()), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.article.datetime !== null
            ? _ctx.formatDate(_ctx.article.datetime)
            : _ctx.formatDate(_ctx.article.created_at)), 1)
        ]),
        (_ctx.user)
          ? _withDirectives((_openBlock(), _createElementBlock("i", {
              key: 0,
              class: _normalizeClass({
          'pi pi-bookmark-fill': _ctx.article.saved,
          'pi pi-bookmark': !_ctx.article.saved,
        }),
              tabindex: "1",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.article.saved ? (_ctx.showDialog = true) : _ctx.saveArticle()), ["stop"])),
              onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.article.saved ? (_ctx.showDialog = true) : _ctx.saveArticle()), ["enter"]))
            }, null, 34)), [
              [
                _directive_tooltip,
                
          _ctx.article.saved ? _ctx.$t('news.remove_saved') : _ctx.$t('news.save_news')
        ,
                void 0,
                { top: true }
              ]
            ])
          : _createCommentVNode("", true)
      ])
    ], 34),
    _createVNode(_component_Dialog, {
      visible: _ctx.showDialog,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showDialog) = $event)),
      class: "successDialog",
      tabindex: "1",
      closable: false,
      modal: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("news.sure")), 1),
        _createElementVNode("div", null, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("news.unsave", { category: _ctx.article.category })), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", {
            class: "defaultButton",
            tabindex: "1",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.removeSavedArticle && _ctx.removeSavedArticle(...args)))
          }, _toDisplayString(_ctx.$t("news.confirm_unsave")), 1),
          _createElementVNode("span", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showDialog = false)),
            tabindex: "1",
            onKeypress: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.showDialog = false), ["enter"]))
          }, _toDisplayString(_ctx.$t("global.cancel")), 33)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}