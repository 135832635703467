
import { defineComponent } from "@vue/runtime-core";
import Button from "primevue/button";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    sipassConsent: false,
  }),
  methods: {
    sipassLogin() {
      let params = { redirect_url: window.location.href, provider: "si-pass" };
      this.$http
        .get("api/v1/auth/generate-auth-url", { params })
        .then((response) => {
          firebaseInstance?.analytics().logEvent("login_screen", {
            page: "login_screen",
            sub_category: "normal_login",
            category_desc: "Prijavni zaslon odprt",
            sub_category_desc: "Uporabnik je izvedel preprosto prijavo",
            ...this.$store.getters["user/analyticsInfo"],
          });
          window.open(response.data.redirect_url, "_self");
        });
    },
  },
  components: {
    Button,
  },
});
