
import { ref, defineComponent, computed } from "vue";

export default defineComponent({
  name: "WeatherChart",
  props: {
    percentage: { type: Number, required: true },
    leftText: { type: String, default: "0" },
    rightText: { type: String, default: "100" },
  },
  components: {},

  setup(props) {
    const degreesTurned = ref(0);

    const maxDegrees = computed(() => {
      return (Math.round(props.percentage) / 100) * 180;
    });

    const interval = setInterval(() => {
      if (degreesTurned.value < maxDegrees.value) {
        degreesTurned.value += 1;
      } else {
        clearInterval(interval);
      }
    }, 5);
    return {
      degreesTurned,
      maxDegrees,
    };
  },
});
