
import { defineComponent } from "vue";
import { QueryParameters } from "@/typings/QueryParameters";
import { Paginated } from "@/typings/Paginated";
import { Coordinates } from "@/typings/Marker";
import FavouriteButton from "@/components/FavouriteButton.vue";
import InfinityScroll from "@/components/InfinityScroll.vue";
import Divider from "primevue/divider";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import ListFilters from "@/components/ListFilters.vue";
import { BikeStation } from "@/typings/BikeStation";

export default defineComponent({
  components: { FavouriteButton, InfinityScroll, Divider, ListFilters },
  data: () => ({
    stations: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<BikeStation>,
    address: null as null | string,
    selectedSorting: "distance",
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    isLoading: false,
  }),
  methods: {
    loadStations(page = 1) {
      this.isLoading = true;

      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
      } = {
        page,
        perPage: 20,
        orderBy: this.configureOrdering(),
      };

      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;

        params.radius = Math.pow(2, 20);

        if (this.address !== null) {
          params.radius = 7000;
        }
      }

      this.$http
        .get("api/v1/bike-stations", { params })
        .then((response) => {
          if (page !== 1) {
            this.stations.data = this.stations.data.concat(response.data.data);
            this.stations.current_page = response.data.current_page;
            return;
          }
          this.stations = response.data;
        })
        .finally(() => (this.isLoading = false));
    },
    setAddress(place: GmapPlaceResult) {
      this.address = place.formatted_address;

      if (this.address === undefined) {
        return;
      }

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      this.center = {
        lat: latitude,
        lng: longitude,
      };

      this.loadStations();
    },
    clearAddress() {
      this.address = null;

      this.center = {
        lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
      };

      this.loadStations();
    },
    setFavouriteStation(station: BikeStation) {
      if (!this.user) {
        this.$router.push({ name: "BikesFavourite" });
        return;
      }

      if (station.favourite === true) {
        this.$http
          .delete("api/v1/locations/" + station.my_location_id)
          .then(() => {
            station.favourite = false;
          });
        return;
      }

      this.$http
        .post("api/v1/locations", {
          type_id: 5,
          external_identifier: station.id,
        })
        .then((response) => {
          station.favourite = true;
          station.my_location_id = response.data.id;
        });
      return;
    },
    configureOrdering() {
      const orderBy: { [key: string]: string } = {};

      if (this.selectedSorting === "distance") {
        orderBy.distance = "ASC";
      }

      if (this.selectedSorting === "occupancy") {
        orderBy.bikes_total_available = "DESC";
      }

      return orderBy;
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  mounted() {
    this.loadStations();
  },
  watch: {
    selectedSorting() {
      this.loadStations();
    },
  },
});
