
import Tree, { TreeNode } from "primevue/tree";
import { defineComponent } from "vue";
import { Coordinates, Marker } from "@/typings/Marker";
import { QueryParameters } from "@/typings/QueryParameters";
import { Parking } from "@/typings/Parking";
import { BikeStation } from "@/typings/BikeStation";
import { AirQualitySensor } from "@/typings/AirQualitySensor";
import Map from "@/components/Map.vue";
import TrafficFeature from "@/typings/TrafficFeature";
import { InfoFeature } from "@/typings/Info";
import { useCssVar } from "@vueuse/core";
import { GeoJson } from "@/typings/GeoJson";
import { TrafficInfo } from "@/typings/TrafficInfo";
import { SharedMobility } from "@/typings/SharedMobility";

export default defineComponent({
  data: () => ({
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    zoom: 15,
    parkingsArray: [] as Marker[],
    bikesArray: [] as Marker[],
    airQualitySensorsArray: [] as Marker[],
    trafficArray: [] as Marker[],
    selectedNodes: [] as TreeNode[],
    cardOpen: false,
    markers: [] as Marker[],
    featureKeys: [] as string[],
    geoJson: [] as GeoJson[],

    mapKeys: ["latitude", "longitude", "radius"],
  }),
  methods: {
    markerIcon(iconName: string) {
      return `/markers/${iconName}.svg`;
    },
    toggleCard() {
      this.cardOpen = !this.cardOpen;
    },
    updateCenter(value: Coordinates) {
      this.center = value;
      this.fillMarkers();
    },
    updateZoom(value: number) {
      this.zoom = value;
      this.fillMarkers();
    },
    fillMarkers() {
      let params = {} as QueryParameters & {
        [key: string]: boolean | number;
      };

      this.markers = [] as Marker[];
      this.geoJson = [] as GeoJson[];

      if (Object.keys(this.selectedNodes).length === 0) {
        return;
      }

      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;
        params.radius = Math.pow(2, 27 - this.zoom);
      }

      Object.keys(this.selectedNodes).forEach((key) => {
        params[key] = true;
      });

      this.getData(params);
    },

    getMapLocations(
      params: QueryParameters & {
        [key: string]: boolean | number;
      },
    ) {
      if (
        Object.keys(params).length === this.mapKeys.length &&
        this.mapKeys.every((key) => Object.keys(params).includes(key))
      ) {
        return;
      }

      this.$http.get("api/v1/map-locations", { params }).then((response) => {
        const responseData = response.data;
        if (responseData.parkings) {
          let parkings = response.data.parkings.data as Parking[];
          this.markers = this.markers.concat(
            parkings.map((parking) => ({
              id: parking.id,
              position: {
                lat: parseFloat(parking.latitude),
                lng: parseFloat(parking.longitude),
              },
              on_click_route_name: "ParkingById",
              type: "parking",
              title: parking.name,
              icon: this.markerIcon("parking"),
            })),
          );
        }

        if (responseData.sensors) {
          let sensors = response.data.sensors.data as AirQualitySensor[];
          this.markers = this.markers.concat(
            sensors.map((sensor) => ({
              id: sensor.id,
              position: {
                lat: parseFloat(sensor.latitude),
                lng: parseFloat(sensor.longitude),
              },
              on_click_route_name: "AirQualityById",
              type: "air_quality",
              title: sensor.name,
              icon: this.markerIcon("airQuality"),
            })),
          );
        }

        if (responseData.traffic) {
          let traffic = response.data.traffic;
          this.markers = this.markers.concat(
            traffic.features.map((traffic: TrafficFeature) => ({
              id: traffic.properties.id,
              position: {
                lat: parseFloat(traffic.geometry.coordinates[1]),
                lng: parseFloat(traffic.geometry.coordinates[0]),
              },
              on_click_route_name: "TrafficById",
              type: this.getTrafficMarkerType(traffic.properties.vzrok),
              title: traffic.properties.opis,
              icon: this.markerIcon(
                this.getTrafficMarkerType(traffic.properties.vzrok),
              ),
            })),
          );
        }

        if (responseData.bikes) {
          let bikes = response.data.bikes.data as BikeStation[];
          this.markers = this.markers.concat(
            bikes.map((bike) => ({
              id: bike.id,
              position: {
                lat: parseFloat(bike.latitude),
                lng: parseFloat(bike.longitude),
              },
              on_click_route_name: "BikesById",
              type: "bike_station",
              title: bike.name,
              icon: this.markerIcon("bike"),
            })),
          );
        }

        if (responseData.trafficInfo) {
          let trafficInfos = response.data.trafficInfo.data as TrafficInfo[];
          this.markers = this.markers.concat(
            trafficInfos.map((trafficInfo) => ({
              id: trafficInfo.id,
              position: {
                lat: parseFloat(trafficInfo.latitude),
                lng: parseFloat(trafficInfo.longitude),
              },
              on_click_route_name: "TrafficInfoById",
              type: this.getTrafficMarkerType(trafficInfo.sub_category),
              title: this.$t("traffic_info.type." + trafficInfo.sub_category),
              icon: this.markerIcon(
                this.getTrafficMarkerType(trafficInfo.sub_category),
              ),
            })),
          );
        }

        if (responseData.sharedMobility) {
          let sharedMobility = response.data.sharedMobility
            .data as SharedMobility[];
          this.markers = this.markers.concat(
            sharedMobility.map((station) => ({
              id: station.id,
              position: {
                lat: parseFloat(station.latitude),
                lng: parseFloat(station.longitude),
              },
              on_click_route_name: "SharedMobilityById",
              type: "shared_mobility",
              title: station.name,
              icon: this.getSharedMobilityMarkerType(station.provider.name),
            })),
          );
        }
      });
    },
    getData(
      params: QueryParameters & {
        [key: string]: boolean | number;
      },
    ) {
      const mapParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key]) => !this.featureKeys.includes(key),
        ),
      );

      this.getMapLocations(mapParams);

      const featureParams = Object.fromEntries(
        Object.entries(params).filter(([key]) =>
          this.featureKeys.includes(key),
        ),
      );

      for (let key of Object.keys(featureParams)) {
        this.$directus(key).then((response) => {
          const featureData = response.data.data;

          for (let item of featureData) {
            const type = item.geometry?.type;

            if (!type) {
              continue;
            }

            if (type === "Point") {
              const [lng, lat] = item.geometry.coordinates as Array<number>;

              this.markers = this.markers.concat({
                id: Number(item.id),
                position: { lat, lng },
                on_click_route_name: "InfoById",
                title: item.name,
                icon: this.markerIcon(key),
              });

              continue;
            }

            this.geoJson = this.geoJson.concat({
              id: String(item.id),
              title: item.name,
              geometry: item.geometry,
              properties: item.properties,
              path: this.configurePoints(item.geometry.coordinates),
              payload: item,
              selected: false,
              color: this.getGeojsonColor(key),
            });
          }
        });
      }
    },
    configurePoints(
      coordinates:
        | Array<number>
        | Array<Array<number>>
        | Array<Array<Array<number>>>,
    ): Coordinates[] {
      let newCoordinates: Array<Coordinates> = [];

      let coordinatesArray:
        | Array<number>
        | Array<Array<number>>
        | Array<Array<Array<number>>> = coordinates;

      if (coordinates.length === 1 && Array.isArray(coordinates[0])) {
        coordinatesArray = coordinates[0] as Array<Array<number>>;
      }

      coordinatesArray.forEach(
        (item: number | Array<number> | Array<Array<number>>) => {
          if (Array.isArray(item)) {
            const [lng, lat] = item;
            newCoordinates.push({
              lat: lat as number,
              lng: lng as number,
            });
          }
        },
      );

      return newCoordinates;
    },
    getTrafficMarkerType(reason: string) {
      if (reason === "Delo" || reason === "roadWorks") {
        return "roadwork";
      }
      if (reason === "Zastoj" || reason === "trafficJam") {
        return "trafficJam";
      }
      if (reason === "Zapora" || reason === "roadClosed") {
        return "roadblock";
      }
      return "obstacle";
    },
    getSharedMobilityMarkerType(providerName: string) {
      if (providerName === "Avant2Go") {
        return `/markers/avant2go.png`;
      }
      return "/markers/greengo.png";
    },
    getGeojsonColor(type: string) {
      if (type === "traffic_projects") {
        return "#DA8E36";
      }

      return useCssVar("--gray-500").value;
    },
  },
  computed: {
    nodes() {
      const showBikesNode = process.env.VUE_APP_BIKES_ENABLE === "true";
      const showSharedMobility =
        process.env.VUE_APP_SHARED_MOBILITY_ENABLE === "true";

      const nodesArray: TreeNode[] = [
        {
          key: "parkings",
          label: this.$t("map.parkings"),
          icon: "icon-warpit_icon_parking",
        },
        {
          key: "aqi",
          label: this.$t("map.aqi"),
          icon: "icon-warpit_icon_air-quality",
        },
        {
          key: "trafficInfo",
          label: this.$t("map.traffic"),
          icon: "icon-warpit_icon_car",
        },
      ];

      if (showBikesNode) {
        nodesArray.push({
          key: "bikes",
          label: this.$t("map.bikes"),
          icon: "icon-warpit_icon_bike-sharing",
        });
      }
      if (showSharedMobility) {
        nodesArray.push({
          key: "sharedMobility",
          label: this.$t("map.shared_mobility"),
          icon: "icon-warpit_icon_car-sharing",
        });
      }
      const features = this.$store.getters["info-features/features"] as
        | InfoFeature[]
        | null;

      const mapFeatures = features?.filter((feature: InfoFeature) => {
        return feature && feature.view === "Map" && feature.enabled;
      });

      mapFeatures?.forEach((feature: InfoFeature) => {
        this.featureKeys.push(feature.collection);
        nodesArray.push({
          key: feature.collection,
          label: feature[
            ("name_" + this.$i18n.locale) as keyof InfoFeature
          ] as string,
          icon: feature.icon,
        });
      });

      return nodesArray.filter((node) => node !== null);
    },
  },
  components: { Map, Tree },
});
