
import { defineComponent } from "vue";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import Button from "primevue/button";

export default defineComponent({
  components: { Button },
  props: {
    searchPlaceholderKey: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    address: null,
    selectedSorting: "distance",
  }),
  methods: {
    clearAddress() {
      this.address = null;
      this.$emit("clear");
    },
  },
});
