import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "category-filter flex align-items-center py-3 border-bottom-1 border-400 w-full" }
const _hoisted_2 = {
  key: 0,
  class: "border-right-1 border-400 h-full"
}
const _hoisted_3 = ["onClick", "onKeyup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      modules: _ctx.modules,
      "slides-per-view": "auto",
      "slides-per-group": 5,
      "space-between": 20,
      navigation: { disabledClass: 'hidden' },
      class: "w-full",
      threshold: 5
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, {
            key: filter.name,
            class: "bg-white w-fit"
          }, {
            default: _withCtx(() => [
              (filter.divider)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    tabindex: "1",
                    class: _normalizeClass(["cursor-pointer", { 'text-primary': filter.id === _ctx.selectedFilter?.id }]),
                    onClick: ($event: any) => (_ctx.$emit('selectFilter', filter)),
                    onKeyup: _withKeys(($event: any) => (_ctx.$emit('selectFilter', filter)), ["enter"])
                  }, _toDisplayString(filter.name), 43, _hoisted_3))
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modules"])
  ]))
}