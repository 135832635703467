
import { defineComponent } from "vue";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import Button from "primevue/button";

export default defineComponent({
  components: { Button },
  emits: ["clear", "mapMode", "addressChange"],
  data: () => ({
    address: null,
  }),
  methods: {
    clearAddress() {
      this.address = null;
      this.$emit("clear");
    },
  },
});
