import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/error.svg'


const _hoisted_1 = { class: "userDashboard" }
const _hoisted_2 = { class: "grayBoxInfo" }
const _hoisted_3 = {
  key: 0,
  class: "errorEmpty"
}
const _hoisted_4 = ["alt"]
const _hoisted_5 = { class: "eventCard deleteEvent" }
const _hoisted_6 = {
  key: 0,
  class: "content"
}
const _hoisted_7 = ["onClick", "onKeypress"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SidebarUserWrapper = _resolveComponent("SidebarUserWrapper")!
  const _component_MapLocations = _resolveComponent("MapLocations")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SidebarUserWrapper, {
      title: _ctx.$t('user.my_locations'),
      sidebarComponent: _ctx.sidebarComponent
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.locations.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    class: "error",
                    alt: _ctx.$t('accessibility.error')
                  }, null, 8, _hoisted_4),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t("locations.title")), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("locations.no_locations")), 1)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locations, (location) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                key: location.id,
                class: "boxMessage",
                to: {
            name: _ctx.getRoute(location.type.name),
            params: { id: location.external_data.id },
          },
                tabindex: "0"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: _normalizeClass(["absolute", _ctx.getIcon(location.type.name)])
                  }, null, 2),
                  _createElementVNode("div", _hoisted_5, [
                    (location.external_data)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("span", null, _toDisplayString(location.external_data.name), 1),
                          _createElementVNode("span", null, _toDisplayString(location.external_data.address), 1),
                          _createElementVNode("span", {
                            class: "icon-warpit_icon_trash icon trash_icon",
                            onClick: _withModifiers(($event: any) => (_ctx.deleteLocation(location)), ["prevent"]),
                            onKeypress: _withKeys(_withModifiers(($event: any) => (_ctx.deleteLocation(location)), ["prevent"]), ["enter"]),
                            tabindex: "0"
                          }, null, 40, _hoisted_7)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "sidebarComponent"]),
    _createVNode(_component_MapLocations)
  ], 64))
}