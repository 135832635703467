
import { defineComponent } from "vue";
import Map from "@/components/Map.vue";
import { Coordinates, Marker } from "@/typings/Marker";
import Information from "@/components/info/Information.vue";
import Button from "primevue/button";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import NearbyCard from "@/components/info/NearbyCard.vue";
import { InfoItem, InfoFeature } from "@/typings/Info";
import { useCssVar } from "@vueuse/core";
import { GeoJson } from "@/typings/GeoJson";

export default defineComponent({
  components: { Map, Button, Information, NearbyCard },
  data: () => ({
    items: [] as Array<InfoItem>,
    selectedItem: null as null | InfoItem,
    selectedMarker: null as null | Marker,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    markers: [] as Marker[],
    geoJson: [] as GeoJson[],
    selectedGeoJson: null as GeoJson | null,
    zoom: 14,
    address: null,
    isLoading: false,
  }),
  computed: {
    nearestItems() {
      return this.items.slice(0, 6);
    },
    infoFeatures(): InfoFeature[] | null {
      return this.$store.getters["info-features/features"];
    },
    infoFeatureExists(): boolean {
      return (
        this.infoFeatures !== null &&
        this.infoFeatures.some(
          (feature) =>
            feature.collection === this.$route.params.feature &&
            feature.view === "Map",
        )
      );
    },
    infoFeatureHasId() {
      if (this.infoFeatures === null) {
        return;
      }

      const feature = this.infoFeatures.find(
        (feature) =>
          feature.collection === this.$route.params.feature &&
          feature.view === "Map",
      );
      const id = this.$route.params.id;

      return { feature, id };
    },
  },
  methods: {
    getTitle(key: string) {
      if (!this.infoFeatures) {
        return key;
      }

      const feature = this.infoFeatures.find(
        (feature: InfoFeature) => feature.collection === key,
      );

      if (!feature) {
        return key;
      }

      return feature[("name_" + this.$i18n.locale) as keyof InfoFeature];
    },
    updateCenter(value: Coordinates) {
      this.center = value;
    },
    updateZoom(value: number, center: Coordinates) {
      this.zoom = value;
      this.center = center;
    },
    async loadItems() {
      this.isLoading = true;

      this.$directus
        .get(`${this.$route.params.feature}`)
        .then((response) => {
          const items = response.data.data as Array<InfoItem>;

          this.items = items;
          this.setPoints();
        })
        .finally(() => (this.isLoading = false));
    },
    async loadSelectedItem() {
      if ("id" in this.$route.params === false) {
        this.unselectItem();
        return;
      }

      this.isLoading = true;

      this.$directus
        .get(`${this.$route.params.feature}/${this.$route.params.id}`)
        .then((response) => {
          const item = response.data.data as InfoItem;

          this.selectedItem = item;

          if (!this.selectedItem) {
            return;
          }

          if (this.selectedItem.geometry.type === "Point") {
            this.selectMarker(this.selectedItem);
          }

          if (
            this.selectedItem.geometry.type === "LineString" ||
            this.selectedItem.geometry.type === "Polygon"
          ) {
            this.selectGeoJson(this.selectedItem);
          }

          this.selectItem(this.selectedItem);
        })
        .finally(() => (this.isLoading = false));
    },
    setPoints() {
      if (!this.items) {
        return;
      }

      this.markers = [] as Marker[];
      this.geoJson = [] as GeoJson[];

      for (let item of this.items) {
        if (item.geometry.type == "Point") {
          const [lng, lat] = item.geometry.coordinates as Array<number>;

          this.markers.push({
            id: Number(item.id),
            position: { lat, lng },
            on_click_route_name: "InfoById",
            selected: item.id === this.selectedMarker?.payload?.id,
            title: item.name,
            icon: "/markers/parking-green.svg",
            payload: item,
          });

          continue;
        }

        this.geoJson.push({
          id: String(item.id),
          title: item.name,
          geometry: item.geometry,
          properties: item.properties,
          path: this.configurePoints(item.geometry.coordinates),
          payload: item,
          selected: item.id === this.selectedGeoJson?.payload?.id,
          color: this.getColor(item),
        });
      }

      if (this.selectedMarker === null) {
        return;
      }

      const existingMarkerIndex = this.markers.findIndex(
        (marker) => marker.id === this.selectedMarker?.id,
      );

      if (existingMarkerIndex !== -1) {
        this.markers[existingMarkerIndex] = this.selectedMarker;
        return;
      }

      this.markers.push(this.selectedMarker);
    },
    configurePoints(
      coordinates:
        | Array<number>
        | Array<Array<number>>
        | Array<Array<Array<number>>>,
    ): Coordinates[] {
      let newCoordinates: Array<Coordinates> = [];

      let coordinatesArray:
        | Array<number>
        | Array<Array<number>>
        | Array<Array<Array<number>>> = coordinates;

      if (coordinates.length === 1 && Array.isArray(coordinates[0])) {
        coordinatesArray = coordinates[0] as Array<Array<number>>;
      }

      coordinatesArray.forEach(
        (item: number | Array<number> | Array<Array<number>>) => {
          if (Array.isArray(item)) {
            const [lng, lat] = item;
            newCoordinates.push({
              lat: lat as number,
              lng: lng as number,
            });
          }
        },
      );

      return newCoordinates;
    },
    selectMarker(item: InfoItem) {
      if (this.selectedMarker?.payload?.id === item.id) {
        return;
      }

      const [lng, lat] = item.geometry.coordinates as Array<number>;

      this.selectedMarker = {
        id: Number(item.id),
        position: { lat, lng },
        on_click_route_name: "InfoById",
        selected: true,
        title: item.name,
        icon: "/markers/parking-selected.svg",
        payload: item,
        type: "selected",
      };

      if ("id" in this.$route.params && this.selectedItem) {
        this.selectItem(item);
      }

      if (this.selectedGeoJson !== null) {
        this.selectedGeoJson = null;
      }

      this.setPoints();
    },
    unselectPoint() {
      this.selectedMarker = null;
      this.selectedGeoJson = null;
      this.setPoints();
    },

    selectGeoJson(item: InfoItem) {
      if (
        this.selectedGeoJson &&
        this.selectedGeoJson?.payload?.id === item.id
      ) {
        return;
      }

      this.selectedGeoJson = {
        id: String(item.id),
        title: item.name,
        geometry: item.geometry,
        path: this.configurePoints(item.geometry.coordinates),
        properties: item.properties,
        payload: item,
        selected: true,
        color: this.getColor(item),
      };

      if ("id" in this.$route.params && this.selectedItem) {
        this.selectItem(item);
      }

      if (this.selectedMarker !== null) {
        this.selectedMarker = null;
      }

      this.setPoints();
    },

    getColor(item: InfoItem): string {
      const colorMap = {
        "Popolna zapora": useCssVar("--red-500").value,
        "Delna zapora": useCssVar("--orange-500").value,
        "Predvidena zapora": useCssVar("--blue-500").value,
        Čakanje: useCssVar("--red-500").value,
        "V delu": useCssVar("--orange-500").value,
        Narejeno: useCssVar("--green-500").value,
      } as Record<string, string>;

      if (
        !item.properties ||
        (item.properties.data_category_txt === null &&
          item.properties.status === null)
      ) {
        return useCssVar("--primary-color").value;
      }

      const polyColorType =
        item.properties.data_category_txt ?? item.properties.status;
      return colorMap[polyColorType] || useCssVar("--primary-color").value;
    },

    selectItem(item: InfoItem) {
      this.selectedItem = item;

      if (
        this.selectedItem.geometry.type === "LineString" ||
        this.selectedItem.geometry.type === "Polygon"
      ) {
        this.selectGeoJson(item);
        const path = this.configurePoints(item.geometry.coordinates);

        this.center = path[0];
      }

      if (this.selectedItem.geometry.type === "Point") {
        this.selectMarker(item);

        const [lng, lat] = item.geometry.coordinates as Array<number>;
        this.center = { lat, lng };
      }

      this.zoom = 16;
      this.$router.push({ name: "InfoById", params: { id: item.id } });
    },

    unselectItem() {
      this.selectedItem = null;

      this.zoom = 14;
      this.updateCenter(this.center);
    },
    setAddress(place: GmapPlaceResult) {
      if (place.formatted_address === undefined) {
        return;
      }

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      this.selectedItem = null;

      this.zoom = 17;
      this.updateCenter({
        lat: latitude,
        lng: longitude,
      });
    },
  },
  watch: {
    infoFeatures: {
      handler() {
        if (this.infoFeatureExists) {
          this.loadItems();
        }
      },
      immediate: true,
    },
    infoFeatureHasId: {
      handler() {
        if (this.infoFeatureHasId?.feature) {
          this.loadSelectedItem();
        }
      },
      immediate: true,
    },
  },
});
