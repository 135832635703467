import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62e81d70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-column gap-3" }
const _hoisted_2 = { class: "p-float-label rounded-input icon error-label mb-0 flex-1 w-full" }
const _hoisted_3 = { for: "title" }
const _hoisted_4 = {
  key: 0,
  class: "text-sm mt-1 mb-0",
  style: {"color":"red"}
}
const _hoisted_5 = { class: "p-float-label rounded-input icon error-label mb-0 flex-1 w-full" }
const _hoisted_6 = { for: "address" }
const _hoisted_7 = {
  key: 0,
  class: "text-sm mt-1 mb-0",
  style: {"color":"red"}
}
const _hoisted_8 = { class: "flex flex-column gap-2 mt-1" }
const _hoisted_9 = { class: "font-semibold" }
const _hoisted_10 = { class: "flex gap-2 flex-wrap" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { style: {"color":"red"} }
const _hoisted_13 = { class: "flex gap-4 justify-content-end mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPencil = _resolveComponent("IconPencil")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_IconMapPin = _resolveComponent("IconMapPin")!
  const _component_GMapAutocomplete = _resolveComponent("GMapAutocomplete")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    modal: true,
    draggable: false,
    header: 
      _ctx.location ? _ctx.$t('route_planner.edit.title') : _ctx.$t('route_planner.add.title')
    ,
    class: "rounded lg",
    onHide: _ctx.clearForm
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_2, [
            _createElementVNode("i", null, [
              _createVNode(_component_IconPencil)
            ]),
            _createVNode(_component_InputText, {
              modelValue: _ctx.form.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event)),
              placeholder: "",
              id: "title",
              maxlength: "255"
            }, null, 8, ["modelValue"]),
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("route_planner.add.name")), 1)
          ]),
          (_ctx.errors.title)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.errors.title), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_5, [
            _createElementVNode("i", null, [
              _createVNode(_component_IconMapPin)
            ]),
            _createVNode(_component_GMapAutocomplete, {
              class: _normalizeClass({
              'autocomplete pac-target-input': !_ctx.form.address,
              'autocomplete pac-target-input p-filled': _ctx.form.address,
            }),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.address = $event.target.value)),
              onPlace_changed: _ctx.setAddress,
              options: {
              componentRestrictions: { country: 'si' },
            },
              value: _ctx.form.address,
              placeholder: "",
              id: "address",
              maxlength: "255"
            }, null, 8, ["class", "onPlace_changed", "value"]),
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("route_planner.add.address")), 1)
          ]),
          (_ctx.errors.address)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.errors.address), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("route_planner.add.icon")), 1),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.icons, (icon) => {
              return (_openBlock(), _createElementBlock("button", {
                key: icon.value,
                class: _normalizeClass(["icon-toggle flex justify-content-center align-items-center border-round-2xl bg-primary-50 border-none cursor-pointer", {
              'bg-primary': _ctx.selectedIcon === icon.value,
            }]),
                onClick: ($event: any) => (_ctx.selectedIcon = icon.value)
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(icon.icon), {
                  class: _normalizeClass(["text-xl", { 'icon-selected': _ctx.selectedIcon === icon.value }])
                }, null, 8, ["class"]))
              ], 10, _hoisted_11))
            }), 128))
          ])
        ]),
        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.errors.location_error), 1),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_Button, {
            class: "button text",
            label: _ctx.$t('global.cancel'),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
          }, null, 8, ["label"]),
          _createVNode(_component_Button, {
            class: "button primary",
            label: _ctx.$t('global.save'),
            onClick: _ctx.saveLocation
          }, null, 8, ["label", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["header", "onHide"]))
}