import { RRule } from "rrule";
import { GetText } from "rrule/dist/esm/nlp/totext";
import { Language } from "rrule/dist/esm/nlp/i18n";
import { RRuleLanguage } from "@/typings/RRuleLanguage";

class RRuleParser {
  private language: RRuleLanguage | null;

  constructor(locale: string) {
    this.language = null;
    const requireModule = require.context(
      "@/localization/rrule",
      true,
      /\.json$/
    );
    const keys = requireModule.keys();
    for (const key in keys) {
      const filename = keys[key];
      if (filename.includes(locale) === true) {
        this.language = requireModule(filename);
        break;
      }
    }
  }

  public localize(rule: string): string {
    if (this.language !== null) {
      const langStrings = this.language.strings;
      const getText = function (text: string): string {
        return langStrings[text as keyof typeof langStrings] || text;
      };
      const rrule = RRule.fromString(rule);
      return rrule.toText(
        getText as GetText,
        this.language.language as Language
      );
    }
    const rrule = RRule.fromString(rule);
    return rrule.toText();
  }
}

export default RRuleParser;
