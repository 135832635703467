/**
 * Automatically imports all the modules and exports as a single module object
 */
const requireModule = require.context(".", false, /\.ts$/);
// eslint-disable-next-line
const modules: { [key: string]: any } = {};

requireModule.keys().forEach((filename) => {
  // Don't register this file as a Vuex module
  if (filename === "./index.ts") return;

  // create the module name from fileName
  // remove the store.ts extension
  const moduleName = filename.replace(/(\.\/|\.store\.ts)/g, "");

  modules[moduleName] =
    requireModule(filename).default || requireModule(filename);
});

export default modules;
