
import { defineComponent } from "vue";
import Password from "primevue/password";
import Button from "primevue/button";
import { DeleteUserForm } from "@/typings/User";

export default defineComponent({
  data: () => ({
    errors: {} as DeleteUserForm,
    form: {} as DeleteUserForm,
  }),
  methods: {
    deleteUser() {
      if (this.validate()) {
        return;
      }

      this.$http
        .delete("api/v2/user", { data: { password: this.form.password } })
        .then(async () => {
          await this.$store.dispatch("user/logout");
          this.$router.push({ name: "Home" });
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 422) {
            this.errors.password = this.$t("error.delete_profile_mistake");
          }
        });
    },
    validate() {
      this.errors = {} as DeleteUserForm;

      if (this.form.password === undefined || this.form.password === "") {
        this.errors.password = this.$t("error.delete_profile_missing_password");
        return true;
      }
      return false;
    },
  },
  components: { Password, Button },
});
