
import { defineComponent } from "vue";
import Toolbar from "./Toolbar.vue";

export default defineComponent({
  components: { Toolbar },
  computed: {
    hasRouteQuery() {
      return Object.keys(this.$route.query).length > 0;
    },
  },
});
