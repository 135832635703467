
import { defineComponent } from "vue";
import Filters from "@/components/events/Filters.vue";
import { Filter } from "@/typings/News";

export default defineComponent({
  data: () => ({
    selectedFilter: null as null | Filter,
    categories: null as null | Array<Filter>,
  }),
  computed: {
    filters(): Array<Filter> {
      return [
        {
          id: "current",
          name: this.$t(`events.categories.current`),
        },
        {
          id: "for-you",
          name: this.$t(`events.categories.for-you`),
        },
        {
          name: "divider",
          divider: true,
        },
        ...(this.categories
          ?.filter((item) => this.$te(`events.categories.${item.name}`))
          .map((cat) => ({
            ...cat,
            name: this.$t(`events.categories.${cat.name}`),
          })) ?? []),
      ];
    },
  },
  methods: {
    async loadCategories() {
      let params = {
        perPage: 30,
      };

      return this.$http
        .get("api/v2/events/categories", { params })
        .then((response) => {
          this.categories = response.data.data;
        });
    },
    selectFilter(filter: Filter) {
      this.selectedFilter = filter;
    },
    useQueryFilter() {
      // Ignore single event and subscriptions views
      if (
        this.$route.name === "Event" ||
        this.$route.name === "EventsSubscriptions"
      ) {
        return;
      }

      let filter: Filter | undefined;

      if (this.$route.query.view) {
        filter = this.filters.find((f) => f.id === this.$route.query.view);
      } else if (this.$route.query.category_id) {
        filter = this.filters.find(
          (f) => f.id === Number(this.$route.query.category_id),
        );
      }

      if (filter === undefined) {
        this.selectedFilter = this.filters[0];
        return;
      }

      this.selectedFilter = filter;
    },
    buildQuery() {
      let query: {
        [key: string]: string | number;
      } = {};

      if (this.selectedFilter && typeof this.selectedFilter.id === "number") {
        query.category_id = this.selectedFilter.id;
      }

      if (this.selectedFilter && typeof this.selectedFilter.id === "string") {
        query.view = this.selectedFilter.id;
      }

      if (isNaN(Number(this.$route.query.page)) === false) {
        query.page = Number(this.$route.query.page);
      }

      if (this.$route.query.from) {
        query.from = String(this.$route.query.from);
      }

      if (this.$route.query.until) {
        query.until = String(this.$route.query.until);
      }

      return query;
    },
  },
  watch: {
    selectedFilter: {
      handler(newValue, oldValue) {
        if (newValue === null) {
          return;
        }

        const query = this.buildQuery();

        // Reset page to 1 on filter change
        if (oldValue && newValue.id !== oldValue.id) {
          query.page = 1;
        }

        this.$router.push({
          name: "Events",
          query,
        });
      },
    },
    "$route.query": {
      handler() {
        if (this.$route.query.view === "for-you") {
          return;
        }

        if (Object.keys(this.$route.query).length === 0) {
          return;
        }

        this.useQueryFilter();
      },
    },
  },
  mounted() {
    this.loadCategories().then(() => this.useQueryFilter());
  },
  components: { Filters },
});
