
import { defineComponent } from "vue";
import Button from "primevue/button";
import SidebarUserWrapper from "@/components/SidebarUserWrapper.vue";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";
import ApplicationBox from "@/components/eApplication/ApplicationBox.vue";
import { Application } from "@/typings/Application";

export default defineComponent({
  data: () => ({
    activeTab: "Submitted",
    applications: [] as Application[],
    sidebarComponent: SidebarUserMenu,
  }),
  mounted() {
    this.loadApplications();
  },
  methods: {
    loadApplications() {
      this.$http.get("api/v1/forms/submissions").then((response) => {
        this.applications = response.data;
      });
    },
  },
  computed: {
    verificationLevel() {
      return this.$store.getters["user/verificationLevel"];
    },
    tabs() {
      return [
        {
          id: 1,
          key: "Submitted",
          title: this.$t("forms.my_form.tabs.submitted"),
        },
        {
          id: 2,
          key: "In Processing",
          title: this.$t("forms.my_form.tabs.in_progress"),
        },
        {
          id: 3,
          key: "Approved",
          title: this.$t("forms.my_form.tabs.approved"),
        },
        {
          id: 4,
          key: "Rejected",
          title: this.$t("forms.my_form.tabs.rejected"),
        },
      ];
    },
    filteredApplications() {
      return this.applications.filter(
        (application) => application.status === this.activeTab
      );
    },
  },
  components: {
    Button,
    ApplicationBox,
    SidebarUserWrapper,
  },
});
