
import { Route } from "@/typings/RoutePlanner";
import { defineComponent, PropType } from "vue";
import IconWalk from "~icons/tabler/walk";
import IconCar from "~icons/tabler/car";
import IconBike from "~icons/tabler/bike";
import IconChevronRight from "~icons/tabler/chevron-right";

export default defineComponent({
  components: {
    IconWalk,
    IconCar,
    IconBike,
    IconChevronRight,
  },

  props: {
    route: {
      type: Object as PropType<Route>,
      required: true,
    },
  },

  methods: {
    handleRouteSelection(route: Route) {
      this.$store.dispatch("route-planner/setRoute", route);
      this.$router.push({ name: "SingleRoutePlannerView" });
    },
    getDuration(route: Route): string {
      const duration = this.$moment.duration(
        this.$moment(route.endTime).diff(this.$moment(route.startTime)),
      );

      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.minutes());

      return hours >= 1 ? `${hours}h ${minutes}min` : `${minutes}min`;
    },

    getArrivalTime(route: Route): string {
      return this.$moment(route.endTime).format("HH:mm");
    },
  },
});
