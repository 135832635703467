
import { PropType, defineComponent } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import TakeawayType from "./TakeawayType.vue";
import {
  WasteTakeAwayForm,
  WasteTakeAwayErrors,
} from "@/typings/WasteManagement";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import { firebaseInstance } from "@/plugins/firebase";
export default defineComponent({
  components: { Button, InputText, TakeawayType, Dialog },
  props: {
    order: {
      type: Object as PropType<WasteTakeAwayForm>,
      required: true,
    },
  },
  data: () => ({
    form: {
      client_number: "",
      client_name: "",
      address: "",
      personal_id_number: "",
      phone_number: "",
      email: "",
      notes: "",
      waste_type_quantity: {
        furniture: 0,
        electronic: 0,
        mattresses: 0,
        carpets: 0,
        sports_equipment: 0,
        garden_machinery: 0,
        stoves_cookers_radiators: 0,
        iron: 0,
      },
      citizen_id: "",
    } as WasteTakeAwayForm,
    errors: {} as WasteTakeAwayErrors,
    regexEmail: /^(?!\s*$)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    regexPhone:
      /^(?:(?:(\+|00)\d{3}\s?)|(?:\d{3}))?(\s?\d{9}|\s?\d{3}(?:[\s-]?\d{3}){2}|\s?\d{2}(?:[\s-]?\d{3}){2})$/,
    regexString: /^(?!\s*$).{1,255}$/,
    showInfoDialog: false,
    lastModifiedType: "",
  }),
  created() {
    this.form = this.order;
  },
  methods: {
    phoneNumberFocusOut() {
      firebaseInstance?.analytics().logEvent("private_waste_screen", {
        page: "private_waste_screen",
        category: "notification_subscription",
        category_desc: "Uporabnik je naročen na obvestila o javnih odpadkih",
        sub_category: "add_telephone_number",
        sub_category_desc: "Uporabnik je dodal telefonsko številko",
        ...this.$store.getters["user/analyticsInfo"],
      });
    },
    showInfoDialogCall() {
      firebaseInstance?.analytics().logEvent("private_waste_screen", {
        page: "private_waste_screen",
        category: "notification_subscription",
        category_desc: "Uporabnik je naročen na obvestila o javnih odpadkih",
        sub_category: "tab_on_question_mark",
        sub_category_desc: "Uporabnik odprl info zaslon",
        ...this.$store.getters["user/analyticsInfo"],
      });
      this.showInfoDialog = true;
    },
    setAddress(place: GmapPlaceResult) {
      this.form.address = place.formatted_address;
    },
    validateAndContinue() {
      this.errors = {} as WasteTakeAwayErrors;
      if (
        this.form.client_number !== "" &&
        !/^\d{5,10}$/.test(this.form.client_number)
      ) {
        this.errors.client_number = this.$t(
          "waste_take_away_errors.client_number",
        );
      }
      if (
        !this.form.client_name ||
        !this.regexString.test(this.form.client_name)
      ) {
        this.errors.client_name = this.$t("waste_take_away_errors.client_name");
      }
      if (!this.form.address || !this.regexString.test(this.form.address)) {
        this.errors.address = this.$t("waste_take_away_errors.address");
      }
      if (
        this.form.personal_id_number &&
        this.form.personal_id_number.length !== 13
      ) {
        this.errors.personal_id_number = this.$t(
          "waste_take_away_errors.personal_id",
        );
      }
      if (this.form.email && !this.regexEmail.test(this.form.email)) {
        this.errors.email = this.$t("waste_take_away_errors.email");
      }
      if (
        !this.form.phone_number ||
        !this.regexPhone.test(this.form.phone_number)
      ) {
        this.errors.phone_number = this.$t("waste_take_away_errors.phone");
      }
      const selected = Object.values(this.form.waste_type_quantity).some(
        (quantity) => quantity > 0,
      );
      if (!selected) {
        this.errors.waste_type_quantity = this.$t(
          "waste_take_away_errors.waste_type",
        );
      }
      if (this.form.notes !== "" && !this.regexString.test(this.form.notes)) {
        this.errors.notes = this.$t("waste_take_away_errors.notes");
      }

      if (this.form.phone_number) {
        this.form.phone_number = this.form.phone_number.replace(/[\s-]/g, "");
      }

      if (Object.keys(this.errors).length === 0) {
        this.$emit("continue", this.form);
      }
    },
    updateTypeValue(wasteType: string, value: number) {
      this.lastModifiedType = wasteType;
      this.form.waste_type_quantity[wasteType] = value;
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
});
