
import { PropType, defineComponent } from "vue";
import NewsGrid from "@/components/news/NewsGrid.vue";
import Paginator from "primevue/paginator";
import { Article } from "@/typings/Article";
import { Paginated } from "@/typings/Paginated";
import { NewsProvider } from "@/typings/News";

export default defineComponent({
  props: {
    selectedProviders: {
      type: [Array, null] as PropType<Array<NewsProvider> | null>,
    },
  },
  data: () => ({
    page: 1,
    news: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Article>,
    storageUrl: process.env.VUE_APP_STORAGE_URL,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    loadSubscribedNews(page = 1) {
      let params = {
        page: this.page,
        perPage: 13,
        filters: {
          provider_id: this.selectedProviders ?? undefined,
        },
        subscribed: true,
      };
      this.$http.get("api/v1/news", { params }).then((response) => {
        if (page !== 1) {
          this.news.data = this.news.data.concat(response.data.data);
          this.news.current_page = response.data.current_page;
          return;
        }
        this.news = response.data;
      });
    },
    updatePage(page: number) {
      if (this.page === page + 1) {
        return;
      }

      this.page = page + 1;
      this.loadSubscribedNews();
    },
    updateSavedValue(article: Article, value: boolean) {
      const found = this.news.data.find((a) => a.id === article.id);

      if (!found) {
        return;
      }

      found.saved = value;
    },
  },
  watch: {
    page() {
      this.$router.push({
        name: "News",
        query: { view: "for-you", tab: 1, page: this.page },
      });
    },
    selectedProviders() {
      this.loadSubscribedNews();
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page);
    }

    this.loadSubscribedNews();
  },
  components: { NewsGrid, Paginator },
});
