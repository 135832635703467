
import { defineComponent } from "vue";
import SignUpRegistration from "@/components/SignUpRegistration.vue";
import NavigationList from "@/components/NavigationList.vue";
import AvatarCustom from "@/components/AvatarCustom.vue";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    showNavigation() {
      return this.$store.getters["navigation/show"];
    },
    tellCityTooltip() {
      return (
        this.$t("tell_city_verification.alert_1") +
        " " +
        this.$t("tell_city_verification.alert_2") +
        " " +
        this.$t("tell_city_verification.alert_3") +
        " " +
        this.$t("tell_city_verification.email")
      );
    },
  },
  methods: {
    navigateToTellCity() {
      firebaseInstance?.analytics().logEvent("tell_city", {
        page: "tell_city",
        category: "tell_city_screen",
        category_desc: "Tell City zaslon odprt",
        sub_category: "click_tell_city",
        sub_category_desc: "Uporabnik je kliknil Tell City",
        ...this.$store.getters["user/analyticsInfo"],
      });
      this.$emit("close");
    },
    logout() {
      this.$store.dispatch("navigation/setShow", false);
      this.$store.dispatch("user/logout").then(() => {
        this.$router.replace({ name: "Login" });
      });
    },
  },
  components: { AvatarCustom, NavigationList, SignUpRegistration },
});
