import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/mobileTabActive.svg'
import _imports_1 from '@/assets/images/mobileTabInactive.svg'


const _hoisted_1 = { class: "top0 wp1200 normalHeight px-4" }
const _hoisted_2 = { class: "flex flex-column gap-3 sm:hidden mt-3" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1
}
const _hoisted_5 = {
  key: 0,
  src: _imports_0
}
const _hoisted_6 = {
  key: 1,
  src: _imports_1
}
const _hoisted_7 = { class: "tabs pt-0 hidden sm:flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SidebarUserWrapper = _resolveComponent("SidebarUserWrapper")!

  return (_openBlock(), _createBlock(_component_SidebarUserWrapper, { sidebarComponent: _ctx.sidebarComponent }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.wasteSubscriberCollection)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: _normalizeClass(["flex align-items-start gap-2 text-900 no-underline", { 'font-bold': _ctx.$route.name === 'PrivateWasteTimeline' }]),
                to: { name: 'PrivateWasteTimeline' }
              }, {
                default: _withCtx(() => [
                  (_ctx.$route.name === 'PrivateWasteTimeline')
                    ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                    : (_openBlock(), _createElementBlock("img", _hoisted_4)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("my_waste.private.timeline")), 1)
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (_ctx.wasteManagement)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                class: _normalizeClass(["flex align-items-start gap-2 text-900 no-underline", { 'font-bold': _ctx.$route.name === 'PrivateWasteTakeaway' }]),
                to: { name: 'PrivateWasteTakeaway' }
              }, {
                default: _withCtx(() => [
                  (_ctx.$route.name === 'PrivateWasteTakeaway')
                    ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                    : (_openBlock(), _createElementBlock("img", _hoisted_6)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("my_waste.private.takeaway")), 1)
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.wasteSubscriberCollection)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'PrivateWasteTimeline' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("my_waste.private.timeline")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.wasteManagement)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: { name: 'PrivateWasteTakeaway' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("my_waste.private.takeaway")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }, 8, ["sidebarComponent"]))
}