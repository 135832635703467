
import { defineComponent, markRaw } from "vue";
import SidebarUserWrapper from "@/components/SidebarUserWrapper.vue";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";
import RelatedAccountsOne from "@/components/user/relatedAccounts/RelatedAccount.vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { RelatedAccount } from "@/typings/RelatedAccount";
import { Paginated } from "@/typings/Paginated";

export default defineComponent({
  data: () => ({
    sidebarComponent: markRaw(SidebarUserMenu),
    relatedAccounts: {} as Paginated<RelatedAccount>,
    loaded: false,
    showDeleteAccountDialog: false,
    relatedAccountToDelete: null as RelatedAccount | null,
  }),
  mounted() {
    this.loadRelatedAccounts();
  },
  methods: {
    loadRelatedAccounts() {
      this.$http.get("api/v1/user-codes").then((response) => {
        this.relatedAccounts = response.data;
        this.loaded = true;
      });
    },
    updateRelatedAccount(relatedAccount: RelatedAccount, customName: string) {
      this.$http
        .put("api/v1/user-codes/" + relatedAccount.id, {
          custom_name: customName,
        })
        .then((response) => {
          relatedAccount.custom_name = response.data.custom_name;
        });
    },
    cancelDeletion() {
      this.showDeleteAccountDialog = false;
      this.relatedAccountToDelete = null;
    },
    showConfirmDeletionDialog(relatedAccount: RelatedAccount) {
      this.showDeleteAccountDialog = true;
      this.relatedAccountToDelete = relatedAccount;
    },
    deleteRelatedAccount(relatedAccountToDelete: RelatedAccount | null) {
      if (relatedAccountToDelete === null) {
        return;
      }
      this.$http
        .delete("api/v1/user-codes/" + relatedAccountToDelete.id)
        .then(() => {
          this.relatedAccounts.data = this.relatedAccounts.data.filter(
            (item) => item.id !== relatedAccountToDelete.id
          );
          this.relatedAccounts.total--;
          this.showDeleteAccountDialog = false;
          this.relatedAccountToDelete = null;
        });
    },
  },
  components: {
    RelatedAccountsOne,
    SidebarUserWrapper,
    Button,
    Dialog,
  },
});
