import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-951c4c88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex align-items-center justify-content-between mb-5" }
const _hoisted_2 = { class: "text-md font-semibold" }
const _hoisted_3 = { class: "flex flex-wrap align-items-end justify-content-between" }
const _hoisted_4 = {
  class: "w-full",
  style: {"min-width":"170px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    tabindex: "0",
    class: "flex flex-column ml-2 mt-3 sm:mt-0 sm:ml-0 justify-content-between bg-gray-100 p-3 border-round-2xl cursor-pointer",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selected', _ctx.item))),
    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('selected', _ctx.item)), ["enter"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("traffic_info.type." + _ctx.item.sub_category)), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "spaces", {}, undefined, true)
      ]),
      _createVNode(_component_Button, {
        class: "bg-primary border-none py-2 px-4 border-round-3xl text-lg flex-shrink-0 mt-2",
        onClick: _withModifiers(_ctx.openGoogleMaps, ["stop"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("parking.card.go")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ], 32))
}