import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/error.svg'


const _hoisted_1 = { class: "pt-3 pb-3 flex flex-column gap-3 sm:flex-row sm:justify-content-between sm:align-items-center sm:pb-5" }
const _hoisted_2 = { class: "text-2xl" }
const _hoisted_3 = {
  key: 0,
  class: "hidden sm:inline"
}
const _hoisted_4 = { class: "selectDate flex gap-2 w-full sm:w-fit" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "errorEmpty c-mt-3"
}
const _hoisted_7 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_ForYou = _resolveComponent("ForYou")!
  const _component_EventsList = _resolveComponent("EventsList")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t("events.title")) + " ", 1),
        (_ctx.selectedFilter)
          ? (_openBlock(), _createElementBlock("strong", _hoisted_3, "/ " + _toDisplayString(_ctx.selectedFilter.name), 1))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Dropdown, {
        tabindex: "1",
        modelValue: _ctx.selectedFilter,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFilter) = $event)),
        options: _ctx.filters.filter((f) => !f.divider),
        optionLabel: "name",
        class: "flex sm:hidden",
        onChange: _cache[1] || (_cache[1] = (e) => _ctx.$emit('selectFilter', e.value))
      }, null, 8, ["modelValue", "options"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Calendar, {
          class: "calendarInput w-full sm:w-10rem",
          modelValue: _ctx.from,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.from) = $event)),
          showIcon: true,
          placeholder: _ctx.$t(`events.from`),
          dateFormat: "dd.mm.yy",
          maxDate: _ctx.$moment(_ctx.until).toDate(),
          "manual-input": false
        }, null, 8, ["modelValue", "placeholder", "maxDate"]),
        _createVNode(_component_Calendar, {
          class: "calendarInput w-full sm:w-10rem",
          modelValue: _ctx.until,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.until) = $event)),
          showIcon: true,
          placeholder: _ctx.$t(`events.to`),
          dateFormat: "dd.mm.yy",
          minDate: _ctx.$moment(_ctx.from).toDate(),
          "manual-input": false
        }, null, 8, ["modelValue", "placeholder", "minDate"])
      ])
    ]),
    (_ctx.$route.query.view === 'for-you')
      ? (_openBlock(), _createBlock(_component_ForYou, {
          key: 0,
          from: _ctx.from,
          until: _ctx.until
        }, null, 8, ["from", "until"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.events.total === 0 && _ctx.events.current_page > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "error",
                  alt: _ctx.$t('accessibility.error')
                }, null, 8, _hoisted_7),
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t("global.no_data")), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_EventsList, {
            events: _ctx.events.data,
            grouped: "",
            onSave: _cache[4] || (_cache[4] = (event) => _ctx.updateSavedValue(event, true)),
            onUnsave: _cache[5] || (_cache[5] = (event) => _ctx.updateSavedValue(event, false))
          }, null, 8, ["events"]),
          (_ctx.events.total > 0)
            ? (_openBlock(), _createBlock(_component_Paginator, {
                key: 1,
                first: _ctx.events.from,
                "onUpdate:first": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.events.from) = $event)),
                rows: Number(_ctx.events.per_page),
                totalRecords: _ctx.events.total,
                class: "pt-8 pb-6",
                onPage: _cache[7] || (_cache[7] = (e) => _ctx.updatePage(e.page))
              }, null, 8, ["first", "rows", "totalRecords"]))
            : _createCommentVNode("", true)
        ]))
  ], 64))
}