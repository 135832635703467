
import { defineComponent } from "vue";
import IconTriangleSquareCircle from "~icons/tabler/triangle-square-circle";
import IconWalk from "~icons/tabler/walk";
import IconBike from "~icons/tabler/bike";
import IconCar from "~icons/tabler/car";
import IconLeaf from "~icons/tabler/leaf";
import IconMoodSmile from "~icons/tabler/mood-smile";

export default defineComponent({
  components: {
    IconTriangleSquareCircle,
    IconWalk,
    IconBike,
    IconCar,
    IconLeaf,
    IconMoodSmile,
  },

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: String,
      default: "all",
    },
  },

  data: () => ({
    modes: [
      { value: "all", icon: IconTriangleSquareCircle },
      { value: "walk", icon: IconWalk },
      { value: "bicycle", icon: IconBike },
      { value: "car", icon: IconCar },
      { value: "co2", icon: IconLeaf },
      { value: "kcal", icon: IconMoodSmile },
    ],
  }),

  computed: {
    selectedMode: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
