
import { defineComponent } from "vue";
import SidebarUserWrapper from "@/components/SidebarUserWrapper.vue";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";

export default defineComponent({
  data: () => ({
    sidebarComponent: SidebarUserMenu,
    isLoading: false,
    loyaltyPoints: 0,
    loyaltyTier: null as null | string,
    pointsTransferEnabled:
      process.env.VUE_APP_LOYALTY_TRANSFER_ENABLE === "true",
  }),
  mounted() {
    this.loadUserLoyaltyInfo();
  },
  methods: {
    loadUserLoyaltyInfo() {
      this.$http
        .get("api/v1/user/" + this.user.id + "/points")
        .then((response) => {
          this.loyaltyPoints = response.data.current;
          this.loyaltyTier = response.data.tier?.title;
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: {
    SidebarUserWrapper,
  },
});
