
import { PropType, defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import NewsCard from "@/components/news/NewsCard.vue";
import { Filter } from "@/typings/News";
import { Article } from "@/typings/Article";
import PlaceholderImage from "@/components/PlaceholderImage.vue";

export default defineComponent({
  props: {
    filters: {
      type: Object as PropType<Array<Filter>>,
      required: true,
    },
  },
  data: () => ({
    article: null as null | Article,
    latestNews: null as null | Array<Article>,
    showDialog: false,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    async loadArticle() {
      return this.$http
        .get("api/v1/news/" + this.$route.params.id)
        .then((response) => {
          this.article = response.data;
        });
    },
    loadLatestNews() {
      let params = {
        perPage: 5,
        filters: {
          category_id: this.article?.category_id ?? undefined,
        },
        orderBy: {
          created_at: "DESC",
        },
      };
      this.$http.get("api/v1/news", { params }).then((response) => {
        this.latestNews = response.data.data;

        if (!this.latestNews) {
          return;
        }

        this.latestNews = this.latestNews
          .filter((item: Article) => item.id !== this.article?.id)
          .slice(0, 3);
      });
    },
    goToNews(filter: Filter) {
      this.$router.push({
        name: "News",
        query: this.buildQuery(filter),
      });
    },
    buildQuery(filter: Filter) {
      const query: {
        [key: string]: string | number;
      } = {};

      if (typeof filter.id === "number") {
        query.category_id = filter.id;
      }

      if (typeof filter.id === "string") {
        query.view = filter.id;
      }

      return query;
    },
    saveArticle() {
      this.$http.post(`api/v1/news/${this.article?.id}/save`).then(() => {
        if (this.article === null) {
          return;
        }

        this.article.saved = true;
      });
    },
    removeSavedArticle() {
      this.$http.post(`api/v1/news/${this.article?.id}/unsave`).then(() => {
        if (this.article === null) {
          return;
        }

        this.showDialog = false;
        this.article.saved = false;
      });
    },
    subscribeToProvider(subscribed: boolean) {
      this.$http.post("/api/v1/news/provider-subscribers", {
        provider_id: this.article?.provider_id,
        subscribed,
      });
    },
    updateSavedValue(article: Article, value: boolean) {
      const found = this.latestNews?.find((a) => a.id === article.id);

      if (!found) {
        return;
      }

      found.saved = value;
    },
  },
  mounted() {
    this.$emit("selectFilter", null);

    this.loadArticle().then(() => {
      this.loadLatestNews();
    });
  },
  watch: {
    "$route.params.id": {
      handler() {
        if (this.$route.params.id) {
          this.loadArticle();
        }
      },
    },
  },
  components: {
    Dialog,
    Dropdown,
    NewsCard,
    PlaceholderImage,
  },
});
