
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    cardOpen: false,
  }),
  props: {
    name: { type: String, default: "" },
    title: { type: String, default: "" },
    sidebarLeft: { type: Boolean, default: false },
    sidebarComponent: { type: Object },
    mapComponent: { type: Object, default: null },
  },
  methods: {
    toggleCard() {
      this.cardOpen = !this.cardOpen;
    },
    toggleSidebar() {
      this.cardOpen = !this.cardOpen;
    },
  },
});
