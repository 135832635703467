
import { defineComponent } from "vue";
import Cookies from "@/components/Cookies.vue";

export default defineComponent({
  name: "EmptyLayout",
  data: () => ({
    storageUrl: process.env.VUE_APP_STORAGE_URL,
  }),
  components: {
    Cookies,
  },
});
