<template>
  <div class="wp1200 withPadding survey">
    <div class="mainTitle">
      <h2 class="surveyMainTitle">{{ $t("survey_list.title") }}</h2>
      <hr />
    </div>
    <div class="homeTabs">
      <div class="flex">
        <router-link to="/survey/list/active">{{
          $t("survey_list.active")
        }}</router-link>
        <router-link to="/survey/list/completed">{{
          $t("survey_list.finished")
        }}</router-link>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "SurveyList",
};
</script>

<style scoped></style>
