
import { defineComponent, markRaw } from "vue";
import MapCustom from "@/components/MapCustom.vue";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import SidebarLines from "@/components/publicTransport/SidebarLines.vue";
import { Trip } from "@/typings/Trip";
import { Marker } from "@/typings/Marker";

export default defineComponent({
  name: "Lines",
  components: { SidebarWrapper, MapCustom },
  data: () => ({
    sidebarComponent: markRaw(SidebarLines),
    trip: null as Trip | null,
    firstStop: null,
    middleStops: null,
    lastStop: null,
  }),
  mounted() {
    this.loadTrip();
  },
  methods: {
    loadTrip() {
      if ("id" in this.$route.params === false) {
        return;
      }
      this.$http
        .get("api/v1/public-transport/trips/" + this.$route.params.id)
        .then((response) => {
          this.trip = response.data;
          let stops = response.data.stops;
          if (stops.length > 0) {
            this.firstStop = stops[0];
            this.middleStops = stops.slice(1, -1);
            this.lastStop = stops.pop();
          }
        });
    },
  },
  computed: {
    markers(): Marker[] | null {
      if (this.trip === null) {
        return null;
      }
      return this.trip.stops.map((stop) => {
        return {
          id: stop.id,
          position: {
            lat: parseFloat(stop.stop.latitude),
            lng: parseFloat(stop.stop.longitude),
          },
          on_click_route_name: null,
          type: "station",
          title: stop.stop.name,
        } as Marker;
      });
    },
  },
  watch: {
    "$route.params.id"() {
      this.loadTrip();
    },
  },
});
