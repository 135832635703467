import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/error.svg'


const _hoisted_1 = { class: "pt-3 pb-3 flex flex-column gap-3 sm:flex-row sm:justify-content-between sm:align-items-center sm:pb-5" }
const _hoisted_2 = { class: "text-2xl" }
const _hoisted_3 = {
  key: 0,
  class: "hidden sm:inline"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "errorEmpty c-mt-3"
}
const _hoisted_6 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_ForYou = _resolveComponent("ForYou")!
  const _component_NewsGrid = _resolveComponent("NewsGrid")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t("news.title")) + " ", 1),
        (_ctx.selectedFilter)
          ? (_openBlock(), _createElementBlock("strong", _hoisted_3, "/ " + _toDisplayString(_ctx.selectedFilter.name), 1))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Dropdown, {
        tabindex: "1",
        modelValue: _ctx.selectedFilter,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFilter) = $event)),
        options: _ctx.filters.filter((f) => !f.divider),
        optionLabel: "name",
        class: "flex sm:hidden mb-2 sm:mb-0",
        onChange: _cache[1] || (_cache[1] = (e) => _ctx.$emit('selectFilter', e.value))
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_MultiSelect, {
        tabindex: "1",
        modelValue: _ctx.selectedProviders,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedProviders) = $event)),
        filter: "",
        display: "chip",
        options: _ctx.providers,
        optionLabel: "title",
        optionValue: "id",
        placeholder: _ctx.$t('news.all_providers'),
        class: "w-full sm:w-16rem",
        onChange: _cache[3] || (_cache[3] = () => _ctx.loadNews()),
        "empty-message": _ctx.$t('news.no_provider'),
        "empty-filter-message": _ctx.$t('global.no_hits'),
        "filter-placeholder": _ctx.$t('global.search')
      }, null, 8, ["modelValue", "options", "placeholder", "empty-message", "empty-filter-message", "filter-placeholder"])
    ]),
    (_ctx.$route.query.view === 'for-you')
      ? (_openBlock(), _createBlock(_component_ForYou, {
          key: 0,
          "selected-providers": _ctx.selectedProviders
        }, null, 8, ["selected-providers"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.news.total === 0 && _ctx.news.current_page > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "error",
                  alt: _ctx.$t('accessibility.error')
                }, null, 8, _hoisted_6),
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t("global.no_data")), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_NewsGrid, {
            news: _ctx.news.data,
            onSave: _cache[4] || (_cache[4] = (article) => _ctx.updateSavedValue(article, true)),
            onUnsave: _cache[5] || (_cache[5] = (article) => _ctx.updateSavedValue(article, false))
          }, null, 8, ["news"]),
          (_ctx.news.total > 0)
            ? (_openBlock(), _createBlock(_component_Paginator, {
                key: 1,
                first: _ctx.news.from,
                "onUpdate:first": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.news.from) = $event)),
                rows: _ctx.news.per_page,
                totalRecords: _ctx.news.total,
                class: "pt-8 pb-6",
                onPage: _cache[7] || (_cache[7] = (e) => _ctx.updatePage(e.page))
              }, null, 8, ["first", "rows", "totalRecords"]))
            : _createCommentVNode("", true)
        ]))
  ], 64))
}