
import { defineComponent } from "vue";
import PlaceholderImage from "../PlaceholderImage.vue";

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Pagination } from "swiper";

import "swiper/swiper-bundle.css";

export default defineComponent({
  data: () => ({
    modules: [Pagination],
    breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
      650: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
      1000: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
      1300: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      1600: {
        slidesPerView: 5,
        spaceBetween: 10,
      },
    },
    storageUrl: process.env.VUE_APP_STORAGE_URL,
  }),
  props: {
    news: { type: Array },
  },
  components: { Swiper, SwiperSlide, PlaceholderImage },
});
