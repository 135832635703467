import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0eb5266"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-column pt-3 gap-3 sm:flex-row sm:justify-content-between sm:align-items-center sm:pb-5 sm:hidden" }
const _hoisted_2 = { class: "text-2xl" }
const _hoisted_3 = { class: "pt-4 pb-3 sm:pb-5" }
const _hoisted_4 = { class: "text-2xl" }
const _hoisted_5 = { class: "w-full flex justify-content-center" }
const _hoisted_6 = { class: "box flex flex-column pb-4" }
const _hoisted_7 = { class: "flex justify-content-between w-full" }
const _hoisted_8 = ["onClick", "onKeyup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Categories = _resolveComponent("Categories")!
  const _component_Providers = _resolveComponent("Providers")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("news.title")), 1),
      _createVNode(_component_Dropdown, {
        tabindex: "1",
        options: _ctx.filters.filter((f) => !f.divider),
        optionLabel: "name",
        placeholder: _ctx.$t('news.choose_category'),
        class: "flex sm:hidden",
        onChange: _cache[0] || (_cache[0] = (e) => _ctx.goToNews(e.value))
      }, null, 8, ["options", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.$t("news.title")), 1),
        _createElementVNode("strong", null, " / " + _toDisplayString(_ctx.$t("news.my_sub")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createElementBlock("span", {
              key: tab.id,
              class: _normalizeClass(["inline-block w-full text-center py-2 px-3 cursor-pointer", {
            'text-primary font-bold border-bottom-2': tab.id === _ctx.selectedTab,
          }]),
              tabindex: "1",
              onClick: ($event: any) => (_ctx.selectedTab = tab.id),
              onKeyup: _withKeys(($event: any) => (_ctx.selectedTab = tab.id), ["enter"])
            }, _toDisplayString(tab.label), 43, _hoisted_8))
          }), 128))
        ]),
        (_ctx.selectedTab === 1)
          ? (_openBlock(), _createBlock(_component_Categories, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.selectedTab === 2)
          ? (_openBlock(), _createBlock(_component_Providers, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}