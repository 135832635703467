import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainImage = _resolveComponent("MainImage")!
  const _component_WidgetBar = _resolveComponent("WidgetBar")!
  const _component_GetApp = _resolveComponent("GetApp")!
  const _component_MyCity = _resolveComponent("MyCity")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainImage),
    _createVNode(_component_WidgetBar),
    _createVNode(_component_GetApp),
    _createVNode(_component_MyCity, { dashboard: _ctx.dashboard }, null, 8, ["dashboard"])
  ], 64))
}