
import { defineComponent } from "vue";
import Button from "primevue/button";
import { FavouriteLocation } from "@/typings/RoutePlanner";
import IconGps from "~icons/tabler/gps";
import { Paginated } from "@/typings/Paginated";

export default defineComponent({
  components: {
    Button,
    IconGps,
  },
  data: () => ({
    page: 1,
    recentLocations: null as null | Paginated<FavouriteLocation>,
  }),
  methods: {
    async getRecentLocations() {
      try {
        const response = await this.$maas.get(
          "api/v1/citizen-locations/recent",
          {
            params: {
              page: this.page,
              perPage: 3,
            },
          },
        );

        if (this.recentLocations && this.page !== 1) {
          this.recentLocations.data = this.recentLocations.data.concat(
            response.data.data,
          );

          this.recentLocations.last_page = response.data.last_page;

          return;
        }

        this.recentLocations = response.data;
      } catch (error) {
        //
      }
    },
    async loadMore() {
      this.page++;
      await this.getRecentLocations();
    },
  },
  computed: {
    showLoadMore() {
      return this.recentLocations && this.recentLocations.last_page > this.page;
    },
  },
  async mounted() {
    await this.getRecentLocations();
  },
});
