
import { defineComponent } from "vue";
import StepOne from "@/components/forgottenPassword/StepOne.vue";
import StepTwo from "@/components/forgottenPassword/StepTwo.vue";
import StepThree from "@/components/forgottenPassword/StepThree.vue";
import StepFour from "@/components/forgottenPassword/StepFour.vue";

export default defineComponent({
  components: { StepOne, StepTwo, StepThree, StepFour },

  data: () => ({
    step: 1,
    username: "",
    token: "",
  }),
  methods: {
    updateUsername(username: string) {
      this.username = username;
      this.step += 1;
    },
    updateToken(token: string) {
      this.token = token;
      this.step += 1;
    },
  },
});
