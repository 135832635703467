
import { PropType, defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import { Widget } from "@/typings/Widget";

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    currentWidgets: {
      type: Object as PropType<Array<Widget>>,
      required: true,
    },
  },
  data: () => ({
    step: 1,
    widgets: null as null | Array<Widget>,
    selectedWidget: null as null | Widget,
    selectedItem: null as null | string | number,
    options: [],
    latitude: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
    longitude: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
  }),
  methods: {
    getOptions() {
      if (this.selectedWidget === null) {
        return;
      }

      const configuration =
        this.configurations[this.selectedWidget.type].configuration;

      if (configuration === undefined) {
        return;
      }
      this.$http
        .get(configuration.url, { params: configuration.params })
        .then((response) => {
          this.options = response.data.data;

          if (!this.selectedWidget) {
            return;
          }

          const field =
            this.configurations[this.selectedWidget.type].configuration?.value;

          if (field === undefined) {
            return;
          }

          // Exclude existing widgets options
          this.options = this.options.filter((item) => {
            return this.existingWidgetsIds.indexOf(String(item[field])) === -1;
          });

          // Display only unique values based on field
          this.options = [
            ...new Map(this.options.map((obj) => [obj[field], obj])).values(),
          ];
        });
    },
    getWidgets() {
      const params = {
        perPage: 20,
      };
      this.$http
        .get("/api/v1/widgets", { params })
        .then((response) => (this.widgets = response.data.data));
    },
    addWidget() {
      this.$http
        .post("/api/v1/dashboard/widgets", {
          widget_id: this.selectedWidget?.id,
          external_id:
            this.selectedItem !== null ? String(this.selectedItem) : undefined,
        })
        .then(() => this.$emit("add"));
    },
    reset() {
      this.selectedWidget = null;
      this.selectedItem = null;
      this.options = [];
    },
    // eslint-disable-next-line
    getOptionLabel(option: Record<string, any>) {
      if (this.selectedWidget === null) {
        return;
      }

      if (this.selectedWidget.type === "waste-collection") {
        return option.address.street + " " + option.address.house_number + ", " + option.address.city;
      }

      return option.name;
    },
  },
  computed: {
    enabledWidgets() {
      return this.widgets
        ?.filter((widget) => {
          if (widget.enabled === true && this.$te(`widgets.${widget.type}`)) {
            return (
              widget.type === "traffic-info" ||
              (widget.type !== "traffic-info" && widget.type !== "traffic")
            );
          }
          return false;
        })
        .map((widget) => {
          return {
            ...widget,
            label: this.$t(`widgets.${widget.type}.title`),
          };
        });
    },
    configurations(): Record<
      string,
      {
        label: string;
        configuration?: {
          url: string;
          params: Record<
            string,
            string | number | Record<string, string | number>
          >;
          value: string;
        };
      }
    > {
      return {
        weather: {
          label: this.$t("widgets.weather.title"),
        },
        "air-quality": {
          label: this.$t("widgets.air-quality.title"),
          configuration: {
            url: "api/v2/air-quality",
            params: {
              perPage: 30,
            },
            value: "id",
          },
        },
        "public-transport": {
          label: this.$t("widgets.public-transport.title"),
          configuration: {
            url: `api/v1/public-transport/stops`,
            params: {
              perPage: 30,
              longitude: this.longitude,
              latitude: this.latitude,
              radius: 5000,
            },
            value: "id",
          },
        },
        events: {
          label: this.$t("widgets.events.title"),
        },
        "waste-collection": {
          label: this.$t("widgets.waste-collection.title"),
          configuration: {
            url: `api/v1/waste-management/collection-subscriber`,
            params: {
              filters: {
                citizen_id: this.user.id,
              },
            },
            value: "address_id",
          },
        },
        traffic: {
          label: this.$t("widgets.traffic-info.title"),
        },
        "traffic-info": {
          label: this.$t("widgets.traffic-info.title"),
        },
      };
    },
    selectedIsConfigurable() {
      if (this.selectedWidget === null) {
        return false;
      }

      return (
        this.configurations[this.selectedWidget.type].configuration !==
        undefined
      );
    },
    existingWidgetsIds() {
      return this.currentWidgets
        .filter(
          (item) =>
            item.external_id !== null &&
            item.type === this.selectedWidget?.type,
        )
        .map((item) => item.external_id);
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  watch: {
    step() {
      if (this.step === 1) {
        this.reset();
      }
      if (this.step === 2) {
        this.getOptions();
      }
    },
    show() {
      if (this.show === true) {
        this.getWidgets();
      }
    },
  },
  components: {
    Dialog,
    Dropdown,
  },
});
