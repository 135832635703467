
import { defineComponent, PropType } from "vue";
import { VueShowdown } from "vue-showdown";
import { loadStaticContent } from "@/static";

export default defineComponent({
  data: () => ({
    contents: null as Record<string, PropType<string | null>> | null,
  }),
  async mounted() {
    this.contents = await loadStaticContent("terms");
  },
  computed: {
    content() {
      return this.contents !== null ? this.contents[this.$i18n.locale] : null;
    },
  },
  components: { VueShowdown },
});
