
import { defineComponent } from "vue";
import { Route } from "@/typings/RoutePlanner";
import RouteDetailsHeader from "@/components/routePlanner/RouteDetailsHeader.vue";
import Map from "@/components/Map.vue";
import { Coordinates } from "@/typings/Marker";

export default defineComponent({
  components: {
    RouteDetailsHeader,
    Map,
  },

  data: () => ({
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    zoom: 15,
  }),

  computed: {
    route(): Route {
      return this.$store.getters["route-planner/route"];
    },
  },

  methods: {
    updateCenter(center: Coordinates) {
      this.center = center;
    },
    updateZoom(zoom: number) {
      this.zoom = zoom;
    },
  },

  mounted() {
    if (!this.route) {
      this.$router.push({ name: "RoutePlanner" });
    }
  },
});
