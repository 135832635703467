import axios from "axios";
import Qs from "qs";
import store from "./store";

const directus = axios.create({
  baseURL: `${process.env.VUE_APP_DIRECTUS_URL}/items/`,
  headers: {
    Accept: "application/json",
  },
});

directus.interceptors.request.use(
  (config) => {
    store.dispatch("loader/increaseActiveRequests");

    config.paramsSerializer = {
      serialize: (params) =>
        Qs.stringify(params, { arrayFormat: "brackets", encode: false }),
    };

    return config;
  },
  (error) => {
    store.dispatch("loader/decreaseActiveRequests");
    return Promise.reject(error);
  },
);

directus.interceptors.response.use(
  (response) => {
    store.dispatch("loader/decreaseActiveRequests");
    return response;
  },
  (error) => {
    store.dispatch("loader/decreaseActiveRequests");
    return Promise.reject(error);
  },
);

export { directus };
