import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-between mt-2 gap-4 align-items-center p-2 px-3 border-round-md" }
const _hoisted_2 = { class: "incrementor flex gap-3 align-items-center text-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`${_ctx.description}`)), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("i", {
        class: _normalizeClass(["icon-warpit_icon_MINUS text-base font-bold cursor-pointer", { 'text-primary': _ctx.count > 0 }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decrement && _ctx.decrement(...args)))
      }, null, 2),
      _createVNode(_component_InputNumber, {
        modelValue: _ctx.count,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.count) = $event)),
        "input-class": _ctx.count > 0 ? 'font-bold' : '',
        useGrouping: false,
        max: 100,
        onInput: _cache[2] || (_cache[2] = (event) => _ctx.$emit('update', event.value))
      }, null, 8, ["modelValue", "input-class"]),
      _createElementVNode("i", {
        class: "icon-warpit_icon_plus text-base font-bold cursor-pointer text-primary",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.increment && _ctx.increment(...args)))
      })
    ])
  ]))
}