import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contentLogin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      class: "submitButton defaultButton",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.rekonoLogin())),
      label: _ctx.$t('login.rekono_entry')
    }, null, 8, ["label"])
  ]))
}