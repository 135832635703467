
import { PropType, defineComponent } from "vue";
import Divider from "primevue/divider";
import { WasteTakeAwayForm } from "@/typings/WasteManagement";
export default defineComponent({
  components: { Divider },
  props: {
    order: {
      type: Object as PropType<WasteTakeAwayForm>,
      required: true,
    },
    successful: Boolean,
  },
  computed: {
    filteredWasteTypeQuantity() {
      return Object.fromEntries(
        Object.entries(this.order.waste_type_quantity).filter(
          ([type, count]) => count > 0
        )
      );
    },
  },
});
