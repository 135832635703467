
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    application: {
      type: Object,
    },
  },
  methods: {
    getStatusClass(type: string) {
      if (type === "Submitted") {
        return "icon-warpit_icon_export";
      } else if (type === "In Processing") {
        return "icon-warpit_icon_wait";
      } else if (type === "Approved") {
        return "icon-warpit_icon_check-round";
      } else {
        return "icon-warpit_icon_no-round";
      }
    },
    getStatusText(type: string) {
      if (type === "Submitted") {
        return this.$t("forms.my_form.status.submitted");
      } else if (type === "In Processing") {
        return this.$t("forms.my_form.status.in_progress");
      } else if (type === "Approved") {
        return this.$t("forms.my_form.status.approved");
      } else {
        return this.$t("forms.my_form.status.rejected");
      }
    },
  },
  computed: {
    formJson() {
      return JSON.parse(this.application?.formDataJson);
    },
  },
});
