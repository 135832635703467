<template>
  <div class="relatedAccountsForm">
    <div class="stepHeader">
      <span>{{ $t("vehicles.new_vehicles") }}</span>
    </div>
    <hr class="primaryShort" />
    <form class="form">
      <ul>
        <li>
          <span class="p-float-label">
            <InputText
              id="regNumber"
              type="text"
              v-model="licencePlate"
              name="title"
            />
            <label for="regNumber">{{ $t("vehicles.number_plate") }}</label>
            <p style="color: red; font-size: 12px; position: absolute">
              {{ error }}
            </p>
          </span>
        </li>
      </ul>
    </form>
    <hr class="grayWide margin-30" />
    <button class="defaultButton" @click="createVehicles()">
      {{ $t("vehicles.add_vehicle") }}
    </button>
    <router-link :to="{ name: 'MyVehicles' }" class="cancel"
      >{{ $t("global.cancel") }}
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    licencePlate: "",
    error: "",
  }),

  methods: {
    createVehicles() {
      firebaseInstance?.analytics().logEvent("my_vehicles", {
        page: "my_vehicles",
        category: "my_vehicles_screen",
        category_desc: "Zaslon mojih vozil odprt",
        sub_category: "my_vehicles_add_new",
        sub_category_desc: "Uporabnik je dodal novo vozilo",
        ...this.$store.getters["user/analyticsInfo"],
      });
      if (this.licencePlate.trim() === "") {
        this.error = this.$t("error.enter_license_plate");
        return;
      }
      if (this.licencePlate.length >= 30) {
        this.error = this.$t("error.shorten_license_plate");
        return;
      }
      this.$http
        .post("api/v1/license-plates", {
          country: "SL",
          plate: this.licencePlate,
        })
        .then(() => {
          this.$router.push({ name: "MyVehicles" });
        })
        .catch((error) => {
          if ("plate" in error.response.data.errors) {
            this.error = this.$t("error.licence_plate");
          }
        });
    },
  },
  components: { InputText },
});
</script>

<style scoped></style>
