
import { defineComponent } from "vue";
import { PublicSurvey } from "@/typings/PublicSurvey";

export default defineComponent({
  data: () => ({
    surveys: [] as PublicSurvey[],
  }),
  mounted() {
    this.loadSurveys();
  },
  methods: {
    loadSurveys() {
      this.$http.get("api/v1/surveys/public-surveys").then((result) => {
        this.surveys = result.data;
      });
    },
    startSurvey(id: number) {
      this.$http.get("api/v1/surveys/public-surveys/" + id).then((result) => {
        let link = result.data;
        if (link.linkUrl === null) {
          return;
        }
        window.open(link.linkUrl, "_blank");
      });
    },
  },
});
