
import { defineComponent } from "vue";
import Button from "primevue/button";

export default defineComponent({
  data: () => ({
    selectedItem: null as null | string,
    isValid: false,
    cardPaymentEnabled: false,
  }),
  methods: {
    selectItem(selectedItem: string) {
      this.selectedItem = selectedItem;
      this.isValid = true;
    },
  },
  components: { Button },
});
