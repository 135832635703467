
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import Cookies from "@/components/Cookies.vue";

export default defineComponent({
  components: {
    Header,
    Cookies,
  },
});
