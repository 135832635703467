
import { defineComponent } from "vue";
import CardInput from "@/components/user/myPayments/CardInput.vue";
import Button from "primevue/button";
import AdministrativeFee from "./AdministrativeFee.vue";
import UPN from "./UPN.vue";

export default defineComponent({
  props: {
    paymentMethod: { type: String, required: true },
  },
  data: () => ({
    isValid: false,
    attachment: null as File | null,
  }),
  created() {
    if (this.paymentMethod === "upn") this.isValid = true;
  },
  computed: {
    getTitle() {
      if (this.paymentMethod === "tax-confirmation")
        return "forms.administrative_fee.tax_confirmation_sub_title";
      if (this.paymentMethod === "upn") return "forms" + ".upn.sub_title";
      return "forms.administrative_fee.payment_sub_title";
    },
  },
  methods: {
    addAttachment(file: File) {
      this.attachment = file;
      this.isValid = true;
    },
  },
  components: { AdministrativeFee, CardInput, Button, UPN },
});
