
import { defineComponent } from "vue";

export default defineComponent({
  props: ["modelValue", "placeholder"],
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(localValue: string) {
        this.$emit("update:modelValue", localValue);
      },
    },
  },
});
