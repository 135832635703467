
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    eApplicationsEnabled: process.env.VUE_APP_EAPPLICATIONS_ENABLE === "true",
    wasteSmsNotification: process.env.VUE_APP_WASTE_SMS_ENABLE === "true",
    wasteSubscriberCollection:
      process.env.VUE_APP_WASTE_SUBSCRIBER_COLLECTION_ENABLED === "true",
    paymentEnabled: process.env.VUE_APP_PAYMENT_ENABLE === "true",
    wasteManagement: process.env.VUE_APP_WASTE_MANAGEMENT_ENABLE === "true",
    loyaltyEnabled: process.env.VUE_APP_LOYALTY_ENABLE === "true",
  }),
});
