
import { defineComponent, markRaw } from "vue";
import SidebarUserWrapper from "@/components/SidebarUserWrapper.vue";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";
import ProgressBar from "primevue/progressbar";
import Button from "primevue/button";
import { User } from "@/typings/User";
import AvatarCustom from "@/components/AvatarCustom.vue";
import Dialog from "primevue/dialog";
import ErrorDialog from "@/components/user/Dialogs/ErrorDialog.vue";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    sidebarComponent: markRaw(SidebarUserMenu),
    showSuccessExportDialog: false,
    showDeleteAccountDialog: false,
    showErrorDialog: false,
  }),
  mounted() {
    firebaseInstance?.analytics().logEvent("user_profile", {
      page: "user_profile",
      category: "user_profile_screen",
      category_desc: "Zaslon uporabniškega profila odprt",
      sub_category: "open_my_profile",
      sub_category_desc: "Uporabnik je odprl profil",
      ...this.$store.getters["user/analyticsInfo"],
    });
  },

  methods: {
    editProfileButtonClicked() {
      firebaseInstance?.analytics().logEvent("user_profile", {
        page: "user_profile",
        category: "user_profile_screen",
        category_desc: "Zaslon uporabniškega profila odprt",
        sub_category: "my_profile_edit",
        sub_category_desc: "Zaslon za urejanje uporabniškega profila odprt",
        ...this.$store.getters["user/analyticsInfo"],
      });
      this.$router.push({ name: "EditProfile" });
    },
    verifyProfile() {
      if (this.user.phone_number !== null && this.user.email === null) {
        this.sendSms();
        return;
      }
      this.sendEmail();
    },
    sendEmail() {
      this.$http
        .post("api/v1/verification-request", { type: "email" })
        .then(() => {
          this.$router.push({ name: "EmailVerification" });
        });
    },
    sendSms() {
      this.$http
        .post("api/v1/verification-request", { type: "phone" })
        .then(() => {
          this.$router.push({ name: "PhoneVerification" });
        })
        .catch(() => {
          this.showErrorDialog = true;
        });
    },
    exportProfileData() {
      this.$http.post("api/v1/user/takeout").then(() => {
        this.showSuccessExportDialog = true;
      });
    },
    calculateAccountCompletion() {
      let value = 0;
      let userParameters = [
        "first_name",
        "last_name",
        "email",
        "phone_number",
        "birth_date",
      ];
      for (let i = 0; i < userParameters.length; i++) {
        if (this.user[userParameters[i]]) {
          value += 10;
        }
      }

      if (this.user.contact_info === null) {
        return value;
      }

      let userContactInfoParameters = [
        "address",
        "house_number",
        "code",
        "city",
        "country",
      ];

      for (let i = 0; i < userContactInfoParameters.length; i++) {
        if (this.user.contact_info[userContactInfoParameters[i]]) {
          value += 10;
        }
      }
      return value;
    },
    calculateAge(user: User) {
      if (user.birth_date === null) {
        return null;
      }
      let birthdate = this.$moment(user.birth_date);
      return this.$moment().diff(birthdate, "years");
    },
    logout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.replace({ name: "Login" });
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },

  components: {
    AvatarCustom,
    SidebarUserWrapper,
    ProgressBar,
    Button,
    Dialog,
    ErrorDialog,
  },
});
