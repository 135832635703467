
import { defineComponent } from "vue";

import Dialog from "primevue/dialog";
import MapCustom from "@/components/MapCustom.vue";
import { Coordinates, Marker } from "@/typings/Marker";
import { WasteStations } from "@/typings/WasteManagement";

export default defineComponent({
  data: () => ({
    showMap: false,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    zoom: 15,
    markers: [] as Marker[],
    wasteStations: [] as WasteStations[],
  }),
  methods: {
    openMap() {
      this.loadWasteStations();
      this.showMap = true;
    },
    closeMap() {
      this.showMap = false;
    },
    updateCenter(value: Coordinates) {
      this.center = value;
      this.loadWasteStations();
    },
    updateZoom(value: number) {
      this.zoom = value;
      this.loadWasteStations();
    },
    loadWasteStations() {
      let params = {
        latitude: 0,
        longitude: 0,
        radius: 0,
        perPage: 100,
      };
      if (this.center !== null && this.center !== undefined) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;
        params.radius = Math.pow(2, 27 - this.zoom);
      }
      this.$http
        .get("api/v1/waste-management/stations", { params })
        .then((response) => {
          this.wasteStations = response.data.data;
          this.markers = this.wasteStations.map(
            (wasteStation: WasteStations) => ({
              id: wasteStation.id,
              position: {
                lat: parseFloat(wasteStation.latitude),
                lng: parseFloat(wasteStation.longitude),
              },
              on_click_route_name: "EcoIslandById",
              type: "waste_station",
              title: wasteStation.title,
            })
          );
        });
    },
    getFilter() {
      let cityId = process.env.VUE_APP_CITY_ID;
      let filter = {
        city_id: cityId,
      };
      return filter;
    },
  },
  components: { MapCustom, Dialog },
});
