
import NewsSlider from "@/components/home/NewsSlider.vue";
import Map from "@/components/home/Map.vue";
import EventSlider from "@/components/home/EventSlider.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    NewsSlider,
    Map,
    EventSlider,
  },
  props: {
    dashboard: {
      type: Object,
      default() {
        return {
          events: null,
          news: null,
        };
      },
    },
  },
});
