
import { defineComponent } from "vue";
import Button from "primevue/button";

export default defineComponent({
  components: { Button },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
});
