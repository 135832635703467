
import { FeedElement } from "@/typings/FeedElement";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Survey",
  props: {
    item: {
      type: Object as () => FeedElement,
    },
  },
  methods: {
    startSurvey(url: string) {
      window.open(url);
    },
  },
});
