import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "fillDialogTitle" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-column gap-2"
}
const _hoisted_3 = ["onClick", "onKeyup"]
const _hoisted_4 = {
  key: 0,
  class: "pi pi-check-circle text-white text-xl"
}
const _hoisted_5 = {
  key: 1,
  class: "flex flex-column"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "fillDialogButtons" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.show,
    class: "successDialog",
    tabindex: "1",
    closable: false,
    draggable: false,
    modal: "",
    onHide: _cache[8] || (_cache[8] = 
      () => {
        _ctx.reset();
        _ctx.step = 1;
        _ctx.$emit('hide');
      }
    )
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.step === 1 ? _ctx.$t("widgets.select") : _ctx.$t("widgets.settings")), 1),
      (_ctx.step === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enabledWidgets, (widget) => {
              return (_openBlock(), _createElementBlock("div", {
                key: widget.id,
                tabindex: "1",
                class: _normalizeClass(["flex justify-content-between align-items-center px-4 py-5 surface-100 border-round-xl cursor-pointer", {
          'bg-primary': _ctx.selectedWidget?.type === widget.type,
        }]),
                onClick: ($event: any) => (_ctx.selectedWidget = widget),
                onKeyup: _withKeys(($event: any) => (_ctx.selectedWidget = widget), ["enter"])
              }, [
                _createTextVNode(_toDisplayString(widget.label) + " ", 1),
                (_ctx.selectedWidget?.type === widget.type)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                  : _createCommentVNode("", true)
              ], 42, _hoisted_3))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.step === 2 && _ctx.selectedWidget)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("widgets.choose")), 1),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedItem,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event)),
              options: _ctx.options,
              "option-value": _ctx.configurations[_ctx.selectedWidget.type].configuration?.value,
              "option-label": _ctx.getOptionLabel,
              tabindex: "1"
            }, {
              empty: _withCtx(() => [
                (
              _ctx.selectedWidget.type === 'waste-collection' &&
              _ctx.existingWidgetsIds.length === 0
            )
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: { name: 'PrivateWasteTimeline' }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("widgets.waste-collection.empty_options")) + " " + _toDisplayString(_ctx.existingWidgetsIds.length), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("widgets.empty_options")), 1))
              ]),
              _: 1
            }, 8, ["modelValue", "options", "option-value", "option-label"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.step === 1 && !_ctx.selectedIsConfigurable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addWidget && _ctx.addWidget(...args))),
              tabindex: "1",
              class: "defaultButton",
              disabled: _ctx.selectedWidget === null
            }, _toDisplayString(_ctx.$t("widgets.add")), 9, _hoisted_8))
          : _createCommentVNode("", true),
        (_ctx.step === 1 && _ctx.selectedIsConfigurable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.step = 2)),
              tabindex: "1",
              class: "defaultButton",
              disabled: _ctx.selectedWidget === null
            }, _toDisplayString(_ctx.$t("global.continue")), 9, _hoisted_9))
          : _createCommentVNode("", true),
        (_ctx.step === 1)
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('hide'))),
              tabindex: "1",
              onKeypress: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.$emit('hide')), ["enter"]))
            }, _toDisplayString(_ctx.$t("global.cancel")), 33))
          : _createCommentVNode("", true),
        (_ctx.step === 2)
          ? (_openBlock(), _createElementBlock("button", {
              key: 3,
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.addWidget && _ctx.addWidget(...args))),
              tabindex: "1",
              class: "defaultButton",
              disabled: _ctx.selectedIsConfigurable && _ctx.selectedItem === null
            }, _toDisplayString(_ctx.$t("widgets.add")), 9, _hoisted_10))
          : _createCommentVNode("", true),
        (_ctx.step === 2)
          ? (_openBlock(), _createElementBlock("span", {
              key: 4,
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.step = 1)),
              tabindex: "1",
              onKeypress: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.step = 1), ["enter"]))
            }, _toDisplayString(_ctx.$t("global.back")), 33))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}