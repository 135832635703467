import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex sm:align-items-center flex-column sm:flex-row gap-1"
}
const _hoisted_2 = { class: "flex justify-content-end gap-1" }
const _hoisted_3 = {
  key: 0,
  class: "icon-warpit_icon_car text-2xl text-600"
}
const _hoisted_4 = {
  key: 1,
  class: "icon-warpit_icon_el-polnilnica text-2xl text-600"
}
const _hoisted_5 = {
  key: 2,
  class: "icon-warpit_icon_invalid text-2xl text-600"
}
const _hoisted_6 = {
  key: 1,
  class: "flex sm:align-items-center flex-column sm:flex-row gap-1"
}
const _hoisted_7 = { class: "flex justify-content-end gap-1" }
const _hoisted_8 = {
  key: 0,
  class: "icon-warpit_icon_car text-2xl text-600 pl-1"
}
const _hoisted_9 = {
  key: 1,
  class: "icon-warpit_icon_el-polnilnica text-2xl text-600 pl-1"
}
const _hoisted_10 = {
  key: 2,
  class: "icon-warpit_icon_invalid text-2xl text-600 pl-1"
}
const _hoisted_11 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (
      _ctx.parking.smart === 1 &&
      _ctx.parking.spaces_total_available !== null &&
      _ctx.parking.spaces_total_available >= 0 &&
      _ctx.checkAvailability(_ctx.parking) === false
    )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("strong", null, _toDisplayString(_ctx.description) + ": " + _toDisplayString(_ctx.parking.spaces_total_available), 1),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.parking.spaces_regular !== null && _ctx.parking.spaces_regular > 0)
            ? (_openBlock(), _createElementBlock("i", _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.parking.spaces_ev !== null && _ctx.parking.spaces_ev > 0)
            ? (_openBlock(), _createElementBlock("i", _hoisted_4))
            : _createCommentVNode("", true),
          (
          _ctx.parking.spaces_accessible !== null && _ctx.parking.spaces_accessible > 0
        )
            ? (_openBlock(), _createElementBlock("i", _hoisted_5))
            : _createCommentVNode("", true)
        ])
      ]))
    : (_ctx.checkAvailability(_ctx.parking))
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.description) + ": " + _toDisplayString(_ctx.availableParkingsOfType(_ctx.parking)), 1),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.selectedType === 'regular')
              ? (_openBlock(), _createElementBlock("i", _hoisted_8))
              : _createCommentVNode("", true),
            (_ctx.selectedType === 'ev')
              ? (_openBlock(), _createElementBlock("i", _hoisted_9))
              : _createCommentVNode("", true),
            (_ctx.selectedType === 'accessible')
              ? (_openBlock(), _createElementBlock("i", _hoisted_10))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("parking.missing_info")), 1))
}