import {
  LocaleMessageDictionary,
  LocaleMessages,
  VueMessageType,
} from "vue-i18n";

export default function loadDefaultMessages(): Record<
  string,
  LocaleMessages<VueMessageType>
> {
  const requireModule = require.context("./default", true, /\.json$/);
  const messages: Record<string, LocaleMessages<VueMessageType>> = {};

  requireModule.keys().forEach((filename) => {
    const languageName = filename.replace(/(\.\/|\.json)/g, "");
    messages[languageName] = requireModule(filename);
  });

  return messages;
}

export async function loadMessagesAsync(
  flavour = "default"
): Promise<Record<string, LocaleMessageDictionary<VueMessageType>>> {
  const requireModule = require.context("./", true, /\.json$/, "lazy");
  const messages: Record<string, LocaleMessageDictionary<VueMessageType>> = {};

  const keys = requireModule.keys();
  for (const key in keys) {
    const filename = keys[key];
    if (filename.includes(flavour) === false) {
      continue;
    }
    const languageName = filename.replace(/(\.\/(.*)\/|\.json)/g, "");
    messages[languageName] = await requireModule(filename);
  }

  return messages;
}
