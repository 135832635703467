
import { defineComponent } from "vue";
import StepOne from "@/components/register/StepOne.vue";
import { RegistrationForm } from "@/typings/RegistrationForm";
import StepTwo from "@/components/register/StepTwo.vue";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    step: 1,
    errors: [] as string[],
    email: null as null | string,
  }),

  mounted() {
    if (window.history.state.phoneEmail) {
      this.toStepTwo();
    }
  },
  methods: {
    toStepTwo() {
      let email = window.history.state.phoneEmail;
      if (email === null) {
        return;
      }
      this.email = email.toString();
      this.step = 2;
    },
    register(form: RegistrationForm) {
      this.errors = [];
      this.$http
        .post("/api/v2/auth/register", {
          ...form,
        })
        .then(() => {
          this.step = 2;
          this.email = form.email;
        })
        .catch((error) => {
          this.errors = [];

          if (error.response.status === 400) {
            this.errors.push(this.$t("error.mistake"));
          }
          const errors = error.response.data.errors;
          if (errors.email) {
            if (errors.email.includes("validation.unique")) {
              this.errors.push(this.$t("register_error.used_email"));
            } else {
              this.errors.push(this.$t("register_error.enter_valid_email"));
            }
          }
          if (errors.phone_number) {
            if (errors.phone_number.includes("validation.unique")) {
              this.errors.push(this.$t("register_error.used_phone"));
            } else {
              this.errors.push(this.$t("register_error.enter_valid_phone"));
            }
          }
          if (errors.password) {
            this.errors.push(this.$t("error.unvalid_password"));
          }
          this.step = 1;
        })
        .then(() => {
          firebaseInstance?.analytics().logEvent("register_screen", {
            page: "register_screen",
            category: "register_screen_step_1",
            category_desc: "Prvi korak registracijskega zaslona odprt",
            sub_category: "registration_completed",
            sub_category_desc: "Uporabnik je zaključil registracijo",
            ...this.$store.getters["user/analyticsInfo"],
          });
        });
    },
  },

  components: {
    StepTwo,
    StepOne,
  },
});
