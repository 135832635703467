
import { defineComponent } from "vue";
import InputNumber from "primevue/inputnumber";

export default defineComponent({
  components: { InputNumber },
  props: {
    description: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    count: 0,
  }),
  methods: {
    increment() {
      this.count++;
      this.$emit("update", this.count);
    },
    decrement() {
      if (this.count === 0) {
        return;
      }

      this.count--;

      this.$emit("update", this.count);
    },
  },
  mounted() {
    this.count = this.value;
  },
});
