<template>
  <div class="form-checkbox">
    <input
      type="checkbox"
      :id="id"
      :checked="checked"
      @input="(event) => $emit('update:checked', event.target.checked)"
    />
    <label :for="id">
      {{ $t(label) }}
    </label>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    id: { type: String, default: "" },
    checked: { type: Boolean, default: false },
    label: { type: String, default: "" },
  },
});
</script>

<style lang="scss">
.form-checkbox {
  display: flex;
  justify-items: flex-start;
  margin-bottom: 14px;
  input {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
    cursor: pointer;
    &:checked {
      accent-color: var(--primary-color);
    }
    &:hover {
      accent-color: var(--primary-color);
    }
  }
  label {
    margin-left: 10px;
    font-size: 19px;
    display: inline-block;
    color: var(--gray-600);
    cursor: pointer;
  }
}
</style>
