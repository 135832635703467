<template>
  <header class="hidden sm:block">
    <div class="flex justify-content-between mx-auto wp1200 py-4">
      <span class="text-2xl font-bold">
        {{ $t("traffic_info.title") }}
      </span>
      <button
        v-if="$route.name === 'TrafficInfoMap'"
        @click="$router.push({ name: 'TrafficInfoList' })"
        class="flex align-items-center gap-2 border-none bg-white text-primary font-bold text-lg cursor-pointer"
      >
        <span>{{ $t("traffic_info.show_list") }}</span>
        <i class="icon-warpit_icon_TE_bullet_list font-bold"></i>
      </button>
      <button
        v-if="$route.name === 'TrafficInfoList'"
        @click="$router.push({ name: 'TrafficInfoMap' })"
        class="flex align-items-center gap-2 border-none bg-white text-primary font-bold text-lg cursor-pointer"
      >
        <span>{{ $t("traffic_info.show_map") }}</span>
        <i class="icon-warpit_icon_map-import font-bold"></i>
      </button>
    </div>
  </header>

  <main>
    <router-view />
  </main>
</template>
