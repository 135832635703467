
import { defineComponent, markRaw } from "vue";
import SidebarUserWrapper from "@/components/SidebarUserWrapper.vue";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import Dialog from "primevue/dialog";
import { Consent } from "@/typings/Consent";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    sidebarComponent: markRaw(SidebarUserMenu),
    consents: [] as Consent[],
    showUpdateSuccessfulDialog: false,
  }),
  mounted() {
    firebaseInstance?.analytics().logEvent("consent_screen", {
      page: "consent_screen",
      category: "consent_screen",
      category_desc: "Uporabnik odprl zaslon soglasji",
      ...this.$store.getters["user/analyticsInfo"],
    });
    this.loadConsents();
  },
  methods: {
    loadConsents() {
      this.$http.get("api/v1/consent").then((response) => {
        this.consents = response.data;
      });
    },
    updateConsents() {
      let consent = this.consents.map((consent) => consent.value).join(",");
      this.$http.put("api/v1/consent", { consent }).then(() => {
        this.showUpdateSuccessfulDialog = true;
      });
    },
  },
  components: {
    SidebarUserWrapper,
    Button,
    InputSwitch,
    Dialog,
  },
});
