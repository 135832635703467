
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { RelatedAccount } from "@/typings/RelatedAccount";

export default defineComponent({
  data: () => ({
    edit: false,
    name: "",
  }),
  props: {
    relatedAccount: {
      type: Object as () => RelatedAccount,
      required: true,
    },
  },
  methods: {
    editRelatedAccount() {
      this.name =
        this.relatedAccount.custom_name || this.relatedAccount.provider.name;
      this.edit = true;
    },
    editAccount() {
      this.$emit("update", this.name);
      this.edit = false;
    },
  },

  components: {
    InputText,
    Button,
  },
});
