<template>
  <div>
    <h2>{{ $t("payments.new_card") }}</h2>
    <hr class="primaryShort" />
    <p>{{ $t("payments.enter_data_of_card") }}</p>
    <CardInput :showSaveSwitch="false" @continue="createCard"></CardInput>
    <router-link :to="{ name: 'PaymentCards' }" class="cancel">
      {{ $t("global.cancel") }}
    </router-link>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import CardInput from "@/components/user/myPayments/CardInput";

export default defineComponent({
  methods: {
    createCard({ cardData, token, customName }) {
      this.$http
        .post("/api/v1/credit-cards", {
          ...cardData,
          token,
          custom_name: customName,
        })
        .then((response) => {
          if (!response.data.redirect_url) {
            this.$router.push({ name: "PaymentCards" });
          }
          window.location.href = response.data.redirect_url;
        });
    },
  },
  components: {
    CardInput,
  },
});
</script>

<style scoped></style>
