
import { defineComponent } from "vue";
import { UserTransation, TransactionData } from "@/typings/Loyalty";
import InfinityScroll from "@/components/InfinityScroll.vue";

export default defineComponent({
  data: () => ({
    transations: {} as UserTransation,
    isLoading: false,
  }),
  mounted() {
    this.loadTransations();
  },
  methods: {
    loadTransations(page = 1) {
      let params = {
        page,
        perPage: 10,
      };
      this.isLoading = true;
      this.$http
        .get("api/v1/user/" + this.user.id + "/transactions", { params })
        .then((response) => {
          if (!this.isLoading) {
            return;
          }
          if (page !== 1) {
            this.transations.data = this.transations.data.concat(
              response.data.data,
            );
            this.transations.meta.current_page =
              response.data.meta.current_page;
            return;
          }
          this.transations = response.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    translateName(transaction: TransactionData) {
      if (transaction.reason === "Bulk Update") {
        if (transaction.change > 0) {
          return this.$t("my_points.received_points");
        }

        return this.$t("my_points.taken_points");
      }

      if (
        transaction.source &&
        transaction.sourceType === "action" &&
        transaction.source.name
      ) {
        return transaction.source.name;
      }

      if (transaction.sourceType === "transferedpoints") {
        if (transaction.change > 0) {
          return this.$t("my_points.received_points");
        }

        return this.$t("my_points.gifted_points");
      }

      if (transaction.sourceType === "reward") {
        return this.$t("my_points.spent_points");
      }
    },
    translateReason(transaction: TransactionData) {
      if (transaction.reason === "Bulk Update") {
        if (transaction.change > 0) {
          return this.$t("my_points.admin_recived_points_description");
        }

        return this.$t("my_points.admin_taken_points_description");
      }
    },
    translateDescription(transaction: TransactionData) {
      if (
        transaction.source &&
        transaction.sourceType === "action" &&
        transaction.source.description
      ) {
        return transaction.source.description;
      }

      if (transaction.sourceType === "transferedpoints") {
        if (transaction.change > 0) {
          return this.$t("my_points.received_points_description");
        }

        return this.$t("my_points.gifted_points_description");
      }

      if (transaction.sourceType === "reward") {
        return (
          this.$t("my_points.spent_points_description") +
          transaction.source.name
        );
      }
    },
    getValuePrefix(value: number) {
      return value > 0 ? "+" + value : value;
    },
    getValueColors(value: number): string {
      return value > 0 ? "#0EA44D" : "#f05323";
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: {
    InfinityScroll,
  },
});
