
import MapCustom from "@/components/MapCustom.vue";
import { defineComponent, markRaw } from "vue";
import SidebarWasteWrapper from "@/components/SidebarWasteWrapper.vue";
import ProgressBar from "primevue/progressbar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { Marker } from "@/typings/Marker";
import {
  WasteStations,
  TrashCanStatistics,
  TrashCans,
  WasteChart,
} from "@/typings/WasteManagement";
import MapWasteStations from "@/components/wasteManagement/MapWasteStations.vue";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import moment from "moment";
import { firebaseInstance } from "@/plugins/firebase";

export default defineComponent({
  name: "EcoIsland",
  components: {
    SidebarWasteWrapper,
    MapCustom,
    Accordion,
    AccordionTab,
    ProgressBar,
    highcharts: Chart,
  },
  data: () => ({
    isSmartStation: false,
    station: {} as WasteStations,
    showSidebar: true,
    showMap: false,
    zoom: 15,
    trashcanCharts: [] as Array<WasteChart | undefined>,
    stationTrashcans: [] as TrashCans[],
    mapComponent: markRaw(MapWasteStations),
    trashcanStatistics: [] as WasteChart[],
  }),
  mounted() {
    firebaseInstance?.analytics().logEvent("private_waste_screen", {
      page: "private_waste_screen",
      category: "private_waste_screen",
      category_desc: "Zaslon zasebnih odpadkov odprt",
      ...this.$store.getters["user/analyticsInfo"],
    });

    this.loadStation();
  },
  methods: {
    loadStation() {
      this.stationTrashcans = [] as TrashCans[];
      if ("id" in this.$route.params === false) {
        return;
      }
      this.$http
        .get("api/v1/waste-management/stations/" + this.$route.params.id)
        .then((response) => {
          this.station = response.data;
          this.stationTrashcans = this.station.trashcans;
          this.checkIfStationSmart();
          this.setStationMarker();
        });
    },
    setFavoriteStation(wasteStation: WasteStations) {
      if (wasteStation === null) return;
      if (wasteStation.favourite === true) {
        let params = {
          city_id: 1,
          external_identifier: wasteStation.id,
        };
        this.$http.get("api/v1/locations", { params }).then((response) => {
          if (response.data.length === 0) {
            return;
          }
          let wasteStationId = response.data[0].id;
          this.$http.delete("api/v1/locations/" + wasteStationId).then(() => {
            wasteStation.favourite = false;
          });
        });
        return;
      }

      this.$http
        .post("api/v1/locations", {
          type_id: 4,
          city_id: 1,
          external_identifier: wasteStation.id,
        })
        .then(() => {
          wasteStation.favourite = true;
        });
    },
    checkIfStationSmart() {
      this.isSmartStation = this.station.trashcans.some(
        (trashcan: TrashCans) => trashcan.smart === 1,
      );
    },
    getTrashcanValue(waste_type: string, type: string) {
      let trashcan = this.trashCans.find(
        (trashCan) => trashCan.name === waste_type,
      ) as TrashCans | undefined;

      return trashcan ? trashcan[type as keyof TrashCans] : "";
    },
    setStationMarker() {
      this.marker = {
        id: this.station.id,
        position: {
          lat: parseFloat(this.station.latitude),
          lng: parseFloat(this.station.longitude),
        },
        on_click_route_name: null,
        type: "waste_station",
        title: this.station.title,
      };
    },
    loadGraph(index: number, trashcanId: number) {
      if (
        this.trashcanCharts[index] &&
        this.trashcanCharts[index]?.trashCanId &&
        this.trashcanCharts[index]?.trashCanId === trashcanId
      ) {
        this.trashcanCharts[index] = undefined;
        return;
      }

      this.$http
        .get("api/v1/waste-management/trashcans/" + trashcanId)
        .then((response) => {
          this.configureGraphsData(index, response.data, trashcanId);
        });
    },
    configureGraphsData(
      index: number,
      data: TrashCanStatistics,
      trashCanId: number,
    ) {
      this.trashcanCharts[index] = {
        trashCanId: trashCanId,
        credits: {
          text: "",
          href: "",
        },
        tooltip: {
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return (
              moment.utc(this.x).format("DD-MM-YYYY HH:mm:ss") +
              "<br /><strong> " +
              this.y +
              " %</strong>"
            );
          },
        },
        title: {
          text: "",
        },
        xAxis: {
          type: "datetime",
          tickInterval: 1000 * 60 * 30,
        },
        yAxis: {
          title: {
            text: this.$t("waste.trashcan_capacity"),
          },
        },
        series: [
          {
            showInLegend: false,
            color: this.getTrashcanValue(data.waste_type, "color"),
            data: data.history.map((item) => [
              new Date(item.datetime).getTime(),
              item.waste_level,
            ]),
          },
        ],
      };
    },
  },
  watch: {
    "$route.params.id"() {
      this.loadStation();
      this.trashcanCharts = [];
    },
  },
  computed: {
    smartStationTrashcans() {
      return this.stationTrashcans.filter((trashCan) => trashCan.smart === 1);
    },
    trashCans() {
      return [
        {
          name: "organic",
          icon: "icon-warpit_icon_waste_organic",
        },
        {
          name: "other",
          icon: "icon-warpit_icon_waste_other",
        },
        {
          name: "plastic",
          icon: "icon-warpit_icon_waste_packaging",
        },
        {
          name: "glass",
          icon: "icon-warpit_icon_waste_glass",
        },
        {
          name: "paper",
          icon: "icon-warpit_icon_waste_paper",
        },
        {
          name: "oil",
          icon: "icon-warpit_icon_oil",
        },
        {
          name: "textile",
          icon: "icon-warpit_icon_chlotes",
        },
        {
          name: "batteries",
          icon: "icon-warpit_icon_battery",
        },
        {
          name: "WEEE",
          icon: "icon-warpit_icon_phone_broken",
        },
      ];
    },
    marker(): Marker | null {
      if (
        this.station === null ||
        this.station.latitude === null ||
        this.station.longitude === null
      ) {
        return null;
      }
      return {
        id: this.station.id,
        position: {
          lat: parseFloat(this.station.latitude),
          lng: parseFloat(this.station.longitude),
        },
        on_click_route_name: null,
        type: "waste_station",
        title: this.station.title,
      };
    },
  },
});
