import { PropType } from "vue";

export async function loadStaticContent(
  page: string
): Promise<Record<string, PropType<string | null>>> {
  const flavour = process.env.VUE_APP_FLAVOUR || "default";
  const requireModule = require.context("./", true, /\.md$/, "lazy");

  const keys = requireModule.keys();

  const content: Record<string, PropType<string | null>> = {};
  for (const key in keys) {
    const filename = keys[key];
    if (!filename.includes(flavour + "/" + page)) {
      continue;
    }
    const languageName = filename.replace(/(\.\/(.*)\/|\.md)/g, "");
    content[languageName] = (await requireModule(filename)).default;
  }

  return content;
}
