
import { defineComponent, PropType } from "vue";
import { AqRanges, Sensor } from "@/typings/Sensor";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import Dialog from "primevue/dialog";
import { aqConversion, aqRangeColors, aqRanges } from "@/services/AirQuality";
import moment from "moment";

export default defineComponent({
  data: () => ({
    history: [],
    graphLoaded: false,
    showDialog: false,
    selectedTimePeriod: "week",
    selectedFilter: "pm10" as keyof AqRanges,
    aqRanges: aqRanges,
    colors: [
      aqRangeColors.blue,
      aqRangeColors.green,
      aqRangeColors.yellow,
      aqRangeColors.orange,
      aqRangeColors.red,
      aqRangeColors.purple,
    ],
    levels: ["very_good", "good", "acceptable", "bad", "very_bad", "worse"],
  }),
  props: {
    sensor: {
      type: Object as PropType<Sensor>,
    },
  },
  mounted() {
    this.loadAirQualityHistory();
  },
  methods: {
    loadAirQualityHistory() {
      this.history = [];
      let params = {
        sensor_attribute: String(this.selectedFilter),
        days: this.selectedTimePeriod === "week" ? 7 : 1,
      };

      this.$http
        .get("api/v2/air-quality/" + this.$route.params.id + "/history", {
          params,
        })
        .then((response) => {
          this.history = response.data;
          this.graphLoaded = true;
        });
    },
    generateGradient(parameter: keyof AqRanges) {
      let breakpoints = aqRanges[parameter].slice().reverse();
      let colors = this.colors.slice().reverse();

      let values: [[number, string]?] = [];
      let colorIndex = 0;

      breakpoints.forEach((breakpoint) => {
        let percentage = 1 - breakpoint / breakpoints[0];

        values.push([percentage - 0.02, colors[colorIndex]]);

        if (percentage === 0 || percentage === 1) {
          return;
        }

        colorIndex++;
        values.push([percentage + 0.02, colors[colorIndex]]);
      });

      return values;
    },
  },
  computed: {
    graphOptions() {
      return {
        chart: {
          type: "line",
          height: 400,
        },
        title: {
          text: this.$t(
            "sensors_parameters.sensor_attributes." +
              this.selectedFilter +
              ".label"
          ),
          align: "left",
          style: {
            fontWeight: "bold",
            fontSize: "20px",
            fontFamily: "proxima-nova, sans-serif",
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          useHTML: true,
          itemStyle: {
            fontFamily: "proxima-nova, sans-serif",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
          },
          enabled: true,
          symbolWidth: 0,
          symbolHeight: 0,
          symbolPadding: 0,
          labelFormatter: () => {
            let legend = "";

            for (let i = 0; i < aqRanges[this.selectedFilter].length; i++) {
              let currentZone = 0;
              if (i !== 0) {
                currentZone = aqRanges[this.selectedFilter][i - 1];
              }
              const nextZone = aqRanges[this.selectedFilter][i];
              const valueRange = `${currentZone}-${nextZone}`;
              const legendItem = `<p style='padding-left: 20px'><div style='width: 16px; height: 16px; border-radius: 50%; background-color: ${this.colors[i]}; margin-right: 5px'></div> ${valueRange}</p>`;
              legend += legendItem;
            }

            return `<div style='display: flex; padding-top: 30px'>${legend}</div>`;
          },
        },
        xAxis: {
          type: "datetime",
          labels: {
            formatter: (
              dateObject: Highcharts.AxisLabelsFormatterContextObject
            ) => {
              if (this.selectedTimePeriod === "day") {
                return (
                  moment(dateObject.value).format("HH:mm") + "<br /> &nbsp;"
                );
              }
              let label = moment(dateObject.value).format("ddd <br> DD.MM");
              return label.charAt(0).toUpperCase() + label.slice(1);
            },
          },
        },
        yAxis: {
          title: {
            text: "µg/m3",
          },
          labels: {
            format: "{value}",
          },
          endOnTick: false,
          max: aqRanges[this.selectedFilter][5],
          min: 0,
        },
        series: [
          {
            name: this.selectedFilter,
            data: this.history.map((point) => [
              new Date(point["datetime"]).getTime(),
              Number(
                (
                  point["sensor_attribute_value"] /
                  aqConversion[this.selectedFilter]
                ).toFixed(2)
              ),
            ]),
            color: {
              linearGradient: [0, 0, 0, 216],
              stops: this.generateGradient(this.selectedFilter),
            },
          },
        ],
      };
    },
  },
  watch: {
    sensor: {
      deep: true,
      handler() {
        this.loadAirQualityHistory();
      },
    },
  },
  components: {
    Chart,
    Dialog,
  },
});
