import { RouteRecordRaw } from "vue-router";
import { Component } from "vue";

import DefaultLayout from "@/layout/DefaultLayout.vue";
import Wrapper from "@/layout/Wrapper.vue";

import Home from "@/views/home/Home.vue";
import UserTabs from "@/views/home/UserTabs.vue";
import Feed from "@/views/home/Feed.vue";
import MyCity from "@/views/home/MyCity.vue";

import EventsWrapper from "@/views/events/EventsWrapper.vue";
import Events from "@/views/events/Events.vue";
import Event from "@/views/events/EventsView.vue";
import EventsSubscriptions from "@/views/events/Subscriptions.vue";

import NewsWrapper from "@/views/news/NewsWrapper.vue";
import News from "@/views/news/News.vue";
import NewsView from "@/views/news/NewsView.vue";
import NewsSubscriptions from "@/views/news/Subscriptions.vue";

import Weather from "@/views/weather/Weather.vue";
import AirQuality from "@/views/airQuality/AirQuality.vue";
import WeatherAlerts from "@/views/weather/WeatherAlerts.vue";

import Parking from "@/views/parking/Parking.vue";
import ParkingMapView from "@/views/parking/MapView.vue";
import ParkingListView from "@/views/parking/ListView.vue";
import ParkingListFavourite from "@/views/parking/ListFavourite.vue";
import ParkingListAll from "@/views/parking/ListAll.vue";

import Bikes from "@/views/bikes/Bikes.vue";
import BikesMapView from "@/views/bikes/MapView.vue";
import BikesListView from "@/views/bikes/ListView.vue";
import BikesListFavourite from "@/views/bikes/ListFavourite.vue";
import BikesListAll from "@/views/bikes/ListAll.vue";

import PublicTransport from "@/views/publicTransport/PublicTransport.vue";
import Lines from "@/views/publicTransport/Lines.vue";
import Stations from "@/views/publicTransport/Stations.vue";
import FindPath from "@/views/publicTransport/FindPath.vue";

import SharedMobility from "@/views/sharedMobility/SharedMobility.vue";
import SharedMobilityMapView from "@/views/sharedMobility/MapView.vue";
import SharedMobilityListView from "@/views/sharedMobility/ListView.vue";
import SharedMobilityListAll from "@/views/sharedMobility/ListAll.vue";

import AboutUs from "@/views/pages/AboutUs.vue";
import PrivacyPolicy from "@/views/pages/PrivacyPolicy.vue";
import TermsAndConditions from "@/views/pages/TermsConditions.vue";
import DeclarationConformity from "@/views/pages/DeclarationConformity.vue";
import Search from "@/views/search/Search.vue";

import SurveyView from "@/views/survey/SurveyView.vue";
import SurveyList from "@/views/survey/SurveyList.vue";
import Public from "@/views/survey/Public.vue";
import Active from "@/views/survey/Active.vue";
import Completed from "@/views/survey/Completed.vue";

import Rewards from "@/views/rewards/Rewards.vue";
import RewardsView from "@/views/rewards/RewardsView.vue";

import TrafficInfo from "@/views/TrafficInfo/TrafficInfo.vue";
import TrafficInfoMapView from "@/views/TrafficInfo/MapView.vue";
import TrafficInfoListView from "@/views/TrafficInfo/ListView.vue";
import TrafficInfoListAll from "@/views/TrafficInfo/ListAll.vue";

// user //
import EmptyLayout from "@/layout/EmptyLayout.vue";
import userView from "@/views/user/UserView.vue";
import MyPayments from "@/views/user/myPayments/MyPayments.vue";
import OpenInvoices from "@/views/user/myPayments/OpenInvoices.vue";
import History from "@/views/user/myPayments/History.vue";
import Cards from "@/views/user/myPayments/Cards.vue";
import AddCard from "@/views/user/myPayments/AddCard.vue";
import InvoiceViewPdf from "@/views/user/myPayments/InvoiceViewPdf.vue";
import Login from "@/views/user/Login.vue";
import Register from "@/views/user/register/Register.vue";
import RegisterSuccess from "@/views/user/register/RegisterSuccess.vue";
import ForgottenPassword from "@/views/user/ForgottenPassword.vue";

import InvoicePayment from "@/views/user/myPayments/InvoicePayment.vue";
import InvoicePaymentSuccess from "@/views/user/myPayments/InvoicePaymentSuccess.vue";

import MySuperCity from "@/views/user/MySuperCity.vue";
import MyLocations from "@/views/user/MyLocations.vue";
import MyVehicles from "@/views/user/MyVehicles.vue";
import AddVehicles from "@/views/user/myVehicles/AddVehicles.vue";

import MyPoints from "@/views/user/MyPoints.vue";
import Overview from "@/views/user/myPoints/Overview.vue";
import Transfer from "@/views/user/myPoints/Transfer.vue";

import RelatedAccounts from "@/views/user/relatedAccounts/RelatedAccounts.vue";
import AddAccount from "@/views/user/relatedAccounts/AddAccount.vue";
import MyProfile from "@/views/user/MyProfile.vue";
import MyConsents from "@/views/user/MyConsents.vue";
import EditProfile from "@/views/user/myProfile/EditProfile.vue";
import ChangePassword from "@/views/user/myProfile/ChangePassword.vue";
import EmailVerification from "@/views/user/myProfile/EmailVerification.vue";
import PhoneVerification from "@/views/user/myProfile/PhoneVerification.vue";
import VerificationSuccess from "@/views/user/myProfile/VerificationSuccess.vue";
import DeleteProfile from "@/views/user/myProfile/DeleteProfile.vue";

import MyWaste from "@/views/user/MyWaste.vue";

import TellCity from "@/views/tellCity/TellCity.vue";
import FeedbackDashboard from "@/views/tellCity/FeedbackDashboard.vue";
import FeedbackSubmit from "@/views/tellCity/FeedbackSubmit.vue";
import Feedbacks from "@/views/tellCity/Feedbacks.vue";
import Contact from "@/views/tellCity/Contact.vue";
import FeedbackThankYou from "@/views/tellCity/FeedbackThankYou.vue";

import LoggedIn from "@/routes/middleware/LoggedIn";
import NotLoggedIn from "@/routes/middleware/NotLoggedIn";
import VerificationLevel1 from "@/routes/middleware/VerificationLevel1";
import LoyaltyEnabled from "@/routes/middleware/LoyaltyEnabled";
import eApplicationsEnabled from "./middleware/EApplicationsEnabled";
import PaymentEnabled from "./middleware/PaymentEnabled";

import Support from "@/views/customerSupport/Support.vue";
import Cookies from "@/views/pages/Cookies.vue";

// Application
import MyApplications from "@/views/user/MyApplications.vue";
import NewApplications from "@/views/user/NewApplications.vue";
import ApplicationWrapper from "@/components/eApplication/steps/ApplicationWrapper.vue";
import wasteManagement from "./middleware/WasteManagement";
import VerificationLevel3 from "./middleware/VerificationLevel3";

//Waste management//
import EcoIsland from "@/views/wasteManagement/EcoIsland.vue";
import PrivateWaste from "@/views/user/myWaste/PrivateWaste.vue";
import WasteTakeAway from "@/views/user/myWaste/WasteTakeAway.vue";
import Timeline from "@/views/user/myWaste/Timeline.vue";
import wasteSubscriberCollection from "./middleware/WasteSubscriberCollection";

import Info from "@/views/info/Info.vue";
import InfoMap from "@/views/info/MapView.vue";
import InfoList from "@/views/info/ListView.vue";
import InfoStatic from "@/views/info/ContentView.vue";

import RoutePlanner from "@/views/routePlanner/RoutePlanner.vue";
import QuickView from "@/views/routePlanner/QuickView.vue";
import Routes from "@/views/routePlanner/Routes.vue";
import SingleRoute from "@/views/routePlanner/SingleRoute.vue";
import routePlannerEnabled from "./middleware/RoutePlannerEnabled";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "Home",
        meta: {
          middleware: NotLoggedIn,
          title: "home",
        },
        component: Home,
      },
      {
        path: "/login",
        name: "Login",
        meta: {
          middleware: NotLoggedIn,
          title: "login",
        },
        component: Login,
      },
      {
        path: "/forgot-password",
        name: "ForgottenPassword",
        meta: {
          middleware: NotLoggedIn,
          title: "forgot_password",
        },
        component: ForgottenPassword,
      },
      {
        path: "register",
        name: "Register",
        meta: {
          middleware: NotLoggedIn,
          title: "register",
        },
        component: Register,
      },
      {
        path: "register/success",
        name: "RegisterSuccess",
        meta: {
          middleware: NotLoggedIn,
        },
        component: RegisterSuccess,
      },
      {
        path: "search",
        name: "Search",
        component: Search,
        meta: {
          title: "search",
        },
      },
      {
        path: "home",
        component: UserTabs,
        meta: {
          middleware: LoggedIn,
        },
        children: [
          {
            path: "feed",
            name: "Feed",
            component: Feed,
            meta: {
              title: "feed",
              disableScrollToTop: true,
            },
          },
          {
            path: "my-city",
            name: "MyCity",
            component: MyCity,
            meta: {
              title: "my_city",
              disableScrollToTop: true,
            },
          },
          {
            path: "",
            redirect: { name: "Feed" },
          },
        ],
      },
      {
        path: "events",
        component: EventsWrapper,
        meta: {
          title: "events",
        },
        children: [
          {
            path: "",
            name: "Events",
            component: Events,
          },
          {
            path: ":id",
            name: "Event",
            component: Event,
          },
          {
            path: "subscriptions",
            name: "EventsSubscriptions",
            component: EventsSubscriptions,
          },
        ],
      },
      {
        path: "news",
        component: NewsWrapper,
        meta: {
          title: "news",
        },
        children: [
          {
            path: "",
            name: "News",
            component: News,
          },
          {
            path: ":id",
            name: "Article",
            component: NewsView,
          },
          {
            path: "subscriptions",
            name: "NewsSubscriptions",
            component: NewsSubscriptions,
          },
        ],
      },
      {
        path: "rewards",
        component: Wrapper,
        meta: {
          middleware: LoyaltyEnabled,
        },
        children: [
          {
            path: "",
            name: "Rewards",
            component: Rewards,
          },
          {
            path: ":id",
            name: "Reward",
            component: RewardsView,
          },
        ],
      },
      {
        path: "/weather",
        component: Wrapper,
        meta: {
          title: "weather",
        },
        children: [
          {
            path: "",
            name: "Weather",
            component: Weather,
          },
          {
            path: "alerts",
            name: "WeatherAlerts",
            component: WeatherAlerts,
          },
        ],
      },
      {
        path: "/air-quality",
        component: Wrapper,
        meta: {
          title: "air_quality",
        },
        children: [
          {
            path: ":id",
            name: "AirQualityById",
            component: AirQuality,
          },
          {
            path: "",
            name: "AirQuality",
            component: AirQuality,
          },
        ],
      },
      {
        path: "/tell-city",
        name: "TellCity",
        meta: {
          middleware: [LoggedIn, VerificationLevel1],
          title: "tell_city",
        },
        component: TellCity,
        children: [
          {
            path: "feedback-dashboard",
            component: Wrapper,
            meta: {
              middleware: [LoggedIn, VerificationLevel1],
            },
            children: [
              {
                path: ":id",
                name: "FeedbackThread",
                component: FeedbackDashboard,
                meta: {
                  middleware: [LoggedIn, VerificationLevel1],
                },
              },
              {
                path: "",
                name: "FeedbackDashboard",
                component: FeedbackDashboard,
                meta: {
                  middleware: [LoggedIn, VerificationLevel1],
                },
              },
            ],
          },
          {
            path: "feedbacks",
            name: "Feedbacks",
            component: Feedbacks,
            meta: {
              title: "feedback",
            },
          },
          {
            path: "contact",
            name: "Contact",
            component: Contact,
          },
          {
            path: "feedback-submit",
            name: "FeedbackSubmit",
            component: FeedbackSubmit,
          },
          {
            path: "feedback-thankyou",
            name: "FeedbackThankYou",
            component: FeedbackThankYou,
          },
        ],
      },
      {
        path: "/parking",
        meta: {
          title: "parking",
        },
        children: [
          {
            path: "",
            name: "Parking",
            component: Parking,
            children: [
              {
                path: "map",
                name: "ParkingMap",
                component: ParkingMapView,
              },
              {
                path: "list",
                component: ParkingListView,
                children: [
                  {
                    path: "",
                    name: "ParkingList",
                    component: ParkingListAll,
                  },
                  {
                    path: "favourite",
                    name: "ParkingFavourite",
                    component: ParkingListFavourite,
                  },
                ],
              },
              {
                path: ":id",
                name: "ParkingById",
                component: ParkingMapView,
              },
            ],
          },
        ],
      },
      {
        path: "/bikes",
        meta: {
          title: "bikes",
        },
        children: [
          {
            path: "",
            name: "Bikes",
            component: Bikes,
            children: [
              {
                path: "map",
                name: "BikesMap",
                component: BikesMapView,
              },
              {
                path: "list",
                component: BikesListView,
                children: [
                  {
                    path: "",
                    name: "BikesList",
                    component: BikesListAll,
                  },
                  {
                    path: "favourite",
                    name: "BikesFavourite",
                    component: BikesListFavourite,
                  },
                ],
              },
              {
                path: ":id",
                name: "BikesById",
                component: BikesMapView,
              },
            ],
          },
        ],
      },
      {
        path: "/info",
        meta: {
          title: "info",
        },
        children: [
          {
            path: "",
            name: "info",
            component: Info,
            children: [
              {
                path: ":feature",
                name: "InfoStatic",
                component: InfoStatic,
              },
              {
                path: ":feature/map",
                name: "InfoMap",
                component: InfoMap,
              },
              {
                path: ":feature/list",
                name: "InfoList",
                component: InfoList,
              },
              {
                path: ":feature/:id",
                name: "InfoById",
                component: InfoMap,
              },
            ],
          },
        ],
      },
      {
        path: "/shared-mobility",
        meta: {
          title: "shared_mobility",
        },
        children: [
          {
            path: "",
            name: "SharedMobility",
            component: SharedMobility,
            children: [
              {
                path: "map",
                name: "SharedMobilityMap",
                component: SharedMobilityMapView,
              },
              {
                path: "list",
                name: "SharedMobilityList",
                component: SharedMobilityListAll,
              },
              {
                path: ":id",
                name: "SharedMobilityById",
                component: SharedMobilityMapView,
              },
            ],
          },
        ],
      },
      {
        path: "/traffic-info",
        meta: {
          title: "traffic_info",
        },
        children: [
          {
            path: "",
            name: "TrafficInfo",
            component: TrafficInfo,
            children: [
              {
                path: "map",
                name: "TrafficInfoMap",
                component: TrafficInfoMapView,
              },
              {
                path: "list",
                component: TrafficInfoListView,
                children: [
                  {
                    path: "",
                    name: "TrafficInfoList",
                    component: TrafficInfoListAll,
                  },
                ],
              },
              {
                path: ":id",
                name: "TrafficInfoById",
                component: TrafficInfoMapView,
              },
            ],
          },
        ],
      },
      {
        path: "/public-transport",
        name: "PublicTransport",
        meta: {
          title: "public_transport",
        },
        component: PublicTransport,

        children: [
          {
            path: "find-path",
            name: "FindPath",
            component: FindPath,
            meta: {
              title: "find_path_traffic",
            },
          },
          {
            path: "stations",
            component: Wrapper,
            meta: {
              title: "stations",
            },
            children: [
              {
                path: ":id",
                name: "StationById",
                component: Stations,
              },
              {
                path: "",
                name: "Station",
                component: Stations,
              },
            ],
          },
          {
            path: "lines",
            component: Wrapper,
            meta: {
              title: "lines",
            },
            children: [
              {
                path: ":id",
                name: "LinesById",
                component: Lines,
              },
              {
                path: "",
                name: "Lines",
                component: Lines,
              },
            ],
          },
        ],
      },
      {
        path: "eco-island",
        component: Wrapper,
        meta: {
          title: "ecoIsland",
          middleware: wasteManagement,
        },
        children: [
          {
            path: ":id",
            name: "EcoIslandById",
            component: EcoIsland,
            meta: {
              middleware: wasteManagement,
            },
          },
          {
            path: "",
            name: "EcoIsland",
            component: EcoIsland,
            meta: {
              middleware: wasteManagement,
            },
          },
        ],
      },
      {
        path: "/about-us",
        name: "AboutUs",
        meta: {
          title: "about_us",
        },
        component: AboutUs,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        meta: {
          title: "privacy_policy",
        },
        component: PrivacyPolicy,
      },
      {
        path: "/terms-and-conditions",
        name: "TermsAndConditions",
        meta: {
          title: "terms_and_conditions",
        },
        component: TermsAndConditions,
      },
      {
        path: "/declaration-conformity",
        name: "DeclarationConformity",
        meta: {
          title: "declaration_conformity",
        },
        component: DeclarationConformity,
      },
      {
        path: "/survey",
        name: "Survey",
        meta: {
          title: "survey",
        },
        component: Public,
      },
      {
        path: "/user",
        name: "User",
        component: userView,
        meta: {
          middleware: LoggedIn,
          title: "profile",
          tempData: {},
        },
        children: [
          {
            path: "my-payments",
            name: "MyPayments",
            meta: {
              middleware: [PaymentEnabled, LoggedIn],
              title: "my_payment",
            },
            component: MyPayments,
            children: [
              {
                path: "open",
                name: "PaymentOpen",
                component: OpenInvoices,
                meta: {
                  middleware: [PaymentEnabled, LoggedIn],
                },
              },
              {
                path: "history",
                name: "PaymentHistory",
                component: History,
                meta: {
                  middleware: [PaymentEnabled, LoggedIn],
                },
              },
              {
                path: "cards",
                name: "PaymentCards",
                component: Cards,
                meta: {
                  middleware: [PaymentEnabled, LoggedIn],
                },
              },
            ],
          },
          {
            path: "my-supercity",
            name: "MySuperCity",
            meta: {
              title: "my_super_city",
              middleware: LoggedIn,
            },
            component: MySuperCity,
          },
          {
            path: "my-locations",
            name: "MyLocations",
            meta: {
              title: "my_locations",
              middleware: LoggedIn,
            },
            component: MyLocations,
          },
          {
            path: "my-points",
            name: "MyPoints",
            meta: {
              title: "my_points",
              middleware: [LoyaltyEnabled, LoggedIn],
            },
            component: MyPoints,
            children: [
              {
                path: "overview",
                name: "MyPointsOverview",
                meta: {
                  title: "my_points_overview",
                  middleware: [LoyaltyEnabled, LoggedIn],
                },
                component: Overview,
              },
              {
                path: "transfer",
                name: "MyPointsTransfer",
                meta: {
                  title: "my_points_transfer",
                  middleware: [LoyaltyEnabled, LoggedIn],
                },
                component: Transfer,
              },
            ],
          },
          {
            path: "my-vehicles",
            name: "MyVehicles",
            meta: {
              title: "my_vehicles",
              middleware: LoggedIn,
            },
            component: MyVehicles,
          },
          {
            path: "my-waste",
            name: "MyWaste",
            meta: {
              title: "my_waste",
              middleware: LoggedIn,
            },
            component: MyWaste,
          },
          {
            path: "my-private-waste",
            name: "PrivateWaste",
            meta: {
              title: "privateWaste",
              middleware: [wasteManagement, LoggedIn],
            },
            component: PrivateWaste,

            children: [
              {
                path: "timeline",
                name: "PrivateWasteTimeline",
                component: Timeline,
                meta: {
                  title: "privateWaste_timeline",
                  middleware: [wasteSubscriberCollection, LoggedIn],
                },
              },
              {
                path: "takeaway",
                name: "PrivateWasteTakeaway",
                component: WasteTakeAway,
                meta: {
                  title: "privateWaste_takeaway",
                  middleware: [wasteManagement, LoggedIn],
                },
              },
            ],
          },
          {
            path: "related-accounts",
            name: "RelatedAccounts",
            meta: {
              middleware: [PaymentEnabled, LoggedIn],
              title: "related_accounts",
            },
            component: RelatedAccounts,
          },
          {
            path: "my-profile",
            name: "MyProfile",
            meta: {
              title: "my_profile",
              middleware: LoggedIn,
            },
            component: MyProfile,
          },
          {
            path: "my-consents",
            name: "MyConsents",
            meta: {
              title: "my_consents",
              middleware: LoggedIn,
            },
            component: MyConsents,
          },
          {
            path: "my-applications",
            name: "MyApplications",
            component: MyApplications,
            meta: {
              middleware: [eApplicationsEnabled, LoggedIn],
            },
          },
          {
            path: "new-applications",
            name: "NewApplications",
            component: NewApplications,
            meta: {
              middleware: [eApplicationsEnabled, LoggedIn, VerificationLevel3],
            },
          },
          {
            path: ":id/new-application",
            name: "NewApplication",
            component: ApplicationWrapper,
            meta: {
              middleware: [eApplicationsEnabled, LoggedIn, VerificationLevel3],
            },
          },
        ],
      },
      {
        path: "/survey",
        component: Wrapper,
        children: [
          {
            path: ":id",
            name: "Survey",
            component: SurveyView,
            meta: {
              title: "survey_id",
            },
          },
          {
            path: "public",
            name: "Public",
            component: Public,
            meta: {
              title: "public_survey",
            },
          },
          {
            path: "list",
            name: "List",
            component: SurveyList,
            meta: {
              middleware: LoggedIn,
            },
            children: [
              {
                path: "active",
                name: "Active",
                component: Active,
                meta: {
                  title: "active_surveys",
                  middleware: LoggedIn,
                },
              },
              {
                path: "completed",
                name: "Completed",
                component: Completed,
                meta: {
                  title: "completed_surveys",
                  middleware: LoggedIn,
                },
              },
            ],
          },
        ],
      },
      {
        path: "/support",
        name: "Support",
        component: Support,
        meta: {
          title: "support",
        },
      },
      {
        path: "/cookies",
        name: "Cookies",
        component: Cookies,
        meta: {
          title: "cookies",
        },
      },
      {
        path: "/route-planner",
        meta: {
          title: "route_planner",
          middleware: routePlannerEnabled,
        },
        children: [
          {
            path: "",
            component: RoutePlanner,
            children: [
              {
                path: "",
                component: QuickView,
                name: "RoutePlanner",
              },
              {
                path: "routes",
                name: "RoutesPlannerView",
                component: Routes,
              },
            ],
          },
          {
            path: "my-route",
            name: "SingleRoutePlannerView",
            component: SingleRoute,
          },
        ],
      },
    ],
  },

  {
    path: "/user",
    component: EmptyLayout,
    meta: {
      middleware: LoggedIn,
    },
    children: [
      {
        path: "my-payments",
        component: Wrapper,
        children: [
          {
            path: "invoices/:id/pdf",
            name: "PaymentInvoicePdf",
            component: InvoiceViewPdf,
            meta: {
              middleware: LoggedIn,
            },
          },
          {
            path: "invoices/:id/payment",
            name: "InvoicePayment",
            component: InvoicePayment,
            meta: {
              middleware: LoggedIn,
            },
          },
          {
            path: "invoices/payment/success",
            name: "InvoicePaymentSuccess",
            component: InvoicePaymentSuccess,
            meta: {
              middleware: LoggedIn,
            },
          },
          {
            path: "cards/new",
            name: "PaymentAddCard",
            component: AddCard,
            meta: {
              middleware: LoggedIn,
            },
          },
        ],
      },
      {
        path: "related-account/",
        component: Wrapper,
        children: [
          {
            path: "add",
            name: "RelatedAccountAdd",
            component: AddAccount,
            meta: {
              title: "related_account",
            },
          },
        ],
      },
      {
        path: "my-vehicles",
        component: Wrapper,
        children: [
          {
            path: "add",
            name: "AddVehicles",
            component: AddVehicles,
            meta: {
              title: "add_vehicles",
            },
          },
        ],
      },

      {
        path: "my-profile",
        component: Wrapper,
        children: [
          {
            path: "edit",
            name: "EditProfile",
            component: EditProfile,
            meta: {
              title: "edit_profile",
            },
          },
          {
            path: "change-password",
            name: "ChangePassword",
            meta: {
              title: "change_password",
            },
            component: ChangePassword,
          },
          {
            path: "delete",
            name: "DeleteProfile",
            component: DeleteProfile,
            meta: {
              title: "delete profile",
            },
          },
          {
            path: "email-verification",
            name: "EmailVerification",
            meta: {
              title: "email_verification",
            },
            component: EmailVerification,
          },
          {
            path: "phone-verification",
            name: "PhoneVerification",
            meta: {
              title: "phone_verification",
            },
            component: PhoneVerification,
          },
          {
            path: "verification/success",
            name: "VerificationSuccess",
            component: VerificationSuccess,
          },
        ],
      },
    ],
  },
];

declare module "vue-router" {
  interface RouteMeta {
    // is optional
    title?: string;
    sidebar?: Component;
    isLeftSidebar?: boolean;
  }
}

export default routes;
