
import { defineComponent } from "vue";
import { Message } from "@/typings/Message";

export default defineComponent({
  props: {
    message: Object as () => Message,
  },
  methods: {
    getUserImage() {
      if (this.user.id === this.message?.user_id) {
        return this.$store.getters["user/userImage"];
      }
      return require("@/assets/images/userIcon2.svg");
    },
    openGoogleMaps(address: string | null) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${address}`,
        "_blank",
      );
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
});
