
import { Event } from "@/typings/Event";
import { PropType, defineComponent } from "vue";
import PlaceholderImage from "@/components/PlaceholderImage.vue";

export default defineComponent({
  props: {
    payload: {
      type: Object as PropType<Array<Event>>,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: { PlaceholderImage },
});
