
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomButton.vue",

  props: {
    label: { type: String, required: true },
    addClass: { type: String, required: false },
  },
});
