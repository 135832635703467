import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/takeOffCodeWhere.png'


const _hoisted_1 = { class: "px-4 pt-2" }
const _hoisted_2 = { class: "flex flex-column gap-3 pl-4 text-base" }
const _hoisted_3 = { class: "wasteForm mt-6" }
const _hoisted_4 = { class: "mb-6" }
const _hoisted_5 = { class: "flex gap-2 align-items-center" }
const _hoisted_6 = { class: "text-base font-bold" }
const _hoisted_7 = { class: "error-label" }
const _hoisted_8 = { class: "mb-6" }
const _hoisted_9 = { class: "text-base font-bold" }
const _hoisted_10 = { style: {"color":"red"} }
const _hoisted_11 = { class: "text-base" }
const _hoisted_12 = { class: "text-base font-bold" }
const _hoisted_13 = { class: "flex flex-column mt-2" }
const _hoisted_14 = { class: "p-float-label error-label" }
const _hoisted_15 = { for: "client_name" }
const _hoisted_16 = { class: "p-float-label error-label" }
const _hoisted_17 = { for: "address" }
const _hoisted_18 = { class: "p-float-label error-label" }
const _hoisted_19 = { for: "personal_id_number" }
const _hoisted_20 = { class: "p-float-label error-label" }
const _hoisted_21 = { for: "telNumber" }
const _hoisted_22 = { class: "p-float-label error-label" }
const _hoisted_23 = { for: "email" }
const _hoisted_24 = { class: "mb-4 mt-6" }
const _hoisted_25 = { class: "text-base font-bold" }
const _hoisted_26 = { class: "error-label" }
const _hoisted_27 = { class: "flex justify-content-end mt-6" }
const _hoisted_28 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_TakeawayType = _resolveComponent("TakeawayType")!
  const _component_GMapAutocomplete = _resolveComponent("GMapAutocomplete")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("waste_take_away.note_3")), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("waste_take_away.note_4")), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("waste_take_away.note_5")), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("waste_take_away.note_6")), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("waste_take_away.client_number_title")), 1),
            _createElementVNode("i", {
              class: "icon-warpit_icon_info text-primary cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInfoDialogCall()))
            })
          ]),
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.form.client_number,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.client_number) = $event)),
              name: "client_number",
              placeholder: _ctx.$t('waste_take_away.client_number')
            }, null, 8, ["modelValue", "placeholder"]),
            _createElementVNode("p", null, _toDisplayString(_ctx.errors.client_number), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("waste_take_away.waste_quantity")), 1),
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.errors.waste_type_quantity), 1),
          _createVNode(_component_TakeawayType, {
            class: _normalizeClass({ 'bg-gray-200': _ctx.lastModifiedType === 'furniture' }),
            description: "waste_take_away_types.furniture",
            value: _ctx.form.waste_type_quantity.furniture,
            onUpdate: _cache[2] || (_cache[2] = (value) => _ctx.updateTypeValue('furniture', value))
          }, null, 8, ["class", "value"]),
          _createVNode(_component_TakeawayType, {
            class: _normalizeClass({ 'bg-gray-200': _ctx.lastModifiedType === 'electronic' }),
            description: "waste_take_away_types.electronic",
            value: _ctx.form.waste_type_quantity.electronic,
            onUpdate: _cache[3] || (_cache[3] = (value) => _ctx.updateTypeValue('electronic', value))
          }, null, 8, ["class", "value"]),
          _createVNode(_component_TakeawayType, {
            class: _normalizeClass({ 'bg-gray-200': _ctx.lastModifiedType === 'mattresses' }),
            description: "waste_take_away_types.mattresses",
            value: _ctx.form.waste_type_quantity.mattresses,
            onUpdate: _cache[4] || (_cache[4] = (value) => _ctx.updateTypeValue('mattresses', value))
          }, null, 8, ["class", "value"]),
          _createVNode(_component_TakeawayType, {
            class: _normalizeClass({ 'bg-gray-200': _ctx.lastModifiedType === 'carpets' }),
            description: "waste_take_away_types.carpets",
            value: _ctx.form.waste_type_quantity.carpets,
            onUpdate: _cache[5] || (_cache[5] = (value) => _ctx.updateTypeValue('carpets', value))
          }, null, 8, ["class", "value"]),
          _createVNode(_component_TakeawayType, {
            class: _normalizeClass({ 'bg-gray-200': _ctx.lastModifiedType === 'sports_equipment' }),
            description: "waste_take_away_types.sports_equipment",
            value: _ctx.form.waste_type_quantity.sports_equipment,
            onUpdate: _cache[6] || (_cache[6] = (value) => _ctx.updateTypeValue('sports_equipment', value))
          }, null, 8, ["class", "value"]),
          _createVNode(_component_TakeawayType, {
            class: _normalizeClass({ 'bg-gray-200': _ctx.lastModifiedType === 'garden_machinery' }),
            description: "waste_take_away_types.garden_machinery",
            value: _ctx.form.waste_type_quantity.garden_machinery,
            onUpdate: _cache[7] || (_cache[7] = (value) => _ctx.updateTypeValue('garden_machinery', value))
          }, null, 8, ["class", "value"]),
          _createVNode(_component_TakeawayType, {
            class: _normalizeClass({
            'bg-gray-200': _ctx.lastModifiedType === 'stoves_cookers_radiators',
          }),
            description: "waste_take_away_types.stoves_cookers_radiators",
            value: _ctx.form.waste_type_quantity.stoves_cookers_radiators,
            onUpdate: _cache[8] || (_cache[8] = (value) => _ctx.updateTypeValue('stoves_cookers_radiators', value))
          }, null, 8, ["class", "value"]),
          _createVNode(_component_TakeawayType, {
            class: _normalizeClass({ 'bg-gray-200': _ctx.lastModifiedType === 'iron' }),
            description: "waste_take_away_types.iron",
            value: _ctx.form.waste_type_quantity.iron,
            onUpdate: _cache[9] || (_cache[9] = (value) => _ctx.updateTypeValue('iron', value))
          }, null, 8, ["class", "value"]),
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("waste_take_away.note_7")), 1)
        ]),
        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("waste_take_away.personal_data")), 1),
        _createElementVNode("ul", _hoisted_13, [
          _createElementVNode("li", null, [
            _createElementVNode("span", _hoisted_14, [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.form.client_name,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.client_name) = $event)),
                name: "client_name"
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("waste_take_away.client_name")) + "*", 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.errors.client_name), 1)
            ])
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("span", _hoisted_16, [
              _createVNode(_component_GMapAutocomplete, {
                class: _normalizeClass({
                'p-inputtext p-component': !_ctx.form.address,
                'p-inputtext p-component p-filled': _ctx.form.address,
              }),
                onPlace_changed: _ctx.setAddress,
                options: {
                componentRestrictions: { country: 'si' },
              },
                onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.form.address = $event.target.value)),
                value: _ctx.form.address,
                placeholder: "",
                id: "address",
                maxlength: "255"
              }, null, 8, ["class", "onPlace_changed", "value"]),
              _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("waste_take_away.address")) + "*", 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.errors.address), 1)
            ])
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("span", _hoisted_18, [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.form.personal_id_number,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.personal_id_number) = $event)),
                name: "emso"
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("waste_take_away.personal_id_number")), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.errors.personal_id_number), 1)
            ])
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("span", _hoisted_20, [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.form.phone_number,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.phone_number) = $event)),
                name: "phone_number",
                onFocusout: _ctx.phoneNumberFocusOut
              }, null, 8, ["modelValue", "onFocusout"]),
              _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("waste_take_away.phone")) + "*", 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.errors.phone_number), 1)
            ])
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("span", _hoisted_22, [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.form.email,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.email) = $event)),
                name: "email"
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("waste_take_away.mail")), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.errors.email), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("waste_take_away.notes_title")), 1),
          _createElementVNode("span", _hoisted_26, [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.form.notes,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.notes) = $event)),
              name: "notes",
              placeholder: _ctx.$t('waste_take_away.notes')
            }, null, 8, ["modelValue", "placeholder"]),
            _createElementVNode("p", null, _toDisplayString(_ctx.errors.notes), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createVNode(_component_Button, {
          class: "defaultButton sm:w-10rem",
          label: _ctx.$t('global.continue'),
          onClick: _ctx.validateAndContinue
        }, null, 8, ["label", "onClick"])
      ])
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showInfoDialog,
      "onUpdate:visible": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.showInfoDialog) = $event)),
      class: "confirmationDialog info-dialog"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("waste_take_away.client_number_description")), 1),
        _createElementVNode("img", {
          class: "w-full",
          src: _imports_0,
          alt: _ctx.$t('waste_take_away.client_number_image')
        }, null, 8, _hoisted_28)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}