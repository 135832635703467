import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "news-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewsCard = _resolveComponent("NewsCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news, (article, index) => {
      return (_openBlock(), _createBlock(_component_NewsCard, {
        key: article.id,
        article: article,
        showcase: index === 0,
        class: _normalizeClass(['grid-item', { showcase: index === 0 }]),
        onSave: ($event: any) => (_ctx.$emit('save', article)),
        onUnsave: ($event: any) => (_ctx.$emit('unsave', article))
      }, null, 8, ["article", "showcase", "class", "onSave", "onUnsave"]))
    }), 128))
  ]))
}