
import { defineComponent } from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";

export default defineComponent({
  data: () => ({
    file: null as null | string | ArrayBuffer | undefined,
    selectedFileData: null as null | File,
    showErrorDialog: false,
  }),
  methods: {
    presentFileSize(size: number) {
      let units = ["B", "KB", "MB", "GB", "TB"],
        index = 0;
      while (size / 1000 > 1) {
        size = Math.abs(size) / 1000;
        index++;
      }

      return size.toFixed(2) + " " + units[index];
    },
    triggerUploadFunction() {
      (this.$refs.fileUpload as HTMLInputElement).click();
    },
    uploadFile() {
      let input = this.$refs.fileUpload as HTMLInputElement;
      let file = input?.files;
      if (file && file[0] && file[0].size <= 5 * 1024 ** 2) {
        const reader = new FileReader();
        this.selectedFileData = file[0];
        reader.onload = (e) => {
          this.file = e.target?.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("fileSelected", file[0]);
        return;
      }
      this.showErrorDialog = true;
    },
  },
  components: { Button, Dialog },
});
