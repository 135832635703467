
import { defineComponent } from "vue";
// import Accordion from "primevue/accordion";
// import AccordionTab from "primevue/accordiontab";
import { Card } from "@/typings/Card";

export default defineComponent({
  data: () => ({
    cards: [] as Card[],
  }),
  mounted() {
    this.loadCards();
  },
  methods: {
    loadCards() {
      this.$http.get("/api/v1/credit-cards").then((res) => {
        this.cards = res.data.data;
      });
    },
    deleteCard(card: Card) {
      this.$http.delete("api/v1/credit-cards/" + card.id).then(() => {
        this.cards.splice(
          this.cards.map((card) => card.id).indexOf(card.id),
          1
        );
      });
    },
    getCardIcon(card: Card) {
      switch (card.payment_network) {
        case "visa":
          return "icon-warpit_icon_Visa";
        case "mastercard":
          return "icon-warpit_icon_Master";
        default:
          return "icon-warpit_icon_visa";
      }
    },
  },
  // components: { Accordion, AccordionTab },
});
