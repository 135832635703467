import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "homeTabs",
  tabindex: "-1"
}
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainImage = _resolveComponent("MainImage")!
  const _component_WidgetBar = _resolveComponent("WidgetBar")!
  const _component_GetApp = _resolveComponent("GetApp")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainImage),
    _createVNode(_component_WidgetBar),
    _createVNode(_component_GetApp),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, { to: { name: 'Feed' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("user_tabs.whats_up")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: { name: 'MyCity' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("user_tabs.my_city")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_router_view, { dashboard: _ctx.dashboard }, null, 8, ["dashboard"])
  ], 64))
}