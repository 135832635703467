<template>
  <header class="hidden sm:block">
    <div class="flex justify-content-between mx-auto wp1200 py-4">
      <span class="text-2xl font-bold">
        {{ $t("shared_mobility.title") }}
      </span>
      <button
        v-if="$route.name === 'SharedMobilityMap'"
        @click="$router.push({ name: 'SharedMobilityList' })"
        class="flex align-items-center gap-2 border-none bg-white text-primary font-bold text-lg cursor-pointer"
      >
        <span>{{ $t("shared_mobility.show_list") }}</span>
        <i class="icon-warpit_icon_TE_bullet_list font-bold"></i>
      </button>
      <button
        v-if="$route.name === 'SharedMobilityList'"
        @click="$router.push({ name: 'SharedMobilityMap' })"
        class="flex align-items-center gap-2 border-none bg-white text-primary font-bold text-lg cursor-pointer"
      >
        <span>{{ $t("shared_mobility.show_map") }}</span>
        <i class="icon-warpit_icon_map-import font-bold"></i>
      </button>
    </div>
  </header>

  <main>
    <router-view />
  </main>
</template>
