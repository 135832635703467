<template>
  <span class="input-text-wrapper p-float-label error-label">
    <slot></slot>
    <label :for="label">
      {{ $t(label) }}
    </label>
    <p v-if="error">
      {{ error }}
    </p>
  </span>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: { type: String, default: "" },
    error: { type: String, default: "" },
  },
});
</script>

<style lang="scss">
.input-text-wrapper {
  p {
    font-size: 14px;
  }
}
</style>
